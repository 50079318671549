import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IActivityComponentDataElement } from '@/models/interfaces/activities';

@Component
export default class PositionedComponents extends Vue {
  @Prop({ required: true })
  data!: IActivityComponentDataElement;

  @Prop({ type: String })
  protected type!: string;

  protected position = {
    top: 0,
    left: 0,
  };

  protected sizing = {
    width: 0,
    height: 0,
    fontSize: 0,
  };

  protected containerSizing = {
    width: 0,
    height: 0,
  };

  protected getAssetContainer() {
    // needs to be a method (not computed) to avoid caching
    return document.querySelectorAll('[data-activity-asset]')[0];
  }

  protected get leftPosition() {
    return (this.windowWidth - this.getAssetContainer().clientWidth) / 2;
  }

  protected get itemCords() {
    if (!this.$el) {
      return {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
      };
    }

    const { x, y } = this.$el.getBoundingClientRect();

    return {
      x1: x,
      y1: y,
      x2: x + this.$el.clientWidth,
      y2: y + this.$el.clientHeight,
    };
  }

  protected get itemStyles() {
    type itemStyle = { [x: string]: string };

    return this.data.value
      .split(/;/g)
      .filter(item => !!item)
      .map(item => {
        const propData = item.split(/:/g);

        return {
          [propData[0]]: `${propData[1]}`,
        };
      })
      .reduce((prev: itemStyle, current: itemStyle) => {
        return Object.assign(prev, current);
      });
  }

  protected setItemUIData() {
    const parseStyle = (style: string) =>
      Number(`${style.replace(/%/g, '')}`) / 100;

    const parseStyleFontSize = (size: string, defaultSize: number) =>
      size == '' ? defaultSize : Number(`${size.replace(/px/g, '')}`);

    const {
      width,
      height,
      top,
      left,
      'font-size': fontSize = '',
    } = this.itemStyles;

    this.containerSizing = {
      width: this.getAssetContainer().clientWidth,
      height: this.getAssetContainer().clientHeight,
    };

    this.position = {
      top: this.containerSizing.height * parseStyle(top),
      left: this.containerSizing.width * parseStyle(left) + this.leftPosition,
    };

    this.sizing = {
      width: this.containerSizing.width * parseStyle(width),
      height: this.containerSizing.height * parseStyle(height),
      fontSize: parseStyleFontSize(fontSize, this.containerSizing.width / 40),
    };
  }

  public mounted() {
    this.$nextTick(() => this.setItemUIData());
  }

  @Watch('windowWidth')
  private onResize() {
    this.$nextTick(() => this.setItemUIData());
  }
}
