<template>
  <div class="spinner" role="status" :style="{ borderColor: color }">
    <span class="spinner__text">Loading...</span>
  </div>
</template>

<style lang="scss" scoped>
.spinner {
  display: block;

  width: 2rem;
  height: 2rem;

  border-width: 0.25em;
  border-radius: 50%;
  border-style: solid;
  border-right-color: transparent !important;

  animation: spinner 0.75s linear infinite;

  margin: auto;

  &__text {
    font-size: 0;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
  @Prop({ default: '#1b75bb' })
  public color!: string;
}
</script>

<docs>
### Default

```jsx
<Spinner />
```

### Custom color

```jsx
<Spinner color="#ff0000" />
```
</docs>
