var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"activity-topbar"},[(_vm.showFlagIcon)?_c('BaseButton',{class:[{ 'activity-topbar--disabled': !_vm.isOnline }],attrs:{"disabled":_vm.isActivityTest && _vm.isQuestionAudioPlaying},nativeOn:{"click":function($event){return _vm.selectActivityLang(_vm.userLanguages.supportLanguage)}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: this.$t('tooltip.activities.supportLanguage').toString(),
      }),expression:"{\n        content: this.$t('tooltip.activities.supportLanguage').toString(),\n      }"}],staticClass:"activity-topbar__lang"},[_vm._v(" "+_vm._s(_vm.userLanguages.supportLanguage)+" ")])]):_vm._e(),_c('BaseButton',{class:[{ 'activity-topbar--disabled': !_vm.isOnline }],attrs:{"disabled":_vm.isActivityTest && _vm.isQuestionAudioPlaying},nativeOn:{"click":function($event){return _vm.selectActivityLang(_vm.userLanguages.teachingLanguage)}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: this.$t('tooltip.activities.teachingLanguage').toString(),
      }),expression:"{\n        content: this.$t('tooltip.activities.teachingLanguage').toString(),\n      }"}],staticClass:"activity-topbar__lang"},[_vm._v(" "+_vm._s(_vm.userLanguages.teachingLanguage)+" ")])]),(!_vm.isActivityTest)?[_c('BaseButton',{class:[
        'activity-topbar__media-btn activity-topbar__media-btn--left',
        { 'activity-topbar--disabled': _vm.disableReplay },
      ],attrs:{"type":"secondary","icon":_vm.isReplayPlaying ? 'pause' : 'audio'},nativeOn:{"click":function($event){return _vm.handleRepeat.apply(null, arguments)}}}),_c('BaseButton',{class:[
        'activity-topbar__media-btn activity-topbar__media-btn--right',
        { 'activity-topbar--disabled': _vm.disableReplay },
      ],attrs:{"type":"secondary","icon":_vm.isSlowPlaying ? 'pause' : 'snail'},nativeOn:{"click":function($event){return _vm.handleSlowRepeat.apply(null, arguments)}}})]:_vm._e(),(_vm.showCloseBtn)?_c('BaseButton',{attrs:{"type":"danger","icon":"exit"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }