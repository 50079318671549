export enum ActivityItemTypes {
  Primary = 'primary',
  Danger = 'danger',
}

export enum StarTypes {
  Full = 'full',
  Half = 'half',
  Empty = 'empty',
}
