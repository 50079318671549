<template>
  <section :class="['sliding-drawer', { 'sliding-drawer--opened': value }]">
    <slot />

    <img
      class="sliding-drawer__rounded-side"
      src="/images/training-menu/rounded-modal-side.png"
      alt="Rounded drawer side"
    />

    <button
      type="button"
      :class="['sliding-drawer__btn', `sliding-drawer__btn--${accentColor}`]"
      @click="toggleVisibility"
    >
      <img
        :class="[
          'sliding-drawer__play-icon',
          { 'sliding-drawer__play-icon--closed': !value },
        ]"
        src="/images/training-menu/play.png"
        alt="Open training menu content"
      />
    </button>
  </section>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SlidingDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  public value!: boolean;

  @Prop({ type: String, required: true })
  public accentColor!: string;

  public toggleVisibility() {
    this.$emit('input', !this.value);
  }
}
</script>

<style scoped lang="scss">
$headerHeight: 82px;
$roundedPanelWidth: 80px;

$baseTransition: 0.5s;

.sliding-drawer {
  position: fixed;
  top: $headerHeight;
  right: 0;

  @include d-flex-col;

  padding: 25px 0 0;

  width: 345px;
  height: calc(100% - #{$headerHeight});

  background: $white100;
  transform: translateX(100%);

  z-index: 7;

  transition: $baseTransition;

  &__rounded-side {
    position: absolute;
    left: -54px;
    top: -10px;

    width: 55px;
    height: calc(100% + 18px);

    @media (max-width: #{$mobile}px) {
      display: none;
    }
  }

  @media (max-width: #{$mobile}px) {
    padding: 25px 0 0;
  }

  &--opened {
    transform: unset;
  }

  &__btn {
    width: 80px;
    height: 80px;

    border-radius: 100%;

    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    left: -90px;

    @include d-flex-center;

    &--green {
      background-color: $green400;
      box-shadow: 0px 4px 0px $green400Shadow;
    }

    &--pink {
      background-color: $pink200;
      box-shadow: 0px 4px 0px $pink200Shadow;
    }

    @media (max-width: #{$mobile}px) {
      padding-right: 20px;
      left: -35px;

      width: 60px;
      height: 60px;
      clip-path: inset(0px 25px -5px 0px);
    }
  }

  &__play-icon {
    height: 85px;

    position: relative;

    transition: $baseTransition;

    &--closed {
      transform: rotate(-180deg);
      left: -5px;

      @media (max-width: #{$mobile}px) {
        left: 0;
      }
    }

    @media (max-width: #{$mobile}px) {
      height: 45px;
    }
  }
}
</style>
