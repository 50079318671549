import {
  IActivityComponentDataElement,
  IActivitySolution,
} from '@/models/interfaces/activities';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ActivityComponentWithDraggableGaps extends Vue {
  protected gapsData: Array<IActivitySolution[]> = [];
  protected isDisableDragging = false;

  protected toggleDraggingAbility() {
    this.isDisableDragging = !this.isDisableDragging;
  }

  protected disableOuterDragging(setEvents = true) {
    const draggableValueElements = document.getElementsByClassName(
      'draggable-value',
    );

    Array.from(draggableValueElements).forEach(item => {
      item.removeEventListener('mouseenter', this.toggleDraggingAbility);
      item.removeEventListener('mouseleave', this.toggleDraggingAbility);

      if (setEvents) {
        item.addEventListener('mouseenter', this.toggleDraggingAbility);
        item.addEventListener('mouseleave', this.toggleDraggingAbility);
      }
    });
  }

  private parsedString(string: string, idx: number) {
    const { value: draggableValue } = this.gapsData[idx][0]
      .data as IActivityComponentDataElement;

    const sanitizedValue = draggableValue.replace(/<\/?[a-z][\s\S]*>/gm, '');

    return string.replace(
      /[_]+/g,
      `<span class="draggable-value">${sanitizedValue}</span>`,
    );
  }

  public beforeDestroy() {
    this.disableOuterDragging(false);
  }
}
