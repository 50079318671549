var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.positioningData)?_c('div',{class:_vm.classNames,style:({
    top: `${_vm.positioningData.top}px`,
    left: `${_vm.positioningData.left}px`,
    width: `${_vm.positioningData.width}px`,
    height: `${_vm.getHeight}px`,
    transform: _vm.positioningData.transform,
    position: _vm.positioningData.position,
  })},[(_vm.type.includes('Wrappers'))?_vm._l((_vm.data),function(hotspot){return _c('DraggableItemWrapper',{key:hotspot.id,attrs:{"data":hotspot,"is-depend-on-controls":_vm.positioningData.controls,"meta-styles":_vm.meta.style}})}):(_vm.type.includes('draggable'))?_vm._l((_vm.data),function(hotspot){return _c('DraggableItem',{key:hotspot.id,attrs:{"data":hotspot,"type":_vm.type,"is-depend-on-controls":_vm.positioningData.controls,"meta-styles":_vm.meta.style}})}):_vm._l((_vm.computedData),function(hotspot){return _c('DefaultItem',{key:hotspot.value,attrs:{"data":hotspot,"type":_vm.type,"meta-styles":_vm.meta.style,"is-depend-on-controls":_vm.positioningData.controls},on:{"click":_vm.handleHotspotClick}})})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }