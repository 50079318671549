import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: '请先选择理由',
    title: '社区指南',
    popup: {
      title: '感谢您帮助维护我们社区的安全！',
      btn: '返回社区准则',
    },
    pt1: {
      subtitle: '什么是 DigiPals 社区',
      text: '我们的 DigiPals 社区是您结交朋友和练习英语的安全场所。',
    },
    pt2: {
      subtitle: '社区规则是什么？',
      text1: '总是用英文写',
      text2: '不要写任何不友好、冒犯或不友好的东西。不允许骂人！',
      text3:
        '不要分享任何个人信息，例如您的全名、地址、电话号码、电子邮件地址或其他在线/社交媒体帐户详细信息',
      text4: '所有消息均由真人审核。',
    },
    pt3: {
      subtitle: '如果我认为有人违反了规则，我该怎么办？',
      text1: '发送此报告有助于我们让社区中的每个人都开心。',
      text2: '我们将联系您的大人以了解更多信息并帮助您',
    },
    btn: '发送报告',

    option1: '我认为有人不是用英语写作',
    option2: '我觉得有人不友善',
    option3: '我认为有人分享了个人信息',
  },

  globe: {
    _: '地球',
    friendsMultiple: '您有 {friends} 個朋友',
    friends: '您有 1 個朋友',
    countries: '在 1 個國家',
    countriesMultiple: '在 {countries} 個國家',
    defaultTitle: '我們的全球 DigiPal 社區！',
    fetchFriend: '現在找一個新朋友！',
  },

  profile: {
    avatar: {
      title: '做我的头像',
    },
    error: '哎呀！出问题了。你能再试一次吗？',
    messagesEmpty:
      '哎呀！这里还没有消息。转到您推荐的 DigiPals 列表，访问他们的个人资料并开始对话！',
    postTitle: '来自我的 DigiPals 的帖子',
    writePost: '写一个 DigiPost',
    profileError: '无法获取个人资料消息。',
    clock: '{username} \n 住的地方几点钟了？',
    country: '{username}\n居住在\n{country}',
    myCountry: '我住在{country}',
    myClock: '我住的地方几点钟了？',
    friendsAccepted: '你已经是朋友！',
    friendsAdd: '发送digipal请求',
    friendsPending: 'digipal请求未决',
  },

  dictionary: {
    alphabet: {
      error: '无法获取字母表',
    },
    words: {
      error: '无法获取单词',
    },
  },

  dates: {
    yearsAgo: '{count} 几年前',
    yearAgo: '一年前',
    monthsAgo: '{count} 几个月前',
    monthAgo: '一个月前',
    daysAgo: '{count} 几天前',
    dayAgo: '一天前',
    hoursAgo: '{count} 几小时前',
    hourAge: '一小时前',
    minutesAgo: '{count} 几分钟前',
    minuteAgo: '一分钟前',
    seconds: '几秒钟前',
  },

  common: {
    someone: '有人',
  },

  digiPal: {
    messagesEmpty: '这里没有消息！写第一个！',
  },

  interests: {
    title: '选择我喜欢的',
  },

  achievements: {
    title: '查看我的成就',
    messageTrophy:
      '通过向您的 DigiPals 发送消息来获取奖杯！只有收到回复的消息才会计入您的奖杯。',
    activityTrophy: '通过完成活动获得奖杯！您赢得的每颗星星都将计入您的奖杯。',
  },

  settings: {
    title: {
      1: '学习英国英语还是美国英语？',
      2: '得到“帮助”？',
    },
  },

  digipals: {
    genericErrorMessage: '哎呀！出问题了。你能再试一次吗？',

    all: {
      title: '我的数码伙伴',
      emptyErrorMessage:
        '您还没有连接的朋友。转到“推荐的 DigiPals”开始结交朋友！',
      successRequest:
        '您已向 {friend} 发送了好友请求。在他们的个人资料上留言，告诉他们为什么你想成为朋友！',
      clock:
        '{digipal} 住的地方现在几点了？请记住，某些 DigiPals 可能在您白天睡觉时睡着了！',
    },
    mutual: {
      title: '相互数字伙伴',
      emptyErrorMessage: '你们没有共同的朋友。',
    },
    pending: {
      title: 'DigiPal 请求',
      emptyErrorMessage:
        '您还没有待处理的请求。转到“推荐的 DigiPals”开始结交朋友！',
      successAccept: '你现在是{friend}的朋友。访问他们的个人资料打个招呼！',
    },
    search: {
      title: '搜索 DigiPal',
    },
    suggested: {
      title: '现在在线的留言数字',
      emptyErrorMessage: '您沒有在線好友',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        '你好！我是多米诺。我会帮你结交世界各地的朋友！如果您需要任何帮助，可以在工具栏中找到我。这是您的 DigiPal 个人资料页面。它告诉每个人关于你的一些事情。开始探索！',
      post: {
        reply: '{username} 已回复您的消息！继续对话 😁',
        wallMessage: '{username} 在您的个人资料上留言！继续对话 😁',
      },
      dominoAdd: '恭喜！你有连胜！现在 Domino 将出现在您的个人资料中！',
      dominoRem:
        '不好了！你失去了连胜纪录。赚取 20 行话，让 Domino 重新出现在您的个人资料中 😁',
    },
    messageDigipal: '信息 {username}',
    messageFrom: '来自的消息\n{userName}',
    friendRequest: '好友请求\n{userName}',
    requestAccepted: '已接受请求\n{userName}',
    likeDash: '{userName}\n喜欢你的页面',
  },

  popup: {
    activityLeaveConfirmation: '您确定要离开活动吗？您的进度将不会被保存。',
    testLeaveConfirmation: '您确定要退出测试吗？您将无法再次重做。',
    streakPrefix: '{streak} 日连胜。',
    streak0:
      '每天赚取 20 Lingos 开始您的连胜！要赚取 Lingos，请向您的朋友发送消息并完成活动！',
    premiumGamesErrorMessage:
      '哎呀！您无权访问游戏。向您的父母询问升级您的帐户！',
    lingosErrorMessage:
      '您需要 Lingos 才能购买一些物品。发送消息以赢取 Lingos。',
    offlineErrorMessage: '哎呀！互联网连接不工作。',
    genericErrorMessage: '哎呀！出问题了。你能再试一次吗？',
    newsfeedErrorMessage: '哎呀！您的消息未发送。你能再试一次吗？',
    avatarCustomizationMenu:
      '自定义您的头像！您需要 Lingos 才能购买一些物品。完成活动或发送消息以赢取 Lingos。',
    wallpaperSettings:
      '自定义您的个人资料壁纸！您需要 Lingos 才能购买一些物品。完成活动或发送消息以赢取 Lingos。',
    interestsSettings:
      '展示您的爱好和喜好！您需要 Lingos 才能购买一些物品。完成活动或发送消息以赢取 Lingos。',
    achievementsMenu: '看看你得了多少奖！',
    globe: `在全球寻找新的 DigiPals。访问他们的个人资料，给他们留言以赢取 Lingos。使用 ${htmlFeatures.plusIcon} 邀请他们成为您的 DigiPal。`,
    friendsSuggested:
      '与其他 DigiPals 交朋友！你推荐了一些 DigiPals，只为你！邀请他们与您联系。',
    friends:
      '查看您连接的 DigiPals。访问他们的个人资料，给他们留言并赢取 Lingos。',
    friendsPending:
      '查看想要与您联系的 DigiPals。接受他们的邀请并转到他们的个人资料以留言以赢取 Lingos。',
    friendsSearch: `知道某人的 DigiPal 名称吗？在此处搜索他们并转到他们的个人资料以留言并赢取更多 Lingos。使用 ${htmlFeatures.plusIcon} 邀请他们成为您的 DigiPal`,
    login: `交朋友真好！访问您的“推荐朋友”或访问全球以结交新朋友 😁`,
    profileLike: `您喜欢{username} 的个人资料页面！他们会超级开心。给他们留言，让他们知道你为什么喜欢它！`,
    streakNotice:
      '想要一个很酷的、可定制的 Domino 宠物吗？要获得 Domino，每天赚取 20 个行话。这是你的“连胜”。',
    profilePage:
      '你好！我是多米诺。我会帮你结交世界各地的朋友！如果您需要任何帮助，可以在工具栏中找到我。这是您的 DigiPal 个人资料页面。它告诉每个人关于你的一些事情。开始探索！',
  },

  activity: {
    rewards: {
      trophyTitle: '你赢得了奖杯！',
      starTitle: '你赢了一颗星！',
      lingoTitle: '你有 {lingos} 行话！',
      score: '分数',
      returnToActivities: '返回活动',
    },
    currentLesson: '当前课程',
    offlineMessage:
      '哦哦！您已失去互联网连接。别担心，我们已经保存了您的活动分数！',
  },

  onboarding: {
    first: '认识来自世界各地的 DigiPals，并用英语向他们发送消息。',
    second: '需要英语方面的帮助吗？你可以从这里开始学习',
    third: '创建你的头像。显示您的喜好。寻找其他 DigiPals。',
    fourth: '访问您的 DigiPals。留言。加入聊天。邀请 DigiPals 向您发送消息。',
    fifth: '赢得 Lingos 以获取消息和完成游戏。为您的头像和 Domino 购买东西。',
    sixth: '在 Little Bridge 保持连胜，并为您的个人资料获取 Domino！',
    seventh: {
      title: '准备好交朋友了吗？',
      btn: `我们走吧！`,
    },
  },
  tooltip: {
    toolbar: {
      home: '家',
      findFriends: '寻找新的\n朋友',
      activities: '多米诺学院',
      dominoAcademy: '多米诺学院',
      friends: '朋友们',
      streak: '条纹',
      globe: '地球',
      information: '信息',
      menu: '菜单',
      lingos: '行话',
      notifications: '通知',
      notificationsactive: '通知',
      audio: '重复问题',
      exit: '关闭活动',
      snail: '慢问题',
      steps: '继续',
      fetchFriend: '找到新朋友',
    },
    profile: {
      interests: '兴趣',
      digipals: '我的 DigiPals',
      achievements: '我的成就',
      editWallpaper: '编辑壁纸',
      addDigipal: '添加 DigiPal',
      offline: '离线',
      online: '在线的',
      myAvatar: '我的digipal',
      avatar: '阿凡达',
      sendMessage: '发信息',
      likeProfile: '喜欢个人资料',
      myFriends: '我的朋友',
      myLikes: '我的喜好',
      digipalLikes: '像这样的个人资料',
      alreadyLiked: '您已经喜欢这个个人资料',
      myMessages: '我的消息',
      requestPending: 'DigiPal 请求待处理',
      weAreDigipals: '我们是DigiPals！',
    },
    messages: {
      likes: '喜欢',
      send: '发送',
      saySomething: '说些什么',
      askSomething: '问一些东西',
      emoji: 'Emoji',
      reply: '回复',
    },
    activities: {
      supportLanguage: '中文说明',
      teachingLanguage: '英文说明',
      next: '下一个',
      previous: '以前的',
      play: '玩',
      slowPlay: '慢',
      record: '记录',
      playback: '回放',
    },
    other: {
      back: '继续',
      getItem: '获取项目',
      zoomIn: '放大',
      zoomOut: '缩小',
    },
  },

  conversations: {
    sendMessage: '发送信息',
    myInbox: '我的收件箱',
    send: '发信息',
    yourChat:
      '您与的对话 <p class="conversation__header__subtitle">{username}</p>',
    noMessages: '这里没有消息！写第一个！',
  },

  messageRewards: {
    title: '您向 {username} 发送了一条消息！',
    returnToHome: '返回个人资料页面',
  },
};
