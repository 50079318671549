<template>
  <button
    :class="`toolbar-item toolbar-item--size-${size}`"
    :style="itemStyles"
    @mousedown="isMoved = true"
    @mouseup="isMoved = false"
    @touchstart="isMoved = true"
    @touchend="isMoved = false"
  >
    <div
      :class="[
        'toolbar-item__content',
        { 'toolbar-item__content--moved': isMoved },
      ]"
    >
      <slot v-if="$slots.default" />

      <img
        v-else
        class="toolbar-item__image"
        :src="`/images/icons/${name}.png`"
        alt="Toolbar Icon"
      />
    </div>

    <div
      :class="['toolbar-item__bg', { 'toolbar-item__bg--moved': isMoved }]"
      :style="shadowStyles"
    />
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import BlueTop from '@/assets/images/toolbars/blue-bg-top.png';
import BlueBottom from '@/assets/images/toolbars/blue-bg-bottom.png';
import GreenTop from '@/assets/images/toolbars/green-bg-top.png';
import GreenBottom from '@/assets/images/toolbars/green-bg-bottom.png';
import VioletTop from '@/assets/images/toolbars/violet-bg-top.png';
import VioletBottom from '@/assets/images/toolbars/violet-bg-bottom.png';
import RedBottom from '@/assets/images/toolbars/red-bg-bottom.png';
import GreenTopLarge from '@/assets/images/toolbars/green-bg-top-large.png';
import BlueTopLarge from '@/assets/images/toolbars/blue-bg-top-large.png';
import YellowTop from '@/assets/images/toolbars/yellow-bg-top.png';
import DarkBlueBottom from '@/assets/images/toolbars/dark-blue-bg-bottom.png';

import BlueShadowTop from '@/assets/images/toolbars/blue-shadow-top.png';
import BlueShadowBottom from '@/assets/images/toolbars/blue-shadow-bottom.png';
import GreenShadowTop from '@/assets/images/toolbars/green-shadow-top.png';
import GreenShadowBottom from '@/assets/images/toolbars/green-shadow-bottom.png';
import VioletShadowTop from '@/assets/images/toolbars/violet-shadow-top.png';
import VioletShadowBottom from '@/assets/images/toolbars/violet-shadow-bottom.png';
import RedShadowBottom from '@/assets/images/toolbars/red-shadow-bottom.png';
import GreenShadowTopLarge from '@/assets/images/toolbars/green-shadow-top-large.png';
import BlueShadowTopLarge from '@/assets/images/toolbars/blue-shadow-top-large.png';
import YellowShadowTop from '@/assets/images/toolbars/yellow-shadow-top.png';
import DarkBlueShadowBottom from '@/assets/images/toolbars/dark-blue-shadow-bottom.png';

type Image = string | null;
type Color = 'green' | 'blue' | 'violet' | 'red' | 'yellow' | 'darkBlue';
type Orientation = 'top' | 'bottom';
type Size = 'default' | 'wide' | 'large';

type Images = Record<Orientation, Record<Color, [Image, Image, Image]>>;

@Component
export default class ToolbarItem extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  public name!: string;

  @Prop({
    type: String,
    default: 'default',
  })
  public size!: Size;

  @Prop({
    required: true,
    type: String,
  })
  public orientation!: Orientation;

  @Prop({
    required: true,
    type: String,
  })
  public color!: Color;

  public isMoved = false;

  public get sizeVariant() {
    const values = {
      default: 0,
      wide: 1,
      large: 2,
    };

    return values[this.size];
  }

  public get itemStyles() {
    const images: Images = {
      top: {
        blue: [BlueShadowTop, null, BlueShadowTopLarge],
        green: [GreenShadowTop, null, GreenShadowTopLarge],
        violet: [null, VioletShadowTop, null],
        red: [null, null, null],
        yellow: [YellowShadowTop, null, null],
        darkBlue: [null, null, null],
      },
      bottom: {
        blue: [BlueShadowBottom, null, null],
        green: [GreenShadowBottom, null, null],
        violet: [null, VioletShadowBottom, null],
        red: [RedShadowBottom, null, null],
        yellow: [null, null, null],
        darkBlue: [DarkBlueShadowBottom, null, null],
      },
    };

    return {
      backgroundImage: `url(${
        images[this.orientation][this.color][this.sizeVariant]
      })`,
    };
  }

  public get shadowStyles() {
    const images: Images = {
      top: {
        blue: [BlueTop, null, BlueTopLarge],
        green: [GreenTop, null, GreenTopLarge],
        violet: [null, VioletTop, null],
        red: [null, null, null],
        yellow: [YellowTop, null, null],
        darkBlue: [null, null, null],
      },
      bottom: {
        blue: [BlueBottom, null, null],
        green: [GreenBottom, null, null],
        violet: [null, VioletBottom, null],
        red: [RedBottom, null, null],
        yellow: [null, null, null],
        darkBlue: [DarkBlueBottom, null, null],
      },
    };

    return {
      backgroundImage: `url(${
        images[this.orientation][this.color][this.sizeVariant]
      })`,
    };
  }

  public makeItemStatic() {
    this.isMoved = false;
  }

  public get tooltipName() {
    return this.name.replace(/-/g, '');
  }

  public mounted() {
    window.addEventListener('mouseup', this.makeItemStatic);
    window.addEventListener('touchend', this.makeItemStatic);
  }

  public beforeDestroy() {
    window.removeEventListener('mouseup', this.makeItemStatic);
    window.removeEventListener('touchend', this.makeItemStatic);
  }
}
</script>

<style lang="scss" scoped>
.toolbar-item {
  position: relative;
  height: 41px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-size: 90%;
  background-position: 100% 100%;
  background-repeat: no-repeat;

  &__image {
    transition: 0.1s;

    position: relative;
    z-index: 2;

    width: 40%;
  }

  &__content {
    position: relative;
    transition: 0.3s;
    z-index: 2;
    margin-right: 5px;

    &--moved {
      margin-right: 0;
      margin-top: 2px;
    }
  }

  &__bg {
    transition: 0.1s;

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-size: 94%;
    background-repeat: no-repeat;

    &--moved {
      background-position: 100% 100%;
    }
  }

  &--size-default {
    width: 47px;
  }

  &--size-wide {
    width: 69px;
  }

  &--size-large {
    width: 268px;
    background-size: 98%;

    .toolbar-item__bg {
      background-size: 99%;
    }
  }
}
</style>
