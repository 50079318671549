<template></template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';
import Sound from '@/utils/helpers/Sound';

import {
  IActivityMeta,
  IActivityRelationPayload,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component
export default class ActivityAudio extends Vue {
  @Prop({ required: true })
  meta!: IActivityMeta;

  @Prop({ required: true })
  data!: IActivityComponentDataElement[];

  @Prop({ default: 'audio' })
  private type!: 'audio' | 'imageAudio';

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private currentAudioIndex = 0;
  private timeoutId!: number;

  private sound!: Sound;
  private foundSound!: Sound;

  public wasPaused = false;
  public autoplayAudioPlayed = false;
  public lastMode = '';

  public play(timeout = 5000, options = { mode: '' }) {
    EventBus.$emit('activity-event-stop-media');

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(() => {
      if (this.wasPaused && this.lastMode === options?.mode) {
        return this.sound.play();
      }

      this.stopAudios();

      const src = this.isOnline
        ? this.audio.value
        : this.findPreloadedAsset('sound', this.audio.value);

      const questionSound = new Sound({
        src,
        autoplay: true,
        html5: true,
        format: ['mp3', 'm4a'],
        rate: options?.mode === 'slow' ? 0.5 : 1,
      });
      this.sound = questionSound;

      // If we have a repeat-audio file, then repeat the question audio and insert this in between
      if (this.meta.qAudioRpt) {
        const repeatSound = new Sound({
          src: this.meta.qAudioRpt,
          autoplay: false,
          html5: true,
          format: ['mp3', 'm4a'],
          rate: options?.mode === 'slow' ? 0.5 : 1,
        });

        this.sound.once('end', () => {
          this.sound = repeatSound;
          this.sound.play();

          this.sound.once('end', () => {
            this.sound = questionSound;
            this.sound.play();

            this.soundEnd();
          });
        });
      } else {
        this.soundEnd();
      }

      EventBus.$emit('activity-event-question-audio-start');

      this.lastMode = options?.mode ?? '';
    }, timeout);
  }

  private soundEnd() {
    this.sound.on('end', () => {
      EventBus.$emit('activity-event-question-audio-end');
      this.wasPaused = false;
    });
  }

  public get audio() {
    return this.data[this.currentAudioIndex];
  }

  public findAndPlay(relationPayload: IActivityRelationPayload) {
    EventBus.$emit('activity-event-stop-media');

    const newAudioIndex = this.data?.findIndex(
      audio => audio.id === relationPayload.data?.relatedComponentId,
    );

    if (newAudioIndex > -1) {
      this.currentAudioIndex = newAudioIndex;

      const src = this.isOnline
        ? this.audio.value
        : this.findPreloadedAsset('sound', this.audio.value);

      this.foundSound = new Sound({
        src,
        autoplay: true,
        html5: true,
        format: ['mp3', 'm4a'],
      });
    }
  }

  private stopAudios() {
    this.sound?.stop();
    this.foundSound?.stop();
  }

  private autoplayNextQuestion() {
    this.autoplayAudioPlayed = false;
    this.playOnAutoplay();
  }

  private playOnAutoplay() {
    /**
     * Play "autoplay" audio only once for one question
     * @see autoplayNextQuestion;
     */
    if (!this.autoplayAudioPlayed && this.meta.autoplay) {
      this.play(this.meta.timeout);
      this.autoplayAudioPlayed = true;
    }
  }

  public mounted() {
    EventBus.$on('activity-event-stop-media', this.stopAudios);
    EventBus.$on(
      'activity-event-instruction-popup-closed',
      this.playOnAutoplay,
    );
    EventBus.$on('activity-event-next-question', this.autoplayNextQuestion);

    // Replay audio if "reset" button is pressed:
    EventBus.$on('activity-event-replay', () => this.play(0));
    EventBus.$on('activity-event-replay-pause', () => {
      this.sound.pause();
      this.wasPaused = true;
    });
    EventBus.$on('activity-event-replay-slow', () =>
      this.play(0, { mode: 'slow' }),
    );

    if (this.type !== 'imageAudio') {
      EventBus.$on('activity-event-relation-change', this.findAndPlay);
    }

    EventBus.$on('activity-event-before-next-question', this.stopAudios);
  }

  public destroyed() {
    clearTimeout(this.timeoutId);

    EventBus.$off('activity-event-stop-media');
    EventBus.$off('activity-event-replay');
    EventBus.$off('activity-event-replay-slow');
    EventBus.$off('activity-event-replay-pause');
    EventBus.$off('activity-event-relation-change', this.findAndPlay);
    EventBus.$off(
      'activity-event-instruction-popup-closed',
      this.playOnAutoplay,
    );
    EventBus.$off('activity-event-next-question', this.autoplayNextQuestion);
    EventBus.$off('activity-event-before-next-question', this.stopAudios);
  }
}
</script>

<docs>
```vue
<template>
  <div>
    <ActivityTextButton @click.native="play">
      Play audio
    </ActivityTextButton>

    <ActivityAudio
      ref="audio"
      :data="[
        {
          id: 'test_audio',
          value: 'https://cdn.littlebridge.com/ck/activities/audio/act_33_q2_uk.mp3',
        }
      ]"
      :meta="{
        autoplay: false,
      }"
    />
  </div>
</template>

<script>
  export default {
    methods: {
      play() {
        this.$refs.audio.play(0)
      }
    }
  }
</script>
```
</docs>
