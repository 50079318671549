import { PreloadedAsset } from '@/store/modules/preload-assets';

export const isCorrectFileExtension = (
  extensions: string[],
  fileName: string,
) => {
  const fileExtension = fileName?.split('.').pop();
  const correct = extensions.includes(fileExtension || '');

  return correct;
};

export const requireFromCache = (
  names: string[],
  preloadedImages: PreloadedAsset[],
) => {
  return preloadedImages.find(img => {
    const conditions = names.map(name => img.originalCdnUrl.includes(name));

    return conditions.every(result => !!result);
  })?.preloadedSrc;
};
