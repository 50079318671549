<template>
  <!-- For now, the draggable logic for the hotspots is used in act_25 -->
  <Draggable
    class="draggable"
    group="dragAndDropActivity"
    animation="150"
    :list="draggableData"
    :style="itemStyles"
    @change="handleUpdate"
  >
    <Hotspot
      class="hotspot"
      :type="type"
      :data="data"
      :disable-positioning="true"
      :bg-color="metaStyles.backgroundColor"
    >
      <template v-if="draggableData.length">
        <img
          v-if="isHotspotValueImgPath(draggableData[0].data.value)"
          :src="
            isOnline
              ? draggableData[0].data.value
              : findPreloadedAsset('image', draggableData[0].data.value)
          "
          class="hotspot__image"
          alt="Hotspot Image"
        />

        <span
          v-else-if="draggableData[0].type == 'draggableTextButtonsForHotspots'"
          class="hotspot__labelTxtBtn"
        >
          {{ draggableData[0].data.value || '' }}
        </span>

        <span v-else class="hotspot__label">
          {{ draggableData[0].data.value || '' }}
        </span>
      </template>
    </Hotspot>
  </Draggable>
</template>

<style lang="scss">
.draggable {
  .sortable-ghost {
    height: 100%;
    width: 100%;
  }

  .activity-image-btn {
    height: 100%;
    width: auto;

    margin: 0;
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.draggable {
  position: absolute;
}

.hotspot {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  &__label {
    color: $blue100;
    font-weight: 600;
    font-size: 20px;

    @media (max-width: #{$mobile}px) {
      font-size: 12px;
    }

    @media (max-width: #{$tamagotchi}px) {
      font-size: 9px;
    }
  }

  &__labelTxtBtn {
    color: $white100;
    font-weight: 500;
    font-size: 20px;
    background-color: $pink100;
    border-radius: 8px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;

    @media (max-width: #{$mobile}px) {
      font-size: 12px;
    }

    @media (max-width: #{$tamagotchi}px) {
      font-size: 9px;
    }
  }

  &__image {
    height: 100%;
  }
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';

import PositionedComponents from '../mixins/PositionedComponents.mixin';
import HotstpotStyles from '../mixins/HotspotStyles.mixin';

import Draggable, { ChangeEvent } from 'vuedraggable';
import Hotspot from './Hotspot.vue';

import {
  IActivitySolution,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';
import { isCorrectFileExtension } from '@/utils/helpers/assets';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    Draggable,
    Hotspot,
  },
})
export default class ActivityHotspot extends Mixins(
  PositionedComponents,
  HotstpotStyles,
) {
  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private draggableData: IActivitySolution[] = [];

  private imageButtonsTypes = [
    'draggableImageButtons',
    'draggableImageButtonsOrderSensitive',
    'draggableTextButtonsForHotspots',
  ];

  private isHotspotValueImgPath(src: string) {
    return isCorrectFileExtension(['png', 'jpg', 'jpeg'], src);
  }

  private handleUpdate(dragData: ChangeEvent<IActivitySolution>) {
    if (!this.draggableData.length) {
      const { type, data } = dragData.removed.element;

      if (this.imageButtonsTypes.includes(type)) {
        EventBus.$emit('activity-event-solution-del', {
          data,
          type,
        });

        EventBus.$emit('activity-event-solution-del', {
          data: this.data,
          type: this.type,
        });
      } else {
        EventBus.$emit(
          'activity-event-solution-del-at-index',
          Number(this.data.index) - 1,
        );
      }
      return;
    }

    const { data, type } = dragData.added.element;

    if (this.draggableData.length > 1) {
      const existing = this.draggableData.find(
        item =>
          (item.data as IActivityComponentDataElement).id !==
          (data as IActivityComponentDataElement).id,
      )!;

      if (this.imageButtonsTypes.includes(type)) {
        EventBus.$emit('activity-event-solution-del', {
          data: existing.data,
          type: existing.type,
        });

        EventBus.$emit('activity-event-solution-del', {
          data: this.data,
          type: this.type,
        });
      } else {
        EventBus.$emit(
          'activity-event-solution-del-at-index',
          Number(this.data.index) - 1,
        );
      }

      EventBus.$emit('activity-event-update-draggable-list', existing);

      this.draggableData = this.draggableData.filter(
        item =>
          (item.data as IActivityComponentDataElement).id ===
          (data as IActivityComponentDataElement).id,
      );
    }

    if (this.imageButtonsTypes.includes(type)) {
      setTimeout(() => {
        EventBus.$emit('activity-event-solution-add', {
          data,
          type,
        });

        EventBus.$emit('activity-event-solution-add', {
          data: this.data,
          type: this.type,
        });
      });
    } else {
      EventBus.$emit(
        'activity-event-solution-add-at-index',
        Number(this.data.index) - 1,
        {
          data,
          type,
        },
      );
    }
  }

  private reset() {
    this.draggableData = [];
  }

  public mounted() {
    EventBus.$on('activity-event-reset-draggable-data', this.reset);
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-reset-draggable-data', this.reset);
  }
}
</script>
