import { Howl } from 'howler';

export default class Sound extends Howl {
  /**
   * Indicates whether sounds should NOT be played when the window is in a state
   * corresponding to the key value.
   *
   * @example
   * focus: false // => play sounds when window is focused
   * blur: true   // => don't play sounds when window is blurred
   */
  static events = {
    focus: false,
    focusin: false,
    pageshow: false,
    blur: true,
    focusout: true,
    pagehide: true,
  };

  constructor(properties: IHowlProperties) {
    super(properties);

    document.addEventListener(
      'visibilitychange',
      this.handlePageVisibilityChange.bind(this),
    );

    this.handlePageVisibilityChange({
      type: document.hidden ? 'blur' : 'focus',
    });
  }

  private handlePageVisibilityChange(event: any) {
    const type = event.type as keyof typeof Sound.events;
    const mute = type in Sound.events ? Sound.events[type] : document.hidden;

    this.mute(mute);
  }
}
