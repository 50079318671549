import DataProvider from '@/utils/helpers/DataProvider';

import { ActionTree, Module } from 'vuex';
import { RootState } from '..';

import { IReportPaylaod } from '@/models/interfaces/community-guidelines';

export type State = Record<string, unknown>;

export const actions: ActionTree<State, RootState> = {
  sendReport(_, payload: IReportPaylaod) {
    return DataProvider.post('reportButton', payload);
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  actions,
};

export default store;
