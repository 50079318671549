var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'speech-bubble draggable': true,
    'speech-bubble--left': _vm.direction === 'left',
    'speech-bubble--right': _vm.direction === 'right',
    'speech-bubble--typing': _vm.isTypingGaps,
  },style:({
    left: `${_vm.position.left}px`,
    top: `${_vm.position.top}px`,
    width: `${_vm.sizing.width}px`,
    fontSize: `${_vm.sizing.fontSize}px`,
    display: _vm.isDraggableGaps ? 'block' : 'flex',
    padding: _vm.hasManyGaps ? '10px' : '0',
  })},[(_vm.isDraggableGaps)?_vm._l((_vm.draggableParts),function(part,idx){return _c('Draggable',{key:part.valueWithUnderscore,class:{
        'draggable-container': true,
        'draggable-container--multiple': _vm.hasManyGaps,
      },attrs:{"list":_vm.gapsData[idx],"disabled":_vm.isDisableDragging,"tag":_vm.hasManyGaps ? 'span' : 'div',"group":"dragAndDropActivity","animation":"150"},domProps:{"innerHTML":_vm._s(
        _vm.gapsData[idx] && _vm.gapsData[idx].length
          ? _vm.parsedString(part.valueWithUnderscore, idx)
          : part.valueWithUnderscore
      )},on:{"change":function($event){return _vm.handleUpdate($event, idx)}}})}):_c('Draggable',{staticClass:"draggable-container",attrs:{"group":"dragAndDropActivity","animation":"150","list":_vm.computedData,"disabled":_vm.data.label},on:{"change":_vm.handleUpdate}},[(_vm.isTypingGaps)?_c('div',[_vm._l((_vm.sentenceParts(_vm.data.label)),function(part,partIdx){return [_vm._v(" "+_vm._s(part.value)+" "),(part.letters)?_c('div',{staticClass:"input-wrapper"},_vm._l((part.letters),function(inputIdx){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.models[_vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label) - 1]
            ),expression:"\n              models[getGapInputIndex(inputIdx, partIdx, data.label) - 1]\n            "}],key:_vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label),ref:`input${_vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label)}`,refInFor:true,staticClass:"input",attrs:{"data-key":_vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label),"type":"text","autocapitalize":"none"},domProps:{"value":(
              _vm.models[_vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label) - 1]
            )},on:{"keyup":function($event){_vm.onRemoveValue(
                $event,
                _vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label),
              )},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.models, _vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label) - 1, $event.target.value)},function($event){_vm.focusNext(
                $event,
                _vm.getGapInputIndex(inputIdx, partIdx, _vm.data.label),
              )}]}})}),0):_vm._e()]})],2):[(_vm.data.label)?_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.data.label)+" ")]):_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.computedData[0] ? _vm.computedData[0].data.value : '?')+" ")])]],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }