<template>
  <AppPage :color="'#ffffff'" :image="null" class="activity">
    <PageTopbar :show-back-button="false">
      <ActivityTopbar
        :selected-activity-language="selectedActivityLanguage"
        :disable-replay="disableReplayQuestionAudio"
        :is-activity-test="isActivityTest"
        :user-languages="userLanguages.data"
        @activity-event-language-change="onLangChange"
      />
    </PageTopbar>

    <template v-if="currentActivity && areRandomisedQuestionsSet">
      <ActivityProgress
        v-if="
          isActivityProgressShown &&
            !showActivityConfirmationVideo &&
            !showActivityConfirmationImage
        "
        :class="{
          'activity-progress--full': isConfirmButtonMissing,
        }"
        :percentage="activityProgress"
        :is-valid="isActivityProgressValid"
      />

      <SideTile class="side-tile--left">
        <img
          class="side-tile__img"
          src="/images/activities/rewards-general.png"
          alt="Domino Academy"
        />
      </SideTile>
      <SideTile class="side-tile--right">
        <img
          class="side-tile__img side-tile__img--domino"
          src="/images/activities/domino.png"
          alt="Domino Academy"
        />

        <LingoInfo :lingos="currentActivity.meta.lingos.toString()" />
      </SideTile>

      <section
        :key="currentQuestion"
        class="activity__container"
        :style="{
          backgroundImage: getComputedBackgroundStyles,
        }"
      >
        <ActivityConfirmationImage
          :key="String(showActivityConfirmationImage)"
          :src="
            isOnline ? answerImage : findPreloadedAsset('image', answerImage)
          "
          :is-visible="showActivityConfirmationImage"
        >
          <component
            :is="'activity_speechBubble'"
            v-if="speechBubblesConfirmationData.length"
            :data="speechBubblesConfirmationData"
            type="speechBubbles"
            :style="{
              zIndex: 102,
              position: 'relative',
            }"
          />
        </ActivityConfirmationImage>

        <ActivityConfirmationVideo
          :src="
            isOnline ? answerVideo : findPreloadedAsset('video', answerVideo)
          "
          :is-visible="showActivityConfirmationVideo"
        />

        <template v-if="noTriggerAssetIsDisplayed">
          <component
            :is="`activity_${child.type}`"
            v-for="(child, index) in components"
            :key="`${currentQuestion}_${child.type}_${index}`"
            :type="child.type"
            :meta="child.meta"
            :data="child.data"
            :root-meta="currentActivity.meta"
            :relations="question.relations.data"
            :solution="question.solution"
            :triggers="question.triggers"
            :is-activity-valid="solutionIsValid"
            :assets-overlap="questionAssetsOverlap"
          />
        </template>
      </section>
    </template>
  </AppPage>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Action, State, Mutation, Getter } from 'vuex-class';
import { NavigationGuardNext, Location } from 'vue-router';

Component.registerHooks(['beforeRouteLeave']);

import {
  IActivity,
  IActivityComponent,
  IActivityEnhancedComponentData,
  ISolutionHistory,
  IActivityPart,
  IActivityTrigger,
  IActivityLesson,
  IActivitySolution,
  IActivityComponentDataElement,
  ITestInstruction,
} from '@/models/interfaces/activities';
import { IPopupData } from '@/models/interfaces/popup';
import { IVuexState } from '@/models/interfaces/store';
import { IUserLanguageData } from '@/models/interfaces/users';

import AppPage from '@/components/AppPage/AppPage.vue';
import PageTopbar from '@/components/PageTopbar/PageTopbar.vue';
import ActivityProgress from '@/components/ActivityProgress/ActivityProgress.vue';
import ActivityTopbar from '@/components/ActivityTopbar/ActivityTopbar.vue';
import ActivityConfirmationImage from '@/components/Activity/ActivityConfirmationImage.vue';
import ActivityConfirmationVideo from '@/components/Activity/ActivityConfirmationVideo.vue';
import SideTile from '@/components/Activity/SideTile.vue';
import LingoInfo from '@/components/Activity/LingoInfo.vue';

import ActivityAudio from '@/components/Activity/ActivityAudio.vue';
import ActivityConfirmButton from '@/components/Activity/ActivityConfirmButton.vue';
import ActivityImage from '@/components/Activity/ActivityImage.vue';
import ActivityImageButtons from '@/components/Activity/ImageButtons/DefaultImageButtons.vue';
import ActivityAdjustableImageButtons from '@/components/Activity/ImageButtons/AdjustableImageButtons.vue';
import ActivityDraggableImageButtons from '@/components/Activity/ImageButtons/DraggableImageButtons.vue';
import ActivityDraggableTextButtons from '@/components/Activity/TextButtons/ActivityDraggableTextButtons.vue';
import ActivityDraggableTextButtonsForHotspots from '@/components/Activity/TextButtons/ActivityDraggableTextButtonsForHotspots.vue';
import ActivityImageCarousel from '@/components/Activity/ActivityImageCarousel.vue';
import ActivityPlaceholder from '@/components/Activity/ActivityPlaceholder.vue';
import ActivitySpeaking from '@/components/Activity/ActivitySpeaking.vue';
import ActivityTextButtons from '@/components/Activity/TextButtons/Default.vue';
import ActivitySpellingGame from '@/components/Activity/ActivitySpellingGame.vue';
import ActivityVerticalTextButtonsWithAudio from '@/components/Activity/ActivityVerticalTextButtonsWithAudio.vue';
import ActivityVideo from '@/components/Activity/ActivityVideo.vue';
import ActivityVideoLooping from '@/components/Activity/ActivityVideoLooping.vue';
import ActivityVideoDisappearing from '@/components/Activity/ActivityVideoDisappearing.vue';
import ActivityWordSearch from '@/components/Activity/ActivityWordSearch.vue';
import ActivitySpeechBubbles from '@/components/Activity/SpeechBubbles/ActivitySpeechBubbles.vue';
import ActivityHiddenVideoWithLabel from '@/components/Activity/ActivityHiddenVideoWithLabel.vue';
import ActivityFillableTextContainer from '@/components/Activity/ActivityFillableTextContainer.vue';
import ActivityAssetsHotspots from '@/components/Activity/AssetsHotspots/Container.vue';
import ActivityImageWithTextContainer from '@/components/Activity/ActivityImageWithTextContainer.vue';
import ActivityHiddenImageWithTextContainer from '@/components/Activity/ActivityHiddenImageWithTextContainer.vue';
import ActivityAudioTrigger from '@/components/Activity/ActivityAudioTrigger.vue';
import ActivityTypeInTextBox from '@/components/Activity/ActivityTypeInTextBox.vue';
import ActivityVerticalTextButtonsSentences from '@/components/Activity/VerticalContainerWithTextBox/Container.vue';
import ActivityKaraoke from '@/components/Activity/ActivityKaraoke.vue';
import ActivityTextContainer from '@/components/ActivityTextContainer/ActivityTextContainer.vue';

import HideNavigation from '@/utils/mixins/HideNavigation';
import RequestState from '@/utils/mixins/RequestState';
import EventBus from '@/utils/helpers/EventBus';
import Sound from '@/utils/helpers/Sound';

import { isCorrectFileExtension } from '@/utils/helpers/assets';
import randomise from '@/utils/helpers/randomise';
import { append, equals, isNil, without, is } from 'ramda';

import findActivityAssetsFromPayload from '@/utils/helpers/activity-assets';

import { PreloadedAssetType } from '@/store/modules/preload-assets';
import { PrefetchedActivity } from '@/store/modules/activities';
import routeNames from '@/router/route-names';

@Component({
  components: {
    AppPage,
    PageTopbar,

    SideTile,
    LingoInfo,

    ActivityProgress,
    ActivityTopbar,
    ActivityConfirmationImage,
    ActivityConfirmationVideo,

    // Components used by the activity factory:
    activity_imageCarousel: ActivityImageCarousel,
    activity_imageCarouselHotspots: ActivityImageCarousel,
    activity_audio: ActivityAudio,
    activity_video: ActivityVideo,
    activity_loopingVideo: ActivityVideoLooping,
    activity_disappearingVideo: ActivityVideoDisappearing,
    activity_videoWithTextContainer: ActivityVideo,
    activity_image: ActivityImage,
    activity_imageButton: ActivityPlaceholder,
    activity_imageButtons: ActivityImageButtons,
    activity_adjustableImageButtons: ActivityAdjustableImageButtons,
    activity_draggableImageButtons: ActivityDraggableImageButtons,
    activity_draggableImageButtonsOrderSensitive: ActivityDraggableImageButtons,
    activity_imageButtonsTriggers: ActivityImageButtons,
    activity_draggableTextButtons: ActivityDraggableTextButtons,
    activity_draggableTextButtonsOrderInsensitive: ActivityDraggableTextButtons,
    activity_draggableTextButtonsWithAudio: ActivityDraggableTextButtons,
    activity_draggableTextButtonsForHotspots: ActivityDraggableTextButtonsForHotspots,
    activity_textButton: ActivityPlaceholder,
    activity_textButtons: ActivityTextButtons,
    activity_textLabel: ActivityPlaceholder,
    activity_textLabels: ActivityPlaceholder,
    activity_verticalTextButtonsWithAudio: ActivityVerticalTextButtonsWithAudio,
    activity_speechBubble: ActivitySpeechBubbles,
    activity_speechBubbles: ActivitySpeechBubbles,
    activity_draggableSpeechBubbles: ActivitySpeechBubbles,
    activity_draggableSpeechBubblesWithGaps: ActivitySpeechBubbles,
    activity_typingSpeechBubbles: ActivitySpeechBubbles,
    activity_confirmButton: ActivityConfirmButton,
    activity_wordSearch: ActivityWordSearch,
    activity_spellingGame: ActivitySpellingGame,
    activity_hiddenVideoWithLabel: ActivityHiddenVideoWithLabel,
    activity_videoHotspots: ActivityAssetsHotspots,
    activity_imageHotspots: ActivityAssetsHotspots,
    activity_draggableImageHotspots: ActivityAssetsHotspots,
    activity_draggableImageHotspotsWrappers: ActivityAssetsHotspots,
    activity_fillableTextLabel: ActivityFillableTextContainer,
    activity_draggableTextContainer: ActivityFillableTextContainer,
    activity_draggableTextContainerWithGaps: ActivityFillableTextContainer,
    activity_typingTextContainerWithGaps: ActivityFillableTextContainer,
    activity_speakingActivity: ActivitySpeaking,
    activity_imageWithTextContainer: ActivityImageWithTextContainer,
    activity_hiddenImageWithTextContainer: ActivityHiddenImageWithTextContainer,
    activity_imageWithTextContainerAndAudio: ActivityImageWithTextContainer,
    activity_hiddenImageWithTextContainerAndAudio: ActivityHiddenImageWithTextContainer,
    activity_audioTrigger: ActivityAudioTrigger,
    activity_typeInTextBox: ActivityTypeInTextBox,
    activity_audioTextButtons: ActivityTextButtons,
    activity_verticalTextButtonsSentences: ActivityVerticalTextButtonsSentences,
    activity_karaokeListen: ActivityKaraoke,
    activity_textContainer: ActivityTextContainer,
  },
})
export default class ActivityPage extends Mixins(HideNavigation, RequestState) {
  private attempts = 0;
  private currentQuestion = 0;

  private solutionHistory: ISolutionHistory[] = [];
  private rawPickedSolutions: any[] = [];

  private orderSensitiveSolutionTypes = [
    'draggableTextButtons',
    'draggableTextButtonsWithAudio',
    'draggableImageButtonsOrderSensitive',
  ];

  private answerImage = '';
  private answerVideo = '';
  private showActivityConfirmationImage = false;
  private showActivityConfirmationVideo = false;
  private isActivityFetchedDirectly = false;

  private selectedActivityLanguage = 'gb';

  private confirmButtonStateTimeout = 0;

  private preloadLinks: HTMLLinkElement[] = [];
  private randomisedQuestions: IActivityPart[] = [];

  private questionAssetsOverlap = false;
  private isActivityVideoFullscreen = false;
  private disableReplayQuestionAudio = true;
  private areRandomisedQuestionsSet = false;

  public isActivityProgressValid = false;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  @State('languages', { namespace: 'profile' })
  private userLanguages!: IVuexState<IUserLanguageData>;

  @State('activity', { namespace: 'activities' })
  private activity!: IVuexState<IActivity>;

  @State('prefetchedActivities', { namespace: 'activities' })
  private preloadedActivities!: PrefetchedActivity[];

  @State('currentLesson', { namespace: 'activities' })
  private currentLesson!: IVuexState<{
    activities: IActivityLesson[];
    unlockLesson: boolean;
  }>;

  @State('archiveLesson', { namespace: 'activities' })
  private archiveLesson!: IVuexState<IActivityLesson[]>;

  @Action('activities/sendSolutions')
  private sendSolutions!: (paylaod: ISolutionHistory[]) => Promise<''>;

  @Action('activities/fetchCurrentLesson')
  private fetchCurrentLesson!: () => Promise<IActivityLesson[]>;

  @Action('activities/fetchArchiveLesson')
  private fetchArchiveLesson!: () => Promise<IActivityLesson[]>;

  @Action('preloadAssets/preloadAssets')
  private preloadAssets!: (assetsUrls: string[]) => void;

  @Mutation('togglePopup')
  private togglePopup!: (data?: IPopupData) => void;

  @Mutation('activities/setOfflineFinishedActivity')
  private setOfflineFinishedActivity!: (id: string) => void;

  @Action('activities/fetchActivity')
  private fetchActivity!: (data: {
    activityId: string;
    language: string;
    shouldSetPendingState?: boolean;
  }) => Promise<IActivity>;

  @Watch('rawPickedSolutions')
  private onSolutionChange() {
    this.confirmButtonStateTimeout = window.setTimeout(() => {
      // timeout fixes the issue with the wrong
      // confirm button state

      if (this.question) {
        const { solution } = this.question;
        const isValid = this.isQuestionMultiChoiceSolution
          ? this.pickedSolutions.length > 0
          : this.pickedSolutions.length === solution.length;

        EventBus.$emit('activity-event-disable-button', !isValid);
      }
    });
  }

  @Watch('currentQuestion')
  private async onQuestionChange() {
    if (this.question) {
      if (this.isActivityVideoFullscreen && !this.questionHasConfirmButton) {
        this.$nextTick(() => {
          EventBus.$emit('activity-event-set-question-fullscreen');
        });
      }

      const shouldRefetchActivity =
        this.isOnline &&
        this.isActivityTest &&
        !['us', 'gb'].includes(this.activity.data?.meta.country ?? '');

      if (shouldRefetchActivity) {
        /**
         * For tests (which may have multiple instructions per activity),
         * we need to re-fetch the activity data in teaching language on question change
         * if the user triggered non-teaching language instruction during last question
         */
        this.isActivityFetchedDirectly = true;

        await this.fetchActivity({
          shouldSetPendingState: false,
          language: this.userLanguages.data?.teachingLanguage ?? '',
          activityId: this.$route.params.activityId,
        });
      }

      this.handleTestInstruction();
    }
  }

  private get speechBubblesConfirmationData() {
    return (
      this.question?.triggers?.correct
        .filter(item => item.type === 'speechBubbles')
        .map(bubble => bubble.data) || []
    );
  }

  private get noTriggerAssetIsDisplayed() {
    return (
      !this.showActivityConfirmationVideo && !this.showActivityConfirmationImage
    );
  }

  private get isActivityTest() {
    return Boolean(this.currentActivity?.meta?.test ?? true);
  }

  private get testInstructions() {
    return this.currentActivity?.testInstructions || [];
  }

  public get isActivityProgressShown() {
    const forbiddenComponentsTypes = [
      'speakingActivity',
      'spellingGame',
      'wordSearch',
      'karaokeListen',
    ];
    return this.question?.components.every(
      ({ type }) => !forbiddenComponentsTypes.includes(type),
    );
  }

  public get isConfirmButtonMissing() {
    const confirmButton = this.question.components.find(
      item => item.type === 'confirmButton',
    );
    return !confirmButton;
  }

  private get pickedSolutions() {
    return this.rawPickedSolutions.filter(Boolean) as IActivitySolution[];
  }

  private get questionHasConfirmButton() {
    return this.question.components.some(
      component => component.type === 'confirmButton',
    );
  }

  private get isQuestionMultiChoiceSolution() {
    return this.question.solution.every(item => item.meta?.shuffle);
  }

  private get getComputedBackgroundStyles() {
    const wallpaper = '/images/activities/page-background.png';
    const wallpaperUrl = this.isOnline
      ? wallpaper
      : this.findPreloadedAsset('image', wallpaper);

    return `url(${wallpaperUrl})`;
  }

  public async validate() {
    const { meta } = this.currentActivity!;
    const isValid = this.solutionIsValid;

    this.attempts += 1;

    if (isValid) {
      this.requestResetSolutions();
      EventBus.$emit('activity-event-success');

      await this.playTrigger('correct');

      this.attempts = 0;
      this.addSolutionHistoryEntry(this.questionID, true);

      this.nextQuestion();
    } else {
      EventBus.$emit('activity-event-failure');
      await this.playTrigger('incorrect');

      if (this.attempts >= meta.attempts) {
        this.requestResetSolutions();

        this.attempts = 0;
        this.addSolutionHistoryEntry(this.questionID, false);

        this.nextQuestion();
      }
    }

    this.isActivityProgressValid = isValid;
  }

  private requestResetSolutions() {
    this.rawPickedSolutions = [];

    // Ask rendered components to clear their state:
    EventBus.$emit('activity-event-reset-solutions');
  }

  private addSolution(solution: any) {
    this.rawPickedSolutions = append(solution, this.rawPickedSolutions);
  }

  private addSolutionAtIndex(i: number, solution: any) {
    this.rawPickedSolutions[i] = solution;
    this.rawPickedSolutions = [...this.rawPickedSolutions];
  }

  private delSolution(solution: any) {
    this.rawPickedSolutions = without([solution], this.pickedSolutions);
  }

  private delSolutionAtIndex(i: number) {
    this.rawPickedSolutions[i] = undefined;
    this.rawPickedSolutions = [...this.rawPickedSolutions];
  }

  private updateSolution(solution: any) {
    this.rawPickedSolutions = solution;
  }

  private addSolutionHistoryEntry(questionId: number, isValid: boolean) {
    const valid = Number(isValid);
    const questionIndex = this.solutionHistory.findIndex(
      entry => entry.questionId === questionId,
    );

    if (questionIndex > -1) {
      this.solutionHistory[questionIndex].valid = valid;
    } else {
      this.solutionHistory.push({ questionId, valid });
    }
  }

  private async playTrigger(type: 'correct' | 'incorrect') {
    return type === 'correct'
      ? await this.playCorrectTrigger()
      : await this.playIncorrectTrigger();
  }

  private async playCorrectTrigger() {
    const queue = this.question.triggers.correct.sort((a, b) => {
      if (a.data.order! > b.data.order!) {
        return -1;
      } else if (a.data.order! < b.data.order!) {
        return 1;
      } else {
        return 0;
      }
    });

    while (queue.length) {
      const trigger = queue.pop()!;

      await this.playTriggerType(trigger.type, trigger.data?.value);
    }

    // Reset positive composite response state for video/image:
    this.answerImage = '';
    this.showActivityConfirmationImage = false;
    this.answerVideo = '';
    this.showActivityConfirmationVideo = false;
  }

  private async playIncorrectTrigger() {
    const trigger = this.question.triggers.incorrect;

    await this.playTriggerType(trigger.type, trigger.data?.value);

    // Reset composite response state for video:
    this.answerVideo = '';
    this.showActivityConfirmationVideo = false;
  }

  private async playTriggerType(type: IActivityTrigger['type'], value: string) {
    switch (type) {
      case 'audio':
        return await this.playAudio(value);
      case 'image':
        return await this.showImage(value);
      case 'video':
        return await this.playVideo(value);
      default:
        return Promise.resolve(() =>
          // eslint-disable-next-line no-console
          console.info(`Unsupported trigger type ${type}`),
        );
    }
  }

  private playAudio(src: string) {
    return new Promise((resolve, reject) => {
      const source = this.isOnline
        ? src
        : this.findPreloadedAsset('sound', src);

      const sound = new Sound({
        src: [source],
        autoplay: true,
        html5: true,
        format: ['mp3', 'm4a'],
      });

      sound.once('end', resolve);
      sound.once('playerror', reject);
      sound.once('loaderror', reject);
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.debug(`Could not play sound "${src}"`);
      // eslint-disable-next-line no-console
      console.debug(error);
    });
  }

  private showImage(src: string) {
    return new Promise(resolve => {
      EventBus.$once('activity-event-image-hide', () => {
        resolve(null);

        this.answerImage = '';
        this.showActivityConfirmationImage = false;
      });

      this.answerImage = src;
      this.showActivityConfirmationImage = true;

      // Reset video:
      this.answerVideo = '';
      this.showActivityConfirmationVideo = false;
    });
  }

  private playVideo(src: string) {
    return new Promise(resolve => {
      EventBus.$once('activity-event-video-eneded', () => {
        resolve(null);
      });

      this.answerVideo = src;
      this.showActivityConfirmationVideo = true;

      // Reset image:
      this.answerImage = '';
      this.showActivityConfirmationImage = false;
    });
  }

  private async redirectToRewardsPage() {
    if (
      this.currentQuestion === this.questionCount &&
      this.noTriggerAssetIsDisplayed
    ) {
      const lingos = this.activity.data?.meta.lingos.toString();
      if (this.isOnline) {
        const { activityId } = this.$route.params;

        if (this.isActivityTest) {
          this.$router.replace({
            name: routeNames.activityRewards,
            params: {
              activityId,
            },
            query: {
              lingos,
              screen: 'digipalTest',
            },
          });

          return;
        }

        await Promise.all([
          this.fetchArchiveLesson(),
          this.fetchCurrentLesson(),
        ]);
        const allActivities = [
          ...this.currentLesson.data!.activities,
          ...this.archiveLesson.data!,
        ];

        const previousUserScore = allActivities.length
          ? allActivities?.find(activity => activity.activityId === activityId)
              ?.userScore ?? this.validSolutionsCount
          : this.validSolutionsCount;

        // activities that are completed in 100%
        const completedActivitiesIn100 = allActivities.filter(
          activity => activity.userScore / activity.totalScore === 1,
        );

        // If the last character of the number is 9
        const theNextDozen =
          (completedActivitiesIn100.length / 10).toString().slice(-1) === '9';

        let rewardScreenToShow = '';

        // the current user score is 100%
        if (this.questionCount === this.validSolutionsCount) {
          if (previousUserScore < this.validSolutionsCount) {
            // the previous user score less than 100%

            rewardScreenToShow = theNextDozen ? 'trophy' : 'star';
          } else {
            // the previous user score is already 100%

            rewardScreenToShow = 'lingo';
          }
        } else {
          rewardScreenToShow = 'lingo';
        }

        this.$router.replace({
          name: routeNames.activityRewards,
          params: {
            activityId,
          },
          query: {
            lingos,
            scorePercentage: `${(this.validSolutionsCount /
              this.questionCount) *
              100}`,
            score: `${this.validSolutionsCount}/${this.questionCount}`,
            screen: rewardScreenToShow,
            ...this.$route.query,
          },
        });
      } else {
        this.setOfflineFinishedActivity(this.$route.params.activityId);

        this.togglePopup({
          message: this.$t('activity.offlineMessage').toString(),
          onClose: () => {
            this.$router
              .replace({
                name: routeNames.trainingMenu,
              })
              .catch(() => {
                /* nope */
              });
          },
        });
      }
    }
  }

  public sendCurrentSolutions() {
    if (this.isOnline) {
      this.sendSolutions(this.solutionHistory);
      return;
    }

    const existingHistory = localStorage.getItem('offlineSolutionHistory');

    if (existingHistory) {
      const parsed = JSON.parse(existingHistory);
      const updatedHistory = [...parsed, ...this.solutionHistory];

      localStorage.setItem(
        'offlineSolutionHistory',
        JSON.stringify(updatedHistory),
      );
    } else {
      localStorage.setItem(
        'offlineSolutionHistory',
        JSON.stringify(this.solutionHistory),
      );
    }
  }

  private nextQuestion() {
    this.sendCurrentSolutions();

    if (this.currentQuestion < this.questionCount) {
      EventBus.$emit('activity-event-stop-media');
      EventBus.$emit('activity-event-before-next-question');

      this.$nextTick(() => {
        this.currentQuestion += 1;

        this.$nextTick(() => {
          EventBus.$emit('activity-event-next-question');
        });

        this.checkComponentsToHaveTwoAssets();
        this.redirectToRewardsPage();
      });
    }
  }

  private get isSolutionOrderSensitive() {
    // Decide if order in solutions is important
    // e.g. it is for activities where you drag and drop some texts
    // return this.question.solution.some((solution: any) => solution.type.includes(this.orderSensitiveSolutionTypes))
    return this.question.solution.some(solution =>
      this.orderSensitiveSolutionTypes.includes(solution.type),
    );
  }

  private get orderInsensitiveSolutionValidation() {
    // Check if the right solutions are picked:
    const condition = (solution: IActivitySolution) => {
      const pickedSolution = this.pickedSolutions.findIndex(picked => {
        const defaultCondition =
          equals(picked.type, solution.type) &&
          equals(picked.data, solution.data);

        const indexCondition = equals(
          `${(picked.relatedIndex ?? 0) + 1}`,
          (solution.data as IActivityComponentDataElement).label,
        );

        return is(Number, picked.relatedIndex)
          ? defaultCondition && indexCondition
          : defaultCondition;
      });

      return pickedSolution !== -1;
    };

    return this.isQuestionMultiChoiceSolution
      ? this.question.solution.some(condition)
      : this.question.solution.every(condition);
  }

  private get shouldBeRandomized() {
    return this.currentActivity?.meta.shuffle;
  }

  private get orderSensitiveSolutionValidation() {
    return this.question.solution.every((solution, currentIndex) => {
      const pickedSolution = this.pickedSolutions[currentIndex];
      return (
        !isNil(pickedSolution) &&
        equals(pickedSolution.type, solution.type) &&
        equals(pickedSolution.data, solution.data)
      );
    });
  }

  private get solutionIsValid() {
    const isValid = this.isSolutionOrderSensitive
      ? this.orderSensitiveSolutionValidation
      : this.orderInsensitiveSolutionValidation;

    // Check if all solutions are picked:
    const sameLength =
      this.question.solution.length === this.pickedSolutions.length ||
      this.isQuestionMultiChoiceSolution;

    return sameLength && isValid;
  }

  private get currentActivity() {
    if (this.isOnline) {
      return this.activity.data;
    } else {
      return this.isActivityFetchedDirectly
        ? this.activity.data
        : this.preloadedActivities.find(
            item => item.id === this.$route.params.activityId,
          )?.activity;
    }
  }

  private get question() {
    return this.shouldBeRandomized
      ? this.randomisedQuestions[this.currentQuestion]
      : this.currentActivity!.data[this.currentQuestion];
  }

  private get questionID() {
    return this.question.solution?.[0]?.meta?.questionId as number;
  }

  private get questionCount() {
    return this.currentActivity!.data.length;
  }

  private get validSolutionsCount() {
    return this.solutionHistory.filter(entry => entry.valid).length;
  }

  private get invalidSolutionsCount() {
    return this.solutionHistory.filter(entry => !entry.valid).length;
  }

  private get activityProgress() {
    return (this.currentQuestion / this.questionCount) * 100;
  }

  private get components() {
    return this.question?.components || [];
  }

  private updateComponentData(enhancedComponentData: {
    data: IActivityEnhancedComponentData;
  }) {
    if (!this.question) return;

    const { value, label, index } = enhancedComponentData.data;
    const updatedComponentIndex = this.question.components.findIndex(
      (component: IActivityComponent) =>
        component.data[0].id === enhancedComponentData.data.relatedComponentId,
    );

    const updatedComponent = this.question.components[updatedComponentIndex];
    const updatedComponentType = isCorrectFileExtension(['mp4'], value)
      ? 'video'
      : updatedComponent.type;

    updatedComponent.type = updatedComponentType;

    const isVideoComponentUpdated = updatedComponentType === 'video';

    updatedComponent.data[0].value = value;
    updatedComponent.data[0].label = label;
    updatedComponent.data[0].index = index;

    updatedComponent.meta = {
      ...updatedComponent.meta,
      hiddenComponentType: enhancedComponentData.data.hiddenComponentType,
    };

    if (isVideoComponentUpdated) {
      updatedComponent.meta = Object.assign(updatedComponent.meta, {
        autoplay: true,
      });
    }
  }

  private onLangChange(language: string) {
    const { activityId } = this.$route.params;
    this.selectedActivityLanguage = language;

    this.onActivityLoad({
      activityId,
      language,
      shouldSetPendingState: false,
      setRandomisedQuestions: false,
    });
  }

  private handleTestInstruction(shouldPlayLastSavedInstruction = false) {
    if (this.isActivityTest) {
      const directInstruction = this.testInstructions.find(
        item => item.question_id === this.questionID,
      );
      const findNearestInstruction = () => {
        if (directInstruction) {
          return directInstruction;
        }

        return this.testInstructions.reduce<ITestInstruction | null>(
          (nearest, item) => {
            if (nearest) {
              if (item.question_id > this.questionID) {
                return nearest;
              }
              return nearest.question_id < item.question_id ? item : nearest;
            }

            return item;
          },
          null,
        );
      };
      const instructionToPlay = shouldPlayLastSavedInstruction
        ? findNearestInstruction()
        : directInstruction;

      if (instructionToPlay) {
        this.togglePopup({
          message: instructionToPlay.text,
          sound: instructionToPlay.audio,
          onClose: () =>
            EventBus.$emit('activity-event-instruction-popup-closed'),
        });
      }
    }
  }

  private async onActivityLoad({
    shouldSetPendingState = true,
    setRandomisedQuestions = true,
    ...other
  }: {
    activityId: string;
    language: string;
    shouldSetPendingState?: boolean;
    setRandomisedQuestions?: boolean;
  }) {
    if (this.isOnline) {
      this.isActivityFetchedDirectly = true;

      await this.fetchActivity({
        ...other,
        shouldSetPendingState,
      });
    }

    if (!this.currentActivity || this.currentActivity!.message) {
      this.$router.replace({
        name: routeNames.activityNoAccess,
      });
      return;
    }

    if (this.shouldBeRandomized && setRandomisedQuestions) {
      this.randomisedQuestions = randomise<IActivityPart>(
        this.currentActivity?.data || [],
      );
    }

    this.areRandomisedQuestionsSet = true;

    this.$nextTick(() => {
      const { audio, title } = this.currentActivity!.meta;
      const sound = this.isOnline
        ? audio
        : this.findPreloadedAsset('sound', `${audio}`);

      // stopping the question audio
      EventBus.$emit('activity-event-before-next-question');

      if (this.isActivityTest) {
        this.handleTestInstruction(!shouldSetPendingState);
      } else {
        this.togglePopup({
          message: title,
          sound: sound || '',
          onClose: () =>
            EventBus.$emit('activity-event-instruction-popup-closed'),
        });
      }
    });
  }

  private preloadAsset(type: 'image' | 'video', href: string) {
    // <link rel="preload" as="video" href="https://cdn.com/small-file.mp4">
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = type;
    link.href = href;

    return link;
  }

  private appendToHead(elements: HTMLElement[]) {
    elements.forEach(element => document.head.appendChild(element));
  }

  private removeFromHead(elements: HTMLElement[]) {
    elements.forEach(element => element.parentNode?.removeChild(element));
  }

  private checkComponentsToHaveTwoAssets() {
    if (this.question) {
      const componentsTypes = this.question.components.map(item => item.type);
      const string = componentsTypes.join(', ').toLowerCase();

      this.questionAssetsOverlap =
        string.includes('audio') && string.includes('video');
    }
  }

  private initActivityEventHub() {
    // Listen for events from activity components:
    EventBus.$on('activity-event-solution-add', this.addSolution);
    EventBus.$on(
      'activity-event-solution-add-at-index',
      this.addSolutionAtIndex,
    );
    EventBus.$on('activity-event-solution-del', this.delSolution);
    EventBus.$on(
      'activity-event-solution-del-at-index',
      this.delSolutionAtIndex,
    );
    EventBus.$on('activity-event-solution-bulk-update', this.updateSolution);
    EventBus.$on('activity-event-confirm', this.validate);
    EventBus.$on(
      'activity-event-hidden-component-change',
      this.updateComponentData,
    );
    EventBus.$on(
      'activity-event-video-fullscreen',
      this.setFullscreenForActivity,
    );
  }

  private setFullscreenForActivity(status: boolean) {
    this.isActivityVideoFullscreen = status;
  }

  private offActivityEventHub() {
    EventBus.$off('activity-event-solution-add', this.addSolution);
    EventBus.$off(
      'activity-event-solution-add-at-index',
      this.addSolutionAtIndex,
    );
    EventBus.$off('activity-event-solution-del', this.delSolution);
    EventBus.$off(
      'activity-event-solution-del-at-index',
      this.delSolutionAtIndex,
    );
    EventBus.$off('activity-event-solution-bulk-update', this.updateSolution);
    EventBus.$off('activity-event-confirm', this.validate);
    EventBus.$off(
      'activity-event-hidden-component-change',
      this.updateComponentData,
    );
    EventBus.$off('activity-event-image-hide');
    EventBus.$off('activity-event-video-eneded');
    EventBus.$off('activity-event-video-fullscreen');
  }

  public async mounted() {
    window.onbeforeunload = () => ({});

    const { activityId } = this.$route.params;

    await this.onActivityLoad({
      activityId,
      language: this.userLanguages.data?.teachingLanguage || 'en',
    });

    if (this.currentActivity!.message) {
      return;
    }

    this.findAndPreloadActivityAssets();

    this.checkComponentsToHaveTwoAssets();

    const triggers = this.currentActivity!.data.flatMap(
      data => data.triggers.correct,
    );

    this.preloadLinks = triggers.reduce<HTMLLinkElement[]>((acc, curr) => {
      switch (curr.type) {
        case 'image':
          acc.push(this.preloadAsset('image', curr.data.value));
          break;
        case 'video':
          acc.push(this.preloadAsset('video', curr.data.value));
          break;
      }

      return acc;
    }, []);

    this.appendToHead(this.preloadLinks);
    this.onSolutionChange();
    this.initActivityEventHub();

    // Also allow replaying of disappearing video
    this.disableReplayQuestionAudio = this.question.components.every(
      component =>
        component.type !== 'audio' && component.type !== 'disappearingVideo',
    );
  }

  public destroyed() {
    window.onbeforeunload = null;
    this.removeFromHead(this.preloadLinks);
    this.offActivityEventHub();
    clearTimeout(this.confirmButtonStateTimeout);
  }

  private findAndPreloadActivityAssets() {
    if (this.isOnline) {
      const { activityId } = this.$route.params;
      const preloadedActivities = this.preloadedActivities.map(item => item.id);

      if (!preloadedActivities.includes(activityId)) {
        const activityAssetsFromPayload = findActivityAssetsFromPayload(
          this.activity.data!,
        );

        this.preloadAssets(activityAssetsFromPayload);
      }
    }
  }

  public beforeRouteLeave(
    to: Location,
    from: Location,
    next: NavigationGuardNext,
  ) {
    if (to.name === routeNames.activityRewards) {
      return next();
    }

    const confirmationMessage = this.$t(
      this.isActivityTest
        ? 'popup.testLeaveConfirmation'
        : 'popup.activityLeaveConfirmation',
    );

    const hasConfirmed = window.confirm(confirmationMessage.toString());

    next(hasConfirmed ? undefined : hasConfirmed);
  }
}
</script>

<style lang="scss">
// AppBackground internal wrapper:
.activity .background__content {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss" scoped>
.page-topbar {
  background: $blue400;
  box-shadow: 0px 4px 0px $blue100;
}

.activity {
  .side-tile {
    position: absolute;
    top: 0;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }

    .lingos-info {
      font-size: 50px;

      ::v-deep img {
        height: 70px;
      }
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
      flex-direction: column;
    }

    &__img {
      width: 100%;

      &--domino {
        background: $pink200;
        border: 15px solid $white100;
        border-radius: 100%;
        width: 90%;

        display: block;
      }
    }
  }

  &__container {
    position: relative;
    flex: 1;

    padding-bottom: 71px;
    background-size: cover;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
}

.activity-progress {
  &--full {
    width: 100%;
    padding: 19px 28px;

    transform: none;

    bottom: 0;
    left: 0;

    background: $white100;
    box-shadow: 0px -2px 0px $grey400;

    @media (max-width: #{$mobile}px) {
      padding: 18px;
    }
  }
}
</style>
