<template>
  <div class="text-container">
    <div
      v-if="isDraggableGaps"
      class="draggable-gaps"
      :style="{ padding: hasManyGaps ? '10px' : '0' }"
    >
      <Draggable
        v-for="(part, idx) in draggableGapsSentenceParts(data[0])"
        :key="part.valueWithUnderscore"
        :list="gapsData[idx]"
        :disabled="isDisableDragging"
        :tag="hasManyGaps ? 'span' : 'div'"
        group="dragAndDropActivity"
        :class="{
          'draggable-container-gaps': true,
          'draggable-container-gaps--multiple': hasManyGaps,
        }"
        animation="150"
        @change="handleUpdateDraggableGaps($event, idx)"
        v-html="
          gapsData[idx] && gapsData[idx].length
            ? parsedString(part.valueWithUnderscore, idx)
            : part.valueWithUnderscore
        "
      />
    </div>

    <div v-else-if="isTypingGaps" class="typing-container">
      <template
        v-for="(part, partIdx) in sentenceParts(data[0].value)"
        class="typing-part"
      >
        {{ part.value }}

        <!-- eslint-disable-next-line -->
        <div class="input-wrapper" v-if="part.letters">
          <input
            v-for="inputIdx in part.letters"
            :key="getGapInputIndex(inputIdx, partIdx, data[0].label)"
            :ref="`input${getGapInputIndex(inputIdx, partIdx, data[0].value)}`"
            v-model="
              models[getGapInputIndex(inputIdx, partIdx, data[0].value) - 1]
            "
            class="input"
            :data-key="getGapInputIndex(inputIdx, partIdx, data[0].value)"
            type="text"
            autocapitalize="none"
            @keyup="
              onRemoveValue(
                $event,
                getGapInputIndex(inputIdx, partIdx, data[0].value),
              )
            "
            @input="
              focusNext(
                $event,
                getGapInputIndex(inputIdx, partIdx, data[0].value),
              )
            "
          />
        </div>
      </template>
    </div>

    <template v-else>
      <p v-if="text" class="text-trigger">{{ text }}</p>

      <Draggable
        v-else
        class="draggable-container"
        group="dragAndDropActivity"
        animation="150"
        :list="list"
        @change="handleUpdate"
        @start="setLastList"
      >
        <ActivityTextButton
          v-for="{ data: button } in list"
          :key="button.id"
          :bg-color-type="button.label ? 'audio' : 'draggable'"
        >
          {{ button.value }}
        </ActivityTextButton>
      </Draggable>
    </template>
  </div>
</template>

<style lang="scss" scoped>
$container-width: $activityComponentContainerWidth;
$container-height: 70px;

.input {
  width: 15px;

  font-size: inherit;
  font-weight: inherit;
  color: $blue100;
  text-align: center;

  border-bottom: 1px solid $blue100;

  margin-right: 2px;

  border-radius: 0;

  &:last-child {
    margin: 0;
  }
}

.typing-container {
  padding: 10px;

  .typing-part {
    display: inline-block;
  }
}

.input-wrapper {
  display: inline-block;
}

.draggable-gaps {
  user-select: none;
  line-height: 1.5;

  ::v-deep {
    .sortable-ghost {
      display: none;
    }

    .draggable-value {
      color: $green100;
    }
  }

  .draggable-container-gaps {
    width: 100%;
    padding: 10px;

    &--multiple {
      padding: 0;
    }
  }
}

.text-container {
  max-width: $activityComponentBoxWidth;
  min-height: 70px;

  margin: 0 auto;

  background: $white100;

  font-size: 18px;
  font-weight: bold;
  line-height: 15px;
  color: $blue100;
  text-align: center;
}

.text-trigger {
  line-height: $container-height;
  margin: 0;
}

.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 12px;
  min-height: 70px;
}
</style>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import draggable, { ChangeEvent } from 'vuedraggable';
import EventBus from '@/utils/helpers/EventBus';

import {
  IActivitySolution,
  IActivityTriggers,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';

import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';
import ActivityDraggableGaps from './mixins/ActivityDraggableGaps.mixin';
import ActivityComponentWithDraggableGaps from './mixins/ActivityComponentWithDraggableGaps.mixin';

type SolutionWithIndex = IActivitySolution & { indexFromNativeList: number };
type List =
  | Array<Omit<SolutionWithIndex, 'meta'>>
  | Array<Omit<IActivitySolution, 'meta'>>;

enum ContainerTypes {
  FillableLabel = 'fillableTextLabel',
  DraggableTextContainer = 'draggableTextContainer',
  DraggableTextContainerWithGaps = 'draggableTextContainerWithGaps',
  TypingWithGaps = 'typingTextContainerWithGaps',
}

@Component({
  components: {
    Draggable: draggable,
    ActivityTextButton,
  },
})
export default class ActivityFillableTextContainer extends Mixins(
  ActivityDraggableGaps,
  ActivityComponentWithDraggableGaps,
) {
  @Prop({ required: true })
  protected solution!: IActivitySolution[];

  @Prop({ required: true })
  protected triggers!: IActivityTriggers;

  @Prop({ required: true })
  protected isActivityValid!: boolean;

  private list: List = [];
  private lastList: List = [];

  private text = '';
  private initialListLength = 0;

  private get isDraggableGaps() {
    return this.type === ContainerTypes.DraggableTextContainerWithGaps;
  }

  private get isTypingGaps() {
    return this.type === ContainerTypes.TypingWithGaps;
  }

  private get trigger() {
    // fillableTextLabel
    return this.triggers.correct.find(
      trigger => trigger.type === ContainerTypes.FillableLabel,
    );
  }

  private get hasManyGaps() {
    return this.draggableGapsSentenceParts(this.data[0]).length > 1;
  }

  private setLastList() {
    this.lastList = [...this.list];
  }

  private concatedList(data = this.list) {
    return (data as Array<Omit<IActivitySolution, 'meta'>>)
      .map(item => (item.data as IActivityComponentDataElement).value)
      .join(' ');
  }

  private updateActivitiesSelection() {
    EventBus.$emit(
      'activity-event-solution-bulk-update',
      (this.list as Array<
        Omit<SolutionWithIndex, 'meta'>
      >).map(({ type, data }) => ({ type, data })),
    );
  }

  private handleUpdate(data: ChangeEvent<IActivitySolution>) {
    if (this.type === ContainerTypes.DraggableTextContainer) {
      if (data.removed) {
        this.setListForDraggableContainer({
          value: this.concatedList(this.lastList),
          id: '',
          index: '',
          label: '',
        });

        EventBus.$emit(
          'activity-event-filter-draggable-list',
          (data.removed.element.data as IActivityComponentDataElement).id,
        );
      } else if (data.added || data.moved) {
        const movedInitialList =
          data.moved && this.list.length === this.initialListLength;

        if (movedInitialList) {
          return;
        }

        const appropriateSolution = this.solution.find(
          sol =>
            (sol.data as IActivityComponentDataElement).value ===
            this.concatedList(),
        );

        EventBus.$emit('activity-event-solution-bulk-update', [
          {
            type: this.type,
            data: {
              index: '',
              label: '',
              id:
                (appropriateSolution?.data as IActivityComponentDataElement)
                  ?.id ?? '',
              value: this.concatedList(),
            },
          },
        ]);
      }
    } else {
      this.updateActivitiesSelection();
    }
  }

  private handleUpdateDraggableGaps(
    data: ChangeEvent<IActivitySolution>,
    draggableGapsIndex: number,
  ) {
    this.disableOuterDragging();

    const { added, removed } = data;
    const dataToChange = this.gapsData[draggableGapsIndex!];

    // If we removed the element from the speech bubble, remove it from the
    // solutions array:
    if (removed) {
      EventBus.$emit('activity-event-solution-del-at-index', 0);
    } else {
      if (!added) {
        return;
      }

      // If the speech bubble already contained an element, remove it from the
      // solutions array and update the available responses list:
      if (dataToChange.length > 1) {
        const prevSol = dataToChange.pop();

        EventBus.$emit('activity-event-solution-del', prevSol);
        EventBus.$emit('activity-event-update-draggable-list', prevSol);
      }

      const currSol = dataToChange[0];
      currSol.relatedIndex = draggableGapsIndex;
      EventBus.$emit('activity-event-solution-add', currSol);
    }
  }

  private handleCorrectTrigger() {
    this.text = this.trigger?.data.value || '';
  }

  private setListForDraggableContainer(data = this.data[0]) {
    this.list = data.value.split(' ').map((item, idx) => ({
      type: '',
      data: {
        value: item,
        id: idx.toString(),
        label: '',
      },
    })) as Array<Omit<IActivitySolution, 'meta'>>;

    this.initialListLength = this.list.length;
  }

  private reset() {
    this.list = [];
    this.gapsData = [];

    this.initDraggableGaps();

    if (this.type === ContainerTypes.DraggableTextContainer) {
      this.setListForDraggableContainer();
    }
  }

  private initDraggableGaps() {
    this.draggableGapsSentenceParts(this.data[0]).forEach(() => {
      this.gapsData.push([]);
    });
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-reset-draggable-data', this.reset);
    this.disableOuterDragging(false);
  }

  public mounted() {
    EventBus.$on('activity-event-reset-draggable-data', this.reset);
    EventBus.$on('activity-event-success', this.handleCorrectTrigger);

    if (this.type === ContainerTypes.DraggableTextContainer) {
      this.setListForDraggableContainer();
    }
  }

  public destroyed() {
    EventBus.$off('activity-event-reset-draggable-data', this.reset);
    EventBus.$off('activity-event-success', this.handleCorrectTrigger);
  }

  public created() {
    this.initDraggableGaps();
  }

  @Watch('models')
  public onTypingModelsChange(models: string[]) {
    const parts = this.sentenceParts(this.data[0].value);
    const lettersTotal = parts.reduce((total, part) => total + part.letters, 0);

    if (lettersTotal === models.length) {
      const userTypedSolution = parts.reduce(
        ({ sentence, previousPartLetters }, part) => {
          const updatedSentence = `${sentence} ${part.value}${models
            .slice(previousPartLetters, previousPartLetters + part.letters)
            .join('')}`.trim();

          return {
            sentence: updatedSentence.replace(/\s+/g, ' '),
            previousPartLetters: part.letters,
          };
        },
        { sentence: '', previousPartLetters: 0 },
      ).sentence;

      EventBus.$emit('activity-event-solution-bulk-update', [
        {
          type: this.type,
          data: {
            ...this.data[0],
            value: userTypedSolution,
            label: userTypedSolution,
          },
        },
      ]);
    }
  }
}
</script>

<docs>
### Default

```vue
<template>
  <div>
    <ActivityFillableTextContainer
      :is-activity-valid="true"
      :triggers="{
        correct: []
      }"
      :solution="[]"
      type="fillableTextLabel"
      :data="[{ label: '1' }]"
    />

    <ActivityDraggableTextButtons
      :data="[
        {
          id: '1',
          value: '1',
          label: 'Draggable 1',
          index: '1',
        },
      ]"
      type="draggableTextButtons"
    />
  </div>
</template>
```

### Draggable gaps

```vue
<template>
  <div>
    <ActivityFillableTextContainer
      :is-activity-valid="true"
      :triggers="{
        correct: []
      }"
      :solution="[]"
      type="draggableTextContainerWithGaps"
      :data="[{ label: 'Hello ___, I like you!' }]"
    />

    <ActivityDraggableTextButtons
      :data="[
        {
          id: '1',
          value: 'Megan',
          label: 'Draggable 1',
          index: '1',
        },
      ]"
      type="draggableTextButtons"
    />
  </div>
</template>
```

### Typing gaps


```vue
<template>
  <div>
    <ActivityFillableTextContainer
      :is-activity-valid="true"
      :triggers="{
        correct: []
      }"
      :solution="[]"
      type="typingTextContainerWithGaps"
      :data="[{ value: 'Hello ___, I like you!', label: '1' }]"
    />
  </div>
</template>
```
</docs>
