import { VueConstructor } from 'vue/types/umd';
import { Route } from 'vue-router';

import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    handleBack: (data: { fallBackUrl?: string; force?: boolean }) => void;
  }
}

interface BackNavigationPlugin extends Vue {
  from: Route;
}

export default {
  install(Vue: VueConstructor) {
    Vue.mixin({
      data() {
        return {
          from: undefined,
        };
      },
      beforeRouteEnter(to, from, next) {
        next(vm => {
          (vm as BackNavigationPlugin).from = from;
        });
      },
      methods: {
        handleBack(data: { fallBackUrl?: string; force?: boolean }) {
          if (data.force) {
            this.$router.push(data.fallBackUrl!);
            return;
          }

          (this as BackNavigationPlugin).from &&
          (this as BackNavigationPlugin).from.path !== '/'
            ? this.$router.back()
            : this.$router.push(data.fallBackUrl || '/');
        },
      },
    });
  },
};
