<template>
  <article class="activity">
    <Spinner
      v-if="!requestedPermission"
      class="activity__loader"
      color="#f3aa45"
    />

    <template v-else>
      <ul class="activity__list activity__messages">
        <li
          v-for="(sentence, index) in data"
          :key="sentence.label + index"
          class="activity__message"
        >
          <ActivitySpeakingMessage
            class="activity__content__message"
            :message="sentence.label"
            :avatar-id="sentence.id"
            :outline="index === currentlyPlayedSentence"
            :direction="index % 2 ? 'right' : 'left'"
          />
        </li>
      </ul>

      <div class="wrapper">
        <ul class="activity__list activity__actions">
          <li>
            <button class="action-item action-item--play" @click="handlePlay">
              <img
                v-tooltip="{
                  content: this.$t('tooltip.activities.play').toString(),
                }"
                src="@/assets/images/activities/play.png"
                class="activity__action"
                alt="Play icon"
                title="Play"
              />
            </button>
          </li>

          <li>
            <button
              class="action-item action-item--slow"
              @click="handlePlay({ isSlow: true })"
            >
              <img
                v-tooltip="{
                  content: this.$t('tooltip.activities.slowPlay').toString(),
                }"
                src="@/assets/images/activities/snail.png"
                class="activity__action"
                alt="Snail icon"
                title="Play slow"
              />
            </button>
          </li>

          <li v-if="grantedPermission">
            <button class="action-item" @click="handleRecord">
              <img
                v-tooltip="{
                  content: this.$t('tooltip.activities.record').toString(),
                }"
                src="@/assets/images/activities/record.png"
                class="activity__action activity__action--record"
                alt="Record icon"
                title="Record"
              />
            </button>
          </li>

          <li v-if="grantedPermission">
            <button class="action-item" @click="handlePlayback">
              <object
                type="image/png"
                :data="avatarUrl"
                :class="{
                  'activity__action activity__action--fallback': true,
                  'activity__action--disabled': !canValidateActivity,
                }"
              >
                <img
                  v-tooltip="{
                    content: this.$t('tooltip.activities.playback').toString(),
                  }"
                  src="/images/globe/default-avatar-texture.png"
                  class="activity__action activity__action--fallback"
                  alt="Avatar"
                  title="Playback"
                />
              </object>

              <img
                src="@/assets/images/activities/playback.png"
                :class="{
                  'activity__action--avatar-playback': true,
                  'activity__action--disabled': !canValidateActivity,
                }"
                alt="Playback icon"
                title="Playback"
              />
            </button>
          </li>
        </ul>

        <ActivityTextButton
          class="confirm"
          color="white"
          :bg-color="disableButton ? 'grey' : '#87c040'"
          :disabled="disableButton"
          :squared="true"
          @click.native="onConfirm"
        >
          Proceed to the next activity
        </ActivityTextButton>
      </div>
    </template>
  </article>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

import Spinner from '@/components/Spinner/Spinner.vue';
import ActivitySpeakingMessage from '@/components/ActivitySpeakingMessage/ActivitySpeakingMessage.vue';
import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';

import {
  IActivityComponent,
  IActivityMeta,
} from '@/models/interfaces/activities';
import { IUser, IUserProfile } from '@/models/interfaces/users';
import { IVuexState } from '@/models/interfaces/store';

import EventBus from '@/utils/helpers/EventBus';
import getAvatarHash from '@/utils/helpers/getAvatarHash';
import getAvatarUrl from '@/utils/helpers/getAvatarUrl';

@Component({
  components: {
    Spinner,
    ActivitySpeakingMessage,
    ActivityTextButton,
  },
})
export default class ActivitySpeaking extends Vue {
  @Prop({ required: true })
  private type!: IActivityComponent<'speakingActivity'>['type'];

  @Prop({ required: true })
  private meta!: IActivityComponent<'speakingActivity'>['meta'];

  @Prop({ required: true })
  private data!: IActivityComponent<'speakingActivity'>['data'];

  @Prop({ required: true })
  private isActivityValid!: boolean;

  @Prop({ required: true, type: Object })
  private rootMeta!: IActivityMeta;

  @State('user', { namespace: 'session' })
  private currentUser!: IUser;

  @State('userProfile', { namespace: 'users' })
  private userProfile!: IVuexState<IUserProfile>;

  @Action('users/fetchUserProfile')
  private fetchUserProfile!: (userId: string) => Promise<IUserProfile>;

  private currentlyPlayedSentence: number | null = null;
  private areAudiosPlaying = false;
  private playedAudios = false;

  private recordsTimeout = this.rootMeta.recordDuration * 1000;
  private records: HTMLAudioElement[] = [];

  private requestedPermission = false;
  private grantedPermission = false;

  get avatarUrl() {
    if (this.userProfile.status.success) {
      const hash = getAvatarHash(this.userProfile.data!.avatar);
      return getAvatarUrl('human', 'thumbnail', hash);
    } else {
      return '/images/globe/default-avatar-texture.png';
    }
  }

  get canValidateActivity() {
    return (
      (!this.grantedPermission && this.playedAudios) ||
      this.hasRecordedEverything
    );
  }

  get hasRecordedEverything() {
    const originalAudios = this.getOriginalAudios();
    const bobAudios = this.getBobAudios();

    return originalAudios.length - bobAudios.length === this.records.length;
  }

  private get disableButton() {
    return this.grantedPermission
      ? !this.hasRecordedEverything
      : !this.playedAudios;
  }

  private getOriginalAudios() {
    return this.data.map(sentence => new Audio(sentence.value));
  }

  private onConfirm() {
    const solutions = this.data.map(item => ({
      type: 'speakingActivity',
      data: item,
    }));

    EventBus.$emit('activity-event-solution-bulk-update', solutions);
    EventBus.$emit('activity-event-confirm');
  }

  private getRecordedAudios() {
    return this.getBobAudios()
      .map((e, i) => {
        const recorded = this.records[i];
        return recorded ? [e, this.records[i]] : [e];
      })
      .flat();
  }

  private getBobAudios() {
    return this.getOriginalAudios().filter((_, index) => index % 2 === 0);
  }

  private getRoseAudios() {
    return this.getOriginalAudios().filter((_, index) => index % 2 === 1);
  }

  private prepareAudiosQueue(
    audios: HTMLAudioElement[],
    timeout = 0,
    onAudioStarted?: (audio: HTMLAudioElement, index: number) => any,
    onAudioEnded?: (audio: HTMLAudioElement, index: number) => any,
    runOnAudioEndedOnLastAudioPlayed = true,
  ) {
    // Creating a queue which will automatically play the next audio as the
    // previous one finishes:
    for (let i = 0; i < audios.length; i += 1) {
      const audio = audios[i];

      audio.addEventListener('play', () => {
        if (typeof onAudioStarted === 'function') {
          onAudioStarted(audio, i);
        }
      });

      audio.addEventListener('ended', () => {
        const originalAudios = this.getOriginalAudios();
        const blockOnAudioEnded =
          originalAudios[originalAudios.length - 1].src === audios[i].src &&
          !runOnAudioEndedOnLastAudioPlayed;

        // additional condition is needed for the recording logic

        // if there is nothing to record after the last audio
        // (here - last audio from audios argument) was played,
        // don't run onAudioEnded
        if (typeof onAudioEnded === 'function' && !blockOnAudioEnded) {
          onAudioEnded(audio, i);
        }

        if (audios[i + 1]) {
          window.setTimeout(() => {
            try {
              audios[i + 1].play();
            } catch (err) {
              // error
            }
          }, timeout);
        } else {
          this.areAudiosPlaying = false;
          this.currentlyPlayedSentence = null;
        }
      });
    }

    return audios;
  }

  private prepareAudioFromMicrophone(
    stream: MediaStream,
    onRecorded?: (audio: HTMLAudioElement) => any,
  ) {
    // @ts-ignore
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks: any[] = [];

    mediaRecorder.addEventListener('dataavailable', (event: any) => {
      audioChunks.push(event.data);
    });

    mediaRecorder.addEventListener('stop', () => {
      const audioBlob = new Blob(audioChunks);
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      if (typeof onRecorded === 'function') {
        onRecorded(audio);
      }
    });

    return mediaRecorder;
  }

  private handlePlay(params?: { isSlow?: boolean }) {
    if (this.areAudiosPlaying) {
      return;
    }

    const handleAudioStart = (audio: HTMLAudioElement, index: number) => {
      this.areAudiosPlaying = true;
      this.currentlyPlayedSentence = index;
    };

    const handleAudioStop = (audio: HTMLAudioElement, index: number) => {
      if (index + 2 >= audios.length) {
        this.playedAudios = true;
        this.areAudiosPlaying = false;
        this.currentlyPlayedSentence = null;
      }
    };

    let audios = this.getOriginalAudios();

    if (params?.isSlow) {
      audios = audios.map(audio => {
        const slowAudio = new Audio(audio.src);

        slowAudio.playbackRate = 0.5;
        return slowAudio;
      });
    }

    const queue = this.prepareAudiosQueue(
      audios,
      0,
      handleAudioStart,
      handleAudioStop,
      params?.isSlow,
    );

    try {
      queue[0].play();
    } catch (err) {
      // Probably missing audio file or blocked by browser…
    }
  }

  private handleRecord() {
    if (this.areAudiosPlaying) {
      return;
    }

    this.records = [];

    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      const recordUserVoice = () => {
        const recorder = this.prepareAudioFromMicrophone(stream, audio => {
          this.records.push(audio);
        });

        recorder.start();
        window.setTimeout(() => {
          recorder.stop();
        }, this.recordsTimeout);
      };

      const handleAudioStart = (audio: HTMLAudioElement, index: number) => {
        this.areAudiosPlaying = true;
        this.currentlyPlayedSentence = index * 2;
      };

      const handleAudioStop = () => {
        this.currentlyPlayedSentence = this.currentlyPlayedSentence! + 1;

        if (this.currentlyPlayedSentence + 1 >= originalAudios.length) {
          window.setTimeout(() => {
            this.areAudiosPlaying = false;
            this.currentlyPlayedSentence = null;
          }, this.recordsTimeout);
        }

        recordUserVoice();
      };

      const originalAudios = this.getOriginalAudios();
      const bobAudios = this.getBobAudios();
      const audios = this.prepareAudiosQueue(
        bobAudios,
        this.recordsTimeout,
        handleAudioStart,
        handleAudioStop,
        false,
      );

      try {
        audios[0].play();
      } catch (err) {
        // erorr
      }
    });
  }

  private handlePlayback() {
    if (this.areAudiosPlaying || !this.canValidateActivity) {
      return;
    }

    // If the user devices doesn't support recording user audio or the user did
    // not gave his permission, validate once all audios played at least once:
    if (!this.grantedPermission && this.playedAudios) {
      EventBus.$emit('activity-event-confirm');
      return;
    }

    const handleAudioStart = (audio: HTMLAudioElement, index: number) => {
      this.areAudiosPlaying = true;
      this.currentlyPlayedSentence = index;
    };

    const handleAudioStop = (audio: HTMLAudioElement, index: number) => {
      if (index + 1 >= audios.length) {
        this.areAudiosPlaying = false;
        this.currentlyPlayedSentence = null;
      }
    };

    const audios = this.getRecordedAudios();
    const queue = this.prepareAudiosQueue(
      audios,
      0,
      handleAudioStart,
      handleAudioStop,
    );

    try {
      queue[0].play();
    } catch (err) {
      // error
    }
  }

  public mounted() {
    this.fetchUserProfile(this.currentUser.userId);

    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          this.requestedPermission = true;
          this.grantedPermission = true;
        })
        .catch(() => {
          this.requestedPermission = true;
          this.grantedPermission = false;
        });
    } catch (err) {
      this.requestedPermission = true;
      this.grantedPermission = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.action-item {
  transition: transform 150ms ease;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 10px;
  border-radius: 50%;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    transform: scale(1);
  }

  &--play,
  &--slow {
    background: #90278e;
  }
}

.activity {
  margin-bottom: 55px;

  &__loader {
    display: block;
    margin: 50px auto;
  }

  &__list {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__messages {
    margin: 20px auto;
    padding-bottom: 15px;

    max-width: 800px;
  }

  &__message {
    margin: 15px 0;

    @media (max-width: #{$mobile}px) {
      bottom: 65px;
      padding: 10px;
    }
  }

  &__actions {
    margin: 0 0 15px;
    display: flex;
    justify-content: center;

    user-select: none;

    position: relative;
  }

  &__action {
    width: 25px;

    &--avatar-playback {
      position: absolute;
      bottom: 0;
      right: 5px;

      width: 17px;
      height: 17px;
    }

    &--fallback {
      margin: 0;
    }

    &--disabled {
      filter: grayscale(100%);
    }

    &--fallback,
    &--record {
      width: 40px;
    }
  }
}

.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 10.5px 0;
  background-image: linear-gradient(to top, #f4aa45, #ed5b2d);

  z-index: map-get($zindex, activityConfirmButton);
  user-select: none;

  @media (max-width: #{$mobile}px) {
    padding: 6px 0;
  }
}

.confirm {
  margin: 0 auto;

  @media (max-width: #{$mobile}px) {
    font-size: 20px;
  }
}
</style>
