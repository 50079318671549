<template>
  <div
    v-tooltip="{
      content: this.$t(`tooltip.toolbar.fetchFriend`),
    }"
    class="fetch-friend"
  >
    <slot />

    <PulseLoader v-if="isLoading" />

    <button
      v-else
      class="fetch-friend__button"
      type="button"
      @click="handleFetchFriend"
    >
      <img
        class="fetch-friend__image"
        src="/images/icons/fetch-friend.png"
        alt="Fetch Friend"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import PulseLoader from './PulseLoader.vue';

import { IRecommendedFriend } from '@/models/interfaces/recommendations';
import routeNames from '@/router/route-names';

@Component({
  components: {
    PulseLoader,
  },
})
export default class FetchFriend extends Vue {
  @Action('friends/fetchRecommendedFriends')
  public fetchRecommendedFriends!: (params?: {
    setPendingState?: boolean;
    type?: string;
  }) => Promise<IRecommendedFriend[]>;

  public isLoading = false;
  public visitedDigipalsIds: string[] = [];

  public async handleFetchFriend() {
    this.isLoading = true;

    const { userId: currentDigipalId } = this.$route.params;

    if (
      currentDigipalId &&
      !this.visitedDigipalsIds.includes(currentDigipalId)
    ) {
      this.visitedDigipalsIds.push(currentDigipalId);
    }

    const baseTimeout = new Promise(resolve =>
      window.setTimeout(resolve, 1500),
    );

    try {
      const friends = await this.fetchRecommendedFriends({
        setPendingState: false,
      });
      await baseTimeout;

      let nextFriend = friends.find(
        item => !this.visitedDigipalsIds.includes(item.userId),
      );

      if (!nextFriend) {
        this.visitedDigipalsIds = [];
        nextFriend = friends[0];
      }

      this.visitedDigipalsIds.push(nextFriend.userId);
      await this.$router.push({
        name: routeNames.digipal,
        params: {
          userId: nextFriend.userId,
        },
      });
    } catch (error) {
      // eslint-disable-next-line
      console.warn(`Failed to fetch a friend: ${error}`);
    }

    this.isLoading = false;
  }
}
</script>

<style lang="scss" scoped>
.fetch-friend {
  width: 70px;
  height: 70px;

  position: relative;

  &__button {
    position: relative;
    z-index: 2;

    width: 100%;
    height: 100%;

    background-color: $pink200;
    border-radius: 50%;
    box-shadow: 0 2px 0 $pink200Shadow;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
  }
}
</style>
