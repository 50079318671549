<template>
  <button :class="['archive-lesson', { 'archive-lesson--active': isActive }]">
    <h6
      ref="title"
      :class="[
        'archive-lesson__title',
        { 'archive-lesson__title--hidden': isTitleHidden },
      ]"
    >
      {{ formatedTitle }}
    </h6>

    <ProgressStar :progress="progress" />
  </button>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

import ProgressStar from './ProgressStar.vue';

@Component({
  components: {
    ProgressStar,
  },
})
export default class ArchiveLessonItem extends Vue {
  @Prop({ type: Number, required: true })
  public progress!: number;

  @Prop({ type: String, required: true })
  public title!: string;

  @Prop({ type: Boolean, required: true })
  public isActive!: boolean;

  public formatedTitle = this.title;
  public isTitleHidden = true;

  public async formatTitle() {
    await this.$nextTick();
    const title = this.$refs.title as HTMLHeadingElement;
    const style = window.getComputedStyle(title);

    const maxHeight = Number(style.lineHeight.replace('px', '')) * 2;
    // the title has more than 3 lines
    if (title.clientHeight > maxHeight) {
      // Removing last word
      const updatedTitle = this.formatedTitle.replace(/\s+\S+$/, '');
      this.formatedTitle = `${updatedTitle}...`;
      this.formatTitle();
    }

    this.isTitleHidden = false;
  }

  public mounted() {
    this.formatTitle();
  }
}
</script>

<style lang="scss" scoped>
.archive-lesson {
  border: 2px solid $grey200;
  background-color: $white100;
  border-radius: 9px;
  box-shadow: 0px 4px 0px $grey400;

  font-weight: 700;
  line-height: 25px;
  color: $blue100;

  padding: 13px 0 15px 20px;

  position: relative;

  width: 100%;
  text-align: left;

  height: 82px;

  @media (max-width: #{$tablet}px) {
    padding: 10px 0 12px 20px;
  }

  * {
    font-size: 25px;

    @media (max-width: #{$tablet}px) {
      font-size: 18px;
    }
  }

  &__title {
    padding-right: 23px;
    line-height: 1;

    &--hidden {
      opacity: 0;
    }
  }

  .progress-star {
    position: absolute;

    height: 48px;
    width: 48px;

    top: 50%;
    transform: translateY(-50%);
    right: -24px;

    ::v-deep img {
      height: 30px;

      @media (max-width: #{$tablet}px) {
        height: 25px;
      }
    }

    @media (max-width: #{$tablet}px) {
      height: 35px;
      width: 35px;

      right: -20px;
    }
  }

  &--active {
    background: $pink200;
    color: $white100;
    box-shadow: 0px 4px 0px $pink200Shadow;
    border: 2px solid $pink200Shadow;
  }
}
</style>
