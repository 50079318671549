import { IActivity } from '@/models/interfaces/activities';
import { uniq } from 'ramda';

export default function findActivityAssetsFromPayload(
  activity: IActivity,
): string[] {
  const assetsFromMeta = [activity.meta.wallpaper, activity.meta.audio || ''];

  const assetsFromQuestions = activity.data.map(question => {
    const fromComponents = question.components.map(component => {
      // just take all values
      return component.data
        .map(({ id, index, label, value }) => [id, index, label, value])
        .flat()
        .filter(value => value.includes('cdn.littlebridge.com'));
    });
    // triggers
    const { correct, incorrect } = question.triggers;

    const fromTriggers = [
      incorrect.data?.value,
      correct.map(trigger => trigger.data?.value),
    ].filter(trigger => !!trigger && trigger.includes('cdn.littlebridge.com'));

    return [...fromComponents.flat(), ...fromTriggers.flat()];
  });

  return uniq([...assetsFromQuestions.flat(), ...assetsFromMeta]).filter(
    asset => !!asset,
  );
}
