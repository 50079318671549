export function getObjectFitSize(
  containerWidth: number,
  containerHeight: number,
  elementWidth: number,
  elementHeight: number,
  objectFit: 'contain' | 'cover' = 'contain',
) {
  const containerRatio = containerWidth / containerHeight;
  const elementRatio = elementWidth / elementHeight;
  const isContain = objectFit === 'contain';

  if (
    isContain ? elementRatio > containerRatio : elementRatio < containerRatio
  ) {
    return {
      width: containerWidth,
      height: containerWidth / elementRatio,
    };
  } else {
    return {
      width: containerHeight * elementRatio,
      height: containerHeight,
    };
  }
}

export default getObjectFitSize;
