var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",class:{
    video: true,
    'video--fullscreen': _vm.isFullscreen,
    'video--fullsize': _vm.fullsize,
  },style:({
    // We cannot use 100vh/100vw due to mobile browsers (iOS/Android bug):
    width: _vm.isFullscreen ? `${_vm.windowWidth}px` : '100%',
    height: _vm.isFullscreen ? `${_vm.windowHeight}px` : '100%',
  })},[_c('video',_vm._b({ref:"video",class:{
      video__asset: true,
    },attrs:{"playsinline":"","preload":"auto","poster":_vm.poster},on:{"loadeddata":_vm.onLoadedData,"ended":_vm.onVideoEnd}},'video',_vm.$attrs,false),[_c('source',{attrs:{"type":"video/mp4","src":_vm.videoSource}}),_vm._v(" Sorry, your browser doesn't support embedded videos. ")]),_vm._t("default"),(_vm.areControlsVisible)?_c('div',{ref:"progressbar",class:{
      video__progressbar: true,
    }},[_c('div',{staticClass:"video__progressbar-currenttime",style:({
        width: `${_vm.percentage}%`,
      })})]):_vm._e(),(_vm.areControlsVisible)?_c('div',{staticClass:"video__buttons"},[(!_vm.playingStates[_vm.PlayingTypes.Default])?_c('button',{staticClass:"video__play-pause",attrs:{"data-default-play-button":""},on:{"click":function($event){return _vm.handlePlay(_vm.PlayingTypes.Default)}}},[_c('i',{staticClass:"fas fa-play"})]):_c('button',{staticClass:"video__play-pause",attrs:{"data-default-pause-button":""},on:{"click":function($event){return _vm.handlePause(_vm.PlayingTypes.Default)}}},[_c('i',{staticClass:"fas fa-pause"})]),(!_vm.playingStates[_vm.PlayingTypes.Slow])?_c('button',{staticClass:"video__play-slow",attrs:{"data-slow-play-button":""},on:{"click":function($event){return _vm.handlePlay(_vm.PlayingTypes.Slow)}}},[_c('img',{attrs:{"src":require("@/assets/images/activities/snail.png"),"alt":"Snail"}})]):_c('button',{staticClass:"video__play-slow",attrs:{"data-slow-pause-button":""},on:{"click":function($event){return _vm.handlePause(_vm.PlayingTypes.Slow)}}},[_c('i',{staticClass:"fas fa-pause"})]),_c('button',{staticClass:"video__fullscreen",on:{"click":function($event){return _vm.fullscreen(!_vm.isFullscreen)}}},[(!_vm.isFullscreen)?_c('i',{staticClass:"fas fa-expand"}):_c('i',{staticClass:"fas fa-compress"})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }