<template>
  <section
    v-if="hideBackground"
    class="app-page app-page--default"
    v-bind="$attrs"
  >
    <slot />
  </section>

  <AppBackground v-else class="app-page" v-bind="backgroundProps">
    <template #header>
      <slot name="header" />
    </template>

    <slot />
  </AppBackground>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import AppBackground from '@/components/AppBackground/AppBackground.vue';

import { IWallpaperSetting } from '@/models/interfaces/manage-wallpaper';

@Component({
  components: {
    AppBackground,
  },
  name: 'AppPage',
})
export default class UserConversation extends Vue {
  @Prop({ default: false, type: Boolean })
  public hideBackground!: boolean;

  @Getter('profile/wallpaperSettings')
  public wallpaperSettings!: IWallpaperSetting;

  public get backgroundProps() {
    return {
      color: this.wallpaperSettings.bgColor,
      image: this.wallpaperSettings.imgUrl,
      ...this.$attrs,
    };
  }

  public get pageComponent() {
    if (this.hideBackground) {
      return 'section';
    }

    return AppBackground.name;
  }
}
</script>

<style lang="scss" scoped>
.app-page {
  padding-top: 55px;

  &--default {
    height: 100vh;
  }

  @media (min-width: 765px) {
    padding-top: 80px;
  }
}

::v-deep {
  #app-background-main {
    min-height: initial;

    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }
}
</style>
