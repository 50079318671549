import Sound from './Sound';

export default {
  notification: new Sound({
    src: ['/sounds/notification.mp3'],
    html5: true,
  }),
  balloon: new Sound({
    src: ['/sounds/balloon_pop.mp3'],
    html5: true,
  }),
  activityNegative: new Sound({
    src: [
      'https://cdn.littlebridge.com/ck/activities/audio/q_response_negative_1_uk.mp3',
    ],
    html5: true,
  }),
  activityPositive: new Sound({
    src: [
      'https://cdn.littlebridge.com/ck/activities/audio/q_response_positive_1_us.mp3',
    ],
    html5: true,
  }),
  like: new Sound({
    src: ['/sounds/buttons/like.mp3'],
    html5: true,
  }),
  globe: new Sound({
    src: ['/sounds/screens/globe.mp3'],
    html5: true,
  }),
  message: new Sound({
    src: ['/sounds/message_sent.mp3'],
    html5: true,
  }),
  achievement: new Sound({
    src: ['/sounds/achievement.mp3'],
    html5: true,
  }),
  pop: new Sound({
    src: ['/sounds/buttons/pop.mp3'],
    html5: true,
  }),
};
