<template>
  <article class="activity-image" data-activity-asset>
    <div class="activity-image__element">
      <AssetImage
        :id="data[0].id"
        ref="image"
        :controls="showControls"
        :src="
          isOnline ? data[0].value : findPreloadedAsset('image', data[0].value)
        "
        @loaded="positionHotspots"
        @on-fullscreen-toggle="$emit('on-fullscreen-toggle', $event)"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
.activity-image {
  position: relative;

  max-width: $activityComponentContainerWidth;
  width: 100%;

  background-color: black;
  border-radius: 20px;

  margin: $activityMediaTopSpace auto 0;

  // 16:9 aspect ratio:
  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  &__element {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    border: $activityMediaBorderSize solid $white100;
    border-radius: 20px;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Ref, Watch, Vue } from 'vue-property-decorator';

import AssetImage from '@/components/Asset/Image.vue';
import EventBus from '@/utils/helpers/EventBus';
import { IActivityComponentData } from '@/models/interfaces/activities';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    AssetImage,
  },
})
export default class ActivityImage extends Vue {
  @Prop({ required: true })
  private data!: IActivityComponentData;

  @Prop({ default: false, type: Boolean })
  private isCarouselItem!: boolean;

  @Ref('image')
  private image!: Vue;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private showControls = true;

  private positionHotspots() {
    if (!this.image.$el) {
      return;
    }

    const { left, width, height } = this.image.$el.getBoundingClientRect();
    const initialPositioningData = {
      width,
      height,
      top: 0,
      position: 'absolute',
    };

    if (this.isCarouselItem) {
      this.$emit('on-position-hotspots', initialPositioningData);
      return;
    }

    const positioningData = {
      ...initialPositioningData,
      left,
    };

    EventBus.$emit('set-assets-hotspots-container-positioning', {
      ...positioningData,
      controls: true,
    });
  }

  public mounted() {
    EventBus.$on('activity-event-hide-fullscreen', () => {
      this.showControls = false;
    });
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-hide-fullscreen');
  }

  @Watch('windowWidth')
  private onResize() {
    this.$nextTick(() => {
      this.positionHotspots();
    });
  }
}
</script>
