<template>
  <component :is="tag" class="rounded-footer">
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RoundedFooter extends Vue {
  @Prop({ type: String, default: 'footer' })
  public tag!: string;
}
</script>

<style lang="scss" scoped>
.rounded-footer {
  width: 100%;
  height: 100px;

  background-image: url('/images/rounded-footer/rounded-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}
</style>
