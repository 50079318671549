<template>
  <div v-if="data.isShown" class="popup">
    <div
      :class="{
        'popup__close-area': true,
        'popup__close-area--before-close': isBeforeClose || isBeforeSwipeClose,
      }"
      @click="closeOnTileClick"
    />
    <div
      v-touch:swipe.right="closeOnSwipe"
      class="popup__content"
      :class="{
        'popup__content--before-close': isBeforeClose,
        'popup__content--swipe-close': isBeforeSwipeClose,
        'popup__content--streak': isStreakPopup,
        'popup__content--lingos': isLingosPopup,
      }"
    >
      <div class="popup__body">
        <div class="popup__icon">
          <img
            class="popup__icon-image"
            :alt="`${data.type} Popup Icon`"
            :title="`${data.type} Popup Icon`"
            :src="popupImageSrc"
          />
        </div>
        <div class="popup__info">
          <p class="popup__text" v-html="data.message" />

          <template v-if="data.type === 'offline'">
            <button v-if="isRetryInProgress" class="popup__retry-message">
              Trying to resolve connection...
            </button>
            <span v-else class="popup__retry-message" @click="retry">
              Retry
            </span>
          </template>
          <p
            v-if="isIntervention"
            class="popup__intervention"
            @click="onInterventionClick"
          >
            {{ data.intervention }}
          </p>
          <router-link
            v-if="isNavigateTo"
            v-slot="{ navigate }"
            :class="{
              'popup__navigate-to': true,
              'popup__navigate-to--text': data.redirectText,
            }"
            :to="data.redirect"
            custom
            @click.native="closeNavigatePopup"
          >
            <button @click="navigate">
              <p v-if="data.redirectText" class="popup__navigate-to__text">
                {{ data.redirectText }}
              </p>

              <img
                v-else
                :src="data.redirectPageIcon"
                alt="Redirect Page Icon"
              />
            </button>
          </router-link>

          <button v-if="data.onOk" type="button" class="ok-btn" @click="onOk">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ok-btn {
  padding: 10px 25px;
  margin-top: 15px;
  border: 0;

  background-color: $white100;
  border-radius: 10px;

  color: $blue100;
  font-size: 18px;
  font-weight: 400;
}

.popup {
  .icon {
    margin: 0;

    position: relative;
    top: 5px;
  }
}
</style>

<style lang="scss" scoped>
$popupIconDesktopSize: 185px;

.popup {
  display: flex;
  flex-direction: column;
  position: fixed;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  text-align: center;

  z-index: map-get($zindex, popupOverlay);

  &__text {
    margin: 0;
  }

  &__retry-message {
    cursor: pointer;
    display: block;

    margin: 10px auto 0;

    font-weight: bold;
    color: $white100;
  }

  &__body {
    @media (min-width: #{$tablet}px) {
      max-width: 1000px;
      margin: auto;
      position: relative;
    }
  }

  &__info {
    @media (min-width: #{$tablet}px) {
      max-width: calc(100% - #{$popupIconDesktopSize}* 2);

      margin: auto;
      padding: 0 10px;

      font-size: 30px;
    }
  }

  &__intervention {
    border-radius: 5px;
    box-shadow: 1.5px 0 1.5px 0px rgba(0, 0, 0, 0.3);
    background-color: $white100;

    line-height: 14px;
    font-size: 15.5px;
    font-weight: $font-weight;
    color: $blue100;
    padding: 8.5px 0 7.5px;
    margin: 21.5px 30px 0;

    cursor: pointer;
  }

  &__navigate-to {
    margin: 18px auto 0;
    display: block;
    width: 37px;
    height: 37px;
    font-size: 0;

    &__text {
      margin: 0;
    }

    img {
      height: 100%;
    }

    @media (min-width: #{$tablet}px) {
      width: 59px;
      height: 59px;
    }

    &--text {
      width: initial;
      height: 50px;

      background: $white100;
      color: $blue100;
      font-size: 18px;

      padding: 0 10px;

      border-radius: 30px;
    }
  }

  &__close-area {
    flex: 1;
    background: $wallpaperOverlay;
    animation: fadeIn 0.5s forwards;

    &--before-close {
      animation: fadeOut 0.5s forwards;
    }
  }

  &__content {
    position: relative;
    background: #e4116e;
    padding: 55px 32px 25px;
    font-size: 16.8px;
    font-weight: $font-weight;
    color: $white100;
    animation: slideUp 0.5s forwards;

    z-index: map-get($zindex, popupContent);

    &--before-close {
      animation: slideDown 0.5s forwards;
    }

    &--swipe-close {
      animation: slideRight 0.5s forwards;
    }

    &--streak,
    &--lingos {
      font-size: 23.8px;
      padding: 70px 0 37px;
    }

    &--streak {
      background: $orange100;

      .popup__icon {
        border: 9px solid $orange100;
      }
    }

    &--lingos {
      background: #f3e800;
      color: #1f78ba;

      .popup__icon {
        border: 9px solid #f3e800;
      }
    }

    @media (min-width: #{$tablet}px) {
      padding: 50px 0 60px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 50%;
    top: -55px;
    transform: translateX(-50%);

    width: 106px;
    height: 106px;

    border-radius: 50%;
    border: 9px solid #e4116e;
    background: $white100;

    user-select: none;

    &-image {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }

    @media (min-width: #{$tablet}px) {
      top: -150px;
      left: 0;
      transform: none;

      width: $popupIconDesktopSize;
      height: $popupIconDesktopSize;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(150%);
  }

  to {
    transform: none;
  }
}

@keyframes slideDown {
  from {
    transform: none;
  }

  to {
    transform: translateY(150%);
  }
}

@keyframes slideRight {
  from {
    transform: none;
  }

  to {
    transform: translateX(150%);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator';

import { Getter, Mutation, State } from 'vuex-class';

import EventBus from '@/utils/helpers/EventBus';
import Sound from '@/utils/helpers/Sound';

import { IPopupData } from '@/models/interfaces/popup';
import { PreloadedAsset } from '@/store/modules/preload-assets';
import { requireFromCache } from '@/utils/helpers/assets';

@Component
export default class Popup extends Vue {
  @Getter('getPopupData')
  private data!: IPopupData;

  @State('preloadedImages', { namespace: 'preloadAssets' })
  private preloadedImages!: PreloadedAsset[];

  @Mutation('togglePopup')
  private togglePopup!: (data?: IPopupData) => void;

  private isBeforeClose = false;
  private isBeforeSwipeClose = false;
  private isRetryInProgress = false;
  public sound!: Sound;

  get isNavigateTo() {
    return this.data.type === 'navigateTo';
  }

  get isStreakPopup() {
    return this.data.type === 'streakPopup';
  }

  get isLingosPopup() {
    return this.data.type === 'lingoPopup';
  }

  get isIntervention() {
    return this.data.type === 'did-you-mean' || this.data.type === 'say-more';
  }

  public onOk() {
    this.data.onOk?.();

    this.close();
  }

  private get popupImageSrc() {
    const passedImage = this.data.image;

    if (passedImage) {
      return passedImage;
    } else {
      return this.isOnline
        ? require('@/assets/images/popup/domino_study_buddy_normal.png')
        : requireFromCache(['domino', 'normal'], this.preloadedImages);
    }
  }

  private close() {
    if (this.data.type === 'offline') {
      return;
    }

    this.isBeforeClose = true;

    // show animation before close
    window.setTimeout(() => {
      this.onClose();
      this.isBeforeClose = false;
    }, 500);
  }

  private closeNavigatePopup() {
    // in cases, when we need just simple button without any redirection
    const isTheSameRoute = this.$route.name === this.data.redirect?.name;

    if (isTheSameRoute) {
      this.close();
    } else {
      this.$nextTick(() => {
        this.togglePopup();
      });
    }
  }

  public closeOnTileClick() {
    if (!this.data.closable) {
      return;
    }

    this.close();
  }

  private onClose() {
    if (this.sound) {
      this.sound.stop();
    }

    if (this.data.onClose) {
      this.data.onClose();
    }

    this.togglePopup();
  }

  private closeOnSwipe() {
    if (!this.data.closable) {
      return;
    }

    this.isBeforeSwipeClose = true;

    // show animation before close
    window.setTimeout(() => {
      this.onClose();
      this.isBeforeSwipeClose = false;
    }, 500);
  }

  private retry() {
    this.isRetryInProgress = true;

    if (this.isOnline) {
      this.togglePopup();
      document.location.reload();
    } else {
      window.setTimeout(() => {
        this.isRetryInProgress = false;
      }, 1000);
    }
  }

  private onInterventionClick() {
    switch (this.data.type) {
      case 'say-more':
        this.data.onSayMoreHandle!(this.data.intervention as string);
        break;

      default:
        break;
    }
  }

  private mounted() {
    EventBus.$on('on-popup-close', () => {
      this.isBeforeClose = true;

      // show animation before close
      window.setTimeout(() => {
        this.togglePopup();
        this.isBeforeClose = false;
      }, 500);
    });
  }

  @Watch('data', { deep: true })
  private onPopupDataChange(data: IPopupData) {
    if (data.isShown) {
      this.sound = new Sound({
        src: [this.data.sound as string],
        autoplay: true,
        // for IOS sound issue
        // https://github.com/goldfire/howler.js/issues/1093#issuecomment-445913299
        html5: true,
        format: ['mp3', 'm4a'],
      });
    }
  }
}
</script>
