<template>
  <div class="activity-confirm">
    <BaseButton
      :class="[{ 'activity-confirm__button--disabled': disabled }]"
      @click.native="handleClick"
    >
      {{ text }}

      <template #append>
        <img
          class="activity-confirm__icon"
          src="/images/icons/steps.png"
          alt="Paw Icon"
        />
      </template>
    </BaseButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EventBus from '@/utils/helpers/EventBus';

import BaseButton from '@/components/BaseButton/BaseButton.vue';
import { IActivityComponentData } from '@/models/interfaces/activities';

@Component({
  components: {
    BaseButton,
  },
})
export default class ActivityConfirmButton extends Vue {
  @Prop({ required: true, type: Array })
  private data!: IActivityComponentData;

  private disabled = true;

  public get text() {
    return this.windowWidth > 765 ? 'Confirm' : '';
  }

  private handleClick() {
    if (!this.disabled) {
      EventBus.$emit('activity-event-confirm');
    }
  }

  private setButtonState(disabled: boolean) {
    this.disabled = disabled;
  }

  private mounted() {
    EventBus.$on('activity-event-disable-button', this.setButtonState);
  }

  private destroyed() {
    EventBus.$off('activity-event-disable-button', this.setButtonState);
  }
}
</script>

<style lang="scss" scoped>
.activity-confirm {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 15px;
  padding-right: 18%;

  box-shadow: 0px -2px 0px $grey400;
  background: $white100;

  text-align: center;

  z-index: map-get($zindex, activityConfirmButton);
  user-select: none;

  &__icon {
    height: calc(100% - 15px);
  }

  .base-button {
    font-size: 20px;

    ::v-deep .base-button__append {
      background: $green400Shadow;

      @media (max-width: #{$mobile}px) {
        right: 0;
      }
    }

    @media (max-width: #{$mobile}px) {
      height: 40px;
      width: 40px;
      padding: 0;
    }
  }

  @media (max-width: #{$mobile}px) {
    padding: 5px 20px 5px 10px;
    font-size: 20px;
  }

  &__button {
    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>

<docs>
**Note:** *the component has a fixed position, so here it's set to 'static', in order not to break a styleguide layout*

### Default
```jsx
<ActivityConfirmButton style="position: static" :data="[{}]" />
```

### With passed label
```jsx
<ActivityConfirmButton style="position: static" :data="[{ value: 'Label' }]" />
```
</docs>
