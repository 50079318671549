export enum AppToolbarsIconsNames {
  Activities = 'activities',
  Globe = 'globe',
  Friends = 'friends',
  Home = 'home',
  Streak = 'streak',
  Info = 'information',
  Menu = 'menu',
  Lingos = 'lingos',
  Notifications = 'notifications',
}
