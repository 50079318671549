<template>
  <header class="page-topbar">
    <NavigateBack v-if="showBackButton" @click="onClick" />

    <!-- For displaying any data inside -->
    <slot />
  </header>
</template>

<style lang="scss" scoped>
.page-topbar {
  background-color: $orange100;
  background-image: linear-gradient($orange400, $orange100);
  box-shadow: 3px 0 2.9px 0.1px rgba(0, 0, 0, 0.3);

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;

  padding: 0 14px;

  height: 80px;
  width: 100%;

  z-index: map-get($zindex, appMenu);

  user-select: none;

  &--around {
    @media (min-width: #{$mobile}px) {
      justify-content: space-around;
    }
  }

  @media (max-width: #{$mobile}px) {
    height: 55px;
  }
}
</style>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import NavigateBack from '@/components/NavigateBack/NavigateBack.vue';
import sounds from '@/utils/helpers/sounds';

@Component({
  components: {
    NavigateBack,
  },
})
export default class PageTopbar extends Vue {
  @Prop({ default: true })
  public showBackButton!: boolean;

  private onClick() {
    sounds.pop.play();
    /**
     * Navigation event, triggered on arrow click
     * @event go-back
     */
    this.$emit('go-back');
  }
}
</script>

<docs>
### Default

```jsx
<PageTopbar style="position: static" />
```

### With slot

```jsx
<PageTopbar style="position: static">
  Any content 
</PageTopbar>
```

### Without navigation

```jsx
<PageTopbar :show-back-button="false" style="position: static">
  Any content 
</PageTopbar>
```
</docs>
