import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';

import {
  IUser,
  IUserStreak,
  IReferral,
  ILicence,
  ILanguage,
  IUserLanguageData,
  IUserLanguagePayload,
  IUserProfile,
} from '@/models/interfaces/users';
import { INotification } from '@/models/interfaces/notifications';
import { IVuexState } from '@/models/interfaces/store';

import { ActionTree, MutationTree, GetterTree, Module } from 'vuex';
import { RootState } from '..';

export interface State {
  lingo: number;
  licences: IVuexState<ILicence[]>;
  referral: IVuexState<IReferral>;
  notifications: IVuexState<INotification[]>;
  languages: IVuexState<IUserLanguageData>;
  teachingLanguages: IVuexState<ILanguage[]>;
  supportLanguages: IVuexState<ILanguage[]>;
  profile: IVuexState<IUserProfile>;
}

export const getters: GetterTree<State, RootState> = {
  wallpaperSettings(state) {
    return state.profile.data?.wallpaper;
  },
  hasPremiumAccount(state) {
    return state.licences.data?.some(licence => licence.sku === 'LBMFULL');
  },
};

export const mutations: MutationTree<State> = {
  setLingos(state, data: { lingos: number }) {
    state.lingo = data.lingos;
  },
  setLicences(state, data?: ILicence[] | Error) {
    state.licences = StoreUtil.updateState(state.licences, data);
  },
  setReferral(state, data?: IReferral | Error) {
    state.referral = StoreUtil.updateState(state.referral, data);
  },
  setUserLanguages(state, data?: IUserLanguageData | Error) {
    state.languages = StoreUtil.updateState(state.languages, data);
  },
  setTeachingLanguages(state, data?: ILanguage[] | Error) {
    state.teachingLanguages = StoreUtil.updateState(
      state.teachingLanguages,
      data,
    );
  },
  setSupportLanguages(state, data?: ILanguage[] | Error) {
    state.supportLanguages = StoreUtil.updateState(
      state.supportLanguages,
      data,
    );
  },
  setProfile(state, data?: IUserProfile | Error) {
    state.profile = StoreUtil.updateState(state.profile, data);
  },
  addLicense(state, license: ILicence) {
    state.licences = StoreUtil.updateState(state.licences, [
      ...state.licences.data!,
      license,
    ]);
  },
};

export const actions: ActionTree<State, RootState> = {
  removeLingos({ commit, state }, amount: number) {
    commit('setLingos', { lingos: state.lingo - amount });
  },
  async fetchMyProfile({ rootState, commit }) {
    const { session } = rootState;

    commit('setProfile');

    try {
      const { data } = await DataProvider.get<IUser>(
        `users/${session.userId}/profile`,
      );
      commit('setProfile', data);
      return data;
    } catch (error) {
      commit('setProfile', error);
      return error;
    }
  },
  async fetchUserLingos({ commit, rootState }) {
    const { session } = rootState;

    try {
      const { data } = await DataProvider.get<{ lingos: number }>(
        `users/${session.userId}/lingos`,
      );

      commit('setLingos', data);
      return data;
    } catch (error) {
      commit('setLingos', { lingos: 0 });
      return error;
    }
  },
  async fetchLicences({ commit, rootState }) {
    const { session } = rootState;

    commit('setLicences');

    try {
      const { data } = await DataProvider.get(`licences/${session.userId}`);
      // @ts-ignore
      commit('setLicences', data.licences);
      // @ts-ignore
      return data.licences;
    } catch (error) {
      commit('setLicences', error);
      return error;
    }
  },
  async fetchMyProfileStreak({ rootState }): Promise<IUserStreak> {
    const { session } = rootState;

    try {
      const { data } = await DataProvider.get<IUserStreak>(
        `streaks/${session.userId}`,
      );
      return data;
    } catch (error) {
      return {
        streak: null,
        streakType: 'lingo',
        message: 'Could not fetch your streak!',
      };
    }
  },
  async fetchStreakWithUser(
    { rootState },
    userId: string,
  ): Promise<IUserStreak> {
    const { session } = rootState;

    try {
      const { data } = await DataProvider.get<IUserStreak>(
        `streaks/${session.userId}/${userId}`,
      );
      return data;
    } catch (error) {
      return {
        streak: null,
        streakType: 'friendship',
        message: 'Could not fetch streak with user',
      };
    }
  },
  async fetchReferral({ commit, rootState }): Promise<IReferral> {
    const { session } = rootState;
    commit('setReferral');

    try {
      const { data } = await DataProvider.get(
        `users/${session.userId}/referral`,
      );
      commit('setReferral', data);
      return data as IReferral;
    } catch (error) {
      commit('setReferral', error);
      return error as IReferral;
    }
  },
  async fetchLanguagesData({ rootState, commit }) {
    const { session } = rootState;
    commit('setUserLanguages');

    try {
      const { data } = await DataProvider.get(
        `users/${session.userId}/languages`,
      );
      commit('setUserLanguages', data);
      return data;
    } catch (error) {
      commit('setUserLanguages', error);
      return error;
    }
  },
  async fetchTeachingLanguages({ commit }) {
    commit('setTeachingLanguages');

    try {
      const { data } = await DataProvider.get('languages/teaching');
      // @ts-ignore
      commit('setTeachingLanguages', data.languages);
      // @ts-ignore
      return data.languages;
    } catch (error) {
      commit('setTeachingLanguages', error);
      return error;
    }
  },
  async fetchSupportingLanguages({ commit }, teachingLanguage: string) {
    commit('setSupportLanguages');

    try {
      const { data } = await DataProvider.get(
        `languages/support/${teachingLanguage}`,
      );
      // @ts-ignore
      commit('setSupportLanguages', data.languages);
      // @ts-ignore
      return data.languages;
    } catch (error) {
      commit('setSupportLanguages', error);
      return error;
    }
  },
  async changeUserLanguage({ commit, rootState }, data: IUserLanguagePayload) {
    const { session } = rootState;

    try {
      const { data: response } = await DataProvider.post(
        `users/${session.userId}/languages`,
        data,
      );
      const baseFlagUrl =
        'https://cdn.littlebridge.com/ck/img/common/flags/large';

      commit('setUserLanguages', {
        teachingLanguageFlag: `${baseFlagUrl}/${data.teachingLanguage}.png`,
        supportLanguageFlag: `${baseFlagUrl}/${data.supportLanguage}.png`,
        ...data,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  mutations,
  actions,
  getters,
  state: {
    lingo: 0,
    licences: StoreUtil.state(),
    referral: StoreUtil.state(),
    notifications: StoreUtil.state(),
    languages: StoreUtil.state(),
    teachingLanguages: StoreUtil.state(),
    supportLanguages: StoreUtil.state(),
    profile: StoreUtil.state(),
  },
};

export default store;
