<template>
  <div class="activity-image-buttons">
    <ActivityImageButton
      v-for="image in data"
      :key="image.id"
      :src="isOnline ? image.value : findPreloadedAsset('image', image.value)"
      :bg-color="selectedButtons.includes(image.id) ? '#87c040' : 'transparent'"
      @click.native="handleClick(image)"
    />
  </div>
</template>

<style lang="scss" scoped>
.activity-image-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 15px 0 0;

  @include activity-centered;
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import EventBus from '@/utils/helpers/EventBus';

import ActivityButtons from '../mixins/ActivityButtons';
import ActivityImageButton from '@/components/ActivityImageButton/ActivityImageButton.vue';

import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    ActivityImageButton,
  },
})
export default class DefaultImageButtons extends Mixins(ActivityButtons) {
  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  public mounted() {
    EventBus.$on('activity-event-reset-solutions', () => {
      this.selectedButtons = [];
    });
  }

  public destroyed() {
    EventBus.$off('activity-event-reset-solutions');
  }
}
</script>

<docs>
### A wrapper that renders image buttons

```jsx
<DefaultImageButtons
  :meta="{}"
  :data="[
    {
      id: '1',
      label: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      index: 1,
    },

    {
      id: '2',
      label: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      index: 2,
    }
  ]"
  :solution="[]"
  type="imageButtons"
/>
```
</docs>
