<template>
  <Draggable
    animation="150"
    group="dragAndDropActivity"
    class="activity-image-buttons"
    :list="enhancedData"
  >
    <div
      v-for="image in enhancedData"
      :key="image.data.id"
      class="activity-image-buttons__wrap"
    >
      <ActivityImageButton
        :src="
          isOnline
            ? image.data.value
            : findPreloadedAsset('image', image.data.value)
        "
      />
    </div>
  </Draggable>
</template>

<style lang="scss" scoped>
@import './styles';

.activity-image-buttons {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
}
</style>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import EventBus from '@/utils/helpers/EventBus';

import ComponentsWithRelations from '../mixins/ComponentsWithRelations';
import {
  IActivityComponentDataElement,
  IActivitySolution,
} from '@/models/interfaces/activities';

import ActivityImageButton from '@/components/ActivityImageButton/ActivityImageButton.vue';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';
import { insert } from 'ramda';

@Component({
  components: {
    ActivityImageButton,
    Draggable,
  },
})
export default class DraggableImageButtons extends Mixins(
  ComponentsWithRelations,
) {
  @Prop({ required: true })
  private type!: string;

  @Prop({ required: true })
  private data!: IActivityComponentDataElement[];

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private enhancedData: Omit<
    IActivitySolution & { indexFromNativeList: number },
    'meta'
  >[] = this.data.map((element, idx) => ({
    data: {
      ...element,
    },
    indexFromNativeList: idx,
    type: this.type,
  }));

  private setUpdatedEnhancedData(data: Omit<IActivitySolution, 'meta'>[]) {
    this.enhancedData = data.map((item, idx) => ({
      ...item,
      indexFromNativeList: idx,
    }));
  }

  private updateList(data: any) {
    this.enhancedData = [...this.enhancedData, data];
  }

  private updateListByIndex(
    updateData: {
      index: number;
      data: Omit<IActivitySolution & { indexFromNativeList: number }, 'meta'>;
    } | null,
  ) {
    if (!updateData) {
      this.setUpdatedEnhancedData(this.enhancedData);
      return;
    }

    this.enhancedData = insert(
      updateData.index,
      updateData.data,
      this.enhancedData,
    );

    this.setUpdatedEnhancedData(this.enhancedData);
  }

  private mounted() {
    EventBus.$on('activity-event-update-draggable-list', this.updateList);
    EventBus.$on(
      'activity-event-update-draggable-list-by-index',
      this.updateListByIndex,
    );
  }

  private destroyed() {
    EventBus.$off('activity-event-update-draggable-list', this.updateList);
    EventBus.$off(
      'activity-event-update-draggable-list-by-index',
      this.updateListByIndex,
    );
  }
}
</script>

<docs>
### A container that renders a list of draggable image buttons

```jsx
<DraggableImageButtons
  type="draggableImageButtons"
  :data="[
    {
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      id: '1',
      index: '1',
      label: ''
    },

    {
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      id: '2',
      index: '2',
      label: ''
    },

    {
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      id: '3',
      index: '3',
      label: ''
    }
  ]"
/>
```
</docs>
