<template>
  <button class="activity-image-btn" :style="{ 'background-color': bgColor }">
    <img :src="src" alt="Digipal icon" />
  </button>
</template>

<style lang="scss" scoped>
$imageButtonDesktopSize: 135px;
$imageButtonTabletSize: 135px;
$imageButtonMobileSize: 90px;

.activity-image-btn {
  height: $imageButtonDesktopSize;
  width: $imageButtonDesktopSize;

  padding: #{$activityImageButtonOutline}px;
  margin: #{$activityImageButtonGap}px;

  border-radius: 5px;
  user-select: none;

  img {
    height: 100%;
  }

  @media (max-width: #{$tablet}px) {
    height: $imageButtonTabletSize;
    width: $imageButtonTabletSize;
  }

  @media (max-width: #{$mobile}px) {
    height: $imageButtonMobileSize;
    width: $imageButtonMobileSize;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActivityImageButton extends Vue {
  @Prop({ required: true })
  private src!: string;

  @Prop({ default: 'transparent' })
  private bgColor!: string;
}
</script>

<docs>
```jsx
<ActivityImageButton src="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg" />
```
</docs>
