<template>
  <div class="simple-keyboard" />
</template>

<style lang="scss">
@import '~simple-keyboard/build/css/index.css';

.simple-keyboard.littlebridge-keyboard {
  height: 100%;
  padding: 12px 15px 14px;

  border-radius: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $blue100;
  background-color: $blue400;

  .hg-row {
    margin-bottom: 10px;
    justify-content: center;
  }

  .hg-button {
    height: 33px;
    flex: 1;
    max-width: 30px;
    padding: 0;
    line-height: 1;

    border-radius: 5px;
    border-bottom: 0;
    background-color: $white100;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.35);

    font-size: 25px;
    color: $blue100;

    position: relative;

    transition: transform 0.2s;

    &:active {
      transform: translateY(5px);
    }

    &.disabled-key {
      cursor: default;

      background: $grey200;

      .ac-helper {
        &__basis--left,
        &__basis--right,
        &__content {
          background: $grey300;
          border: 1px solid $grey300;
        }
      }
    }

    @media (min-width: #{$mobile}px) {
      max-width: initial;
    }
  }

  .hg-functionBtn {
    max-width: initial;
    flex-grow: 1;
  }

  .hg-button[data-skbtnuid^='numbers-'] {
    width: 33%;
    max-width: none;
  }
}

.simple-keyboard {
  overflow: visible;

  .ac-helper {
    display: none;
    flex-direction: column;
    align-items: center;

    position: absolute;

    bottom: calc(100% - 5px);

    cursor: default;
    pointer-events: none;

    &__content {
      border-radius: 5px;
      background-color: $grey300;
      box-shadow: 1.5px 0 1.5px 0px rgba(0, 0, 0, 0.3);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 33px;

      margin-bottom: -1px;
    }

    &__basis {
      display: flex;

      &--left,
      &--right {
        width: 15px;
        background-color: $grey300;
      }

      &--left {
        border-left: 1px solid $grey300;
      }

      &--right {
        border-right: 1px solid $grey300;
      }
    }

    &--active {
      display: flex;

      &-blue {
        .ac-helper__content,
        .ac-helper__basis--left,
        .ac-helper__basis--right {
          background-color: $grey300;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

// @ts-ignore
import Keyboard from 'simple-keyboard';

enum Mode {
  Lowercase = 'default',
  Capitalize = 'capitalize',
  Uppercase = 'uppercase',
  Numbers = 'numbers',
}

@Component
export default class KeyboardFreewrite extends Vue {
  @Prop({ required: true })
  public input!: string;

  @Prop({ default: () => [], type: Array })
  private disabledKeys!: string[];

  @Prop({ default: Mode.Capitalize })
  private mode!: Mode;

  private setModeValue = this.mode;

  @Prop({
    default: () => [
      'q w e r t y u i o p',
      'a s d f g h j k l',
      '{shift} z x c v b n m {backspace}',
      '{numbers} {space} {ent}',
    ],
  })
  public defaultLayout!: string[];
  private disabledButtonsTimeoutId!: NodeJS.Timeout;

  private keyboard!: Keyboard;

  private setMode(mode: Mode) {
    this.resetAccessabilityHelpers();
    this.keyboard.setOptions({ layoutName: mode });
    this.setModeValue = mode;

    this.$nextTick(() => {
      this.configureAccessabilityHelpers();
    });
  }

  private onChange(input: string) {
    this.$emit('onChange', input);

    if (this.setModeValue === Mode.Capitalize) {
      this.setMode(Mode.Lowercase);
    } else {
      if (input.length === 0) {
        // Case: deleting all letters and re-entering the 1st one:
        this.setMode(Mode.Capitalize);
      } else if (
        input.length >= 2 &&
        input[input.length - 1] === ' ' &&
        input[input.length - 2] === '.'
      ) {
        // Case: capitalizing the 1st letter after a dot:
        this.setMode(Mode.Capitalize);
      }
    }
  }

  private onKeyPress(button: string) {
    this.$emit('onKeyPress', button);

    if (button === '{shift}' || button === '{SHIFT}' || button === '{lock}') {
      this.handleShift();
    } else if (button === '{numbers}' || button === '{abc}') {
      this.handleNumbers();
    }
  }

  private handleShift() {
    const currentLayout = this.keyboard.options.layoutName;

    if (currentLayout === 'default') {
      this.setMode(Mode.Capitalize);
    } else if (currentLayout === 'capitalize') {
      this.setMode(Mode.Uppercase);
    } else {
      this.setMode(Mode.Lowercase);
    }
  }

  private handleNumbers() {
    this.resetAccessabilityHelpers();
    const currentLayout = this.keyboard.options.layoutName;
    const numbersToggle = currentLayout !== 'numbers' ? 'numbers' : 'default';

    this.keyboard.setOptions({
      layoutName: numbersToggle,
    });

    this.$nextTick(() => {
      this.configureAccessabilityHelpers();
    });
  }

  private createAccessabilityHelper(target: HTMLDivElement) {
    const { offsetWidth, offsetHeight } = target;

    const helper = document.createElement('div');
    helper.className = 'ac-helper';
    helper.style.width = `${offsetWidth * 1.3}px`;

    // creating content
    const content = document.createElement('div');
    content.className = 'ac-helper__content';
    content.innerHTML = target.attributes['data-skbtn' as any].value;

    // creating basises
    const basis1 = document.createElement('div');
    basis1.className = 'ac-helper__basis--left';
    basis1.style.height = `${offsetHeight / 2}px`;

    const basis2 = document.createElement('div');
    basis2.className = 'ac-helper__basis--right';
    basis2.style.height = `${offsetHeight / 2}px`;

    const basisSection = document.createElement('div');
    basisSection.className = 'ac-helper__basis';
    basisSection.appendChild(basis1);
    basisSection.appendChild(basis2);

    helper.appendChild(content);
    helper.appendChild(basisSection);

    return helper;
  }

  private setActiveHelperState(node: HTMLDivElement) {
    if (node?.classList?.contains('disabled-key')) {
      return;
    }

    const helper = node.getElementsByClassName('ac-helper')[0];
    helper?.classList?.add('ac-helper--active');
  }

  private setInactiveHelperState(node: HTMLDivElement) {
    const helper = node.getElementsByClassName('ac-helper')[0];
    helper?.classList?.add('ac-helper--active-blue');

    const timeout = setTimeout(() => {
      helper?.classList?.remove('ac-helper--active');
      helper?.classList?.remove('ac-helper--active-blue');

      if (node?.classList?.contains('disabled-key')) {
        node.style.pointerEvents = 'none';
      }
      clearTimeout(timeout);
    }, 70);
  }

  private configureAccessabilityHelpers() {
    const buttons = Array.from(
      document.getElementsByClassName('hg-standardBtn'),
    );

    buttons.forEach(node => {
      const accessabilityHelper = this.createAccessabilityHelper(
        node as HTMLDivElement,
      );
      node.appendChild(accessabilityHelper);

      node.addEventListener('mousedown', () =>
        this.setActiveHelperState(node as HTMLDivElement),
      );
      node.addEventListener('touchstart', () =>
        this.setActiveHelperState(node as HTMLDivElement),
      );

      node.addEventListener('mouseup', () =>
        this.setInactiveHelperState(node as HTMLDivElement),
      );
      node.addEventListener('touchend', () =>
        this.setInactiveHelperState(node as HTMLDivElement),
      );
    });
  }

  private resetAccessabilityHelpers() {
    const buttons = Array.from(
      document.getElementsByClassName('hg-standardBtn'),
    );

    buttons.forEach(node => {
      const accessabilityHelper = node.getElementsByClassName('ac-helper')[0];

      node.removeEventListener('mousedown', () =>
        this.setActiveHelperState(node as HTMLDivElement),
      );
      node.removeEventListener('touchstart', () =>
        this.setActiveHelperState(node as HTMLDivElement),
      );

      node.removeEventListener('mouseup', () =>
        this.setInactiveHelperState(node as HTMLDivElement),
      );
      node.removeEventListener('touchend', () =>
        this.setInactiveHelperState(node as HTMLDivElement),
      );

      (node as HTMLButtonElement).style.pointerEvents = '';

      if (accessabilityHelper) {
        node.removeChild(accessabilityHelper);
      }
    });
  }

  private setDisabledButtons(buttons: string[]) {
    Array.from(document.getElementsByClassName('hg-standardBtn')).forEach(
      button => {
        const letter = button.attributes.getNamedItem('data-skbtn');
        const activeClass = 'disabled-key';

        if (letter && buttons.includes(letter.value.toLowerCase())) {
          const activeClass = 'disabled-key';
          button?.classList?.add(activeClass);
        } else {
          button?.classList?.remove(activeClass);
        }
      },
    );
  }

  public mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      theme: 'hg-theme-default littlebridge-keyboard',
      layout: {
        [Mode.Lowercase]: this.defaultLayout,
        [Mode.Capitalize]: [
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          '{shift} Z X C V B N M {backspace}',
          '{numbers} {space} {ent}',
        ],
        [Mode.Uppercase]: [
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          '{SHIFT} Z X C V B N M {backspace}',
          '{numbers} {space} {ent}',
        ],
        numbers: [
          '1 2 3 4 5 6 7 8 9 0',
          '. , ! ? ; : ( ) * &',
          '" \' - + = # @ _ / °',
          '{abc} {space} {ent}',
        ],
      },
      display: {
        '{numbers}': '123',
        '{space}': 'space',
        '{ent}': 'return',
        '{escape}': 'esc ⎋',
        '{tab}': 'tab ⇥',
        '{backspace}': '⌫',
        '{capslock}': 'caps lock ⇪',
        '{shift}': '⇧',
        '{SHIFT}': '⬆',
        '{controlleft}': 'ctrl ⌃',
        '{controlright}': 'ctrl ⌃',
        '{altleft}': 'alt ⌥',
        '{altright}': 'alt ⌥',
        '{metaleft}': 'cmd ⌘',
        '{metaright}': 'cmd ⌘',
        '{abc}': 'ABC',
      },
    });

    this.setMode(this.setModeValue);
  }

  private beforeDestroy() {
    clearTimeout(this.disabledButtonsTimeoutId);
  }

  @Watch('input', { immediate: true })
  private onInputChange(newInput: string) {
    // Keyboard might not be mounted yet when this gets executed:
    window.requestAnimationFrame(() => {
      this.keyboard.setInput(newInput);
    });
  }

  @Watch('disabledKeys')
  private onActiveKeysChanged(newKeys: string[]) {
    // timeout is used to avoid UI issues with configuring ac helpers
    this.disabledButtonsTimeoutId = setTimeout(
      () => this.setDisabledButtons(newKeys),
      50,
    );
  }
}
</script>
