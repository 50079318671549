import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: 'Пожалуйста, сначала выберите причину!',
    title: 'Правила сообщества',
    popup: {
      title: 'Спасибо за помощь в обеспечении безопасности нашего сообщества!',
      btn: 'Вернуться к правилам сообщества',
    },
    pt1: {
      subtitle: 'Что такое сообщество DigiPals?',
      text:
        'Наше сообщество DigiPals - это безопасное место, где вы можете найти друзей и попрактиковаться в английском языке.',
    },
    pt2: {
      subtitle: 'Каковы правила сообщества?',
      text1: 'Всегда пиши на английском',
      text2:
        'Не пишите ничего недружелюбного, оскорбительного или недоброго. Нецензурная брань не допускается!',
      text3:
        'Не сообщайте никакие личные данные, например Ваше полное имя, адреса, номера телефонов, адреса электронной почты или другие данные учетной записи в Интернете / социальных сетях.',
      text4: 'Все сообщения просматривают реальные люди.',
    },
    pt3: {
      subtitle: 'Что мне делать, если я думаю, что кто-то нарушает правило?',
      text1:
        'Отправка этого отчета помогает нам сделать всех в нашем сообществе счастливыми.',
      text2: 'Мы свяжемся с вашим взрослым, чтобы узнать больше и помочь вам',
    },
    btn: 'Отправить жалобу',

    option1: 'Я думаю, что кто-то пишет не по-английски',
    option2: 'Я думаю, что кто-то ведет себя недружелюбно',
    option3: 'Я думаю, кто-то поделился личной информацией',
  },

  globe: {
    _: 'Глобус',
    friendsMultiple: 'У тебя есть {friends} друзей',
    friends: 'У тебя есть 1 друг',
    countries: 'в 1 стране',
    countriesMultiple: 'в {countries} странах',
    defaultTitle: 'Наше глобальное сообщество DigiPal!',
    fetchFriend: 'Приведи нового друга прямо сейчас!',
  },

  profile: {
    avatar: {
      title: 'Создать мой аватар',
    },
    error: 'Ой! Что-то пошло не так. Не могли бы вы попробовать еще раз?',
    messagesEmpty:
      'Ой! Здесь пока нет сообщений. Перейдите к списку рекомендованных DigiPals, посетите их профили и начните общение!',
    postTitle: 'Сообщения с моего DigiPals',
    writePost: 'Напишите DigiPost',
    profileError: 'Не удалось получить сообщения профиля.',
    clock: 'В какое время живет {username}?',
    country: '{username}\nживет в\n{country}',
    myCountry: 'Я живу в\n{country}',
    myClock: 'Который час там,\nгде я живу?',
    friendsAccepted: 'Вы уже друзья!',
    friendsAdd: 'Отправить дигипальный запросс',
    friendsPending: 'Дигипальный запрос в ожидании',
  },

  dictionary: {
    alphabet: {
      error: 'Не удалось получить алфавит',
    },
    words: {
      error: 'Не удалось получить слова',
    },
  },

  dates: {
    yearsAgo: '{count} лет назад',
    yearAgo: 'год назад',
    monthsAgo: '{count} месяцев назад',
    monthAgo: 'месяц назад',
    daysAgo: '{count} дней назад',
    dayAgo: 'день назад',
    hoursAgo: '{count} часов назад',
    hourAge: 'час назад',
    minutesAgo: '{count} минут назад',
    minuteAgo: 'минуту назад',
    seconds: 'несколько секунд назад',
  },

  common: {
    someone: 'Кто-то',
  },

  digiPal: {
    messagesEmpty: 'Здесь нет сообщений! Напишите первым!',
  },

  interests: {
    title: 'Выбери мои интересы',
  },

  achievements: {
    title: 'Смотреть мои достижения',
    messageTrophy:
      'Получайте трофеи, отправляя сообщения на DigiPals! Только сообщения, на которые будет получен ответ, будут засчитываться в ваши трофеи.',
    activityTrophy:
      'Получайте трофеи, выполняя задания! Каждая выигранная звезда засчитывается для ваших трофеев.',
  },

  settings: {
    title: {
      1: 'Учить британский английский или американский английский?',
      2: 'Получать помощь на?',
    },
  },

  digipals: {
    genericErrorMessage:
      'Ой! Что-то пошло не так. Не могли бы вы попробовать еще раз?',

    all: {
      title: 'Мои DigiPals',
      emptyErrorMessage:
        'У вас еще нет друзей в сети. Перейдите в раздел «Рекомендуемые DigiPals», чтобы завести друзей!',
      successRequest:
        'Вы отправили {friend} предложение дружбы. Оставьте сообщение в их профиле, чтобы рассказать им, почему вы хотите стать друзьями!',
      clock:
        'Который час, где живет {digipal}? Помните, что некоторые DigiPals могут спать, когда для вас это дневное время!',
    },
    mutual: {
      title: 'Общие DigiPals',
      emptyErrorMessage: 'У тебя нет общих друзей.',
    },
    pending: {
      title: 'DigiPal запросы',
      emptyErrorMessage:
        'У вас еще нет ожидающих запросов. Перейдите в раздел «Рекомендуемые DigiPals», чтобы завести друзей!',
      successAccept:
        'Yтеперь ты дружишь с {friend}. Посетите их профиль, чтобы поздороваться!',
    },
    search: {
      title: 'Найди DigiPal',
    },
    suggested: {
      title: 'Сообщение друзей, которые онлайн сейчас!',
      emptyErrorMessage: 'У вас нет друзей в сети.',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        'Привет! Я Domino. Я помогу тебе найти друзей по всему миру! Если вам понадобится помощь, вы можете найти меня на панели инструментов. Это страница вашего профиля DigiPal. Он всем что-то рассказывает о вас. Начни исследовать!',
      post: {
        reply: '{username} ответил на ваше сообщение! Продолжаем разговор 😁',
        wallMessage:
          '{username} оставил сообщение в вашем профиле! Продолжаем разговор 😁',
      },
      dominoAdd:
        'Поздравляем! Вы добились успеха! Теперь Domino появится в вашем профиле!',
      dominoRem:
        'о нет! Вы потеряли свою серию. Заработайте 20 монет, чтобы вернуть Domino в свой профиль :)',
    },
    messageDigipal: 'Написать {username}',
    messageFrom: 'Сообщение от\n{userName}',
    friendRequest: 'Запрос в друзья\n{userName}',
    requestAccepted: 'Запрос принят\n{userName}',
    likeDash: '{userName}\nпонравилась ваша страница',
  },

  popup: {
    activityLeaveConfirmation:
      'Вы уверены, что хотите выйти из активности? Ваш прогресс не будет сохранен.',
    testLeaveConfirmation:
      'Вы уверены, что хотите выйти из теста? Вы не сможете повторить это снова.',
    streakPrefix: 'Серия {streak} дня(ей)',
    streak0:
      'Зарабатывайте 20 монет в день, чтобы начать свою серию! Чтобы заработать Lingos, отправляйте сообщения своим друзьям и выполняйте задания!',
    premiumGamesErrorMessage:
      'Ой! У вас нет доступа к играм. Попросите родителей об обновлении аккаунта!',
    lingosErrorMessage:
      'Монеты нужны вам для покупки некоторых вещей. Отправляйте сообщения, чтобы выиграть монеты.',
    offlineErrorMessage: 'Ой! Интернет-соединение не работает.',
    genericErrorMessage:
      'Ой! Что-то пошло не так. Не могли бы вы попробовать еще раз?',
    newsfeedErrorMessage:
      'Ой! Ваше сообщение не было отправлено. Не могли бы вы попробовать еще раз?',
    avatarCustomizationMenu:
      'Настройте свой аватар! Монеты нужен вам для покупки некоторых вещей. Выполняйте задания или отправляйте сообщения, чтобы выиграть монеты.',
    wallpaperSettings:
      'Настройте обои своего профиля! Монеты нужны вам для покупки некоторых вещей. Выполняйте задания или отправляйте сообщения, чтобы выиграть монеты.',
    interestsSettings:
      'Покажи свои увлечения и хобби! Монеты нужны вам для покупки некоторых вещей. Выполняйте задания или отправляйте сообщения, чтобы выиграть монеты.',
    achievementsMenu: 'Посмотрите, сколько наград вы выиграли!',
    globe: `Найдите новые DigiPals по всему миру. Посетите их профиль, оставьте им сообщение, чтобы выиграть монеты. Используйте ${htmlFeatures.plusIcon}, чтобы пригласить их стать вашим DigiPal.`,
    friendsSuggested:
      'Подружитесь с другими DigiPals! Вам рекомендуют несколько DigiPals, специально для вас! Пригласите их связаться с вами.',
    friends:
      'См. DigiPals, к которой вы подключены. Посетите их профили, оставьте им сообщение и выиграйте Lingos.',
    friendsPending:
      'Смотрите DigiPals, кто хочет связаться с вами. Примите их приглашение и перейдите в их профиль, чтобы оставить сообщение, чтобы выиграть монеты.',
    friendsSearch: `Знаете чье-то имя в DigiPal? Найдите их здесь и перейдите в их профиль, чтобы оставить сообщение и выиграть больше монеты. Используйте ${htmlFeatures.plusIcon}, чтобы пригласить их стать вашим DigiPal`,
    login: `Заводить друзей - это здорово! Посетите "Рекомендованных друзей" или посетите весь мир, чтобы завести новых друзей 😁`,
    profileLike: `Вам понравилась страница профиля {username}! Они будут очень счастливы. Оставьте им сообщение, чтобы они знали, почему вам это понравилось!`,
    streakNotice:
      'Хотите крутого, настраиваемого питомца Domino? Чтобы получить Domino, зарабатывайте 20 монет каждый день. Это ваша «Серия».',
    profilePage:
      'Привет! Я Домино. Я помогу тебе найти друзей по всему миру! Если вам понадобится помощь, вы можете найти меня на панели инструментов. Это страница вашего профиля DigiPal. Он всем что-то рассказывает о вас. Начни исследовать!',
  },

  activity: {
    rewards: {
      trophyTitle: 'Вы выиграли трофей!',
      starTitle: 'Вы выиграли звезду!',
      lingoTitle: 'У вас есть столько монет: {lingos}',
      score: 'Счет',
      returnToActivities: 'Вернуться к действиям',
    },
    currentLesson: 'Текущий урок',
    offlineMessage:
      'Ой ой! Вы потеряли подключение к Интернету. Не волнуйтесь, мы сохранили ваш счет активности!',
  },

  onboarding: {
    first:
      'Познакомьтесь с DigiPals со всего мира и отправьте им сообщения на английском языке.',
    second: 'Нужна помощь с английским? Вы можете начать обучение здесь.',
    third:
      'Создайте свой аватар. Покажите, что вам нравится. Найдите другие DigiPals.',
    fourth:
      'Посетите ваш DigiPals. Оставляйте сообщения. Присоединяйтесь к чату. Пригласите DigiPals написать вам сообщение.',
    fifth:
      'Выигрывайте Lingos за сообщения и прохождение игр. Покупайте вещи для своего аватара и для Domino.',
    sixth: 'Сохраните успех в Little Bridge и получите Domino в свой профиль!',
    seventh: {
      title: 'Готовы подружиться?',
      btn: `Поехали!`,
    },
  },
  tooltip: {
    toolbar: {
      findFriends: 'Найдите\nновых\nдрузей',
      home: 'Дом',
      activities: 'Домино Академия',
      dominoAcademy: 'Домино\nАкадемия',
      friends: 'друзья',
      streak: 'Полоса',
      globe: 'Глобус',
      information: 'Инфо',
      menu: 'Меню',
      lingos: 'Лингос',
      notifications: 'Уверенность',
      notificationsactive: 'Уверенность',
      audio: 'Повторить вопрос',
      exit: 'Закрыть активность',
      snail: 'Медленный вопрос',
      steps: 'Продолжать',
      fetchFriend: 'Найдите новых друзей',
    },
    profile: {
      interests: 'Интересы',
      digipals: 'Мои дигипалы',
      achievements: 'Мои достижения',
      editWallpaper: 'Редактировать обои',
      addDigipal: 'Добавить дигипал',
      offline: 'Не в сети',
      online: 'онлайн',
      myAvatar: 'Мой дигипал',
      avatar: 'Аватар',
      sendMessage: 'Отправить сообщение',
      likeProfile: 'Лайкнуть профиль',
      myFriends: 'Мои друзья',
      myLikes: 'Мои лайки',
      digipalLikes: 'Нравится этот профиль',
      alreadyLiked: 'Вам уже нравится этот профиль',
      myMessages: 'Мои сообщения',
      requestPending: 'Ожидание запроса DigiPal',
      weAreDigipals: 'Мы DigiPals!',
    },
    messages: {
      likes: 'Нравится',
      send: 'Отправлять',
      saySomething: 'Скажите что-то',
      askSomething: 'Спросить что-то',
      emoji: 'Emoji',
      reply: 'Отвечать',
    },
    activities: {
      supportLanguage: 'русская инструкция',
      teachingLanguage: 'Английские инструкции',
      next: 'Следующий',
      previous: 'Предыдущий',
      play: 'Играть',
      slowPlay: 'Медленно',
      record: 'Записывать',
      playback: 'Воспроизведение',
    },
    other: {
      back: 'Назад',
      getItem: 'Получить предмет',
      zoomIn: 'Приблизить',
      zoomOut: 'Уменьшить',
    },
  },

  conversations: {
    sendMessage: 'Отправить сообщение',
    myInbox: 'Мой почтовый ящик',
    send: 'Отправить сообщение',
    yourChat:
      'Ваш разговор с <p class="conversation__header__subtitle">{username}</p>',
    noMessages: 'Сообщений пока нет! Возьми инициативу в свои руки и напиши!',
  },

  messageRewards: {
    title: 'Вы отправили сообщение {username}!',
    returnToHome: 'Вернуться на страницу профиля',
  },
};
