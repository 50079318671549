<template>
  <div class="speech-bubbles">
    <DraggableSpeechBubble
      v-for="bubble in data"
      :key="bubble.id"
      :data="bubble"
      :type="type"
      :order="getOrder(Number(bubble.index))"
      :draggable-parts="draggableGapsSentenceParts(bubble)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import DraggableSpeechBubble from './DraggableSpeechBubble.vue';

import ActivityDraggableGaps from '../mixins/ActivityDraggableGaps.mixin';

@Component({
  components: {
    DraggableSpeechBubble,
  },
})
export default class ActivitySpeechBubbles extends Mixins(
  ActivityDraggableGaps,
) {}
</script>

<docs>
### A container that renders speech bubbles

```vue
<template>
  <div data-activity-asset style="height: 400px; position: relative">
    <ActivitySpeechBubbles
      v-if="show"
      type="draggableSpeechBubbles"
      :data="[
        {
          id: '1',
          label: 'Test 1',
          index: 1,
          value: 'left:30%;top:20%;width:25%;height:20%;direction:left'
        },
        {
          id: '2',
          label: 'Test 2',
          index: 1,
          value: 'left:10%;top:50%;width:25%;height:20%;direction:right'
        }
      ]"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.$nextTick(() => (this.show = true))
  }
}
</script>
```
</docs>
