import { Component, Vue } from 'vue-property-decorator';

export const features = {
  __test_true: true,
  __test_false: false,
  SCREEN_PROFILE: true,
  SCREEN_GLOBE: true,
  SCREEN_NEWSFEED: false,
  SCREEN_TRAINING: true,
  SCREEN_REFERRALS: false,
  SCREEN_SETTINGS: true,
  MENU_DROPDOWN: true,
  POPUP_STREAK: true,
  POPUP_LINGOS: true,
  POPUP_NOTIFICATIONS: true,
  FEATURE_FRIENDSHIP_STREAK: false,
  STUDDY_BUDDY: true,
  SETTINGS_PAGE_EMAIL: false,
  SETTINGS_PAGE_SB_SELECTION: false,
  MUTUAL_FRIENDS: false,
  APP_DICTIONARY: true,
  MENU_STAR: false,
  SUPPORTED_HELP_LANGUAGES: [
    'es',
    'gb',
    'us',
    'fr',
    'tr',
    'ru',
    'az',
    'th',
    'cn',
  ],
};

export type Features = typeof features;
export type FeatureKeys = keyof Features;

export function isFeatureEnabled(feature: FeatureKeys) {
  return features[feature] === true;
}

@Component
export default class AppFeatures extends Vue {
  public isAppFeatureEnabled(feature: FeatureKeys) {
    return isFeatureEnabled(feature);
  }
}
