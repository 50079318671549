var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-container"},[(_vm.isDraggableGaps)?_c('div',{staticClass:"draggable-gaps",style:({ padding: _vm.hasManyGaps ? '10px' : '0' })},_vm._l((_vm.draggableGapsSentenceParts(_vm.data[0])),function(part,idx){return _c('Draggable',{key:part.valueWithUnderscore,class:{
        'draggable-container-gaps': true,
        'draggable-container-gaps--multiple': _vm.hasManyGaps,
      },attrs:{"list":_vm.gapsData[idx],"disabled":_vm.isDisableDragging,"tag":_vm.hasManyGaps ? 'span' : 'div',"group":"dragAndDropActivity","animation":"150"},domProps:{"innerHTML":_vm._s(
        _vm.gapsData[idx] && _vm.gapsData[idx].length
          ? _vm.parsedString(part.valueWithUnderscore, idx)
          : part.valueWithUnderscore
      )},on:{"change":function($event){return _vm.handleUpdateDraggableGaps($event, idx)}}})}),1):(_vm.isTypingGaps)?_c('div',{staticClass:"typing-container"},[_vm._l((_vm.sentenceParts(_vm.data[0].value)),function(part,partIdx){return [_vm._v(" "+_vm._s(part.value)+" "),(part.letters)?_c('div',{staticClass:"input-wrapper"},_vm._l((part.letters),function(inputIdx){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.models[_vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value) - 1]
          ),expression:"\n            models[getGapInputIndex(inputIdx, partIdx, data[0].value) - 1]\n          "}],key:_vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].label),ref:`input${_vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value)}`,refInFor:true,staticClass:"input",attrs:{"data-key":_vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value),"type":"text","autocapitalize":"none"},domProps:{"value":(
            _vm.models[_vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value) - 1]
          )},on:{"keyup":function($event){_vm.onRemoveValue(
              $event,
              _vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value),
            )},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.models, _vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value) - 1, $event.target.value)},function($event){_vm.focusNext(
              $event,
              _vm.getGapInputIndex(inputIdx, partIdx, _vm.data[0].value),
            )}]}})}),0):_vm._e()]})],2):[(_vm.text)?_c('p',{staticClass:"text-trigger"},[_vm._v(_vm._s(_vm.text))]):_c('Draggable',{staticClass:"draggable-container",attrs:{"group":"dragAndDropActivity","animation":"150","list":_vm.list},on:{"change":_vm.handleUpdate,"start":_vm.setLastList}},_vm._l((_vm.list),function({ data: button }){return _c('ActivityTextButton',{key:button.id,attrs:{"bg-color-type":button.label ? 'audio' : 'draggable'}},[_vm._v(" "+_vm._s(button.value)+" ")])}),1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }