import DataProvider from '@/utils/helpers/DataProvider';
import StoreUtil from '@/utils/helpers/StoreUtil';

import {
  IUser,
  IUserProfile,
  IUserStatus,
  ILikeData,
} from '@/models/interfaces/users';
import { IVuexState } from '@/models/interfaces/store';
import { RootState } from '..';
import { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import { AxiosError } from 'axios';
import { uniq } from 'ramda';

export interface State {
  user: IVuexState<IUser>;
  userProfile: IVuexState<IUserProfile>;
  userStatus: IVuexState<IUserStatus>;
  likedUsersIds: string[];
}

export const getters: GetterTree<State, RootState> = {
  isUserLiked(state) {
    return (id: string) => state.likedUsersIds.includes(id);
  },
};

export const mutations: MutationTree<State> = {
  setUser(state, data?: IUser | Error) {
    state.user = StoreUtil.updateState(state.user, data);
  },
  setUserProfile(state, data?: IUserProfile | Error) {
    state.userProfile = StoreUtil.updateState(state.userProfile, data);
  },
  setUserStatus(state, data?: IUserStatus | Error) {
    state.userStatus = StoreUtil.updateState(state.userStatus, data);
  },
  addLikedUserId(state, id: string) {
    state.likedUsersIds = uniq([...state.likedUsersIds, id]);
  },
};

export const actions: ActionTree<State, RootState> = {
  async fetchUser({ commit }, userId: string) {
    commit('setUser');

    try {
      const { data } = await DataProvider.get(`users/${userId}`);
      commit('setUser', data);
      return data;
    } catch (error) {
      commit('setUser', error);
      return error;
    }
  },
  async fetchUserProfile({ commit }, userId: string): Promise<IUserProfile> {
    commit('setUserProfile');

    try {
      const { data } = await DataProvider.get<IUserProfile>(
        `users/${userId}/profile`,
      );
      commit('setUserProfile', data);
      return data;
    } catch (error) {
      commit('setUserProfile', error);
      // @ts-ignore
      return error;
    }
  },
  async fetchUserStatus({ commit }, userId: string): Promise<IUserStatus> {
    commit('setUserStatus');

    try {
      const { data } = await DataProvider.get<IUserStatus>(
        `users/${userId}/status`,
      );
      commit('setUserStatus', data);
      return data;
    } catch (error) {
      commit('setUserStatus', error);
      return error as IUserStatus;
    }
  },
  async updateUserProfile(
    { commit },
    {
      userId,
      payload,
      mutateOnRequest = true,
    }: {
      userId: string;
      payload: Partial<IUserProfile>;
      mutateOnRequest: boolean;
    },
  ) {
    const mutate = (payload?: Partial<IUserProfile> | AxiosError) => {
      commit('profile/setProfile', payload, { root: true });
      commit('setUserProfile', payload);
    };

    mutateOnRequest && mutate(payload);

    try {
      const { data } = await DataProvider.put(
        `users/${userId}/profile`,
        payload,
      );
      mutateOnRequest && mutate(payload);
      return data;
    } catch (error) {
      // @ts-ignore
      mutate(error);
      return error;
    }
  },
  async likeUserProfile({ commit }, data: ILikeData) {
    try {
      const { data: response } = await DataProvider.post('likes', data);
      commit('addLikedUserId', data.likeUserId);
      return response;
    } catch (error) {
      return error;
    }
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  mutations,
  actions,
  getters,
  state: {
    user: StoreUtil.state(),
    userProfile: StoreUtil.state(),
    userStatus: StoreUtil.state(),
    likedUsersIds: [],
  },
};

export default store;
