import { render, staticRenderFns } from "./DraggableItemWrapper.vue?vue&type=template&id=65173c7e&scoped=true"
import script from "./DraggableItemWrapper.vue?vue&type=script&lang=ts"
export * from "./DraggableItemWrapper.vue?vue&type=script&lang=ts"
import style0 from "./DraggableItemWrapper.vue?vue&type=style&index=0&id=65173c7e&prod&lang=scss"
import style1 from "./DraggableItemWrapper.vue?vue&type=style&index=1&id=65173c7e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65173c7e",
  null
  
)

export default component.exports