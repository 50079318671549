var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._b({class:[
    'base-button',
    _vm.typeClass(),
    {
      'base-button--touched': _vm.isTouched,
      'base-button--append': _vm.$slots.append,
      'base-button--loading': _vm.loading,
      'base-button--squared': _vm.squared,
    },
  ],attrs:{"type":"button"},on:{"mousedown":function($event){_vm.isTouched = true},"mouseup":function($event){_vm.isTouched = false},"touchstart":function($event){_vm.isTouched = true},"touchend":function($event){_vm.isTouched = false},"blur":function($event){_vm.isTouched = false}}},'button',_vm.$attrs,false),[(_vm.loading)?_c('Spinner',{style:({ margin: 0 }),attrs:{"color":"white"}}):_vm._t("default"),(_vm.icon)?_c('img',{staticClass:"base-button__icon",attrs:{"src":`/images/icons/${_vm.icon}.png`,"alt":"Base Button Icon"}}):_vm._e(),(_vm.$slots.append)?_c('div',{class:['base-button__append', _vm.typeClass('__append')]},[_vm._t("append")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }