import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ActivityWithTypingGaps extends Vue {
  protected models: string[] = [];

  protected getInput(inputIdx: number) {
    return this.$refs[`input${inputIdx}`] as HTMLInputElement[];
  }

  protected sentenceParts(sentence: string) {
    const partsBetweenGaps = sentence.split(/[_]+/);

    // calculating the dashes data
    const underscoreMatches = sentence.match(/([_])\1*/g) || [];
    const dashParts = underscoreMatches.map(item => item.length);

    return partsBetweenGaps.map((item, idx) => ({
      value: item,
      letters: dashParts[idx] ? dashParts[idx] : 0,
      valueWithUnderscore: `${item} ${underscoreMatches[idx]}`,
    }));
  }

  protected focusNext(e: InputEvent, inputIdx: number) {
    const value = (e.target as HTMLInputElement).value;
    const nextInput = this.getInput(inputIdx + 1);

    if (value && value.length > 1) {
      this.models[inputIdx - 1] = value.substring(0, 1);

      if (nextInput) {
        nextInput[0].focus();
        this.models[inputIdx] = value[value.length - 1];
      }

      return;
    }

    if (value && value.length === 1) {
      if (nextInput) {
        nextInput[0].focus();
      }
    }
  }

  protected onRemoveValue(e: KeyboardEvent, inputIndex: number) {
    const prevInput = this.$refs[
      `input${inputIndex - 1}`
    ] as HTMLInputElement[];

    if (e.key === 'Backspace' && prevInput) {
      prevInput[0].focus();
    }
  }

  protected getGapInputIndex(
    inputIndex: number,
    sentencePartIndex: number,
    label: string,
  ) {
    const parts = this.sentenceParts(label);
    const prevParts = parts.filter(
      (part, partIdx) => partIdx < sentencePartIndex,
    );

    if (!prevParts.length) {
      return inputIndex;
    }

    const prevPartsInputs = prevParts.reduce((a, b) => a + b.letters, 0);

    return inputIndex + prevPartsInputs;
  }
}
