import {
  IUpdateColorSettings,
  IColorSettings,
} from '@/models/interfaces/color-settings';

import VisualSettings from '@/utils/helpers/VisualSettings';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '..';

const defaultSongMenuSettings = VisualSettings.getCommonDefaultSettings();

export interface State {
  songMenuSettings: IColorSettings;
}

export const actions: ActionTree<State, RootState> = {
  saveSongMenuSettings({ state }) {
    localStorage.setItem(
      'songMenuSettings',
      JSON.stringify(state.songMenuSettings),
    );
  },
};

export const mutations: MutationTree<State> = {
  updateSongMenuSettings(state, settings: IUpdateColorSettings) {
    state.songMenuSettings = Object.assign(state.songMenuSettings, settings);
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state: {
    songMenuSettings: VisualSettings.getTilesSettingsFromStorage(
      defaultSongMenuSettings,
      'songMenuSettings',
    ),
  },
};
