<template>
  <div class="progressbar">
    <div class="progressbar__wrapper">
      <div class="progressbar__bottom" />

      <div
        :class="[
          'progressbar__top',
          { 'progressbar__top--inactive': percentage === 0 },
        ]"
        :style="{ width: `${percentage}%` }"
      />

      <div
        :style="{ left: `calc(${percentage}% - 15px)` }"
        :class="[
          'progressbar__circle',
          `progressbar__circle--${isValid ? 'success' : 'error'}`,
          { 'progressbar__circle--inactive': !isCircleShown },
        ]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ActivityProgress extends Vue {
  @Prop({ required: true })
  public percentage!: number;

  @Prop({ required: true })
  public isValid!: boolean;

  public isCircleShown = false;

  @Watch('percentage')
  public onPercentageChange() {
    this.isCircleShown = true;

    window.setTimeout(() => (this.isCircleShown = false), 1000);
  }
}
</script>

<docs>
Component for the showing the activity progress

```jsx
<ActivityProgress
  style="position: relative;"
  :percentage="20"
  :is-valid="false"
/>
```
</docs>

<style lang="scss" scoped>
$bottom-border-color: $grey200;
$bottom-bg-color: #f6f6f6;

$top-border-color: #f8ad18;
$top-bg-color: #f3e80e;

$circle-border-color-error: #e90c6e;
$circle-bg-color-error: #f696c0;

$circle-border-color-success: #00a251;
$circle-bg-color-success: #88c23f;

$circle-pimp-color: #f8f8f8;

.progressbar {
  position: fixed;

  width: 50%;
  bottom: 27px;
  left: calc(50% - 90px);

  transform: translateX(-50%);

  z-index: map-get($zindex, activityProgressBar);

  @media (max-width: #{$mobile}px) {
    bottom: 18px;
    transform: none;
    left: 10px;
    width: calc(100% - 80px);
  }

  &__bottom,
  &__top {
    position: absolute;

    border-radius: 11px;
    height: 100%;
  }

  &__bottom {
    z-index: 1;
    width: 100%;

    border: 3.5px solid $bottom-border-color;
    background-color: $bottom-bg-color;
  }

  &__top {
    z-index: 2;

    border: 3.5px solid $top-border-color;
    background-color: $top-bg-color;

    transition: width 0.5s;

    &--inactive {
      border: 0;
    }
  }

  &__circle {
    position: absolute;
    z-index: 2;

    top: -7px;

    width: 33px;
    height: 33px;

    border-radius: 50%;
    transition: 0.5s;

    &--error {
      border: 3.5px solid $circle-border-color-error;
      background-color: $circle-bg-color-error;
    }

    &--success {
      border: 3.5px solid $circle-border-color-success;
      background-color: $circle-bg-color-success;
    }

    &--inactive {
      transform: scale(0);
    }
  }

  &__wrapper {
    position: relative;

    height: 17px;
  }
}
</style>
