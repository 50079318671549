<template>
  <div>
    <transition name="fade" appear>
      <div v-if="isVisible" class="activity-confirm-image" data-activity-asset>
        <div class="activity-confirm-image__element">
          <AssetImage :src="src" :controls="false" />
        </div>
      </div>
    </transition>

    <slot v-if="isVisible" />
  </div>
</template>

<style lang="scss" scoped>
.activity-confirm-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  max-width: $activityComponentContainerWidth;
  margin: auto;
  z-index: map-get($zindex, confirmationImage);

  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
}

.activity-confirm-image__element {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: $activityMediaTopSpace;
  border: $activityMediaBorderSize solid $white100;
  border-radius: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-in-out 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AssetImage from '@/components/Asset/Image.vue';
import EventBus from '@/utils/helpers/EventBus';

@Component({
  components: {
    AssetImage,
  },
})
export default class ActivityConfirmationImage extends Vue {
  @Prop({ required: true, default: '' })
  public src!: string;

  @Prop({ required: true, default: false })
  public isVisible!: boolean;

  public mounted() {
    this.handleImageHide();
  }

  private handleImageHide() {
    if (this.isVisible) {
      window.setTimeout(() => {
        EventBus.$emit('activity-event-image-hide');
      }, 3000);
    }
  }
}
</script>
