import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { IPopupData } from '@/models/interfaces/popup';
import { TranslateResult } from 'vue-i18n';

const INSTRUCTION_POPUPS_ROUTE_NAMES = [
  'friends',
  'friends-pending',
  'search-friends',
  'suggested-friends',
  'globe',
  'profile-achievements',
  'profile',
  'interests',
  'settings-wallpaper',
];

@Component
export default class PopupMixin extends Vue {
  @Mutation('togglePopup')
  protected togglePopup!: (data?: IPopupData) => void;

  private get disabledPopupForPage() {
    return !INSTRUCTION_POPUPS_ROUTE_NAMES.includes(this.$route.name!);
  }

  protected showPopup({
    message,
    popupOptions = {},
  }: {
    message: TranslateResult;
    popupOptions?: Pick<IPopupData, Exclude<keyof IPopupData, 'message'>>;
  }) {
    this.togglePopup({
      message: message.toString(),
      ...popupOptions,
    });
  }
}
