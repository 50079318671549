<template>
  <header class="top-toolbar">
    <router-link to="/">
      <img
        class="top-toolbar__logo"
        src="/images/icons/app-logo.png"
        alt="App Logo"
      />
    </router-link>

    <div class="top-toolbar__tools">
      <div class="top-toolbar__info-item">
        <img
          class="top-toolbar__info-img"
          src="/images/icons/lingos.png"
          alt="Lingo Icon"
        />

        {{ lingos }}
      </div>

      <div
        v-if="isAppFeatureEnabled('MENU_STAR')"
        class="top-toolbar__info-item"
      >
        <img
          class="top-toolbar__info-img"
          src="/images/icons/star.png"
          alt="Star Icon"
        />
        94
      </div>

      <AppBurgerButton @select="toggleMenu" />
    </div>

    <NavigationMenu
      v-if="isAppFeatureEnabled('MENU_DROPDOWN') && isDropdownMenuVisible"
      @on-close="toggleMenu"
    />
  </header>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';

import NavigationMenu from '@/components/NavigationMenu/NavigationMenu.vue';
import AppBurgerButton from '@/components/AppBurgerButton/AppBurgerButton.vue';

import AppFeatures from '@/utils/mixins/AppFeatures';
import sounds from '@/utils/helpers/sounds';

@Component({
  components: {
    NavigationMenu,
    AppBurgerButton,
  },
})
export default class AppTopToolbar extends Mixins(AppFeatures) {
  @State('lingo', { namespace: 'profile' })
  public lingos!: number;

  @Getter('getDropdownMenuState')
  public isDropdownMenuVisible!: boolean;

  @Mutation('toggleRootDropdownMenuVisibility')
  public setDropdownMenuVisibility!: (state: boolean) => void;

  public toggleMenu() {
    sounds.pop.play();

    this.setDropdownMenuVisibility(!this.isDropdownMenuVisible);
  }
}
</script>

<style lang="scss" scoped>
.top-toolbar {
  background-color: $blue400;
  box-shadow: 0px 2px 0px $blue100;

  display: flex;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  padding: 0 23px;
  height: 55px;

  width: 100%;
  z-index: 20;

  user-select: none;

  @media (min-width: #{$mobile}px) {
    height: 80px;
  }

  &__info-img {
    height: 40px;

    margin-right: 10px;
  }

  &__info-item {
    display: flex;
    align-items: center;

    color: $white100;
    font-weight: 700;
    font-size: 25px;

    @media (min-width: #{$mobile}px) {
      margin-right: 40px;
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-grow: 1;

    @media (min-width: #{$mobile}px) {
      flex-grow: initial;

      margin-left: auto;
    }
  }

  &__logo {
    height: 65px;

    display: none;

    @media (min-width: #{$mobile}px) {
      display: block;
    }
  }
}
</style>
