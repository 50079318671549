<template>
  <article class="karaoke">
    <ActivityVideo
      v-if="type === 'karaokeListen'"
      type="video"
      :meta="meta"
      :data="data"
      :is-activity-valid="isActivityValid"
      :assets-overlap="assetsOverlap"
      @on-end="onVideoEnd"
    />

    <ul v-else class="activity__actions">
      <li>
        <button @click="handlePlay">
          <img
            v-tooltip="{
              content: this.$t('tooltip.activities.play').toString(),
            }"
            src="@/assets/images/activities/play.png"
            class="activity__action"
            alt="Play icon"
            title="Play"
          />
        </button>
      </li>
    </ul>

    <ActivityConfirmButton class="confirm" :data="[]" />
  </article>
</template>

<style lang="scss" scoped>
.confirm {
  @media (max-width: #{$mobile}px) {
    font-size: 20px;
  }
}

.karaoke {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.activity {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__actions {
    display: flex;
    justify-content: center;

    // Align horizontally:
    position: fixed;
    bottom: 80px;
    left: 50%;
    z-index: 2;

    transform: translateX(-50%);
    margin: 0;
    padding: 0;

    list-style: none;

    @media (max-width: #{$tablet}px) {
      bottom: 125px;
    }
  }

  &__action {
    height: 40px;
    width: 40px;

    margin: 0 10px;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ActivityVideo from '@/components/Activity/ActivityVideo.vue';
import ActivityConfirmButton from '@/components/Activity/ActivityConfirmButton.vue';

import {
  IActivityComponentData,
  IActivityMeta,
} from '@/models/interfaces/activities';
import EventBus from '@/utils/helpers/EventBus';
import Sound from '@/utils/helpers/Sound';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    ActivityVideo,
    ActivityConfirmButton,
  },
})
export default class KaraokeListen extends Vue {
  @Prop({ required: true, type: Array })
  private data!: IActivityComponentData;

  @Prop({ required: true, type: Object })
  private meta!: IActivityMeta;

  @Prop({ required: true, type: String })
  private type!: 'karaokeListen';

  @Prop({ required: true, type: Boolean })
  private isActivityValid!: boolean;

  @Prop({ required: true, type: Boolean })
  private assetsOverlap!: boolean;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private get sound() {
    return new Sound({
      src: this.isOnline
        ? this.data[0].value
        : this.findPreloadedAsset('sound', this.data[0].value),
      autoplay: false,
    });
  }

  private onVideoEnd() {
    EventBus.$emit('activity-event-solution-bulk-update', [
      {
        type: this.type,
        data: this.data[0],
      },
    ]);
  }

  private handlePlay() {
    if (!this.sound.playing()) {
      EventBus.$emit('activity-event-song-play');
    }
  }
}
</script>
