import Vue from 'vue';
import Router from 'vue-router';
import Initial from '@/screens/Initial/Initial.vue';
import store from '@/store';

// Screens that should be loaded at once
import TrainingMenu from '@/screens/TrainingMenu/TrainingMenu.vue';
import ActivityScreen from '@/screens/ActivityBuilder/ActivityBuilder.vue';

import amplitude from 'amplitude-js';
import routeNames from '@/router/route-names';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: routeNames.initial,
      component: Initial,
    },
    {
      path: '/dictionary',
      name: routeNames.dictionary,
      component: () => import('@/screens/AppDictionary/AppDictionary.vue'),
    },
    {
      path: '/intro',
      name: routeNames.intro,
      component: () => import('@/screens/Intro/Intro.vue'),
    },
    {
      path: '/onboarding',
      name: routeNames.onboarding,
      component: () => import('@/screens/Onboarding/Onboarding.vue'),
    },
    {
      path: '/globe',
      name: 'globe',
      component: () => import('@/screens/Globe/Globe.vue'),
    },
    {
      path: '/referrals',
      name: routeNames.referrals,
      component: () => import('@/screens/Referrals/Referrals.vue'),
    },
    {
      path: '/profile/achievements',
      name: routeNames.achievements,
      component: () =>
        import('@/screens/ProfileAchievements/ProfileAchievements.vue'),
    },
    {
      path: '/profile/:id/achievements',
      name: routeNames.digipalAchievements,
      component: () =>
        import('@/screens/ProfileAchievements/ProfileAchievements.vue'),
    },
    {
      path: '/profile/:userId?/interests',
      name: routeNames.interests,
      component: () =>
        import('@/screens/ProfileInterests/ProfileInterests.vue'),
    },
    {
      path: '/profile/:userId?/status',
      name: routeNames.status,
      component: () => import('@/screens/ProfileStatus/ProfileStatus.vue'),
    },
    {
      path: '/profile/:userId?/wallpaper',
      name: routeNames.settingsWallpaper,
      component: () =>
        import('@/screens/SettingsWallpaper/SettingsWallpaper.vue'),
    },
    {
      path: '/profile/friends',
      name: routeNames.friends,
      component: () => import('@/screens/Friends/Friends.vue'),
    },
    {
      path: '/profile/:userId?/friends',
      name: routeNames.friendsMutual,
      component: () => import('@/screens/FriendsMutual/FriendsMutual.vue'),
    },
    {
      path: '/profile/friends/suggested',
      name: routeNames.friendsSuggested,
      component: () =>
        import('@/screens/FriendsSuggested/FriendsSuggested.vue'),
    },
    {
      path: '/profile/friends/pending',
      name: routeNames.friendsPending,
      component: () => import('@/screens/FriendsPending/FriendsPending.vue'),
    },
    {
      path: '/profile/friends/search',
      name: routeNames.friendsSearch,
      component: () => import('@/screens/FriendsSearch/FriendsSearch.vue'),
    },
    {
      path: '/profile',
      name: routeNames.myProfile,
      component: () => import('@/screens/MyProfile/MyProfile.vue'),
    },
    {
      path: '/user/conversations',
      name: routeNames.conversations,
      component: () =>
        import('@/screens/UserConversations/UserConversations.vue'),
    },
    {
      path: '/conversation/:id?',
      name: routeNames.conversation,
      component: () =>
        import('@/screens/UserConversation/UserConversation.vue'),
    },
    {
      path: '/digipal/:userId?',
      name: routeNames.digipal,
      component: () => import('@/screens/DigipalProfile/DigipalProfile.vue'),
    },
    {
      path: '/newsfeed/new/:userId?',
      name: routeNames.newsfeedNew,
      component: () => import('@/screens/NewsfeedWrite/NewsfeedWrite.vue'),
    },
    {
      path: '/training-menu',
      name: routeNames.trainingMenu,
      component: TrainingMenu,
    },
    {
      path: '/message-rewards',
      name: routeNames.messageRewards,
      component: () => import('@/screens/MessageRewards/MessageRewards.vue'),
    },
    {
      path: '/activity/:activityId/rewards',
      name: routeNames.activityRewards,
      component: () =>
        import('@/screens/ActivityRewardScreens/ActivityRewardsScreen.vue'),
    },
    {
      path: '/activity/:activityId',
      name: routeNames.activityBuilder,
      component: ActivityScreen,
    },
    {
      path: '/song-menu',
      name: routeNames.songMenu,
      component: () => import('@/screens/SongMenu/SongMenu.vue'),
    },
    {
      path: '/settings',
      name: routeNames.settings,
      component: () => import('@/screens/Settings/Settings.vue'),
    },
    {
      path: '/activity-no-access',
      name: routeNames.activityNoAccess,
      component: () =>
        import('@/screens/ActivityNoAccess/ActivityNoAccess.vue'),
    },
    {
      path: '/community-guidelines',
      name: routeNames.communityGuidelines,
      component: () =>
        import('@/screens/CommunityGuidelines/CommunityGuidelines.vue'),
    },
    {
      path: '/notifications',
      name: routeNames.notifications,
      component: () =>
        import('@/screens/ProfileNotifications/ProfileNotifications.vue'),
    },
  ],
});

function isRoutePublic(name: string) {
  const publicRoutes = [routeNames.initial];
  return publicRoutes.includes(name);
}

function isRoutePremium(routeName: string) {
  const premiumRoutes = [routeNames.trainingMenu, routeNames.activityBuilder];
  return premiumRoutes.some(route => route === routeName);
}

// Guard for analytics:
router.beforeEach((to, from, next) => {
  amplitude.getInstance().logEvent('navigate', {
    path: to.fullPath,
  });

  next();
});

// Guard for authentication:
router.beforeEach((to, from, next) => {
  const { userId, userAccessToken } = store.state.session;

  if (
    !isRoutePublic(to.name?.toString() ?? '') &&
    (!userId || !userAccessToken)
  ) {
    next({
      name: routeNames.initial,
      query: { redirect: to.path, ...to.query },
    });
  } else {
    next();
  }
});

// Guard for popup:
router.beforeEach((to, from, next) => {
  const { isShown } = store.state.popupData;

  if (isShown) {
    // hide the popup on route change
    store.commit('togglePopup', null, { root: true });
  }

  next();
});

// Guard for premium routes:
router.beforeEach((to, from, next) => {
  const hasPremiumAccount = store.getters['profile/hasPremiumAccount'];

  if (to.name && isRoutePremium(to.name) && !hasPremiumAccount) {
    next({ name: routeNames.myProfile });
  } else {
    next();
  }
});

export default router;
