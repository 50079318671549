import theme from '@/styles/theme';

export const RESOLUTIONS = {
  DESKTOP: theme.desktop,
  TABLET: theme.tablet,
  MOBILE: theme.mobile,
};

export const RESOLUTIONS_NAME = {
  DESKTOP: 'large',
  TABLET: 'medium',
  MOBILE: 'small',
};
