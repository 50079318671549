import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class HotspotsStyles extends Vue {
  @Prop({ required: true, type: Object })
  protected metaStyles!: {
    backgroundColor: string;
    textColor: string;
  };
}
