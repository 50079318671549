<template>
  <section>
    <ActivityImage
      :type="type"
      :meta="meta"
      :data="data"
      :is-activity-valid="isActivityValid"
    />

    <ActivityAudio
      v-if="audioData.length"
      ref="audio"
      :key="audioKey"
      type="imageAudio"
      :data="audioData"
      :meta="{ timeout: 1 }"
    />

    <ActivityTextContainer v-if="showHotspotText || data[0].label">
      {{ showHotspotText ? hotspotLabel : data[0].label }}
    </ActivityTextContainer>
  </section>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity cubic-bezier(0.52, 1.56, 0.58, 1) 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import {
  IActivityComponentData,
  IActivityComponentMetaData,
  IActivityType,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';

import ActivityImage from './ActivityImage.vue';
import ActivityAudio from './ActivityAudio.vue';

import ActivityTextContainer from '@/components/ActivityTextContainer/ActivityTextContainer.vue';
import EventBus from '@/utils/helpers/EventBus';
import Sound from '@/utils/helpers/Sound';

@Component({
  components: {
    ActivityImage,
    ActivityAudio,

    ActivityTextContainer,
  },
})
export default class ActivityImageWithTextContainer extends Vue {
  @Prop({ required: true })
  private type!: IActivityType;

  @Prop({ required: true })
  private meta!: IActivityComponentMetaData;

  @Prop({ required: true })
  private data!: IActivityComponentData;

  @Prop({ required: true })
  private isActivityValid!: boolean;

  private audioData: Array<{ value: string; id: string }> = [];
  private audioKey = 0;

  private showHotspotText = false;
  private hotspotLabel = '';

  public $refs!: {
    audio: HTMLAudioElement & {
      play: (timeout: number) => void;
      sound: Sound;
    };
  };

  public mounted() {
    EventBus.$on(
      'activity-event-audio-hotspot-select',
      (hotspot: IActivityComponentDataElement) => {
        this.showHotspotText = true;
        this.hotspotLabel = hotspot.id;
      },
    );
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-audio-hotspot-select');
  }

  @Watch('data', { deep: true })
  private onComponentUpdated() {
    const ref = this.$refs.audio;

    if (this.type.toLowerCase().includes('audio')) {
      this.audioData = [
        {
          value: this.data[0].index,
          id: this.data[0].id,
        },
      ];

      if (ref) {
        ref.sound?.stop();
      }

      this.audioKey++;

      this.$nextTick(() => {
        this.$refs.audio.play(0);
      });
    }
  }
}
</script>

<docs>
```jsx
<ActivityImageWithTextContainer
  type="imageWithTextContainer"
  :meta="{}"
  :is-activity-valid="true"
  :data="[
    { 
      label: 'text',
      index: '1',
      id: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg'
    },
  ]"
/>
```
</docs>
