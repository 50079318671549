<template>
  <article
    :class="{
      background: true,
      'background--is-fixed': shouldBeFixed,
      'background--full-height': fullHeight,
    }"
    :style="{
      backgroundColor: color,
      backgroundImage: `url(${image})`,
    }"
  >
    <AppSupheader v-if="$slots.header" id="app-background-header">
      <slot name="header" />
    </AppSupheader>

    <main id="app-background-main" class="background__content">
      <slot />
    </main>
  </article>
</template>

<style lang="scss" scoped>
.background {
  display: flex;
  flex-direction: column;

  width: 100%;

  background-size: 120px;
  background-repeat: repeat;

  &--full-height {
    min-height: 100vh;

    .background__content {
      min-height: 100vh;
    }
  }

  &--is-fixed {
    background-attachment: fixed;
  }

  &__content {
    min-height: 100%;
  }
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import AppSupheader from '@/components/AppSupheader/AppSupheader.vue';
import { isMobile } from 'mobile-device-detect';

const defaultBackgroundImage = require('@/assets/images/profile/profile-wallpaper-placeholder.jpg');

@Component({
  components: {
    AppSupheader,
  },

  name: 'AppBackground',
})
export default class AppBackground extends Vue {
  @Prop({ default: '#fff' })
  public color!: string;

  @Prop({ default: defaultBackgroundImage })
  public image!: string;

  @Prop({ default: true })
  public fullHeight!: boolean;

  @Prop({ default: true })
  public isFixed!: boolean;

  private get shouldBeFixed() {
    return this.isFixed && !isMobile;
  }
}
</script>
