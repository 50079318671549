import { AxiosError } from 'axios';

import DataProvider from '@/utils/helpers/DataProvider';
import StoreUtil from '@/utils/helpers/StoreUtil';

import { FriendRequestStatus } from '@/models/constants/users';
import { IRecommendedFriend } from '@/models/interfaces/recommendations';
import {
  IFriendRequestPayload,
  IUserFriendData,
  IRequest,
  ISendRequestPayload,
} from '@/models/interfaces/users';
import { IVuexState } from '@/models/interfaces/store';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { RootState } from '..';

import i18n from '@/i18n';
import { uniq } from 'ramda';

export const getUniqueRequesteeIds = (ids: string[], idToAdd: string) => {
  return uniq([...ids, idToAdd]);
};

export interface State {
  recommendedFriends: IVuexState<IRecommendedFriend[]>;
  mutualFriends: IVuexState<IUserFriendData[]>;
  userPendingFriends: IVuexState<IUserFriendData[]>;
  searchFriendsResult: IVuexState<IUserFriendData[]>;
  userFriends: IVuexState<IUserFriendData[]>;
  friendRequestAcceptData: IVuexState<any>;
  friendRequestsRequesteeIds: string[];
}

export const mutations: MutationTree<State> = {
  updateRequestStatus(state, data?: any) {
    state.friendRequestAcceptData = StoreUtil.updateState(
      state.friendRequestAcceptData,
      data,
    );
  },
  addPendingFriendRequest(state, data: string) {
    state.friendRequestsRequesteeIds = getUniqueRequesteeIds(
      state.friendRequestsRequesteeIds,
      data,
    );
  },
  setMutualFriends(state, data?: IUserFriendData[] | Error) {
    if (data instanceof Error || !data) {
      state.mutualFriends = StoreUtil.updateState(state.mutualFriends, data);
    } else {
      data.forEach(friendData => {
        if (friendData.request.status === FriendRequestStatus.Pending) {
          state.friendRequestsRequesteeIds = getUniqueRequesteeIds(
            state.friendRequestsRequesteeIds,
            friendData.friend.userId,
          );
        }
      });

      const filteredMutuals = data.filter(
        friendData => friendData.request.status !== FriendRequestStatus.Pending,
      );

      state.mutualFriends = StoreUtil.updateState(
        state.mutualFriends,
        filteredMutuals,
      );
    }
  },
  setUserPendingFriends(
    state,
    data?: { friends: IUserFriendData[]; userId: string } | Error,
  ) {
    if (!data || data instanceof Error) {
      state.userPendingFriends = StoreUtil.updateState(
        state.userPendingFriends,
        data,
      );
    } else {
      const { userId } = data;
      const pendingFriends = data.friends.filter(
        friendData => friendData.request.status === FriendRequestStatus.Pending,
      );

      const addressedToMe = pendingFriends.filter(
        friendData => friendData.request.requestorId !== userId,
      );

      state.userPendingFriends = StoreUtil.updateState(
        state.userPendingFriends,
        addressedToMe,
      );

      pendingFriends.forEach(friendData => {
        const { requestorId } = friendData.request;

        if (requestorId === userId) {
          state.friendRequestsRequesteeIds = getUniqueRequesteeIds(
            state.friendRequestsRequesteeIds,
            requestorId,
          );
        }
      });
    }
  },
  setUserFriends(state, data?: IUserFriendData[] | Error) {
    const getFilteredDigipals = () => {
      const onlineDigipals: IUserFriendData[] = [];
      const offlineDigipals: IUserFriendData[] = [];

      (data as IUserFriendData[]).forEach((friendData: IUserFriendData) => {
        friendData.friend.online
          ? onlineDigipals.push(friendData)
          : offlineDigipals.push(friendData);
      });

      const offlineDigipalsOrdered = offlineDigipals.sort(
        (first: IUserFriendData, second: IUserFriendData) => {
          return (
            (new Date(second.friend.lastSeen) as any) -
            (new Date(first.friend.lastSeen) as any)
          );
        },
      );

      const filteredData = [...onlineDigipals, ...offlineDigipalsOrdered];

      return filteredData.filter(
        friendData =>
          friendData.request.status === FriendRequestStatus.Accepted,
      );
    };

    const dataToSet =
      data instanceof Error || !data ? data : getFilteredDigipals();
    state.userFriends = StoreUtil.updateState(state.userFriends, dataToSet);
  },
  setSearchFriends(state, data?: IUserFriendData[] | AxiosError) {
    if (data instanceof Error || !data) {
      state.searchFriendsResult = StoreUtil.updateState(
        state.searchFriendsResult,
        data,
      );
    } else {
      const friendsByStatus = (status: FriendRequestStatus) =>
        data.filter(
          friend => friend.request && friend.request.status === status,
        );

      const alreadyFriends = friendsByStatus(FriendRequestStatus.Accepted);
      const pendingFriends = friendsByStatus(FriendRequestStatus.Pending);
      const otherFriends = data!.filter(friend => !friend.request);

      pendingFriends.forEach(data => {
        state.friendRequestsRequesteeIds = getUniqueRequesteeIds(
          state.friendRequestsRequesteeIds,
          data.friend.userId,
        );
      });

      state.searchFriendsResult = StoreUtil.updateState(
        state.searchFriendsResult,
        [...alreadyFriends, ...pendingFriends, ...otherFriends],
      );

      pendingFriends.forEach(friendData => {
        state.friendRequestsRequesteeIds = getUniqueRequesteeIds(
          state.friendRequestsRequesteeIds,
          friendData.friend.userId,
        );
      });
    }
  },
  setRecommendedFriends(state, data?: IRecommendedFriend[] | Error) {
    state.recommendedFriends = StoreUtil.updateState(
      state.recommendedFriends,
      data,
    );
  },
  removePendingFriend(state, requestorId: string) {
    const updatedList = state.userPendingFriends.data!.filter(
      data => data.request.requestorId !== requestorId,
    );

    state.friendRequestsRequesteeIds = state.friendRequestsRequesteeIds.filter(
      id => id !== requestorId,
    );

    state.userPendingFriends = StoreUtil.updateState(
      state.userPendingFriends,
      updatedList,
    );
  },
};

export const actions: ActionTree<State, RootState> = {
  async fetchRecommendedFriends(
    { commit },
    {
      type = 'faf',
      setPendingState = true,
    }: {
      type?: string;
      setPendingState?: boolean;
    } = {},
  ) {
    if (setPendingState) {
      commit('setRecommendedFriends');
    }

    try {
      const { data } = await DataProvider.get(
        `recommendations/friends?type=${type}`,
      );
      // @ts-ignore
      commit('setRecommendedFriends', data.recommendations);
      // @ts-ignore
      return data.recommendations;
    } catch (error) {
      commit('setRecommendedFriends', error);
      return error;
    }
  },
  async fetchMutualFriends({ commit }, userId: string) {
    commit('setMutualFriends');

    try {
      const { data } = await DataProvider.get(`users/${userId}/mutualFriends`);
      // @ts-ignore
      commit('setMutualFriends', data.friends);
      // @ts-ignore
      return data.friends;
    } catch (error) {
      commit('setMutualFriends', error);
      return error;
    }
  },
  async fetchUserPendingFriends({ commit, rootState }) {
    // @ts-ignore
    // TODO: remove ts ignore as soon as session type is set to index.ts
    const { session } = rootState;
    const { userId } = session;

    commit('setUserPendingFriends');

    try {
      const { data } = await DataProvider.get<IUserFriendData[]>(
        `users/${userId}/friends`,
      );
      commit('setUserPendingFriends', {
        friends: data || [],
        userId,
      });
      return data;
    } catch (error) {
      commit('setUserPendingFriends', error);
      return error;
    }
  },
  async fetchUserFriends({ commit, rootState }) {
    // @ts-ignore
    // TODO: remove ts ignore as soon as session type is set to index.ts
    const { session } = rootState;
    const { userId } = session;

    commit('setUserFriends');

    try {
      const { data } = await DataProvider.get<IUserFriendData[]>(
        `users/${userId}/friends`,
      );
      commit('setUserFriends', data);
      return data;
    } catch (error) {
      commit('setUserFriends', error);
      return error;
    }
  },
  async sendFriendRequest({ commit, rootState }, data: ISendRequestPayload) {
    const {
      // @ts-ignore
      // TODO: remove ts ignore as soon as session type is set to index.ts
      session: { userId: requestorId },
    } = rootState;

    try {
      const result = await DataProvider.post<IFriendRequestPayload>(
        'friendRequests',
        {
          requesteeId: data.targetUserId,
          requestorId,
        },
      );

      commit('addPendingFriendRequest', data.targetUserId);
      commit(
        'togglePopup',
        {
          message: i18n.t('digipals.all.successRequest', {
            friend: data.targetUser.username,
          }),
        },
        { root: true },
      );
      return result.data;
    } catch (error) {
      return error;
    }
  },
  async acceptFriendRequest({ commit }, payload: IRequest) {
    commit('updateRequestStatus');

    try {
      const { data } = await DataProvider.put('friendRequests', {
        ...payload,
        status: FriendRequestStatus.Accepted,
      });
      commit('removePendingFriend', payload.requestorId);
      commit('updateRequestStatus', data);
      return data;
    } catch (error) {
      commit('updateRequestStatus', error);
      return error;
    }
  },
  async searchFriends({ commit }, query: string) {
    commit('setSearchFriends');

    try {
      const { data } = await DataProvider.get(
        `users/summaries?username=${query}`,
      );
      commit('setSearchFriends', data);
      return data;
    } catch (error) {
      commit('setSearchFriends', error);
      return error;
    }
  },
};

export const getters: GetterTree<State, RootState> = {
  isFriendPending(state) {
    return (userId: string) =>
      state.friendRequestsRequesteeIds.includes(userId);
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    recommendedFriends: StoreUtil.state<IRecommendedFriend[]>(),
    mutualFriends: StoreUtil.state<IUserFriendData[]>(),
    userPendingFriends: StoreUtil.state<IUserFriendData[]>(),
    searchFriendsResult: StoreUtil.state<IUserFriendData[]>(),
    userFriends: StoreUtil.state<IUserFriendData[]>(),
    friendRequestAcceptData: StoreUtil.state(),
    friendRequestsRequesteeIds: [],
  },
  mutations,
  actions,
  getters,
};

export default store;
