import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';
import {
  ISuggestion,
  ISuggestionResponse,
  IMessageDto,
  IMessageConfig,
  IStatus,
  IStatusUpateDto,
  ISentMessage,
  IMessage,
} from '@/models/interfaces/messages';
import { ActionTree, MutationTree, Module } from 'vuex';
import { RootState } from '..';
import { IVuexState } from '@/models/interfaces/store';

export interface State {
  replyMessage: IVuexState<IMessage>;
  messageConfig: IVuexState<IMessageConfig>;
  sentMessage: IVuexState<ISentMessage>;
  userStatus: IVuexState<IStatus>;
  userStatusUpdate: IVuexState<any>;
}

export const actions: ActionTree<State, RootState> = {
  async getStudyBuddySuggestion(store, query: string): Promise<ISuggestion[]> {
    return (
      await DataProvider.get<ISuggestionResponse>(
        `messages/suggestions?q=${query}`,
      )
    ).data.suggestions;
  },
  async fetchMessageConfig({ commit, state }): Promise<IMessageConfig> {
    // Message config needs to be fetched only once:
    if (state.messageConfig !== null && state.messageConfig.status.success) {
      return new Promise(resolve => resolve(state.messageConfig!.data!));
    }

    commit('setMessageConfig');

    try {
      const { data } = await DataProvider.get('messages/config');
      // @ts-ignore
      commit('setMessageConfig', data.messageConfig);
      // @ts-ignore
      return data.messageConfig;
    } catch (error) {
      commit('setMessageConfig', error);
      // @ts-ignore
      return error;
    }
  },
  async fetchUserStatus({ commit }, userId: string) {
    commit('setUserStatus');

    try {
      const { data } = await DataProvider.get(`messages/statuses/${userId}`);
      commit('setUserStatus', data);
      return data;
    } catch (error) {
      commit('setUserStatus', error);
      return error;
    }
  },
  async sendMessage({ commit }, payload: IMessageDto): Promise<boolean> {
    commit('setSendMessage');

    try {
      const { data } = await DataProvider.post('messages/chats', payload);
      commit('setSendMessage', data);
      return true;
    } catch (error) {
      commit('setSendMessage', error);
      return false;
    }
  },
  async updateUserStatus({ commit }, payload: IStatusUpateDto) {
    commit('setUserStatusUpdate');

    try {
      const { data } = await DataProvider.post('messages/statuses', payload);
      commit('setUserStatusUpdate', data);
      return data;
    } catch (error) {
      commit('setUserStatusUpdate', error);
      return error;
    }
  },
};

export const mutations: MutationTree<State> = {
  setMessageConfig(state, data?: IMessageConfig | Error) {
    state.messageConfig = StoreUtil.updateState(state.messageConfig, data);
  },
  setSendMessage(state, data?: ISentMessage | Error) {
    state.sentMessage = StoreUtil.updateState(state.sentMessage, data);
  },
  setUserStatus(state, data?: IStatus | Error) {
    state.userStatus = StoreUtil.updateState(state.userStatus, data);
  },
  setUserStatusUpdate(state, data?: any | Error) {
    state.userStatusUpdate = StoreUtil.updateState(
      state.userStatusUpdate,
      data,
    );
  },
  setReplyMessage(state, data?: IMessage | Error) {
    state.replyMessage = StoreUtil.updateState(state.replyMessage, data);
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    replyMessage: StoreUtil.state<IMessage>(),
    messageConfig: StoreUtil.state<IMessageConfig>(),
    sentMessage: StoreUtil.state<ISentMessage>(),
    userStatus: StoreUtil.state<IStatus>(),
    userStatusUpdate: StoreUtil.state<any>(),
  },
  mutations,
  actions,
};

export default store;
