var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"progressbar"},[_c('div',{staticClass:"progressbar__wrapper"},[_c('div',{staticClass:"progressbar__bottom"}),_c('div',{class:[
        'progressbar__top',
        { 'progressbar__top--inactive': _vm.percentage === 0 },
      ],style:({ width: `${_vm.percentage}%` })}),_c('div',{class:[
        'progressbar__circle',
        `progressbar__circle--${_vm.isValid ? 'success' : 'error'}`,
        { 'progressbar__circle--inactive': !_vm.isCircleShown },
      ],style:({ left: `calc(${_vm.percentage}% - 15px)` })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }