import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '..';
import axios from 'axios';

export type PreloadedAsset = {
  originalCdnUrl: string;
  preloadedSrc: string;
};

export type PreloadedAssetType = 'image' | 'video' | 'sound';

export interface State {
  preloadedImages: PreloadedAsset[];
  preloadedSounds: PreloadedAsset[];
  preloadedVideos: PreloadedAsset[];
}

export const actions: ActionTree<State, RootState> = {
  async preloadAssets({ commit }, payload: string[]) {
    for (const assetCdnUrl of payload) {
      axios({
        method: 'GET',
        responseType: 'blob',
        url: `${assetCdnUrl}?x-request=xhr`,
      }).then(response => {
        const src = URL.createObjectURL(response.data);
        const fileNameArray = assetCdnUrl.split('.');
        const fileType = fileNameArray[fileNameArray.length - 1];

        const preloadedAsset = {
          originalCdnUrl: assetCdnUrl,
          preloadedSrc: src,
        };

        switch (fileType) {
          case 'mp3':
            commit('setPreloadedSound', preloadedAsset);
            break;
          case 'mp4':
            commit('setPreloadedVideo', preloadedAsset);
            break;
          case 'png':
            commit('setPreloadedImage', preloadedAsset);
            break;
          default:
            break;
        }
      });
    }
  },
};

export const mutations: MutationTree<State> = {
  setPreloadedImage(state, asset: PreloadedAsset) {
    state.preloadedImages = [...state.preloadedImages, asset];
  },
  setPreloadedVideo(state, asset: PreloadedAsset) {
    state.preloadedVideos = [...state.preloadedVideos, asset];
  },
  setPreloadedSound(state, asset: PreloadedAsset) {
    state.preloadedSounds = [...state.preloadedSounds, asset];
  },
};

export const getters: GetterTree<State, RootState> = {
  findPreloadedAsset: state => (
    type: PreloadedAssetType,
    originalCdnUrl: string,
  ) => {
    const preloadedAssets =
      type === 'image'
        ? state.preloadedImages
        : type === 'sound'
        ? state.preloadedSounds
        : state.preloadedVideos;

    return (
      preloadedAssets.find(item => item.originalCdnUrl === originalCdnUrl)
        ?.preloadedSrc || ''
    );
  },
};

export const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    preloadedImages: [],
    preloadedSounds: [],
    preloadedVideos: [],
  },
  mutations,
  getters,
  actions,
};

export default store;
