var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
    'activity-text-btn',
    _vm.sizingClass,
    { 'activity-text-btn--focused': _vm.focused },
    `activity-text-btn--${_vm.hexToString(_vm.secondaryBgColor || _vm.bgColor)}`,
    { 'activity-text-btn--squared': _vm.squared },
    { 'activity-text-btn--clue': _vm.isClue },
    { 'activity-text-btn--hint': _vm.hint },
    { 'activity-text-btn--disabled': _vm.disabled || _vm.hint },
  ],style:({
    background: _vm.secondaryBgColor || _vm.bgColor,
    color: _vm.color,
  }),on:{"mousedown":function($event){_vm.focused = true},"mouseup":function($event){_vm.focused = false},"touchstart":function($event){_vm.focused = true},"touchend":function($event){_vm.focused = false},"focus":_vm.onFocus,"blur":_vm.onBlur}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }