<template>
  <div class="text-buttons">
    <div
      :class="{
        'text-buttons__wrap': true,
        'text-buttons__wrap--column': false /* TOOD: get from meta */,
      }"
    >
      <ActivityTextButton
        v-for="word in data"
        :key="word.id"
        :bg-color="getBgColor(word)"
        @click.native="onBtnClick(word)"
      >
        {{ word.value }}
      </ActivityTextButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-buttons {
  padding: 15px 0;
  @include activity-centered;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &--column {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';
import {
  IActivityComponentData,
  IActivityComponentDataElement,
  IActivityComponentMetaData,
  IActivitySolution,
} from '@/models/interfaces/activities';

import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';
import ActivityButtons from '../mixins/ActivityButtons';
import { isCorrectFileExtension } from '@/utils/helpers/assets';
import Sound from '@/utils/helpers/Sound';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    ActivityTextButton,
  },
})
export default class ActivityTextButtons extends Mixins(ActivityButtons) {
  @Prop({ required: true })
  meta!: IActivityComponentMetaData;

  @Prop({ required: true })
  data!: IActivityComponentData;

  @Prop({ required: true })
  isActivityValid!: boolean;

  @Prop({ required: false, default: '' })
  buttonType!: string;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private correctExtension = isCorrectFileExtension;
  private sound!: Sound;

  private onBtnClick(word: IActivityComponentDataElement) {
    const shouldPlaySound = isCorrectFileExtension(['mp3', 'wav'], word.label);

    if (shouldPlaySound) {
      const src = this.isOnline
        ? word.label
        : this.findPreloadedAsset('sound', word.label);

      if (this.sound && this.sound.playing()) {
        this.sound.stop();
      }

      this.sound = new Sound({
        src,
        autoplay: true,
        html5: true,
        format: ['mp3', 'm4a'],
      });
    }

    this.handleClick(word);
  }

  private getBgColor(word: IActivityComponentDataElement) {
    const isAudio = isCorrectFileExtension(['mp3', 'wav'], word.label);
    const defaultColor = isAudio ? '#e90c6e' : '#1b75bb';
    const activeColor = isAudio ? '#f8b6d3' : '#88c23f';

    return this.selectedButtons.includes(word.id) ? activeColor : defaultColor;
  }

  public mounted() {
    EventBus.$on('activity-event-reset-solutions', () => {
      this.selectedButtons = [];
    });

    // when we have a composition of an image carousel
    // and text buttons, we should delete carousel solution
    // that is added automatically on slide change
    // see @/components/Activity/ActivityImageCarousel.vue

    EventBus.$on(
      'activity-event-solution-add',
      (solution: IActivitySolution) => {
        if (solution.type === 'imageCarousel') {
          this.$nextTick(() =>
            EventBus.$emit('activity-event-solution-del', solution),
          );
        }
      },
    );
  }

  public destroyed() {
    EventBus.$off('activity-event-reset-solutions');
  }
}
</script>
