<template>
  <div :class="getComputedClassName">
    <span v-if="!chip" class="counter__text">Clue {{ count }}</span>

    <div class="counter__icon">
      <img
        src="@/assets/images/activities/spelling/balloon-blue.png"
        alt="DigiPal icon"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './Counter.styles.scss';

.counter {
  box-shadow: 0px 1.5px 1.5px 0px rgba(0, 0, 0, 0.3);
  border: solid 1px #21a7e0;
  background-image: radial-gradient(circle at 0 0, #2fe5f7, #21a7e0);

  &__icon {
    background-image: radial-gradient(circle at 0 0, #ffffff, #addff4);
  }

  &--disabled {
    filter: grayscale(1);
  }

  &.small {
    .counter__icon {
      img {
        width: 11px;
      }
    }
  }

  &.x-small {
    .counter__icon {
      img {
        height: 19px;
        width: 11px;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import Counter from './Counter.mixin';

@Component
export default class ClueCounter extends Mixins(Counter) {}
</script>

<docs>
### Default

```jsx
<div style="display: flex">
  <ClueCounter :count="5" />
</div>
```

### Chip

```jsx
<div style="display: flex">
  <ClueCounter :count="5" :chip="true" />
</div>
```

### Different sizes

#### Large

```jsx
<div style="display: flex">
  <ClueCounter
    :count="5"
    desktop-size="large"
    tablet-size="large"
    mobile-size="large"
  />
</div>
```

#### Medium

```jsx
<div style="display: flex">
  <ClueCounter
    :count="5"
    desktop-size="medium"
    tablet-size="medium"
    mobile-size="medium"
  />
</div>
```

#### Small

```jsx
<div style="display: flex">
  <ClueCounter
    :count="5"
    desktop-size="small"
    tablet-size="small"
    mobile-size="small"
  />
</div>
```

#### Extra Small

```jsx
<div style="display: flex">
  <ClueCounter
    :count="5"
    desktop-size="x-small"
    tablet-size="x-small"
    mobile-size="x-small"
  />
</div>
```
</docs>
