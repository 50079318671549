<template>
  <section class="activity">
    <h3 class="activity__topic">{{ rootMeta.topic }}</h3>

    <ul class="activity__list">
      <!-- eslint-disable-next-line -->
      <li
        v-for="letter in guessedLetters"
        :style="letterStyles"
        class="activity__letter"
      >
        {{ letter }}
      </li>
    </ul>

    <ClueCounter
      class="activity__counter"
      :count="clueCost"
      :disabled="lifes.length <= clueCost"
      @click.native="clue"
    />

    <ul class="activity__list">
      <!-- eslint-disable-next-line -->
      <li v-for="balloon in lifes" class="activity__balloon">
        <img
          :src="require(`@/assets/images/activities/spelling/${balloon}`)"
          alt="Balloons"
        />
      </li>
    </ul>

    <KeyboardFreewrite
      ref="keyboard"
      input=""
      mode="default"
      :disabled-keys="disabledKeys"
      :default-layout="keyboardLayout"
      @onKeyPress="handleKeyPress"
    />
  </section>
</template>

<style lang="scss">
.simple-keyboard.littlebridge-keyboard {
  position: fixed;
  bottom: 0;

  // Align horizontally:
  left: 50%;
  transform: translateX(-50%);

  // Reset height:
  height: initial;
  max-width: 800px;
}
</style>

<style lang="scss" scoped>
.activity {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__counter {
    margin: 0 auto 40px;
  }

  &__list {
    display: flex;
    justify-content: center;

    padding: 0;
    margin: 20px 0;

    list-style: none;
  }

  &__letter {
    margin: 0 3px;

    width: 15px;
    height: 30px;

    font-size: 18px;
    color: $white100;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;

    @include d-flex-center;
  }

  &__balloon {
    width: 29px;
    height: 50px;

    img {
      width: 100%;
    }
  }

  &__topic {
    color: $white100;
    font-size: 18px;
    text-align: center;

    margin: 20px 0;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Watch, Ref, Vue } from 'vue-property-decorator';
import { difference } from 'ramda';

import EventBus from '@/utils/helpers/EventBus';

import ClueCounter from '@/components/Counter/ClueCounter.vue';
import KeyboardFreewrite from '@/components/Keyboard/KeyboardFreewrite.vue';

import {
  IActivityMeta,
  IActivityTriggers,
  IActivityComponent,
} from '@/models/interfaces/activities';
import sounds from '@/utils/helpers/sounds';

@Component({
  components: {
    ClueCounter,
    KeyboardFreewrite,
  },
})
export default class ActivitySpellingGame extends Vue {
  @Prop({ required: true })
  private data!: IActivityComponent<'spellingGame'>['data'];

  @Prop({ required: true })
  private rootMeta!: IActivityMeta;

  @Prop({ required: true })
  public meta!: IActivityMeta;

  @Prop({ required: true })
  private triggers!: IActivityTriggers;

  @Ref('keyboard')
  private keyboard!: HTMLDivElement;

  private keyboardLayout = [
    'q w e r t y u i o p',
    'a s d f g h j k l',
    'z x c v b n m',
  ];

  private clueCost = 2;

  private guessedLetters: string[] = [];
  private disabledKeys: string[] = [];

  private lifes = [
    'balloons-orange.png',
    'balloon-green.png',
    'balloon-yellow.png',
    'balloon-blue.png',
    'balloon-pink.png',
    'balloon-purple.png',
    'balloons-orange.png',
    'balloon-green.png',
    'balloon-yellow.png',
    'balloon-blue.png',
  ];
  private initialLifesCount = this.lifes.length;

  public get letterStyles() {
    const { backgroundColor, textColor } = this.meta.style;

    return {
      backgroundColor,
      color: textColor,
      borderBottom: `3px solid ${textColor}`,
    };
  }

  private get wordToGuess() {
    return this.data[0].value.toLowerCase();
  }

  private validate() {
    const isWordGuessed = this.guessedLetters.every(letter => letter !== '');
    const hasLostAllLifes = this.lifes.length === 0;

    if (hasLostAllLifes) {
      EventBus.$emit('activity-event-solution-add', {
        type: 'FORCE_TO_FAIL',
        data: null,
      });

      EventBus.$emit('activity-event-confirm');
      this.disabledKeys = [];
      this.updateKeyboard();
    } else if (isWordGuessed) {
      const valueToValidate =
        // for the cases, when the answer is case sensitive
        this.guessedLetters.join('') === this.wordToGuess
          ? this.data[0].value // just setting the original value
          : this.guessedLetters.join('');

      EventBus.$emit('activity-event-solution-add', {
        type: 'spellingGame',
        data: {
          ...this.data[0],
          value: valueToValidate,
        },
      });
      EventBus.$emit('activity-event-confirm');
    }
  }

  private updateKeyboard() {
    (this.keyboard as any).resetAccessabilityHelpers();
    (this.keyboard as any).configureAccessabilityHelpers();
  }

  private clue() {
    if (this.lifes.length > this.clueCost) {
      const wordLetters = this.wordToGuess.split('');
      const missingLetters = difference(wordLetters, this.guessedLetters);

      // Remove lifes:
      this.lifes = this.lifes.slice(0, this.lifes.length - this.clueCost);
      this.handleKeyPress(missingLetters[0]);
    }
  }

  private setSpaces() {
    this.guessedLetters = Array(this.wordToGuess.length).fill('');
  }

  private revealLetter(letter: string) {
    for (let i = 0; i < this.wordToGuess.length; i++) {
      if (this.wordToGuess[i] === letter) {
        Vue.set(this.guessedLetters, i, letter);
      }
    }
  }

  private handleKeyPress(input: string) {
    const letter = input.toLowerCase();

    this.disabledKeys = [...this.disabledKeys, letter];

    if (this.wordToGuess.includes(letter)) {
      this.revealLetter(letter);
    } else {
      this.lifes.pop();
    }

    this.validate();
  }

  public mounted() {
    this.setSpaces();
  }

  @Watch('lifes')
  private onLifeAmountChange() {
    if (this.lifes.length !== this.initialLifesCount) {
      sounds.balloon.play();
    }
  }
}
</script>
