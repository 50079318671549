import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

@Component
export default class HideNavigation extends Vue {
  @Mutation('toggleRootNavbarVisibility')
  private setNavbarVisible!: (isVisible: boolean) => void;

  public mounted() {
    this.setNavbarVisible(false);
  }

  public destroyed() {
    this.setNavbarVisible(true);
  }
}
