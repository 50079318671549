import { Module, ActionTree, MutationTree } from 'vuex';

import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';

import { RootState } from '..';
import { IVuexState } from '@/models/interfaces/store';
import {
  IConversation,
  IConversationData,
  IConversationMessage,
} from '@/models/interfaces/messaging';

export interface State {
  conversations: IVuexState<IConversation[]>;
  conversationData: IVuexState<IConversationData>;
}

export const actions: ActionTree<State, RootState> = {
  async fetchConversations({ commit }, userId: string) {
    commit('setConversations');

    try {
      const { data } = await DataProvider.get<{ messages: IConversation[] }>(
        `users/${userId}/conversations`,
      );
      commit('setConversations', data.messages);
      return data.messages;
    } catch (error) {
      commit('setConversations', error);
      throw error;
    }
  },

  async fetchConversationData({ commit }, threadId: string) {
    commit('setConversationData');

    try {
      const { data } = await DataProvider.get<IConversationData>(
        `conversations/${threadId}`,
      );
      commit('setConversationData', data);
      return data;
    } catch (error) {
      commit('setConversationData', error);
      throw error;
    }
  },

  sendMessage(_, { message, userId }: { message: string; userId: string }) {
    return DataProvider.post<IConversationMessage>(
      `users/${userId}/conversations`,
      { message },
    );
  },
};

export const mutations: MutationTree<State> = {
  resetConversation(state) {
    state.conversationData = StoreUtil.state();
  },

  setConversations(state, data?: IConversation[] | Error) {
    state.conversations = StoreUtil.updateState(state.conversations, data);
  },

  setConversationData(state, data?: IConversationData | Error) {
    state.conversationData = StoreUtil.updateState(
      state.conversationData,
      data,
    );
  },
};

export const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    conversations: StoreUtil.state(),
    conversationData: StoreUtil.state(),
  },
  mutations,
  actions,
};

export default store;
