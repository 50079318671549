<template>
  <div class="activity-words">
    <div
      :class="{
        'activity-words__wrap': true,
        'activity-words__wrap--column': onePerLine,
      }"
    >
      <ActivityTextButton
        v-for="word in computedWords"
        :key="word.id"
        :bg-color="word.active ? '#86c040' : '#f3aa45'"
      >
        {{ word.value }}
      </ActivityTextButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.activity-words {
  height: 100%;

  padding: 11px 0 5px;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include activity-centered;

    &--column {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';
import { ActivitySearchWord } from '@/models/interfaces/activities';

@Component({
  components: {
    ActivityTextButton,
  },
})
export default class ActivityWordSearchWords extends Vue {
  @Prop({ required: true })
  private words!: ActivitySearchWord[];

  @Prop({ default: false })
  private onePerLine!: boolean;

  @Prop({ default: () => [] })
  private activeWords!: ActivitySearchWord[];

  private get computedWords() {
    return this.words.map(word => {
      const active = this.activeWords.find(item => item.id === word.id);
      return { ...word, active };
    });
  }
}
</script>

<docs>
### Component for organizing the activity words words

```jsx
<ActivityWordSearchWords
  :words="[
    {
      value: 'word 1',
      id: '1',
    },
    {
      value: 'word 2',
      id: '2',
    },
    {
      value: 'word 3',
      id: '3',
    },
  ]"
/>
```

### If you need a column

```jsx
<ActivityWordSearchWords
  :words="[
    {
      value: 'word 1',
      id: '1',
    },
    {
      value: 'word 2',
      id: '2',
    },
    {
      value: 'word 3',
      id: '3',
    },
  ]"
  :one-per-line="true"
/>
```

### Passing active words

```jsx
<ActivityWordSearchWords
  :words="[
    {
      value: 'word 1',
      id: '1',
    },
    {
      value: 'word 2',
      id: '2',
    },
    {
      value: 'word 3',
      id: '3',
    },
  ]"
  :active-words="[
    {
      value: 'word 1',
      id: '1',
    },
  ]"  
/>
```
</docs>
