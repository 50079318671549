import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: 'Lütfen önce bir neden seçin!',
    title: 'Topluluk kuralları',
    popup: {
      title:
        'Topluluğumuzu güvende tutmaya yardımcı olduğun için sana teşekkür ederiz!',
      btn: 'Topluluk Kurallarına Dön',
    },
    pt1: {
      subtitle: 'DigiPal Topluluğu nedir?',
      text:
        'DigiPal topluluğumuz, arkadaş edinmen ve İngilizce pratik yapman için güvenilir bir adres.',
    },
    pt2: {
      subtitle: 'Topluluk kuralları nelerdir?',
      text1: 'Her zaman İngilizce yaz',
      text2:
        'Düşmanca, saldırganca veya kaba hiçbir şey yazma. Küfür yasaktır!',
      text3:
        'Tam adın, adresin, telefon numaran, e-posta adresin veya diğer dijital/sosyal medya hesaplarının bilgileri gibi kişisel bilgilerini paylaşma',
      text4: 'Tüm mesajlar gerçek kişiler tarafından inceleniyor.',
    },
    pt3: {
      subtitle: 'Birinin bir kuralı çiğnediğini düşünürsem ne yapmalıyım?',
      text1:
        'Rapor göndererek topluluğumuzdaki herkesi mutlu etmemize yardımcı olacaksın.',
      text2:
        'Daha fazlasını öğrenmek ve sana yardımcı olmak için anne ya da babanla iletişime geçeceğiz.',
    },
    btn: 'Rapor gönder',

    option1: 'Sanırım birisi İngilizce yazmıyor',
    option2: 'Bence birisi kaba davranıyor',
    option3: 'Sanırım birisi kişisel bilgilerini paylaştı',
  },

  globe: {
    _: 'Küre',
    friendsMultiple: '{friends} arkadaşın var',
    friends: '1 arkadaşın var',
    countries: '1 ülkede',
    countriesMultiple: '{countries} ülkede',
    defaultTitle: 'Küresel DigiPal topluluğumuz!',
    fetchFriend: 'Şimdi yeni bir arkadaş getir!',
  },

  profile: {
    avatar: {
      title: 'Avatarım yapmak istediğimde',
    },
    error: 'Hay aksi! Bir hata oluştu. Tekrar deneyebilir misin?',
    messagesEmpty: `Hay aksi! Burada hiç mesaj yok. Önerilen DigiPal'lar listene git, profillerini ziyaret et ve sohbet etmeye başla!`,
    postTitle: "DigiPals'ımdan gönderiler",
    writePost: 'DigiPost yaz',
    profileError: 'Profil mesajları alınamadı.',
    clock: '{username} Yaşıyor saat kaç?',
    country: "{username},\n{country}'da\nyaşıyor",
    myCountry: '{country}\niçinde yaşıyorum',
    myClock: 'Yaşadığım yerde\nsaat kaç?',
    friendsAccepted: 'Sen zaten arkadaşsın!',
    friendsAdd: 'Bir digipal isteği gönderin',
    friendsPending: 'Digipal isteği beklemede',
  },

  dictionary: {
    alphabet: {
      error: 'Alfabe getirilemedi',
    },
    words: {
      error: 'Kelimeler getirilemedi',
    },
  },

  dates: {
    yearsAgo: '{count} yıl önce',
    yearAgo: 'bir yıl önce',
    monthsAgo: '{count} ay önce',
    monthAgo: 'bir ay önce',
    daysAgo: '{count} gün önce',
    dayAgo: 'bir gün önce',
    hoursAgo: '{count} saat önce',
    hourAge: 'bir saat önce',
    minutesAgo: '{count} dakika önce',
    minuteAgo: 'bir dakika önce',
    seconds: 'bir kaç saniye önce',
  },

  common: {
    someone: 'Birisi',
  },

  digiPal: {
    messagesEmpty: 'Burada hiç mesaj yok! İlkini yaz!',
  },

  interests: {
    title: 'Beğenilerini seç',
  },

  achievements: {
    title: 'Başarılarına göz at',
    messageTrophy: `DigiPal'lerine mesaj göndererek kupalar kazan! Yalnızca yanıt alan mesajların kupa olarak sayılacaktır.`,
    activityTrophy:
      'Aktiviteleri tamamlayarak kupalar kazan! Kazandığın her yıldız kupan olarak sayılacak.',
  },

  settings: {
    title: {
      1: 'İngiltere İngilizcesi mi yoksa ABD İngilizcesi mi öğrenin?',
      2: "'Yardım' mı alın?",
    },
  },

  digipals: {
    genericErrorMessage: 'Hay aksi! Bir hata oluştu. Tekrar deneyebilir misin?',

    all: {
      title: "Benim DigiPals'ım",
      emptyErrorMessage: `Henüz bağlantı kurduğun bir arkadaş yok. Arkadaş edinmeye başlamak için "Önerilen DigiPal'lar"a git!`,
      successRequest:
        '{friend} arkadaşına bir arkadaşlık isteği gönderdin. Profillerine neden arkadaş olmak istediğini anlatmak için bir mesaj bırak!',
      clock:
        "{digipal} 'ın yaşadığı saat kaç? Unutmayın, bazı DigiPals sizin için gündüz vakti uyuyor olabilir!",
    },
    mutual: {
      title: 'Karşılıklı DigiPals',
      emptyErrorMessage: 'Ortak arkadaşınız yok.',
    },
    pending: {
      title: 'DigiPal istekleri',
      emptyErrorMessage:
        "Henüz bekleyen isteğiniz yok. Arkadaş edinmeye başlamak için 'Önerilen DigiPals'a gidin!",
      successAccept:
        'Artık {friend} ile arkadaşsınız. Merhaba demek için profilini ziyaret edin!',
    },
    search: {
      title: 'DigiPal arayın',
    },
    suggested: {
      title: 'Şimdi çevrimiçi olan Mesaj DigiPals!',
      emptyErrorMessage: 'Çevrimiçi arkadaşın yok.',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        "Merhaba! Ben Domino'yum. Dünyanın her yerinden arkadaşlar edinmenize yardımcı olacağım! Yardıma ihtiyacınız olursa, beni araç çubuğunda bulabilirsiniz. Bu, DigiPal profil sayfanızdır. Herkese senin hakkında bir şeyler anlatır. Keşfetmeye başlayın!",
      post: {
        reply: '{username} mesajınızı yanıtladı! Sohbete devam edin 😁',
        wallMessage:
          '{username} profilinize bir mesaj bıraktı! Sohbete devam edin 😁',
      },
      dominoAdd:
        'Tebrikler! Bir seri yakaladınız! Şimdi Domino profilinizde görünecek!',
      dominoRem:
        "Oh hayır! Çizgini kaybettin. Domino'yu profilinize geri almak için 20 lingo kazanın :)",
    },
    messageDigipal: 'Mesaj {username}',
    messageFrom: 'gelen mesaj\n{userName}',
    friendRequest: 'Arkadaşlık isteği\n{userName}',
    requestAccepted: 'İstek Kabul edildi\n{userName}',
    likeDash: '{userName}\nsayfanı beğendim',
  },

  popup: {
    activityLeaveConfirmation:
      'Etkinlikten çıkmak istediğinizden emin misiniz? İlerlemeniz kaydedilmeyecek.',
    testLeaveConfirmation:
      'Testten çıkmak istediğinizden emin misiniz? Tekrar tekrar yapamazsınız.',
    streakPrefix: '{streak} günlük seri.',
    streak0:
      'Serinize başlamak için günde 20 Lingo kazanın! Lingo kazanmak için arkadaşlarınıza mesaj gönderin ve aktiviteleri tamamlayın!',
    premiumGamesErrorMessage:
      'Oops! Oyunlara erişiminiz yok. Ebeveynlerinizden hesabınızı yükseltmesini isteyin!',
    lingosErrorMessage:
      "Bazı ürünleri satın almak için Lingo'lara ihtiyacınız var. Lingos'u kazanmak için mesajlar gönderin.",
    offlineErrorMessage: 'Oops! İnternet bağlantısı çalışmıyor.',
    genericErrorMessage: 'Hay aksi! Bir hata oluştu. Tekrar deneyebilir misin?',
    newsfeedErrorMessage:
      'Oops! Mesajınız gönderilemedi. Tekrar deneyebilir misin',
    avatarCustomizationMenu:
      "Avatarınızı özelleştirin! Bazı ürünleri satın almak için Lingo'lara ihtiyacınız var. Lingos'u kazanmak için aktiviteleri tamamlayın veya mesaj gönderin.",
    wallpaperSettings:
      "Profil duvar kağıdınızı özelleştirin! Bazı ürünleri satın almak için Lingo'lara ihtiyacınız var. Lingos'u kazanmak için aktiviteleri tamamlayın veya mesaj gönderin.",
    interestsSettings:
      "Hobilerinizi ve beğenilerinizi gösterin! Bazı ürünleri satın almak için Lingo'lara ihtiyacınız var. Lingos'u kazanmak için aktiviteleri tamamlayın veya mesaj gönderin.",
    achievementsMenu: 'Kaç ödül kazandığınızı görün!',
    globe: `Dünyanın her yerinden yeni DigiPals bulun. Profillerini ziyaret edin, Lingos'u kazanmak için onlara bir mesaj bırakın. Onları DigiPal'ınız olmaya davet etmek için ${htmlFeatures.plusIcon} kullanın.`,
    friendsSuggested:
      'Diğer DigiPals ile arkadaş olun! Size özel bazı DigiPals önerileri var! Onları sizinle bağlantı kurmaya davet edin.',
    friends:
      "Bağlandığınız DigiPals'a bakın. Profillerini ziyaret edin, onlara bir mesaj bırakın ve Lingos'u kazanın.",
    friendsPending:
      "Sizinle bağlantı kurmak isteyen DigiPals'ı görün. Lingos'u kazanmak için bir mesaj bırakmak için davetlerini kabul edin ve profillerine gidin.",
    friendsSearch: `Birinin DigiPal adını biliyor musunuz? Onları burada arayın ve bir mesaj bırakmak ve daha fazla Lingo kazanmak için profillerine gidin. Onları DigiPal'ınız olmaya davet etmek için ${htmlFeatures.plusIcon} kullanın`,
    login: `Arkadaş edinmek harika! 'Önerilen arkadaşlarınızı' ziyaret edin veya yeni arkadaşlar edinmek için dünyayı ziyaret edin 😁`,
    profileLike: `{username} profil sayfasını beğendiniz! Çok mutlu olacaklar. Bunu neden beğendiğinizi bildirmek için onlara bir mesaj bırakın!`,
    streakNotice:
      "Harika, özelleştirilebilir bir Domino evcil hayvanı ister misiniz? Domino almak için her gün 20 lingo kazanın. Bu senin 'Streak'.",
    profilePage:
      "Merhaba! Ben Domino'yum. Dünyanın her yerinden arkadaşlar edinmenize yardımcı olacağım! Yardıma ihtiyacınız olursa, beni araç çubuğunda bulabilirsiniz. Bu, DigiPal profil sayfanızdır. Herkese senin hakkında bir şeyler anlatır. Keşfetmeye başlayın!",
  },

  activity: {
    rewards: {
      trophyTitle: 'Bir kupa kazandın!',
      starTitle: 'Bir yıldız kazandınız!',
      lingoTitle: '{lingos} diliniz var!',
      score: 'Puan',
      returnToActivities: 'Aktivitelere Dön',
    },
    currentLesson: 'Mevcut ders',
    offlineMessage:
      'Uh oh! İnternet bağlantınızı kaybettiniz. Merak etmeyin, aktivite puanınızı kaydettik!',
  },

  onboarding: {
    first:
      'Dünyanın her yerinden DigiPals ile tanışın ve onlara İngilizce mesaj gönderin.',
    second:
      'İngilizce konusunda yardıma mı ihtiyacınız var? Buradan öğrenmeye başlayabilirsiniz.',
    third:
      "Avatarınızı oluşturun. Beğenilerinizi sergileyin. Diğer DigiPals'ı bulun.",
    fourth:
      "DigiPals'ınızı ziyaret edin. Mesaj bırakın. Sohbetlere katılın. DigiPals'ı size mesaj göndermeye davet edin.",
    fifth:
      'Mesajlar ve tamamlanan oyunlar için Lingos kazanın. Avatarınız ve Domino için bir şeyler satın alın.',
    sixth:
      "Little Bridge'de galibiyetinizi koruyun ve profiliniz için Domino kazanın!",
    seventh: {
      title: 'Arkadaş edinmeye hazır mısınız?',
      btn: `Hadi gidelim!`,
    },
  },
  tooltip: {
    toolbar: {
      home: 'Ev',
      findFriends: 'Yeni\narkadaşlar\nbul',
      activities: 'Domino Akademisi',
      dominoAcademy: 'Domino \n Akademisi',
      friends: 'Arkadaş',
      streak: 'Rüzgâr gibi geçmek',
      globe: 'Kürek',
      information: 'Bilgi',
      menu: 'Menü',
      lingos: 'Lingos',
      notifications: 'Bildirimler',
      notificationsactive: 'Bildirimler',
      audio: 'Soruyu tekrarla',
      exit: 'Yakın aktivite',
      snail: 'Yavaş soru',
      steps: 'Devam et',
      fetchFriend: 'Yeni arkadaşlar bul',
    },
    profile: {
      interests: 'Ilgi alanları',
      digipals: 'Benim digipallerim',
      achievements: 'Başarılarım',
      editWallpaper: 'Duvar Kağıdını Düzenle',
      addDigipal: 'Digipal ekle',
      offline: 'Çevrimdışı',
      online: 'İnternet üzerinden',
      myAvatar: 'Benim digipal',
      avatar: 'avatar',
      sendMessage: 'Mesaj gönder',
      likeProfile: 'profili beğen',
      myFriends: 'Arkadaşlarım',
      digipalLikes: 'Bu profil gibi',
      myLikes: 'Beğenilerim',
      alreadyLiked: 'Bu profili zaten seviyorsun',
      myMessages: 'Mesajlarım',
      requestPending: 'DigiPal isteği beklemede',
      weAreDigipals: "Biz DigiPals'ız!",
    },
    messages: {
      likes: 'Seviyor',
      send: 'Göndermek',
      saySomething: 'Bir şey söyle',
      askSomething: 'Bir şey sor',
      emoji: 'Emoji',
      reply: 'Cevap vermek',
    },
    activities: {
      supportLanguage: 'türkçe talimat',
      teachingLanguage: 'İngilizce talimatları',
      next: 'Sonraki',
      previous: 'Öncesi',
      play: 'Oyna',
      slowPlay: 'Yavaş',
      record: 'Kayıt',
      playback: 'Geri çalma',
    },
    other: {
      back: 'Geri',
      getItem: 'Öğeyi almak',
      zoomIn: 'Yakınlaştır',
      zoomOut: 'Uzaklaştır',
    },
  },

  conversations: {
    sendMessage: 'Bir mesaj göndermek',
    myInbox: 'Gelen kutum',
    send: 'Mesaj gönder',
    yourChat:
      'ile konuşmanız <p class="conversation__header__subtitle">{username}</p>',
    noMessages: 'Burada hiç mesaj yok! İlkini yaz!',
  },

  messageRewards: {
    title: '{username} adlı kullanıcıya bir mesaj gönderdiniz!',
    returnToHome: 'Profil sayfasına dön',
  },
};
