<template>
  <article class="vertical-containter-with-text-box">
    <ActivityTextContainer
      :key="selectedWordsKey"
      data-testID="container-activity-text-container"
      class="text-box"
    >
      {{ textContainerText() }}
    </ActivityTextContainer>

    <Carousel
      ref="carousel"
      class="carousel"
      data-testID="container-carousel"
      manual-initialize
      hide-buttons-on-start-end
      :items-per-view="getItemsPerView"
    >
      <Column
        v-for="(words, columnIndex) in columns"
        :key="columnIndex"
        data-testID="container-column"
        :bg-color="meta.style.backgroundColor"
        :words="words"
        :selected-word="getSelectedWord(columnIndex)"
        @on-select="selectWord($event, columnIndex)"
      />

      <img
        slot="prevButton"
        v-tooltip="{
          content: this.$t('tooltip.activities.previous').toString(),
        }"
        :src="
          isOnline
            ? require('@/assets/images/activities/arrow-left.png')
            : requireFromCache(['arrow-left'], preloadedImages)
        "
        data-testID="container-prev-button"
        alt="Prev Slider Icon"
        class="carousel-prev"
        title="Previous slide"
      />

      <img
        slot="nextButton"
        v-tooltip="{
          content: this.$t('tooltip.activities.next').toString(),
        }"
        :src="
          isOnline
            ? require('@/assets/images/activities/arrow-right.png')
            : requireFromCache(['arrow-right'], preloadedImages)
        "
        class="carousel-next"
        data-testID="container-next-button"
        alt="Next Slider Icon"
        title="Next slide"
      />
    </Carousel>
  </article>
</template>

<style lang="scss">
.vertical-containter-with-text-box {
  .carousel__disable-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<style lang="scss" scoped>
.text-box {
  margin-bottom: 10px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.word {
  white-space: pre-wrap;
}

.carousel {
  &-next,
  &-prev {
    height: 60px;
  }
}

.carousel {
  max-width: $activityComponentBoxWidth;
  margin: auto;
}

::v-deep {
  .carousel__element {
    margin-right: 20px;

    .col-wrap {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';

import ActivityTextContainer from '@/components/ActivityTextContainer/ActivityTextContainer.vue';
import Carousel, { Carousel as CarouselType } from 'vue2-simple-carousel';

import Column from './Column.vue';
import {
  IActivityComponentDataElement,
  IActivityComponentMetaData,
} from '@/models/interfaces/activities';

import EventBus from '@/utils/helpers/EventBus';

import theme from '@/styles/theme';
import { groupWith } from 'ramda';
import { PreloadedAsset } from '@/store/modules/preload-assets';
import { State } from 'vuex-class';
import { requireFromCache } from '@/utils/helpers/assets';

@Component({
  components: {
    ActivityTextContainer,
    Carousel,

    Column,
  },
})
export default class VerticalContainerWithTextBox extends Vue {
  @Ref('carousel')
  private carousel!: CarouselType;

  @Prop({ required: true, type: Object })
  private meta!: IActivityComponentMetaData;

  @Prop({ required: true, type: Array })
  private data!: Array<IActivityComponentDataElement>;

  @Prop({ required: true, type: String })
  private type!: string;

  @State('preloadedImages', { namespace: 'preloadAssets' })
  private preloadedImages!: PreloadedAsset[];

  private requireFromCache = requireFromCache;

  private selectedWords: Array<
    | {
        word: IActivityComponentDataElement;
        column: number;
      }
    | undefined
  > = [];

  private selectedWordsKey = 0;
  private columns: Array<IActivityComponentDataElement[]> = [];

  private get getItemsPerView() {
    return this.windowWidth < theme.mobile ? 2 : 3;
  }

  private textContainerText() {
    return this.selectedWords
      .map(item => {
        return item && item.word ? item.word.value : '';
      })
      .join(' ');
  }

  private initCarousel() {
    const isSmallResolution = this.windowWidth < theme.mobile;
    const maxColumnsLength = 3;
    const tooMuchColumns = this.columns.length > maxColumnsLength;

    // true on init
    const { disabled } = this.carousel;

    if (disabled) {
      if (isSmallResolution || tooMuchColumns) {
        this.carousel.initialize();
      }
    } else {
      if (!isSmallResolution && !tooMuchColumns) {
        this.carousel.destroy();
      }
    }
  }

  private getSelectedWord(columnIdx: number) {
    return this.selectedWords[columnIdx]?.word;
  }

  private selectWord(word: IActivityComponentDataElement, colIdx: number) {
    const sameSelected =
      this.selectedWords[colIdx] &&
      this.selectedWords[colIdx]?.word.id === word.id;

    this.selectedWords[colIdx] = sameSelected
      ? undefined
      : { word, column: colIdx };

    this.selectedWordsKey++;

    const solution = this.selectedWords
      .filter(item => !!item)
      .map(item => ({
        data: item?.word,
        type: this.type,
      }));

    EventBus.$emit('activity-event-solution-bulk-update', solution);
  }

  private mounted() {
    this.columns = groupWith((a, b) => a.index === b.index, this.data);

    this.initCarousel();
  }

  @Watch('windowWidth')
  private onResize() {
    this.initCarousel();
  }
}
</script>
