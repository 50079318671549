import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';

import { MutationTree, ActionTree, Module } from 'vuex';
import { RootState } from '..';
import { IVuexState } from '@/models/interfaces/store';
import { INewsfeedItem } from '@/models/interfaces/newsfeed';
import {
  ISentenceMakerPhrase,
  ISentenceMakerOptions,
} from '@/models/interfaces/sentence-maker';
import { ILikeData } from '@/models/interfaces/users';
import { uniq } from 'ramda';

export interface State {
  postLikeStatus: IVuexState<ILikeData>;
  sentenceMakerOptions: IVuexState<ISentenceMakerPhrase[]>;
  likedMessagesIds: string[];
}

export const getUniqueLikedMessagesIds = (ids: string[], idToAdd: string) => {
  return uniq([...ids, idToAdd]);
};

export const setUniqueLikedMessagesIds = (data: INewsfeedItem[]) => {
  let ids: string[] = [];

  data.forEach(item => {
    if (!item.chatMessage.canBeLiked) {
      ids = getUniqueLikedMessagesIds(ids, item.chatMessage.messageId);
    }
  });

  return ids;
};

export const mutations: MutationTree<State> = {
  setLikeStatus(state, data?: ILikeData | Error) {
    state.postLikeStatus = StoreUtil.updateState(state.postLikeStatus, data);
  },
  addLikedMessagedId(state, id: string) {
    state.likedMessagesIds = getUniqueLikedMessagesIds(
      state.likedMessagesIds,
      id,
    );
  },
  setSentenceMakerOptions(state, payload?: ISentenceMakerPhrase[] | Error) {
    state.sentenceMakerOptions = StoreUtil.updateState(
      state.sentenceMakerOptions,
      payload,
    );
  },
};

export const actions: ActionTree<State, RootState> = {
  async likePost({ commit }, data: ILikeData) {
    commit('setLikeStatus');

    try {
      const { data: response } = await DataProvider.post<{
        likedItemId: string;
      }>('likes', data);
      commit('addLikedMessagedId', response.likedItemId);
      commit('setLikeStatus', response);
      return response;
    } catch (error) {
      commit('setLikeStatus', error);
      return error;
    }
  },
  async fetchSentenceMakerOptions({ commit }, type: 'say' | 'ask') {
    commit('setSentenceMakerOptions');

    try {
      const { data } = await DataProvider.get<ISentenceMakerOptions>(
        'sentenceMaker',
        { params: { type } },
      );
      commit('setSentenceMakerOptions', data.options);
      return data;
    } catch (error) {
      commit('setSentenceMakerOptions', error);
      return error;
    }
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  actions,
  mutations,
  state: {
    postLikeStatus: StoreUtil.state(),
    sentenceMakerOptions: StoreUtil.state(),
    likedMessagesIds: [],
  },
};

export default store;
