import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AppHeaderMenus extends Vue {
  public mounted() {
    window.addEventListener('click', this.$_AppHeaderMenus_onWindowClick);
  }

  public destroyed() {
    window.removeEventListener('click', this.$_AppHeaderMenus_onWindowClick);
  }

  private $_AppHeaderMenus_onWindowClick(event: Event) {
    const target = event.target as HTMLElement;
    const targetClasses = ['app__header', 'app__bottom-nav'];

    if (targetClasses.includes(target.className)) {
      this.$emit('on-close');
    }
  }
}
