import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: 'Xahiş edirəm əvvəl bir səbəb seçin!',
    title: 'Community qaydaları',
    popup: {
      title: `Cəmiyyətimizin təhlükəsizliyini təmin etməyə kömək etdiyiniz üçün təşəkkür edirik!`,
      btn: 'İcma Qaydalarına qayıt',
    },
    pt1: {
      subtitle: 'DigiPals Community nədir?',
      text:
        'Bizim DigiPals icma dostlar və İngilis dili praktiki üçün təhlükəsiz yerdir.',
    },
    pt2: {
      subtitle: 'icma qaydaları hansılardır?',
      text1: 'Həmişə İngilis yazmaq',
      text2:
        'Biz soyuq, təhqiredici və ya bədxah yazmaq bir şey etməyin. Söyüş icazə verilmir!',
      text3:
        'məsələn, hər hansı bir şəxsi deyin Tam adı, ünvanları, telefon nömrələri, e-poçt ünvanları və ya digər online / social media hesab ayrıntıları',
      text4: 'Bütün mesajlar real insanlar tərəfindən nəzərdən keçirilir.',
    },
    pt3: {
      subtitle: 'Mən kimsə bir qayda qırılma, əgər mən nə etməliyəm?',
      text1:
        'Bu hesabat göndərilməsi bizim icma xoşbəxt hər kəs saxlamaq üçün kömək edir.Biz daha çox və sizə kömək tapmaq üçün böyüklər qədər müraciət edəcək.',
      text2: 'We will contact your grown up to find out more and help you',
    },
    btn: 'bir hesabat göndər',

    option1: 'Mən kimsə ingilis yazılı deyil edirəm',
    option2: 'Mən kimsə olan naxələf hesab edirəm',
    option3: 'Mən kimsə Şəxsi məlumatları paylaşdı edirəm',
  },

  globe: {
    _: 'Qlobus',
    friendsMultiple: '{friends} dostunuz var',
    friends: '1 dostunuz var',
    countries: '1 ölkədə',
    countriesMultiple: '{countries} ölkədə',
    defaultTitle: 'Qlobal DigiPal icmamız!',
    fetchFriend: 'İndi yeni dost gətirin!',
  },

  profile: {
    avatar: {
      title: 'Avatarınızı düzəldin',
    },
    error: 'Vay! Bir şey səhv oldu. Yenidən cəhd edə bilərsən?',
    messagesEmpty:
      'Burada hələ heç bir mesaj yoxdur. Tövsiyə olunan DigiPal-lar siyahınıza keçin, onların profillərinə daxil olun və söhbətə başlayın.',
    postTitle: 'DigiPals-dan göndərilən mesajlar',
    writePost: 'DigiPost yazın',
    profileError: 'Profil mesajları alınmadı.',
    clock: '{username} Yaşadığı vaxt nə vaxtdır?',
    country: '{username}\n{ölkədə}\nyaşayır',
    myCountry: 'Mən {country}\niçində yaşayıram',
    myClock: 'Yaşadığım yerdə\nsaat neçədir?',
    friendsAccepted: 'Artıq dostsan!',
    friendsAdd: 'Bir diapazon tələbi göndərin',
    friendsPending: 'Digipal tələbi gözləyir',
  },

  dictionary: {
    alphabet: {
      error: 'Əlifba alınmadı',
    },
    words: {
      error: 'Sözlər alınmadı',
    },
  },

  dates: {
    yearsAgo: '{count} il əvvəl',
    yearAgo: 'bir il əvvəl',
    monthsAgo: '{count} ay əvvəl',
    monthAgo: 'bir ay əvvəl',
    daysAgo: '{count} gün əvvəl',
    dayAgo: 'bir gün əvvəl',
    hoursAgo: '{count} saat əvvəl',
    hourAge: 'bir saat əvvəl',
    minutesAgo: '{count} dəqiqə əvvəl',
    minuteAgo: 'bir dəqiqə əvvəl',
    seconds: 'bir neçə saniyə əvvəl',
  },

  common: {
    someone: 'Kimsə',
  },

  digiPal: {
    messagesEmpty: 'Burada mesaj yoxdur! Birincisini yazın!',
  },

  interests: {
    title: 'Bəyəndiyimi seçin',
  },

  achievements: {
    title: 'Nailiyyətlərimə baxın',
    messageTrophy:
      'DigiPal-lar mesajlarınıza avab verdiyi zaman kuboklar qazanın.',
    activityTrophy:
      'Fəaliyyətləri başa çatdırmağa görə kuboklar qazanın! Qazandığınız hər ulduz bir kubok olaraq hesablanır.',
  },

  settings: {
    title: {
      1: 'İngiltərə İngilis dilini və ya ABŞ İngilis dilini öyrənirsiniz?',
      2: "'Kömək' alın?",
    },
  },

  digipals: {
    genericErrorMessage: 'Xəta baş verdi. Yenidən cəhd edə bilərsiniz?',

    all: {
      title: 'Mənim DigiPals',
      emptyErrorMessage:
        'Hələ qoşulmuş dostlarınız yoxdur. Dostlar qazanmağa başlamaq üçün "Tövsiyə olunan DigiPal-lar"a keçin.',
      successRequest:
        '{friend} adlı istifadəçiyə dostluq sorğusu göndərmisiniz. Niyə dost olmaq istədiyinizi bildirmək üçün profilinə mesaj yazın.',
      clock:
        '{digipal} yaşayan yerdə saat neçədir? Unutmayın, siz oyaq olduğunuz zaman bəzi DigiPal-lar yata, ya da tərsinə ola bilər.',
    },
    mutual: {
      title: 'Qarşılıqlı DigiPals',
      emptyErrorMessage: 'Qarşılıqlı dostunuz yoxdur.',
    },
    pending: {
      title: 'DigiPal istəkləri',
      emptyErrorMessage:
        'Hələ gözləyən tələbiniz yoxdur. Dostlar qazanmağa başlamaq üçün "Tövsiyə olunan DigiPal-lar"a keçin.',
      successAccept:
        'Artıq {friend} ilə dostsunuz. Salamlamaq üçün onun profilinə daxil olun.',
    },
    search: {
      title: 'DigiPal axtarın',
    },
    suggested: {
      title: 'İndi onlayn olan mesaj dostları!',
      emptyErrorMessage: 'İnternetdə dostlarınız yoxdur.',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        'Salam! Mən Dominoyam. Sizə dünyanın hər yerindən dostlar qazanmağa kömək edəcəyəm. Köməyə ehtiyacınız olsa, məni alətlər panelində tapın. Bu, DigiPal profil səhifənizdir. Bu, hər kəsə sizin haqqınızda nə isə deyir. Araşdırmağa başlayın.',
      post: {
        reply: '{username} mesajınıza cavab verdi!Söhbəti davam etdirin 😁',
        wallMessage: `{username} profilinizə mesaj yazdı! Söhbət davam etdirilsin? 😁.`,
      },
      dominoAdd:
        'Təbriklər 1+ günlük zolaq əldə etdiniz! İndi Domino profilinizdə görünəcək!',
      dominoRem:
        'Zolağınızı itirdiniz. Dominonu geri almaq üçün 5 günlük zolaq əldə edin 😁',
    },
    messageDigipal: 'Mesaj {username}',
    messageFrom: 'Mesaj\n{userName}',
    friendRequest: 'Dostluq təklifi\n{userName}',
    requestAccepted: 'Sorğu qəbul edildi\n{userName}',
    likeDash: '{userName}\nsəhifənizi bəyəndim',
  },

  popup: {
    activityLeaveConfirmation:
      'Fəaliyyəti tərk etmək istədiyinizə əminsiniz? Sizin tərəqqiniz yadda saxlanmayacaq.',
    testLeaveConfirmation:
      'Testdən çıxmaq istədiyinizə əminsiniz? Bir daha təkrar edə bilməyəcəksiniz.',
    streakPrefix: '{streak}  günlük zolaq.',
    streak0:
      'Little Bridge-də olduğunuz hər gün 20 Lingo qazanın və zolağınızı qurun. Longo-lar qazanmaq üçün dostlarınıza mesajlar göndərin və fəaliyyətləri tamamlayın.',
    premiumGamesErrorMessage:
      'Oyunlara giriş imkanınız yoxdur. Valideynlərinizdən hesabınızı yeniləşdirməsini istəyin.',
    lingosErrorMessage:
      'Bəzi əşyalar almaq üçün Lingos a ehtiyacınız var. Lingos qazanmaq üçün mesaj göndərin.',
    offlineErrorMessage: `Hmmm. İnternet bağlantısı işləmir. Yenidən cəhd edin.`,
    genericErrorMessage: 'Vay! Bir şey səhv oldu. Yenidən cəhd edə bilərsən?',
    newsfeedErrorMessage:
      'Mesajınız göndərilmədi. Yenidən cəhd edə bilərsiniz?',
    avatarCustomizationMenu:
      'Avatarınızı fərdiləşdirin. Lingos qazanmaq üçün mesajlar göndərin. Yeniləşdirmələr almaq üçün Lingos-dan istifadə edin.',
    wallpaperSettings:
      'Divar kağızınızı fərdiləşdirin. Lingos qazanmaq üçün mesajlar göndərin. Yenidən bəzəmək üçün Lingos-dan istifadə edin.',
    interestsSettings:
      'Nəyi etməyi və oynamağı xoşladığınızı göstərin. Bəzi elementlər almaq üçün sizə Lingos lazımdır. Lingos qazanmaq üçün mesajlar göndərin.',
    achievementsMenu: 'Nə qədər mükafat qazandığınıza baxın.',
    globe: `Dünyanın hər yerindən yeni DigiPal-lar tapın. Onların profilinə daxil olun, onlara mesajlar yazın və Lingos qazanın. Onları DigiPal-ıınız olmağa dəvət etmək üçün ${htmlFeatures.plusIcon} istifadə edin.n Lingos.`,
    friendsSuggested:
      'Digər DigiPal-larla dost qazanın. Sizin üçün bəzi DigiPal-lar tövsiyə olunub. Onları sizinlə əlaqə qurmağa dəvət edin.',
    friends:
      'Qoşulmuş DigiPal-larınıza baxın. Onların profillərinə daxil olun, onlara mesajlar yazın və Lingos qazanın.',
    friendsPending:
      'DigiPal-lar sizinlə görüşmək istəyir. Onların dəvətini qəbul edin və Lingos qazanmaq üçün mesajlar yazın.',
    friendsSearch: `Kiminsə İstifadəçi adını blirsiniz? Onları burada axtarın və Lingos qazanmaq üçün onlara mesaj yazın. Onları DigiPal-ıınız olmağa dəvət etmək üçün ${htmlFeatures.plusIcon} istifadə edin.`,
    login: `"Tövsiyə olunan dostlar"ınıza daxil olun və ya yeni dostlar qazanmaq üçün qlobusu ziyarət edin 😁`,
    profileLike: `{username} adlı istifadəçinin profil səhifəsini bəyəndim. Buna sevinəcək. Niyə bəyəndiyinizi bildirmək üçün ona mesaj yazın.`,
    streakNotice:
      'Dominonun profilinizdə görünməsi üçün X günlük zolaq əldə edin.',
    profilePage:
      'Salam! Mən Dominoyam. Sizə dünyanın hər yerindən dostlar qazanmağa kömək edəcəyəm. Köməyə ehtiyacınız olsa, məni alətlər panelində tapın.    Bu, DigiPal profil səhifənizdir. Bu, hər kəsə sizin haqqınızda nə isə deyir. Araşdırmağa başlayın.',
  },

  activity: {
    rewards: {
      trophyTitle: 'Kuboku qazandınız!',
      starTitle: 'Bir ulduz qazandınız!',
      lingoTitle: '{lingos} lingosunuz var!',
      score: 'Hesab',
      returnToActivities: 'Fəaliyyətlərə qayıt',
    },
    currentLesson: 'Hazırkı dərs',
    offlineMessage:
      'Uh oh! İnternet bağlantınızı itirmisiniz. Narahat olma, aktivlik hesabını saxladıq!',
  },

  onboarding: {
    first: `Dünyanın hər yerindən olan DigiPal-larla görüşün və onlara ingilis dilində mesajlar göndərin.`,
    second: `İngilis dili ilə bağlı köməyə ehtiyacınız var? Doğru yere gəlmisiniz! Cümlə qurucumuzdan istifadə etməyə başlayın. Qısa zamanda söhbətləşməyə başlayacaqsınız.`,
    third: `Avatarınızı yaradın. Sizi maraqlandıran şeyləri göstərin. Eyni şeyləri sevən digər DigiPal-ları tapın.`,
    fourth:
      'DigiPal-larınızı ziyarət edin. Mesaj yazın. Söhbətlərə qoşulun. DigiPal-ları sizə mesaj yazmağa dəvət edin.',
    fifth:
      'Mesajlara və oyunları tamamladığınıza görə Lingos qazanın. Avatarınız və Domino üçün əşyalar alın.',
    sixth:
      'Little Bridge-də zolaq saxlayın və Dominonu ev heyvanı olaraq əldə edin.',
    seventh: {
      title: 'Dost qazanmağa hazırsınız?',
      btn: `Getdik!`,
    },
  },
  tooltip: {
    toolbar: {
      findFriends: 'Yeni dostlar\ntapın',
      home: 'Ev',
      activities: 'Domino Akademiyası',
      dominoAcademy: 'Domino\nAkademiyası',
      friends: 'dostlar',
      streak: 'Zol',
      globe: 'Qlobe',
      information: 'Məlumat',
      menu: 'Menyu',
      lingos: 'Lingos',
      notifications: 'Bildirişlər',
      notificationsactive: 'Bildirişlər',
      audio: 'Təkrarlamaq',
      exit: 'Yaxınlıq',
      snail: 'Yavaş sual',
      steps: 'Davam et',
      fetchFriend: 'Yeni dostlar tapın',
    },
    profile: {
      interests: 'Maraqlar',
      digipals: 'Qiyamətim',
      achievements: 'Nailiyyətlərim',
      editWallpaper: 'Divar kağızı düzəldin',
      addDigipal: 'Digipal əlavə edin',
      offline: 'Xətdənsiz',
      online: 'Onlayn',
      myAvatar: 'Mənim digipal',
      avatar: 'Avatar',
      sendMessage: 'Mesaj göndər',
      likeProfile: 'Profili bəyən',
      myFriends: 'Mənim dostlarım',
      myLikes: 'Bəyəndiyim',
      digipalLikes: 'Bu profil kimi',
      alreadyLiked: 'Artıq bu profili bəyənirsiniz',
      myMessages: 'Mesajlarım',
      requestPending: 'DigiPal sorğusu gözlənilir',
      weAreDigipals: 'Biz DigiPals!',
    },
    messages: {
      likes: 'Bəyənir',
      send: 'Göndər',
      saySomething: 'Nəsə de',
      askSomething: 'Bir şey soruşmaq',
      emoji: 'Emoji',
      reply: 'Cavab vermək',
    },
    activities: {
      supportLanguage: 'Azərbaycan təlimatı',
      teachingLanguage: 'İngilis dili təlimatları',
      next: 'Sonrakı',
      previous: 'Əvvəlki',
      play: 'Oynamaq',
      slowPlay: 'Yavaş',
      record: 'Rekord',
      playback: 'Səs-küy',
    },
    other: {
      back: 'Geri',
      getItem: 'Element almaq',
      zoomIn: 'Yaxınlaşdırma',
      zoomOut: 'Böyütmək',
    },
  },

  conversations: {
    sendMessage: 'Mesaj göndərmək',
    myInbox: 'Gələnlər qutum',
    send: 'Mesaj göndər',
    yourChat:
      'ilə söhbətiniz <p class="conversation__header__subtitle">{username}</p>',
    noMessages: 'Burada hələ heç bir mesaj yoxdur! Birincisini yazın!',
  },

  messageRewards: {
    title: 'Siz {username} adlı istifadəçiyə mesaj göndərdiniz!',
    returnToHome: 'Profil səhifəsinə qayıt',
  },
};
