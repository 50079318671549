type Variant = 'human' | 'dog';
type Type = 'thumbnail' | 'preview';

export function getDirectory(variant: Variant, type: Type) {
  const directories = {
    human: {
      preview: 'human-full',
      thumbnail: 'human',
    },
    dog: {
      preview: 'dog-full',
      thumbnail: 'dog',
    },
  };

  return directories[variant][type];
}

export default function getAvatarUrl(
  variant: Variant,
  type: Type,
  hash: string,
) {
  const template =
    process.env.VUE_APP_CDN_AVATARS_URL || '{{directory}}/{{hash}}.png';
  const dir = getDirectory(variant, type);

  return template.replace('{{directory}}', dir).replace('{{hash}}', hash);
}
