<template></template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EventBus from '@/utils/helpers/EventBus';
import {
  IActivityRelationPayload,
  IActivityDataPayload,
  IActivityComponentData,
  IActivityEnhancedComponentData,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';

@Component
export default class ActivityHiddenContainer extends Vue {
  @Prop({ required: true })
  private data!: IActivityComponentData;

  @Prop({ required: true })
  private type!: string;

  private matchComponentsData(relationPayload: IActivityRelationPayload) {
    const currentComponent = this.data.find(
      (data: IActivityDataPayload) =>
        data.id === relationPayload.data.componentId,
    ) as IActivityComponentDataElement;

    const relationData = relationPayload.data;

    const enhancedComponentData = {
      ...currentComponent,
      ...relationData,
      hiddenComponentType: this.type,
    };

    this.changeComponent(enhancedComponentData);
  }

  public changeComponent(
    enhancedComponentData: IActivityEnhancedComponentData,
  ) {
    EventBus.$emit('activity-event-hidden-component-change', {
      data: enhancedComponentData,
    });
  }

  mounted() {
    EventBus.$on('activity-event-relation-change', this.matchComponentsData);
  }

  destroyed() {
    EventBus.$off('activity-event-relation-change', this.matchComponentsData);
  }
}
</script>
