var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"activity"},[(!_vm.requestedPermission)?_c('Spinner',{staticClass:"activity__loader",attrs:{"color":"#f3aa45"}}):[_c('ul',{staticClass:"activity__list activity__messages"},_vm._l((_vm.data),function(sentence,index){return _c('li',{key:sentence.label + index,staticClass:"activity__message"},[_c('ActivitySpeakingMessage',{staticClass:"activity__content__message",attrs:{"message":sentence.label,"avatar-id":sentence.id,"outline":index === _vm.currentlyPlayedSentence,"direction":index % 2 ? 'right' : 'left'}})],1)}),0),_c('div',{staticClass:"wrapper"},[_c('ul',{staticClass:"activity__list activity__actions"},[_c('li',[_c('button',{staticClass:"action-item action-item--play",on:{"click":_vm.handlePlay}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: this.$t('tooltip.activities.play').toString(),
              }),expression:"{\n                content: this.$t('tooltip.activities.play').toString(),\n              }"}],staticClass:"activity__action",attrs:{"src":require("@/assets/images/activities/play.png"),"alt":"Play icon","title":"Play"}})])]),_c('li',[_c('button',{staticClass:"action-item action-item--slow",on:{"click":function($event){return _vm.handlePlay({ isSlow: true })}}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: this.$t('tooltip.activities.slowPlay').toString(),
              }),expression:"{\n                content: this.$t('tooltip.activities.slowPlay').toString(),\n              }"}],staticClass:"activity__action",attrs:{"src":require("@/assets/images/activities/snail.png"),"alt":"Snail icon","title":"Play slow"}})])]),(_vm.grantedPermission)?_c('li',[_c('button',{staticClass:"action-item",on:{"click":_vm.handleRecord}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: this.$t('tooltip.activities.record').toString(),
              }),expression:"{\n                content: this.$t('tooltip.activities.record').toString(),\n              }"}],staticClass:"activity__action activity__action--record",attrs:{"src":require("@/assets/images/activities/record.png"),"alt":"Record icon","title":"Record"}})])]):_vm._e(),(_vm.grantedPermission)?_c('li',[_c('button',{staticClass:"action-item",on:{"click":_vm.handlePlayback}},[_c('object',{class:{
                'activity__action activity__action--fallback': true,
                'activity__action--disabled': !_vm.canValidateActivity,
              },attrs:{"type":"image/png","data":_vm.avatarUrl}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: this.$t('tooltip.activities.playback').toString(),
                }),expression:"{\n                  content: this.$t('tooltip.activities.playback').toString(),\n                }"}],staticClass:"activity__action activity__action--fallback",attrs:{"src":"/images/globe/default-avatar-texture.png","alt":"Avatar","title":"Playback"}})]),_c('img',{class:{
                'activity__action--avatar-playback': true,
                'activity__action--disabled': !_vm.canValidateActivity,
              },attrs:{"src":require("@/assets/images/activities/playback.png"),"alt":"Playback icon","title":"Playback"}})])]):_vm._e()]),_c('ActivityTextButton',{staticClass:"confirm",attrs:{"color":"white","bg-color":_vm.disableButton ? 'grey' : '#87c040',"disabled":_vm.disableButton,"squared":true},nativeOn:{"click":function($event){return _vm.onConfirm.apply(null, arguments)}}},[_vm._v(" Proceed to the next activity ")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }