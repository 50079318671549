import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueWindowSize from 'vue-window-size';
import Vue2TouchEvents from 'vue2-touch-events';
import VueObserveVisibility from 'vue-observe-visibility';

// @ts-ignore v2.0.3 TS declarations are broken, waiting for v2.0.4 release
import VueTooltip from 'v-tooltip';

import BackNavigation from '@/utils/plugins/BackNavigation';

import i18n from '@/i18n';

// @ts-ignore
import VueOffline from 'vue-offline';

Vue.config.productionTip = false;

Vue.use(BackNavigation);
Vue.use(VueTooltip);
Vue.use(Vue2TouchEvents);
Vue.use(VueWindowSize);
Vue.use(VueOffline);
Vue.use(VueObserveVisibility);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
