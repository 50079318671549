<template>
  <button
    :class="[
      'activity-text-btn',
      sizingClass,
      { 'activity-text-btn--focused': focused },
      `activity-text-btn--${hexToString(secondaryBgColor || bgColor)}`,
      { 'activity-text-btn--squared': squared },
      { 'activity-text-btn--clue': isClue },
      { 'activity-text-btn--hint': hint },
      { 'activity-text-btn--disabled': disabled || hint },
    ]"
    :style="{
      background: secondaryBgColor || bgColor,
      color,
    }"
    @mousedown="focused = true"
    @mouseup="focused = false"
    @touchstart="focused = true"
    @touchend="focused = false"
    @focus="onFocus"
    @blur="onBlur"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
$hintBg: #f4aa45;

.activity-text-btn {
  padding: 0 13px;
  margin: 0 10px 12px;
  transition: all 0.2s;

  min-height: 20px;
  min-width: 0;

  border-radius: 10px;

  color: $white100;
  font-weight: $font-weight;
  font-size: 13px;

  display: flex;
  align-items: center;
  justify-content: center;

  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &--hint {
    background-color: $hintBg;
  }

  &--disabled {
    pointer-events: none;

    cursor: default;
  }

  &--clue {
    min-width: 60px;

    margin: auto;
  }

  &--medium {
    min-height: 30px;

    padding: 0 20px;

    font-size: 16px;

    border-radius: 5px;
  }

  &--large {
    min-height: 40px;

    padding: 0 25px;

    font-size: 18px;

    border-radius: 8px;
  }

  &--squared {
    min-height: 39px;

    margin-top: 68px;

    font-size: 26px;

    border-radius: 3px;
  }

  &--blue {
    box-shadow: 0px 4px 0px 0px $blue100Shadow;
  }

  &--green {
    box-shadow: 0px 4px 0px 0px $green400Shadow;
  }

  &--pink {
    box-shadow: 0px 4px 0px 0px $pink200Shadow;
  }

  &--purple {
    box-shadow: 0px 4px 0px 0px $blue100Shadow;
  }

  &--light-pink {
    box-shadow: 0px 4px 0px 0px $pink300Shadow;
  }

  &--focused {
    transform: translateY(3px);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RESOLUTIONS_NAME } from '@/models/constants/resolutions';

import getDefaultSizingClass from '@/utils/helpers/getDefaultSizingClass';

type Size = 'small' | 'medium' | 'large';

const bgColorTypes = {
  audio: {
    selected: '#f8b6d3',
    default: '#e90c6e',
  },
  draggable: {
    selected: '#f9d39f',
    default: '#f4aa45',
  },
};

@Component
export default class ActivityTextButton extends Vue {
  private sizingClass = '';
  private secondaryBgColor = '';

  @Prop({ default: false })
  private squared!: boolean;

  @Prop({ default: 'white' })
  private color!: string;

  @Prop({ default: '#1b75bb' })
  private bgColor!: string;

  @Prop({ default: '' })
  private bgColorType!: 'audio' | 'draggable';

  @Prop({ default: false })
  private isClue!: boolean;

  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ default: false })
  private hint!: boolean;

  @Prop({ default: RESOLUTIONS_NAME.MOBILE })
  private mobileSize!: Size;

  @Prop({ default: RESOLUTIONS_NAME.TABLET })
  private tabletSize!: Size;

  @Prop({ default: RESOLUTIONS_NAME.DESKTOP })
  private desktopSize!: Size;

  public focused = false;

  private onResize() {
    this.sizingClass = `activity-text-btn--${getDefaultSizingClass(
      this.desktopSize,
      this.tabletSize,
      this.mobileSize,
    )}
    `;
  }

  private onFocus() {
    if (this.bgColorType) {
      this.secondaryBgColor = bgColorTypes[this.bgColorType].selected;
    }
  }

  private onBlur() {
    if (this.bgColorType) {
      this.secondaryBgColor = bgColorTypes[this.bgColorType].default;
    }
  }

  public hexToString(hex: string) {
    switch (hex) {
      case '#1b75bb':
        return 'blue';

      case '#88c23f':
        return 'green';

      case '#e90c6e':
        return 'pink';

      case '#f8b6d3':
        return 'light-pink';

      case '#90278E':
        return 'purple';

      default:
        // Add other colors in related tasks
        return hex;
    }
  }

  private mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  private created() {
    if (this.bgColorType) {
      this.secondaryBgColor = bgColorTypes[this.bgColorType].default;
    }
  }

  private destroyed() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<docs>
Text button component (responsive)

### default

```jsx
<ActivityTextButton>Button text</ActivityTextButton>
```

### squared

```jsx
<ActivityTextButton :squared="true">Button text</ActivityTextButton>
```

### custom colors

```jsx
<ActivityTextButton
  color="white"
  bg-color="red"
>
  Button text
</ActivityTextButton>
```

### clue

```jsx
<ActivityTextButton
  :is-clue="true"
>
  Button text
</ActivityTextButton>
```

### custom resolutions sizes

### small

```jsx
<ActivityTextButton desktop-size="small">
  Button text
</ActivityTextButton>
```

### medium

```jsx
<ActivityTextButton desktop-size="medium">
  Button text
</ActivityTextButton>
```

### large

```jsx
<ActivityTextButton desktop-size="large">
  Button text
</ActivityTextButton>
```
</docs>
