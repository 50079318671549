<template>
  <article class="activity-video" data-activity-asset>
    <div class="activity-video__element">
      <AssetVideo
        :id="data[0].id"
        ref="video"
        :key="videoSrc"
        :src="isOnline ? videoSrc : findPreloadedAsset('video', videoSrc)"
        :muted="true"
        :loop="true"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
.activity-video {
  position: relative;

  max-width: $activityComponentContainerWidth;
  width: 100%;

  background-color: black;

  margin: auto;

  // 16:9 aspect ratio:
  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  &__element {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';

import AssetVideo from '@/components/Asset/Video.vue';
import VideoMixin from '@/components/Activity/mixins/Video.mixin';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    AssetVideo,
  },
})
export default class ActivityVideoLooping extends Mixins(VideoMixin) {
  @Prop({ required: true })
  private data!: any;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  get videoSrc() {
    return this.data[0].value;
  }
}
</script>

<docs>
### An activity component that displays looping video

```jsx
<ActivityVideoLooping :data="[{ value: 'https://www.w3schools.com/html/mov_bbb.mp4' }]" />
```
</docs>
