<template>
  <div
    class="speaking-message"
    :class="{
      'speaking-message': true,
      'speaking-message--left': direction === 'left',
      'speaking-message--right': direction === 'right',
      'speaking-message--outline': outline,
    }"
  >
    <img
      class="speaking-message__avatar"
      alt="User Avatar"
      title="User Avatar"
      :src="avatar"
    />
    <div class="speaking-message__text">
      {{ message }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.speaking-message {
  display: flex;
  align-items: flex-start;

  &__avatar {
    height: 45px;
    width: 45px;

    // Used for the outline:
    background-color: #e6106e;
    border-radius: 50%;

    user-select: none;
  }

  &__text {
    position: relative;
    flex-grow: 1;

    padding: 20px 15px;

    font-size: 18px;
    font-weight: $font-weight;
    text-align: center;
    color: $blue100;

    background-color: $white100;
    border-radius: 8px;
    box-shadow: 3.5px 11px 6.5px -11px rgba(0, 0, 0, 0.3);

    &::after {
      display: block;
      position: absolute;
      bottom: 0;

      border-style: solid;
      box-shadow: 3.5px 11px 6.5px -11px rgba(0, 0, 0, 0.3);

      content: '';
    }
  }

  &--right {
    .speaking-message__text {
      margin: 7px 0 0 10px;

      &::after {
        right: calc(100% - 9px);

        border-width: 27px 50px 0 0;
        border-color: transparent $white100 transparent transparent;
      }
    }
  }

  &--left {
    flex-direction: row-reverse;

    .speaking-message__text {
      margin: 7px 10px 0 0;

      &::after {
        left: calc(100% - 9px);

        border-width: 27px 0px 0 50px;
        border-color: transparent transparent transparent $white100;
      }
    }
  }

  &--outline {
    .speaking-message__avatar {
      padding: 5px;
    }

    &.speaking-message--right {
      .speaking-message__text {
        background-color: #e8e8e8;

        &::after {
          border-color: transparent #e8e8e8 transparent transparent;
        }
      }
    }

    &.speaking-message--left {
      .speaking-message__text {
        background-color: #e8e8e8;

        &::after {
          border-color: transparent transparent transparent #e8e8e8;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import Vue from 'vue';

import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ActivitiesSpeakingMessage extends Vue {
  @Prop({ default: 'left' })
  private direction!: 'left' | 'right';

  @Prop({ default: false })
  private outline!: boolean;

  @Prop({ required: true })
  private message!: string;

  @Prop({ required: true, type: String })
  private avatarId!: string;

  private get avatar() {
    return `https://cdn.littlebridge.com/ck/activities/images/${this.avatarId}.png`;
  }
}
</script>

<docs>
### Direction `left`

```jsx
<div style="padding: 40px; background: grey;">
  <ActivitiesSpeakingMessage avatarId="123" direction="left" message="Test message" />
</div>
```

### Variant `right`

```jsx
<div style="padding: 40px; background: grey;">
  <ActivitiesSpeakingMessage avatarId="123" direction="right" message="Test message" />
</div>
```
</docs>
