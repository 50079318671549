import DataProvider from '@/utils/helpers/DataProvider';
import StoreUtil from '@/utils/helpers/StoreUtil';

import { IVuexState } from '@/models/interfaces/store';
import {
  IActivity,
  IActivityLesson,
  ISolutionHistory,
} from '@/models/interfaces/activities';
import { ActionTree, MutationTree, Module } from 'vuex';
import { RootState } from '..';
import findActivityAssetsFromPayload from '@/utils/helpers/activity-assets';

export type PrefetchedActivity = {
  id: string;
  activity: IActivity;
};

export interface State {
  activity: IVuexState<IActivity>;
  prefetchedActivities: PrefetchedActivity[];
  archiveLesson: IVuexState<IActivityLesson[]>;
  currentLesson: IVuexState<IActivityLesson[]>;
  finishedOfflineActivities: string[];
}

export const actions: ActionTree<State, RootState> = {
  async fetchActivity(
    { commit },
    {
      activityId,
      language,
      shouldSetPendingState,
    }: {
      activityId: string;
      language: string;
      shouldSetPendingState: boolean;
    },
  ): Promise<IActivity> {
    if (shouldSetPendingState) {
      commit('setActivity');
    }

    try {
      const { data: result } = await DataProvider.get<IActivity>(
        `activities/${activityId}`,
        {
          params: {
            language,
          },
        },
      );
      commit('setActivity', result);
      return result;
    } catch (error) {
      commit('setActivity', error);
      // @ts-ignore
      return error;
    }
  },
  async prefetchActivities({ commit, dispatch, rootState }, ids: string[]) {
    const prefetchedData: PrefetchedActivity[] = [];
    const language = rootState.profile.languages.data?.teachingLanguage || 'en';

    try {
      for (const id of ids) {
        dispatch('fetchActivity', {
          activityId: id,
          language,
          shouldSetPendingState: false,
        }).then((activity: IActivity) => {
          prefetchedData.push({
            id,
            activity,
          });

          const activityAssets = findActivityAssetsFromPayload(activity);
          dispatch('preloadAssets/preloadAssets', activityAssets, {
            root: true,
          });
        });
      }

      commit('setPrefetchedActivities', prefetchedData);
    } catch (error) {
      return error;
    }
  },
  async fetchArchiveLesson({ commit, rootState }): Promise<IActivityLesson[]> {
    const { session } = rootState;
    const { userId } = session;

    commit('setActivityLesson');

    try {
      const { data } = await DataProvider.get<{
        activities: IActivityLesson[];
      }>(`users/${userId}/lessonArchive`);
      commit('setActivityLesson', data.activities);
      return data.activities;
    } catch (error) {
      commit('setActivityLesson', error);
      // @ts-ignore
      return error;
    }
  },
  async fetchCurrentLesson({
    commit,
    rootState,
  }): Promise<{ activities: IActivityLesson[]; unlockLesson: boolean }> {
    const { session } = rootState;
    const { userId } = session;

    commit('setCurrentLesson');

    try {
      const { data } = await DataProvider.get<{
        activities: IActivityLesson[];
        unlockLesson: boolean;
      }>(`users/${userId}/currentLesson`);
      commit('setCurrentLesson', data);
      return data;
    } catch (error) {
      commit('setCurrentLesson', error);
      // @ts-ignore
      return error;
    }
  },
  async sendSolutions({ rootState }, payload: ISolutionHistory[]): Promise<''> {
    const { session } = rootState;
    const { userId } = session;

    try {
      const result = await DataProvider.post<''>(
        `users/${userId}/solution`,
        payload,
      );
      return result.data;
    } catch (error) {
      // @ts-ignore
      return error;
    }
  },
};

export const mutations: MutationTree<State> = {
  setActivity(state, data?: IActivity | Error) {
    state.activity = StoreUtil.updateState(state.activity, data);
  },
  setPrefetchedActivities(state, data: PrefetchedActivity[]) {
    state.prefetchedActivities = data;
  },
  setActivityLesson(state, data?: IActivityLesson[] | Error) {
    state.archiveLesson = StoreUtil.updateState(state.archiveLesson, data);
  },
  setCurrentLesson(state, data?: IActivityLesson[] | Error) {
    state.currentLesson = StoreUtil.updateState(state.currentLesson, data);
  },
  setOfflineFinishedActivity(state, id: string) {
    state.finishedOfflineActivities = [...state.finishedOfflineActivities, id];
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    activity: StoreUtil.state<IActivity>(),
    archiveLesson: StoreUtil.state<IActivityLesson[]>(),
    currentLesson: StoreUtil.state<IActivityLesson[]>(),
    prefetchedActivities: [],
    finishedOfflineActivities: [],
  },
  mutations,
  actions,
};

export default store;
