module.exports = {
  // Colors:
  grey100: '#dcdcdc',
  grey200: '#d9d9d9',
  grey300: '#efefef',
  grey400: '#b1b1b1',
  darkGrey100: '#4b4b4b',
  purple100: '#90278E',
  orange100: '#f3aa45',
  orange150: '#efb937',
  orange200: '#f4aa45',
  orange300: '#f1a744',
  orange400: '#ed5b2d',
  yellow100: '#f3e800',
  yellow200: '#ffd979',
  yellow300: '#f3e8ad',
  yellow400: '#f3e80c',
  yellow500: '#f3e80f',
  white100: '#fff',
  lightBlue: '#ebfefe',
  lightBlue200: '#c8f0ff',
  black100: '#000',
  green100: '#86c040',
  green200: '#87c040',
  green300: '#d5ff00',
  green400: '#88c23f',
  blue100: '#1b75bb',
  blue200: '#26a7df',
  blue300: '#46adda',
  blue400: '#00aaeb',
  blue450: '#38b6ff',
  blue500: '#f1faf9',
  pink100: '#dd1259',
  pink200: '#e90c6e',
  pink300: '#f8b6d3',
  transparent: 'rgba(0, 0, 0, 0)',

  // shadows
  blue100Shadow: '#2e318e',
  green400Shadow: '#00a251',
  pink200Shadow: '#c5252c',
  pink300Shadow: '#e90c6e',
  yellow500Shadow: '#f8ad17',
  purple100Shadow: '#2E3191',

  // Queries:
  desktop: 1600,
  tablet: 1024,
  mobile: 765,
  tamagotchi: 365,

  // Fonts:
  'font-main': "'Source Sans Pro', sans-serif",
  'font-weight': 400,

  // Others:
  wallpaperOverlay: 'rgba(255, 255, 255, 0.75)',
  activityComponentContainerWidth: '500px',
  activityComponentBoxWidth: '650px',

  activityImageButtonOutline: 10,
  activityImageButtonGap: 5,
  activityMediaTopSpace: '30px',
  activityMediaBorderSize: '17px',

  centeredContentWidth: '586px',

  // z-indexes:
  zindex: {
    confirmationVideo: 102,
    confirmationImage: 102,
    activityProgressBar: 7,
    activityConfirmButton: 6,
    activityTrophy: 10,
    speechBubble: 101,
    popupOverlay: 10000,
    popupContent: 10001,
    appMenu: 998,
    activityHotspot: 20,
    fullscreenActivityHotspot: 1000,
    fullscreenAsset: 999,
    fullscreenButton: 21,
  },
};
