import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

import gb from '@/i18n/locales/gb';
import es from '@/i18n/locales/es';
import us from '@/i18n/locales/us';
import fr from '@/i18n/locales/fr';
import tr from '@/i18n/locales/tr';
import ru from '@/i18n/locales/ru';
import az from '@/i18n/locales/az';
import th from '@/i18n/locales/th';
import cn from '@/i18n/locales/cn';

export default new VueI18n({
  locale: 'en',
  messages: {
    gb,
    es,
    us,
    fr,
    ru,
    tr,
    az,
    th,
    cn,
  },
});
