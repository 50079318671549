import { RESOLUTIONS, RESOLUTIONS_NAME } from '@/models/constants/resolutions';

export default function getDefaultSizingClass(
  desktop = RESOLUTIONS_NAME.DESKTOP,
  tablet = RESOLUTIONS_NAME.TABLET,
  mobile = RESOLUTIONS_NAME.MOBILE,
  windowWidth?: number,
) {
  const width = windowWidth || window.innerWidth;

  if (width > RESOLUTIONS.TABLET) {
    return desktop;
  } else if (width > RESOLUTIONS.MOBILE) {
    return tablet;
  } else {
    return mobile;
  }
}
