<template>
  <article class="initial">
    <Spinner color="#f3aa45" />
  </article>
</template>

<style lang="scss" scoped>
.initial {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import amplitude from 'amplitude-js';

import HideNavigation from '@/utils/mixins/HideNavigation';
import Spinner from '@/components/Spinner/Spinner.vue';

import {
  IUserLanguageData,
  ILicence,
  IUser,
  IUserProfile,
} from '@/models/interfaces/users';
import { INotification } from '@/models/interfaces/notifications';
import { IVuexState } from '@/models/interfaces/store';
import { CommonStringObject } from '@/models/types/common';

import PopupMixin from '@/utils/mixins/Popup';
import { ISolutionHistory } from '@/models/interfaces/activities';

@Component({
  components: {
    Spinner,
  },
})
export default class Initial extends Mixins(HideNavigation, PopupMixin) {
  @State('notifications', { namespace: 'notifications' })
  public notifications!: IVuexState<INotification[]>;

  @State('user', { namespace: 'session' })
  private currentUser!: IUser;

  @Action('session/login')
  public login!: (token: string) => Promise<IUser>;

  @Action('session/logout')
  public logout!: () => Promise<void>;

  @Action('profile/fetchLanguagesData')
  private fetchLanguagesData!: () => Promise<IUserLanguageData>;

  @Action('profile/fetchUserLingos')
  private fetchUserLingos!: () => Promise<{ lingos: number }>;

  @Action('profile/fetchLicences')
  public fetchLicences!: () => Promise<ILicence[]>;

  @Action('notifications/fetchNotifications')
  private fetchNotifications!: () => Promise<INotification[]>;

  @Action('profile/fetchMyProfile')
  private fetchMyProfile!: () => Promise<IUserProfile>;

  @Action('activities/sendSolutions')
  private sendSolutions!: (payload: ISolutionHistory[]) => Promise<''>;

  @Mutation('session/setUserId')
  private setUserId!: (userId: string) => void;

  @Mutation('session/setUserAccessToken')
  private setUserAccessToken!: (accessToken: string) => void;

  get urlParams(): CommonStringObject {
    const urlParams = this.$route.query as CommonStringObject;

    const tokenFromUrl = urlParams.t;
    const tokenFromStorage = localStorage.getItem('token') as string;

    const redirect = urlParams.redirect || '/profile';

    return { t: tokenFromUrl, tokenFromStorage, redirect, ...urlParams };
  }

  private checkSavedActivityScore() {
    const savedActivityScore = localStorage.getItem('offlineSolutionHistory');

    if (savedActivityScore) {
      this.sendSolutions(JSON.parse(savedActivityScore));
      localStorage.removeItem('offlineSolutionHistory');
    }
  }

  public mounted() {
    const {
      t: tokenFromUrl,
      tokenFromStorage,

      redirect,
      ...query
    } = this.urlParams;

    const token = tokenFromUrl || tokenFromStorage;

    if (token) {
      this.login(token)
        .then(async () => {
          await Promise.all([
            this.fetchLanguagesData(),
            this.fetchUserLingos(),
            this.fetchMyProfile(),
            this.fetchLicences(),
          ]);

          this.checkSavedActivityScore();

          amplitude
            .getInstance()
            .init(
              process.env.VUE_APP_AMPLITUDE_API_KEY as string,
              this.currentUser.userId,
              {
                userId: this.currentUser.userId,
                domain: window.location.hostname,
                // eslint-disable-next-line no-console
                onError: () => console.error('Littlebridge Amplitude error'),
              },
            );

          this.$router.replace({ path: redirect, query }).then(() => {
            // NOTE: we have to fetch the notifications only after the initial
            // redirect has been completed because some notifications might
            // trigger a redirect as well and it becomes unpredictable:
            this.fetchNotifications();
            setInterval(() => this.fetchNotifications(), 30 * 1000);

            if (tokenFromUrl) {
              this.showPopup({
                message: this.$t('popup.login'),
              });
            }
          });
        })
        .catch(() => {
          this.logout();
        });
    } else {
      this.logout();
    }
  }
}
</script>
