var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{class:{
    background: true,
    'background--is-fixed': _vm.shouldBeFixed,
    'background--full-height': _vm.fullHeight,
  },style:({
    backgroundColor: _vm.color,
    backgroundImage: `url(${_vm.image})`,
  })},[(_vm.$slots.header)?_c('AppSupheader',{attrs:{"id":"app-background-header"}},[_vm._t("header")],2):_vm._e(),_c('main',{staticClass:"background__content",attrs:{"id":"app-background-main"}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }