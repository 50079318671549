import { Component, Prop, Vue } from 'vue-property-decorator';
import getDefaultSizingClass from '@/utils/helpers/getDefaultSizingClass';
import { RESOLUTIONS_NAME } from '@/models/constants/resolutions';

@Component
export default class Counter extends Vue {
  @Prop({ default: 0 })
  public count!: number | string;

  @Prop({ default: false, type: Boolean })
  public chip!: boolean;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: RESOLUTIONS_NAME.MOBILE })
  public mobileSize!: string;

  @Prop({ default: RESOLUTIONS_NAME.TABLET })
  public tabletSize!: string;

  @Prop({ default: RESOLUTIONS_NAME.DESKTOP })
  public desktopSize!: string;

  public sizingClass = getDefaultSizingClass(
    this.desktopSize,
    this.tabletSize,
    this.mobileSize,
  );

  get getComputedClassName() {
    const isDisabled = this.disabled ? 'counter--disabled' : '';
    const chipClass = !this.chip ? 'counter--unchip' : '';

    return `counter ${isDisabled} ${this.sizingClass} ${chipClass}`;
  }

  private mounted() {
    window.addEventListener('resize', this.onResize);
  }

  private destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  private onResize() {
    this.sizingClass = getDefaultSizingClass(
      this.desktopSize,
      this.tabletSize,
      this.mobileSize,
    );
  }
}
