import { IVuexState } from '@/models/interfaces/store';
import { AxiosError } from 'axios';

export class StoreUtil {
  public state<T>(): IVuexState<T> {
    return {
      data: null,
      status: {
        pending: false,
        success: false,
        fail: false,
        error: null,
      },
    };
  }

  public updateState<T>(
    state: IVuexState<T>,
    data?: T | Error | null | AxiosError,
  ) {
    if (data === null || data === undefined) {
      return this.mutationPending({ ...state });
    }

    return data instanceof Error
      ? this.mutationFail({ ...state }, data)
      : this.mutationSuccess({ ...state }, data);
  }

  private mutationPending<T>(state: IVuexState<T>) {
    return {
      data: state.data,
      status: {
        pending: true,
        success: false,
        fail: false,
        error: null,
      },
    };
  }

  private mutationSuccess<T>(state: IVuexState<T>, data?: T) {
    return {
      data: data,
      status: {
        pending: false,
        success: true,
        fail: false,
        error: null,
      },
    };
  }

  private mutationFail<T>(state: IVuexState<T>, data: Error) {
    return {
      data: null,
      status: {
        pending: false,
        success: false,
        fail: true,
        error: data,
      },
    };
  }
}

export default new StoreUtil();
