<template>
  <div class="activity-image-buttons">
    <ActivityImageButton
      v-for="image in randomisedData"
      :key="image.id"
      :src="isOnline ? image.value : findPreloadedAsset('image', image.value)"
      :bg-color="selectedButtons.includes(image.id) ? '#87c040' : 'transparent'"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
      @click.native="handleClick(image)"
    />
  </div>
</template>

<style lang="scss" scoped>
.activity-image-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: #{$mobile}px;
  margin: 0 auto;
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import theme from '@/styles/theme';

import EventBus from '@/utils/helpers/EventBus';
import Breakpoints from '@/utils/mixins/Breakpoints';

import ActivityButtons from '../mixins/ActivityButtons';
import ActivityImageButton from '@/components/ActivityImageButton/ActivityImageButton.vue';

import randomise from '@/utils/helpers/randomise';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    ActivityImageButton,
  },
})
export default class AdjustableImageButtons extends Mixins(
  ActivityButtons,
  Breakpoints,
) {
  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private get randomisedData() {
    return randomise(this.data);
  }

  public getColumns() {
    // [breakpoint]: {
    //   0: number of columns for 1,4 elements to render;
    //   1: number of columns for 5-7 elements to render;
    //   2: number of columns for 8+ elements to render;
    // }
    const columns = {
      desktop: [2, 3, 4],
      tablet: [2, 3, 4],
      mobile: [2, 3, 3],
    }[this.breakpoint];

    if (this.data.length < 5) {
      return columns[0];
    } else if (this.data.length < 8) {
      return columns[1];
    } else {
      return columns[2];
    }
  }

  public get size() {
    const container = Math.min(this.windowWidth, theme.mobile);
    const columns = this.getColumns();
    const spacing =
      theme.activityImageButtonOutline + theme.activityImageButtonGap;

    return container / columns - spacing * columns;
  }

  public mounted() {
    EventBus.$on('activity-event-reset-solutions', () => {
      this.selectedButtons = [];
    });
  }

  public destroyed() {
    EventBus.$off('activity-event-reset-solutions');
  }
}
</script>

<docs>
### A flexible wrapper that renders image buttons

```jsx
<AdjustableImageButtons
  :meta="{}"
  :data="[
    {
      id: '1',
      label: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      index: 1,
    },

    {
      id: '2',
      label: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      index: 2,
    },

    {
      id: '3',
      label: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      index: 3,
    },

    {
      id: '4',
      label: '',
      value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg',
      index: 4,
    }
  ]"
  :solution="[]"
  type="adjustableImageButtons"
/>
```
</docs>
