<template>
  <div class="activity-container">
    <!-- Other sentences -->
    <section :class="['activity-section', `activity-section--${direction}`]">
      <ActivityTextButton
        v-for="sentence in otherSentences"
        :key="sentence.id"
        :bg-color="getColor(sentence)"
        @click.native="handleSentenceClick(sentence)"
      >
        {{ sentence.value }}
      </ActivityTextButton>
    </section>

    <!-- Correctly selected sentences -->
    <section
      :class="[
        'activity-section',
        `activity-section--${oppositeDirection}`,
        'activity-section--valid',
      ]"
    >
      <ActivityTextButton
        v-for="sentence in validSentences"
        :key="sentence.id"
        :bg-color="getColor(sentence)"
        size="medium"
        :disabled="true"
      >
        {{ sentence.value }}
      </ActivityTextButton>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.activity-container {
  display: inline-block;

  width: 50%;

  margin-top: 25px;
}

.activity-text-btn {
  margin-bottom: 10px;
}

.activity-section {
  display: flex;
  flex-direction: column;

  &--left {
    align-items: flex-end;
    padding-right: 35px;

    ::v-deep {
      .activity-text-btn {
        margin-left: 50%;

        @media (max-width: 1450px) {
          margin-left: 30%;
        }

        @media (max-width: #{$tablet}px) {
          margin-left: 0;
        }
      }
    }
  }

  &--right {
    padding-left: 35px;
    align-items: flex-start;

    ::v-deep {
      .activity-text-btn {
        margin-right: 50%;

        @media (max-width: 1450px) {
          margin-right: 30%;
        }

        @media (max-width: #{$tablet}px) {
          margin-right: 0;
        }
      }
    }
  }

  &--valid {
    ::v-deep {
      .activity-text-btn {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &.activity-section--right {
      align-items: flex-end;
      padding-right: 5px;
    }

    &.activity-section--left {
      align-items: flex-start;
      padding-left: 5px;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  IActivityComponent,
  IActivitySolution,
  IActivityTriggers,
} from '@/models/interfaces/activities';

import EventBus from '@/utils/helpers/EventBus';
import Sound from '@/utils/helpers/Sound';
import theme from '@/styles/theme';

import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';

type ComponentType = IActivityComponent<'verticalTextButtonsWithAudio'>['type'];
type ComponentMeta = IActivityComponent<'verticalTextButtonsWithAudio'>['meta'];
type ComponentData = IActivityComponent<'verticalTextButtonsWithAudio'>['data'];

@Component({
  components: {
    ActivityTextButton,
  },
})
export default class ActivityVerticalTextButtonsWithAudio extends Vue {
  @Prop({ required: true })
  protected type!: ComponentType;

  @Prop({ required: true })
  protected meta!: ComponentMeta;

  @Prop({ required: true })
  protected data!: ComponentData;

  @Prop({ required: true })
  protected solution!: IActivitySolution[];

  @Prop({ required: true })
  protected triggers!: IActivityTriggers;

  public validSentences: ComponentData = [];
  public otherSentences: ComponentData = this.data.sort(
    () => Math.random() - 0.5,
  );

  public selectedSentence: ComponentData[number] | null = null;
  public playingSound!: Sound;

  public get direction() {
    return this.meta.order === 1 ? 'left' : 'right';
  }

  public get oppositeDirection() {
    return this.direction === 'left' ? 'right' : 'left';
  }

  public getColor(sentence: ComponentData[number]) {
    if (this.selectedSentence?.id === sentence.id) {
      return this.direction === 'left' ? theme.green100 : theme.green100;
    } else {
      return this.direction === 'left' ? theme.blue100 : theme.purple100;
    }
  }

  public handleSentenceClick(sentence: ComponentData[number]) {
    this.selectedSentence = sentence;

    if (this.playingSound) {
      this.playingSound.stop();
    }

    this.playingSound = new Sound({
      src: [sentence.label],
      autoplay: true,
      html5: true,
    });

    EventBus.$emit(
      `activity-event-on-${this.direction}-sentence-click`,
      sentence,
    );
  }

  public handleClearingSelectedSentences() {
    this.selectedSentence = null;
  }

  public handleAddingValidSentence(sentence: ComponentData[number]) {
    this.selectedSentence = null;

    this.validSentences = [...this.validSentences, sentence];
    this.otherSentences = this.otherSentences.filter(s => s.id !== sentence.id);
  }

  public handleReceivedSentence(sentence: ComponentData[number]) {
    if (!this.selectedSentence) {
      return;
    }

    if (this.selectedSentence.index === sentence.index) {
      EventBus.$emit('activity-event-solution-add', {
        type: 'verticalTextButtonsWithAudio',
        data: { ...sentence },
      });

      EventBus.$emit('activity-event-solution-add', {
        type: 'verticalTextButtonsWithAudio',
        data: { ...this.selectedSentence },
      });

      EventBus.$emit(
        `activity-event-add-valid-sentence-${this.direction}`,
        this.selectedSentence,
      );

      EventBus.$emit(
        `activity-event-add-valid-sentence-${this.oppositeDirection}`,
        sentence,
      );
    } else {
      window.setTimeout(() => {
        EventBus.$emit('activity-event-clear-selected-sentences');

        if (
          this.triggers.incorrect &&
          this.triggers.incorrect.type === 'audio'
        ) {
          new Sound({
            src: [this.triggers.incorrect.data.value],
            autoplay: true,
            html5: true,
          });
        }
      }, 2000);
    }
  }

  public mounted() {
    EventBus.$on(
      `activity-event-on-${this.oppositeDirection}-sentence-click`,
      this.handleReceivedSentence,
    );

    EventBus.$on(
      'activity-event-clear-selected-sentences',
      this.handleClearingSelectedSentences,
    );

    EventBus.$on(
      `activity-event-add-valid-sentence-${this.direction}`,
      this.handleAddingValidSentence,
    );
  }

  public destroyed() {
    EventBus.$off(`activity-event-on-${this.oppositeDirection}-sentence-click`);
    EventBus.$off('activity-event-clear-selected-sentences');
    EventBus.$off(`activity-event-add-valid-sentence-${this.direction}`);
  }
}
</script>
