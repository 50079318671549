import { IColorSettings } from '@/models/interfaces/color-settings';

class VisualSettings {
  public getCommonDefaultSettings(): IColorSettings {
    return {
      color: '#ffcff8',
      images: [],
    };
  }

  public getDefaultInterestsSettings(): IColorSettings {
    return {
      color: 'linear-gradient(to top, #00ffff, #00ff00)',
      images: [
        'https://cdn.littlebridge.com/ck/img/common/wallpapers/interest_29.png',
        'https://cdn.littlebridge.com/ck/img/common/wallpapers/interest_17.png',
        'https://cdn.littlebridge.com/ck/img/common/wallpapers/interest_5.png',
      ],
    };
  }

  public getDefaultAchievementsSettings(): IColorSettings {
    return {
      color: 'linear-gradient(to top, #00ffff, #00ff00)',
    };
  }

  public getTilesSettingsFromStorage(
    defaultSettings: IColorSettings,
    localStorageSettingsName: string,
  ): IColorSettings {
    let settings = defaultSettings;

    try {
      settings =
        JSON.parse(localStorage.getItem(localStorageSettingsName) as string) ||
        defaultSettings;
    } catch (e) {
      return settings;
    }

    return settings;
  }
}

export default new VisualSettings();
