<template>
  <Draggable
    class="draggable"
    group="dragAndDropActivity"
    animation="150"
    :list="draggableData"
    :style="itemStyles"
    @change="handleUpdate"
  >
    <Hotspot
      class="hotspot"
      :data="data"
      :disable-positioning="true"
      :bg-color="metaStyles.backgroundColor"
    />
  </Draggable>
</template>

<style lang="scss">
.draggable {
  .sortable-ghost {
    width: 100%;
    max-height: 50px;
  }
}
</style>

<style lang="scss" scoped>
.draggable {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';

import PositionedComponents from '../mixins/PositionedComponents.mixin';
import HotstpotStyles from '../mixins/HotspotStyles.mixin';

import Draggable, { ChangeEvent } from 'vuedraggable';
import Hotspot from './Hotspot.vue';

import {
  IActivitySolution,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';
import sounds from '@/utils/helpers/sounds';

@Component({
  components: {
    Draggable,
    Hotspot,
  },
})
export default class ActivityHotspot extends Mixins(
  PositionedComponents,
  HotstpotStyles,
) {
  private draggableData: IActivitySolution[] = [];

  private handleUpdate(
    dragData: ChangeEvent<
      Omit<IActivitySolution & { indexFromNativeList: number }, 'meta'>
    >,
  ) {
    const { element } = dragData.added;

    const addedItemData = element.data as IActivityComponentDataElement;

    if (addedItemData.index !== this.data.index) {
      sounds.activityNegative.play();

      EventBus.$emit('activity-event-update-draggable-list-by-index', {
        data: element,
        index: element.indexFromNativeList,
      });

      this.draggableData = this.draggableData.filter(
        ({ data }) =>
          (data as IActivityComponentDataElement).id !== addedItemData.id,
      );
    } else {
      sounds.activityPositive.play();

      EventBus.$emit('activity-event-update-draggable-list-by-index', null);

      EventBus.$emit('activity-event-solution-add', {
        type: dragData.added.element.type,
        data: addedItemData,
      });
    }
  }

  private reset() {
    this.draggableData = [];
  }

  public mounted() {
    EventBus.$on('activity-event-reset-draggable-data', this.reset);
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-reset-draggable-data', this.reset);
  }
}
</script>
