<template>
  <AppPage class="training-menu" hide-background>
    <AppSupheader>
      <h3>
        {{ pageTitle }}
      </h3>
    </AppSupheader>

    <article class="menu">
      <div class="menu__content">
        <ActivityItem
          v-for="(activity, index) in currentActivitiesSet"
          :key="activity.activityId"
          :index="index + 1"
          :progress="getActivityProgress(activity)"
          :disabled="isLocked(activity, index)"
          :show-arrow="!!currentActivitiesSet[index + 1]"
          :type="selectedArchiveLessonTitle ? 'danger' : 'primary'"
          :thumbnail="
            isOnline
              ? activity.thumbnail
              : findPreloadedAsset('image', activity.thumbnail)
          "
          @select="openActivity(activity.activityId)"
        />
      </div>
    </article>

    <img class="training-menu__domino" src="/images/training-menu/domino.png" />

    <SlidingDrawer
      v-model="isContentDrawerOpened"
      :accent-color="selectedArchiveLessonTitle ? 'pink' : 'green'"
    >
      <BaseButton squared @click.native="selectedArchiveLessonTitle = ''">
        {{ currentLessonTitle }}
      </BaseButton>

      <BaseSpinner v-if="isPending(archiveActivities)" />

      <VerticalSlider
        v-else-if="hasSucceded(archiveActivities)"
        item-key="id"
        :items="archiveLessons"
        :items-per-view="itemsPerView"
      >
        <template #item="{ item }">
          <ArchiveLessonItem
            :title="item.title"
            :is-active="selectedArchiveLessonTitle === item.title"
            :progress="getArchiveLessonProgress(item)"
            @click.native="selectedArchiveLessonTitle = item.title"
          />
        </template>
      </VerticalSlider>
    </SlidingDrawer>
  </AppPage>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';

import AppPage from '@/components/AppPage/AppPage.vue';
import AppSupheader from '@/components/AppSupheader/AppSupheader.vue';
import BaseButton from '@/components/BaseButton/BaseButton.vue';
import BaseSpinner from '@/components/Spinner/Spinner.vue';

import SlidingDrawer from './SlidingDrawer.vue';
import ActivityItem from './ActivityItem.vue';
import VerticalSlider from './VerticalSlider.vue';
import ArchiveLessonItem from './ArchiveLessonItem.vue';

import { IActivity, IActivityLesson } from '@/models/interfaces/activities';
import { IVuexState } from '@/models/interfaces/store';
import { IUserLanguageData } from '@/models/interfaces/users';

import {
  PreloadedAsset,
  PreloadedAssetType,
} from '@/store/modules/preload-assets';
import { requireFromCache } from '@/utils/helpers/assets';
import RequestState from '@/utils/mixins/RequestState';
import routeNames from '@/router/route-names';

type ArchiveLesson = {
  title: string;
  activities: IActivityLesson[];
};

@Component({
  components: {
    AppPage,
    AppSupheader,
    BaseButton,
    BaseSpinner,

    SlidingDrawer,
    ActivityItem,
    VerticalSlider,
    ArchiveLessonItem,
  },
})
export default class TrainingMenu extends Mixins(RequestState) {
  @State('finishedOfflineActivities', { namespace: 'activities' })
  public finishedOfflineActivities!: string[];

  @State('archiveLesson', { namespace: 'activities' })
  public archiveActivities!: IVuexState<IActivityLesson[]>;

  @State('preloadedImages', { namespace: 'preloadAssets' })
  public preloadedImages!: PreloadedAsset[];

  @State('currentLesson', { namespace: 'activities' })
  public currentLesson!: IVuexState<{
    activities: IActivityLesson[];
    unlockLesson: boolean;
  }>;

  @State('languages', { namespace: 'profile' })
  public userLanguages!: IVuexState<IUserLanguageData>;

  @Getter('preloadAssets/findPreloadedAsset')
  public findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  @Action('activities/fetchArchiveLesson')
  public fetchArchiveActivities!: () => Promise<IActivityLesson[]>;

  @Action('activities/fetchCurrentLesson')
  public fetchCurrentLesson!: () => Promise<IActivityLesson[]>;

  @Action('activities/prefetchActivities')
  public prefetchActivities!: (ids: string[]) => Promise<IActivity[]>;

  @Action('preloadAssets/preloadAssets')
  public preloadAssets!: (
    assetsUrls: (string | { mockUrl: string; src: NodeRequire })[],
  ) => void;

  public requireFromCache = requireFromCache;

  public lastLockedIndex!: number;
  public selectedArchiveLessonTitle = '';
  public isContentDrawerOpened = false;

  public get itemsPerView() {
    if (this.windowHeight < 550) {
      return 1;
    }

    if (this.windowHeight < 650) {
      return 2;
    }

    if (this.windowHeight < 750) {
      return 3;
    }

    return 4;
  }

  public get archiveLessons() {
    return this.archiveActivities.data?.reduce<ArchiveLesson[]>(
      (lessons, activity) => {
        const addedLesson = lessons.find(item => item.title === activity.title);

        if (addedLesson) {
          return lessons.map(item =>
            item.title === addedLesson.title
              ? { ...item, activities: [...item.activities, activity] }
              : item,
          );
        }

        return [
          ...lessons,
          {
            title: activity.title,
            activities: [activity],
          },
        ];
      },
      [],
    );
  }

  public get pageTitle() {
    return this.selectedArchiveLessonTitle || this.currentLessonTitle;
  }

  public get currentLessonTitle() {
    return this.hasSucceded(this.currentLesson)
      ? this.currentLesson.data!.activities[0].title ||
          this.$t('activity.currentLesson')
      : '';
  }

  public get currentActivitiesSet() {
    if (this.selectedArchiveLessonTitle) {
      return (
        this.archiveLessons?.find(
          item => item.title === this.selectedArchiveLessonTitle,
        )?.activities ?? []
      );
    }

    return this.currentLesson.data?.activities ?? [];
  }

  public isSuccessCompleted(lesson: IActivityLesson) {
    return lesson.userScore / lesson.totalScore >= 0.5;
  }

  public getArchiveLessonProgress(archiveLesson: ArchiveLesson) {
    const allActivitiesCompleted = archiveLesson.activities.every(
      this.isSuccessCompleted,
    );

    return allActivitiesCompleted ? 100 : 0;
  }

  public isLocked(activity: IActivityLesson, idx: number) {
    if (
      !this.isOnline &&
      this.finishedOfflineActivities.includes(activity.activityId)
    ) {
      return false;
    }

    if (this.selectedArchiveLessonTitle) {
      // if archived activity
      return false;
    }
    if (activity.locked) {
      return true;
    }
    if (this.currentLesson.data!.unlockLesson) {
      // if unlock whole lesson
      return false;
    }

    const previousActivity = this.currentActivitiesSet[idx - 1];

    if (activity.test && typeof activity.userScore === 'number') {
      return true;
    } else if (idx === 0) {
      // if lesson is the first
      // because 1st lesson is always active/animated
      return false;
    } else if (activity.test && idx > this.lastLockedIndex) {
      return true;
    } else {
      const isPrevActivityActive = this.isActivityActive(
        previousActivity,
        idx - 1,
      );

      if (activity.test && isPrevActivityActive) {
        this.lastLockedIndex = idx;
        return true;
      }

      return false;
    }
  }

  public openActivity(activityId: string) {
    this.$router.push({
      name: routeNames.activityBuilder,
      params: {
        activityId,
      },
    });
  }

  public isActivityActive(lesson: IActivityLesson, idx: number) {
    const previousLesson = this.currentLesson.data!.activities[idx - 1];

    if (idx === 0) {
      // if lesson is the first one
      // and it's score is 0
      return lesson.userScore === 0;
    } else if (
      this.isSuccessCompleted(previousLesson) &&
      !this.isSuccessCompleted(lesson)
    ) {
      // if previous lesson achieved more than 50% score
      // and current lesson is zero-scored
      return true;
    } else {
      return false;
    }
  }

  public getActivityProgress(activity: IActivityLesson) {
    const value = activity.userScore / activity.totalScore;
    return value * 100;
  }

  private prefetchBasicActivityScreenAssets() {
    const { data: langsData } = this.userLanguages;

    const topbarImages = [
      langsData!.teachingLanguageFlag,
      langsData!.supportLanguageFlag,
      '/images/icons/pause.png',
      '/images/icons/snail.png',
      '/images/icons/audio.png',
      '/images/icons/exit.png',
    ];

    this.preloadAssets(topbarImages);
  }

  private prefetchTrainingMenuAssets() {
    this.preloadAssets([
      '/images/activities/page-background.png',
      '/images/activities/lingo.png',
      '/images/activities/person-1.png',
      '/images/activities/person-2.png',
      '/images/training-menu/star-full.png',
      '/images/training-menu/star-empty.png',
      '/images/training-menu/star-half.png',
      '/images/training-menu/play.png',
      '/images/training-menu/slider-arrow-up.png',
      '/images/training-menu/slider-arrow-down.png',
      '/images/training-menu/domino.png',
      '/images/training-menu/rounded-modal-side.png',
    ]);
  }

  public mounted() {
    if (this.isOnline) {
      this.fetchArchiveActivities();

      this.fetchCurrentLesson().then(() => {
        // why first four? Most lessons contain 4 activities,
        // that is why the app has to preload 4.

        // prefetching activities and store them in VUEX for offline access

        const firstFourActivities = (this.currentLesson.data?.activities || [])
          .slice(0, 4)
          .map(activity => activity.activityId);

        this.prefetchActivities(firstFourActivities);

        // prefetch the thumbnails
        this.preloadAssets(
          (this.currentLesson.data?.activities || []).map(
            item => item.thumbnail,
          ),
        );

        this.prefetchBasicActivityScreenAssets();
        this.prefetchTrainingMenuAssets();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.app-page {
  @include d-flex-col;
}

.menu {
  flex-grow: 1;

  background: url('/images/activities/page-background.png') no-repeat;
  background-size: cover;

  @include d-flex-col;

  &__content {
    flex-grow: 1;
    height: 0;
    overflow: auto;

    @include d-flex-col;

    max-width: $centeredContentWidth;
    width: 100%;

    margin: auto;
    padding: 60px 21px 60px 26px;

    background: $wallpaperOverlay;

    @media (max-width: #{$mobile}px) {
      max-width: 320px;
      background: transparent;

      padding: 45px 30px 45px;
    }
  }
}

.activity-item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: #{$mobile}px) {
    margin-bottom: 15px;
  }
}

.base-button {
  font-size: 25px;
  padding: 30px 25px;
  margin: 0 40px;

  @media (max-width: #{$mobile}px) {
    font-size: 18px;
    padding: 25px 15px;
  }
}

.v-slider {
  margin: 12px 0 0 40px;
}

.archive-lesson {
  width: calc(100% - 40px);
}

.training-menu {
  position: relative;

  &__domino {
    position: absolute;

    bottom: 0;
    right: 60px;

    @media (max-width: #{$tablet}px) {
      display: none;
    }
  }
}
</style>
