import { render, staticRenderFns } from "./SlidingDrawer.vue?vue&type=template&id=6b5e64e0&scoped=true"
import script from "./SlidingDrawer.vue?vue&type=script&lang=ts"
export * from "./SlidingDrawer.vue?vue&type=script&lang=ts"
import style0 from "./SlidingDrawer.vue?vue&type=style&index=0&id=6b5e64e0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5e64e0",
  null
  
)

export default component.exports