import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export class DataProvider {
  private http!: AxiosInstance;

  constructor(private apiUrl = process.env.VUE_APP_API_BASE_URL as string) {
    this.http = axios.create({
      baseURL: this.apiUrl,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  public get<T>(url: string, params: AxiosRequestConfig = {}) {
    return this.http.get<T>(`${this.apiUrl}${url}`, params);
  }

  public post<T>(url: string, payload: any) {
    return this.http.post<T>(`${this.apiUrl}${url}`, payload);
  }

  public put<T>(url: string, payload: any) {
    return this.http.put<T>(`${this.apiUrl}${url}`, payload);
  }

  public setDefaultHeader(name: string, value: string) {
    this.http.defaults.headers.common[name] = value;
  }
}

export default new DataProvider();
