import ModelNormalized from './ModelNormalized';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'; //'../loaders/GLTFLoader';
import { Object3D } from 'three';
import { Product } from '@/models/interfaces/products';

export type HumanModelParts =
  | 'body'
  | 'head'
  | 'eyelids'
  | 'eyes'
  | 'hair'
  | 'glasses'
  | 'top'
  | 'bottom'
  | 'socks'
  | 'accessories'
  | 'badge'
  | 'topTexture'
  | 'hat'
  | 'shoes';

interface HumanModelPartConfig {
  model: string;
  styles: {
    colour: string;
  };
}

export interface HumanModelConfig {
  body: HumanModelPartConfig;
  head: HumanModelPartConfig;
  eyelids: HumanModelPartConfig;
  eyes: HumanModelPartConfig;
  hair: HumanModelPartConfig;
  glasses: HumanModelPartConfig;
  top: HumanModelPartConfig;
  bottom: HumanModelPartConfig;
  shoes: HumanModelPartConfig;
  socks: HumanModelPartConfig;
  hat: HumanModelPartConfig;
  topTexture: HumanModelPartConfig;
  badge: HumanModelPartConfig;
  accessories: HumanModelPartConfig;
}

export default interface HumanModel {
  // Config:
  gltf: GLTF;
  config: ModelNormalized<HumanModelParts>;

  // Getters:
  readonly body: Object3D;
  readonly head: Object3D;
  readonly eyelids: Object3D;
  readonly eyes: Object3D;
  readonly hair: Object3D;
  readonly glasses: Object3D;
  readonly top: Object3D;
  readonly bottom: Object3D;
  readonly shoes: Object3D;
  readonly socks: Object3D;
  readonly accessories: Object3D;
  readonly topTexture: Object3D;
  readonly badge: Object3D;
  readonly hat: Object3D;

  // Setters:
  bodyColor: any;
  headColor: any;
  eyelidsColor: any;
  eyesColor: any;
  hairColor: any;
  glassesColor: any;
  topColor: any;
  bottomColor: any;
  shoesColor: any;
  accessoriesColor: any;
  socksColor: any;
  hatColor: any;
}

export enum ModelParts {
  Preview = 'characterPreview',
  AvatarBody = 'avatarBody',
  AvatarHead = 'avatarHead',
  AvatarGlasses = 'avatarGlasses',
  AvatarEyes = 'avatarEyes',
  AvatarHair = 'avatarHair',
  AvatarBadge = 'avatarBadge',
  AvatarHat = 'avatarHat',
  AvatarTop = 'avatarTop',
  AvatarBottom = 'avatarBottom',
  AvatarShoes = 'avatarShoes',
  AvatarSocks = 'avatarSocks',
  AvatarTopTexture = 'avatarTopTexture',
  AvatarAccessories = 'avatarAccessories',
  DominoHair = 'dominoHair',
  DominoHead = 'dominoHead',
  DominoEyes = 'dominoEyes',
  DominoCollar = 'dominoCollar',
  DominoTop = 'dominoTop',
}

export enum HumanModelLayers {
  Preview = 'Preview',
  AvatarBody = 'body',
  AvatarHead = 'head',
  AvatarGlasses = 'glasses',
  AvatarEyes = 'eyes',
  AvatarHair = 'hair',
  AvatarBadge = 'badge',
  AvatarHat = 'hat',
  AvatarTop = 'top',
  AvatarTopTexture = 'topTexture',
  AvatarBottom = 'bottom',
  AvatarShoes = 'shoes',
  AvatarSocks = 'socks',
  AvatarAccessories = 'accessories',
  DominoHead = 'head',
  DominoEyes = 'eyes',
  DominoCollar = 'collar',
  DominoTop = 'top',
}

export const complexPartsChildren: Partial<Record<Product, Product>> = {
  [ModelParts.AvatarHat]: ModelParts.AvatarBadge,
  [ModelParts.AvatarTop]: ModelParts.AvatarTopTexture,
};
