import { Module, ActionTree, MutationTree } from 'vuex';
import { AxiosResponse, AxiosError } from 'axios';

import DataProvider from '@/utils/helpers/DataProvider';

import { RootState } from '..';
import { IUser } from '@/models/interfaces/users';

export interface State {
  user?: IUser;
  userId?: string;
  userAccessToken?: string;
}

export const actions: ActionTree<State, RootState> = {
  async login({ commit }, token: string) {
    const bearerToken = `Bearer ${token}`;

    DataProvider.setDefaultHeader('Authorization', bearerToken);

    return DataProvider.get('users/current')
      .then((response: AxiosResponse) => {
        localStorage.setItem('token', token as string);

        commit('setUser', response.data);
        commit('setUserId', response.data.userId);
        commit('setUserAccessToken', bearerToken);

        return response.data;
      })
      .catch((error: AxiosError) => {
        return error;
      });
  },
  async logout({ state, commit }) {
    const defaultLogout = (
      url = process.env.VUE_APP_LOGOUT_REDIRECT_URL as string,
    ) => {
      localStorage.removeItem('token');
      DataProvider.setDefaultHeader('Authorization', '');

      commit('setUser', undefined);
      commit('setUserId', undefined);
      commit('setUserAccessToken', undefined);

      window.location.href = url;
    };

    const isTestEnv = process.env.NODE_ENV === 'test';

    if (state.userAccessToken === process.env.VUE_APP_TEST_TOKEN || isTestEnv) {
      defaultLogout();
      return;
    }

    try {
      const {
        // @ts-ignore
        data: { logoutUrl },
      } = await DataProvider.get('logout');

      defaultLogout(logoutUrl);
    } catch (error) {
      defaultLogout();
    }
  },
};

export const mutations: MutationTree<State> = {
  setUser(state, data: IUser) {
    state.user = data;
  },
  setUserId(state, userId: string) {
    state.userId = userId;
  },
  setUserAccessToken(state, accessToken: string) {
    state.userAccessToken = accessToken;
  },
};

export const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    user: undefined,
    userId: undefined,
    userAccessToken: undefined,
  },
  mutations,
  actions,
};

export default store;
