<template>
  <div class="text-container">
    <slot v-if="$slots.default" />

    <template v-else>
      {{ data ? data[0].label : '' }}
    </template>
  </div>
</template>

<style lang="scss" scoped>
.text-container {
  background-color: $white100;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: $blue100;
  text-align: center;

  max-width: $activityComponentBoxWidth;
  min-height: 50px;

  margin: auto;
  padding: 15px;
}
</style>

<script lang="ts">
import { IActivityComponentData } from '@/models/interfaces/activities';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActivityTextContainer extends Vue {
  @Prop({ default: null })
  private data!: IActivityComponentData;
}
</script>

<docs>
A text/content wrapper component for activities


### Varian with a simple text

```jsx
<ActivityTextContainer>
  Simple text
</ActivityTextContainer>
```

### Varian with random content

```jsx
<ActivityTextContainer>
  <img
    src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"
    alt="Test Text Container Image"
    title="Test Text Container Image"
    height="100"
  />
</ActivityTextContainer>
```
</docs>
