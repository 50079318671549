<template>
  <div
    ref="container"
    :style="{
      // We cannot use 100vh/100vw due to mobile browsers (iOS/Android bug):
      width: isFullscreen ? `${windowWidth}px` : '100%',
      height: isFullscreen ? `${windowHeight}px` : '100%',
    }"
    :class="{
      image: true,
      'image--fullscreen': isFullscreen,
    }"
  >
    <img
      ref="image"
      class="image__asset"
      :src="src"
      :draggable="false"
      @load="$emit('loaded')"
    />

    <slot />

    <div v-if="controls" class="image__buttons">
      <button class="image__fullscreen" @click="fullscreen(!isFullscreen)">
        <i v-if="!isFullscreen" class="fas fa-expand"></i>
        <i v-else class="fas fa-compress"></i>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  user-select: none;

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;

    background-color: black;

    z-index: map-get($zindex, fullscreenAsset);
  }
}

.image__asset {
  object-fit: contain;

  height: 100%;
  width: 100%;
}

.image__buttons {
  position: absolute;
  bottom: 0;

  height: 40px;
  width: 100%;

  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
}

.image__fullscreen {
  position: absolute;
  bottom: 0;
  right: 0;

  padding: 15px;

  color: $white100;
  text-shadow: 0 0 2px black;

  z-index: map-get($zindex, fullscreenButton);
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';
import getObjectFitSize from '@/utils/helpers/getObjectFitSize';
import { fsHash } from './constants';

@Component
export default class AssetImage extends Vue {
  @Prop({ required: true })
  public src!: string;

  @Prop({ default: true })
  public controls!: boolean;

  private hotspotsTimeout = 0;
  private resizeTimeout = 0;

  public $refs!: {
    image: HTMLImageElement;
    container: HTMLDivElement;
  };

  public isFullscreen = false;

  public fullscreen(isFullscreen: boolean) {
    this.$emit('on-fullscreen-toggle', isFullscreen);

    this.isFullscreen = isFullscreen;

    this.hotspotsTimeout = window.setTimeout(() => {
      // wait until carousel will change structure
      this.$nextTick(() => this.positionHotspots());
    }, 500);

    EventBus.$emit('activity-event-video-fullscreen', isFullscreen);
  }

  public positionHotspots() {
    const { image, container } = this.$refs;
    if (!image || !container) {
      return;
    }
    const { left: extraLeft } = container.getBoundingClientRect();

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const assetSize = getObjectFitSize(
      containerWidth,
      containerHeight,
      image.naturalWidth,
      image.naturalHeight,
    );

    const assetTop = (containerHeight - assetSize.height) / 2;
    const assetLeft = (containerWidth - assetSize.width) / 2;

    const positioningData = {
      ...assetSize,
      height: assetSize.height,
      top: assetTop,
      left: assetLeft + extraLeft,
      transform: null,
      position: this.isFullscreen ? 'fixed' : 'absolute',
    };

    EventBus.$emit('set-assets-hotspots-container-positioning', {
      ...positioningData,
      controls: this.controls,
    });
  }

  get getComputedFullscreenStyles() {
    return {
      width: this.isFullscreen ? `${this.windowWidth}px` : 'auto',
      height: this.isFullscreen ? `${this.windowHeight}px` : 'auto',
    };
  }

  private onHashChange(e: Event) {
    const event = e as HashChangeEvent;

    if (event.oldURL.includes(fsHash)) {
      this.fullscreen(false);
    } else {
      if (process.env.NODE_ENV !== 'styleguide') {
        this.$router.back();
      }
    }
  }

  public mounted() {
    window.addEventListener('hashchange', this.onHashChange);

    EventBus.$on('activity-event-set-question-fullscreen', () => {
      this.fullscreen(true);
    });
  }

  destroyed() {
    window.addEventListener('hashchange', this.onHashChange);
    window.clearTimeout(this.hotspotsTimeout);
    window.clearTimeout(this.resizeTimeout);
    EventBus.$off('activity-event-set-question-fullscreen');
  }

  @Watch('windowWidth')
  private onResize() {
    this.resizeTimeout = window.setTimeout(() => {
      // Wait until user will rotate a device fully
      this.positionHotspots();
    }, 500);
  }

  @Watch('isFullscreen')
  private onFullscreenChange(val: boolean) {
    // removing hash from the current route
    // vue router may throw an error in this case,
    // because we're redirecting to the current location
    this.$router.push({ hash: val ? fsHash : '' }).catch(() => {
      /* nope */
    });
  }
}
</script>

<docs>
### Default

```
<AssetImage src="https://media.nature.com/w700/magazine-assets/d41586-019-03839-z/d41586-019-03839-z_17499176.jpg" />
```

### With children

```
<AssetImage src="https://media.nature.com/w700/magazine-assets/d41586-019-03839-z/d41586-019-03839-z_17499176.jpg">
  <div style="position: absolute; top: 50px; left: 50px; width: 50px; height: 50px; background-color: red">Hi</div>
</AssetImage>
```
</docs>
