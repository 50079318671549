export enum EventsNames {
  CustomiseInterestTile = 'customise interest tile',
  CustomiseWallpaper = 'customise wallpaper',
  CustomiseHumanAvatar = 'customise human avatar',
  CustomiseDominoAvatar = 'customise domino avatar',
  PurchaseInterestTile = 'puchase interest tile',
  PurchaseWallpaper = 'purchase wallpaper',
  PurchaseAvatarItem = 'purchase avatar item',
  PurchaseDominoAvatarItem = 'purchase domino avatar item',
  TapAcheivementsPopUp = 'tap acheivements pop up',
  NavDpFromGlobe = 'nav dp from globe',
  SendFrOnDpProfile = 'send fr on dp profile',
  UserMakesDpSearch = 'user makes dp search',
  NavFromSearchToDpProfile = 'nav from search to dp profile',
  SendFriendRequestWhileInDpSearchResults = 'send friend request while in dp search results',
  NavFromMyDpListToADpProfile = "nav from 'my dp' list to a dp profile",
  NavFromSuggestedDpToADpProfile = "nav from 'suggested dp' to a dp profile",
  SendFriendRequestWhileInSuggestedDp = "send friend request while in 'suggested dp'",
  UserAcceptsDpRequest = 'user accepts dp request',
  UserStartsANewThread = 'user starts a new thread',
  UserTapsOkBtnOnPaywall = "User taps the 'ok' button on the paywall",
  UserClicksOnSentenceMakerOptions = 'User clicks on sentence maker options',
}

const eventCodes: Record<string, string> = {
  [EventsNames.CustomiseInterestTile]: 'lbm1',
  [EventsNames.CustomiseWallpaper]: 'lbm2',
  [EventsNames.CustomiseHumanAvatar]: 'lbm3',
  [EventsNames.CustomiseDominoAvatar]: 'lbm4',
  [EventsNames.PurchaseInterestTile]: 'lbm5',
  [EventsNames.PurchaseWallpaper]: 'lbm6',
  [EventsNames.PurchaseAvatarItem]: 'lbm7',
  [EventsNames.PurchaseDominoAvatarItem]: 'lbm8',
  [EventsNames.TapAcheivementsPopUp]: 'lbm11',
  [EventsNames.NavDpFromGlobe]: 'lbm13',
  [EventsNames.SendFrOnDpProfile]: 'lbm14',
  [EventsNames.UserMakesDpSearch]: 'lbm16',
  [EventsNames.NavFromSearchToDpProfile]: 'lbm17',
  [EventsNames.SendFriendRequestWhileInDpSearchResults]: 'lbm18',
  [EventsNames.NavFromSuggestedDpToADpProfile]: 'lbm21',
  [EventsNames.SendFriendRequestWhileInSuggestedDp]: 'lbm22',
  [EventsNames.UserAcceptsDpRequest]: 'lbm23',
  [EventsNames.UserStartsANewThread]: 'lbm24',
  [EventsNames.UserTapsOkBtnOnPaywall]: 'lbm39',
  [EventsNames.UserClicksOnSentenceMakerOptions]: 'lbm40',
};

export const eventCodeByName = (name: string) => eventCodes[name];
