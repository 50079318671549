import Vue from 'vue';
import Vuex from 'vuex';

import session from './modules/session';
import notifications from './modules/notifications';
import products from './modules/products';
import profile from './modules/profile';
import users from './modules/users';
import achievements from './modules/achievements';
import activities from './modules/activities';
import messages from './modules/messages';
import newsfeed from './modules/newsfeed';
import songMenu from './modules/song-menu';
import friends from './modules/friends';
import dictionary from './modules/dictionary';
import preloadAssets from './modules/preload-assets';
import communityGuidelines from './modules/community-guidelines';
import messaging from './modules/messaging';

import { State as AchievementsState } from './modules/achievements';
import { State as ActivityState } from './modules/activities';
import { State as NotificationsState } from './modules/notifications';
import { State as SessionState } from './modules/session';
import { State as MessagesState } from './modules/messages';
import { State as NewsfeedState } from './modules/newsfeed';
import { State as SongMenuState } from './modules/song-menu';
import { State as UsersState } from './modules/users';
import { State as PreloadAssetsState } from './modules/preload-assets';
import { State as ProfileState } from './modules/profile';
import { State as MessagingState } from './modules/messaging';

import { IPopupData } from '@/models/interfaces/popup';

Vue.use(Vuex);

export interface State {
  isRootDropdownMenuVisible: boolean;
  isRootNavVisible: boolean;
  popupData: IPopupData;
}

export interface RootState extends State {
  achievements: AchievementsState;
  activities: ActivityState;
  notifications: NotificationsState;
  session: SessionState;
  messages: MessagesState;
  newsfeed: NewsfeedState;
  songMenu: SongMenuState;
  users: UsersState;
  preloadAssets: PreloadAssetsState;
  profile: ProfileState;
  messaging: MessagingState;
  // TODO: add vuex modules state here
}

export default new Vuex.Store({
  state: {
    isRootDropdownMenuVisible: false,
    isRootNavVisible: true,
    popupData: {
      isShown: false,
    },
  } as any,
  mutations: {
    toggleRootDropdownMenuVisibility(state, isVisible) {
      state.isRootDropdownMenuVisible = isVisible;
    },
    toggleRootNavbarVisibility(state, isVisible) {
      state.isRootNavVisible = isVisible;
    },
    togglePopup(state, data?: IPopupData) {
      if (data) {
        const { sound, redirectPageIcon } = data;

        state.popupData = Object.assign(
          { isShown: true, closable: true, ...data },
          {
            sound: sound ? sound : '/sounds/bark.mp3',
            redirectPageIcon: redirectPageIcon
              ? redirectPageIcon
              : require('@/assets/images/app-header/globe.png'),
          },
        );
      } else {
        state.popupData = {
          isShown: false,
        };
      }
    },
  },
  getters: {
    getDropdownMenuState(state) {
      return state.isRootDropdownMenuVisible;
    },
    getNavbarState(state) {
      return state.isRootNavVisible;
    },
    getPopupData(state) {
      return state.popupData;
    },
  },
  modules: {
    session,
    notifications,
    products,
    profile,
    achievements,
    activities,
    users,
    messages,
    newsfeed,
    songMenu,
    friends,
    dictionary,
    preloadAssets,
    communityGuidelines,
    messaging,
  },
});
