var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-slider"},[(_vm.items.length)?_c('button',{staticClass:"v-slider__trigger",attrs:{"type":"button","disabled":!_vm.canScrollUp},on:{"click":_vm.scrollUp}},[_c('img',{attrs:{"src":"/images/training-menu/slider-arrow-up.png","alt":"Arrow top"}})]):_vm._e(),_c('div',{staticClass:"v-slider__content",style:({
      height: `${_vm.itemViewHeight}px`,
    })},[_c('div',{staticClass:"v-slider__scroller",style:({
        height: `${_vm.scrollerHeight}px`,
        transform: `translateY(${_vm.scrollerPosition}px)`,
      })},_vm._l((_vm.items),function(item){return _c('div',{key:item[_vm.itemKey],ref:"sliderItems",refInFor:true,style:({
          marginBottom: `${_vm.itemSpacing}px`,
        })},[_vm._t("item",null,{"item":item})],2)}),0)]),(_vm.items.length)?_c('button',{staticClass:"v-slider__trigger",attrs:{"type":"button","disabled":!_vm.canScrollDown},on:{"click":_vm.scrollDown}},[_c('img',{attrs:{"src":"/images/training-menu/slider-arrow-down.png","alt":"Arrow bottom"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }