<template>
  <div class="activity-topbar">
    <BaseButton
      v-if="showFlagIcon"
      :class="[{ 'activity-topbar--disabled': !isOnline }]"
      :disabled="isActivityTest && isQuestionAudioPlaying"
      @click.native="selectActivityLang(userLanguages.supportLanguage)"
    >
      <span
        v-tooltip="{
          content: this.$t('tooltip.activities.supportLanguage').toString(),
        }"
        class="activity-topbar__lang"
      >
        {{ userLanguages.supportLanguage }}
      </span>
    </BaseButton>

    <BaseButton
      :class="[{ 'activity-topbar--disabled': !isOnline }]"
      :disabled="isActivityTest && isQuestionAudioPlaying"
      @click.native="selectActivityLang(userLanguages.teachingLanguage)"
    >
      <span
        v-tooltip="{
          content: this.$t('tooltip.activities.teachingLanguage').toString(),
        }"
        class="activity-topbar__lang"
      >
        {{ userLanguages.teachingLanguage }}
      </span>
    </BaseButton>

    <template v-if="!isActivityTest">
      <BaseButton
        type="secondary"
        :icon="isReplayPlaying ? 'pause' : 'audio'"
        :class="[
          'activity-topbar__media-btn activity-topbar__media-btn--left',
          { 'activity-topbar--disabled': disableReplay },
        ]"
        @click.native="handleRepeat"
      />

      <BaseButton
        type="secondary"
        :icon="isSlowPlaying ? 'pause' : 'snail'"
        :class="[
          'activity-topbar__media-btn activity-topbar__media-btn--right',
          { 'activity-topbar--disabled': disableReplay },
        ]"
        @click.native="handleSlowRepeat"
      />
    </template>

    <BaseButton
      v-if="showCloseBtn"
      type="danger"
      icon="exit"
      @click.native="close"
    />
  </div>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus from '@/utils/helpers/EventBus';

import { IUserLanguageData } from '@/models/interfaces/users';
import {
  PreloadedAsset,
  PreloadedAssetType,
} from '@/store/modules/preload-assets';

import ToolbarItem from '@/components/ToolbarItem/ToolbarItem.vue';
import BaseButton from '@/components/BaseButton/BaseButton.vue';

import routeNames from '@/router/route-names';

@Component({
  components: {
    ToolbarItem,
    BaseButton,
  },
})
export default class ActivityTopbar extends Vue {
  public blockNavigation = false;

  @Prop({ type: Object, required: true })
  public userLanguages!: IUserLanguageData;

  @Getter('preloadAssets/findPreloadedAsset')
  public findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  @State('preloadedImages', { namespace: 'preloadAssets' })
  public preloadedImages!: PreloadedAsset[];

  @Prop({ required: true })
  public selectedActivityLanguage!: string;

  @Prop({ required: true, type: Boolean })
  public isActivityTest!: boolean;

  @Prop({ required: true, type: Boolean })
  public disableReplay!: boolean;

  public isReplayPlaying = false;
  public isSlowPlaying = false;
  public isQuestionAudioPlaying = false;

  public get showFlagIcon() {
    const { supportLanguage } = this.userLanguages;
    const ignoredLanguages = ['gb', 'us'];

    return !ignoredLanguages.includes(supportLanguage);
  }

  public get showCloseBtn() {
    return !this.blockNavigation && !this.isActivityTest;
  }

  public handleSlowRepeat() {
    this.isReplayPlaying = false;

    if (this.isSlowPlaying) {
      EventBus.$emit('activity-event-replay-pause');
      this.isSlowPlaying = false;
      return;
    }

    EventBus.$emit('activity-event-replay-slow');
    this.isSlowPlaying = true;
  }

  public handleRepeat() {
    this.isSlowPlaying = false;

    if (this.isReplayPlaying) {
      EventBus.$emit('activity-event-replay-pause');
      this.isReplayPlaying = false;
      return;
    }

    EventBus.$emit('activity-event-replay');
    this.isReplayPlaying = true;
  }

  public isSelected(lang: string) {
    return this.selectedActivityLanguage === lang;
  }

  public close() {
    this.$router.push({ name: routeNames.trainingMenu }).catch(() => {
      /* nope */
    });
  }

  public selectActivityLang(lang: string) {
    this.$emit('activity-event-language-change', lang);
  }

  public mounted() {
    EventBus.$on('activity-event-question-audio-end', () => {
      this.isReplayPlaying = false;
      this.isSlowPlaying = false;
      this.isQuestionAudioPlaying = false;
    });
    EventBus.$on('activity-event-question-audio-start', () => {
      this.isQuestionAudioPlaying = true;
    });
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-question-audio-end');
    EventBus.$off('activity-event-question-audio-start');
  }

  public created() {
    const { blockNavigation } = this.$route.query;

    this.blockNavigation = Boolean(blockNavigation);
  }
}
</script>

<style lang="scss" scoped>
.activity-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: #{$mobile}px;
  width: 100%;

  margin: 0 auto;

  @media (min-width: #{$mobile}px) {
    justify-content: space-around;
  }

  &__media-btn {
    &--left {
      margin-left: auto;
      margin-right: 10px;
    }

    &--right {
      margin-right: auto;

      @media (max-width: #{$mobile}px) {
        margin-right: 10px;
      }
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__lang {
    color: $white100;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .base-button {
    &--secondary,
    &--danger {
      padding: 7px 30px;
    }

    &--default {
      padding: 7px 30px;
    }

    &:first-child {
      margin-right: 10px;
    }

    @media (max-width: #{$mobile}px) {
      width: 100%;

      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>

<docs>
Common topbar for activity screens.

### Default

```jsx
<ActivityTopbar
  :disable-replay="false"
  :is-activity-test="false"
  selected-activity-language="en"
  :user-languages="{
    supportLanguage: 'gb',
    supportLanguageFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png',
    teachingLanguage: 'gb',
    teachingLanguageFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png',
  }"
/>
```

### Adapted for test activities

```jsx
<ActivityTopbar
  :disable-replay="false"
  :is-activity-test="true"
  selected-activity-language="en"
  :user-languages="{
    supportLanguage: 'gb',
    supportLanguageFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png',
    teachingLanguage: 'gb',
    teachingLanguageFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png',
  }"
/>
```
</docs>
