<template>
  <div>
    <div class="activity-word-search__table">
      <div class="activity-word-search__rows">
        <div
          v-for="(rowV, row) in size"
          :key="row - 1"
          class="activity-word-search__table-row"
        >
          <div v-for="(colV, col) in size" :key="`${row}_${col}`">
            <button
              :class="letterTileClasses(col, row)"
              :data-x="col"
              :data-y="row"
              type="button"
              @mousedown.prevent="onStartHandle"
              @mouseup="onEndHandle"
              @mousemove="onEndHandle"
              @touchstart.prevent="onStartHandle"
              @touchend="onEndHandle"
              @touchmove="onEndHandle"
            >
              {{ gridVal(col, row).toUpperCase() }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <ActivityWords :words="data" :active-words="computedFoundWords" />
  </div>
</template>

<style lang="scss" scoped>
.activity-word-search {
  &__button {
    color: $blue100;
    font-size: 18px;

    width: 27px;

    padding: 4px 8px;

    &--active {
      color: $white100;

      background: $blue100;
    }

    &--hovered {
      background: rgba(27, 117, 187, 0.1);
    }
  }

  &__table {
    &-row {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  &__rows {
    background: $white100;

    padding: 6px 0;

    border: 1px solid $blue100;
    border-top: none;

    max-width: 350px;
    margin: auto;
  }
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import ActivityWords from './ActivityWordSearchWords.vue';
import {
  ActivitySearchWord,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';

import EventBus from '@/utils/helpers/EventBus';

@Component({
  components: {
    ActivityWords,
  },
})
export default class ActivityWordSearch extends Vue {
  @Prop({ required: true })
  private data!: IActivityComponentDataElement[];

  private size = 12;

  private grid: string[][] = [];

  private letterGrid: any[] = [[]];

  private debugEnabled = false;
  private backwards = false;

  private foundWords: string[] = [];

  private guess: Array<{ x: number; y: number }> = [];
  private foundTiles: Array<{ x: number; y: number }> = [];

  private selectedRange: {
    start: { x: number; y: number } | undefined;
    end: { x: number; y: number } | undefined;
  } = {
    start: undefined,
    end: undefined,
  };

  private get words(): string[] {
    return this.data
      .map((word: ActivitySearchWord) => word.value.replace(/ /g, ''))
      .sort((a: string, b: string) => (a.length > b.length ? -1 : 1));
  }

  private get computedFoundWords() {
    return this.foundWords.map((item: string) =>
      this.data.find(cItem => cItem.value.replace(/ /g, '') === item),
    );
  }

  private get guessedWord() {
    return this.guess.map(l => this.gridVal(l.x, l.y)).join('');
  }

  private letterTileClasses(x: number, y: number) {
    const classes = ['activity-word-search__button'];

    if (this.isHovered(x, y)) {
      classes.push('activity-word-search__button--hovered');
    }

    if (this.isFound(x, y)) {
      classes.push('activity-word-search__button--active');
    }

    return classes;
  }

  private isHovered(x: number, y: number) {
    const r = this.selectedRange;
    if (r.start && r.end) {
      const minX = Math.min(r.start.x, r.end.x);
      const maxX = Math.max(r.start.x, r.end.x);
      const minY = Math.min(r.start.y, r.end.y);
      const maxY = Math.max(r.start.y, r.end.y);
      if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
        // in range. If X or Y are equal then it's a straight line, otherwise
        // Work out diagonal logic.
        return (
          r.start.x === r.end.x ||
          r.start.y === r.end.y ||
          Math.abs(r.start.x - x) === Math.abs(r.start.y - y)
        );
      }
    }

    return false;
  }

  private isFound(x: number, y: number) {
    for (let i = 0; i < this.foundTiles.length; i += 1) {
      if (this.foundTiles[i].x === x && this.foundTiles[i].y === y) {
        return true;
      }
    }
    return false;
  }

  private gridVal(x: number, y: number) {
    if (typeof this.letterGrid[y] !== 'undefined') {
      if (typeof this.letterGrid[y][x] !== 'undefined') {
        return this.letterGrid[y][x];
      }
    }
    return '';
  }

  private onStartHandle(event: Event) {
    const touchedElement = (event.target as HTMLElement).closest(
      'button.activity-word-search__button',
    ) as HTMLElement;

    if (touchedElement && touchedElement.dataset && touchedElement.dataset.x) {
      this.selectedRange.start = {
        x: parseInt(touchedElement.dataset.x, 10),
        y: parseInt(touchedElement.dataset.y as string, 10),
      };

      return true;
    }

    return false;
  }

  private onEndHandle(event: Event) {
    // We never start selecting if start is undefined.

    if (this.selectedRange.start === undefined) {
      return false;
    }

    let touch: { clientX: number; clientY: number } = event as any;

    if (event.type.indexOf('touch') === 0) {
      touch = (event as Event & { changedTouches: any }).changedTouches.item(0);
    }

    // any to avoid "document null error"
    const touchedElement = (document as any)
      .elementFromPoint(touch.clientX, touch.clientY)
      .closest('button.activity-word-search__button');

    if (touchedElement && touchedElement.dataset && touchedElement.dataset.x) {
      const x = parseInt(touchedElement.dataset.x, 10);
      const y = parseInt(touchedElement.dataset.y, 10);
      const dx = Math.abs(x - this.selectedRange.start.x);
      const dy = Math.abs(y - this.selectedRange.start.y);

      // Verify the end is valid.
      // If dy (change in y) or dx (change in x) is zero, then it is horizontal/vertcal == OK.
      // Or if dx === dy, then it is diagonal.

      if ((dy === 0 && dx > 0) || (dx === 0 && dy > 0) || dx === dy) {
        this.selectedRange.end = { x, y };
        // If it's mouseup, then we finished the dragging a range
        if (event.type === 'mouseup' || event.type === 'touchend') {
          this.guess = [];
          // Build guess

          const sx = this.selectedRange.start.x;
          const sy = this.selectedRange.start.y;
          const ex = this.selectedRange.end.x;
          const ey = this.selectedRange.end.y;
          // 0 = up, 1 = up-right, 2 = right, 3 = down-right, 4 = down
          // 5 = down-left, 6 = left, 7 = up-left
          if (dx === 0) {
            // Vertical
            const step = ey > sy ? 1 : -1;
            for (let i = sy; step > 0 ? i <= ey : i >= ey; i += step) {
              this.guess.push({ x: sx, y: i });
            }
          } else if (dy === 0) {
            // Horizontal
            const step = ex > sx ? 1 : -1;
            for (let i = sx; step > 0 ? i <= ex : i >= ex; i += step) {
              this.guess.push({ x: i, y: sy });
            }
          } else {
            // Diagonal
            const stepX = ex > sx ? 1 : -1;
            const stepY = ey > sy ? 1 : -1;

            for (
              let iX = sx, iY = sy;
              (stepY > 0 ? iY <= ey : iY >= ey) ||
              (stepX > 0 ? iX <= ex : iX >= ex);
              iY += stepY, iX += stepX
            ) {
              this.guess.push({ x: iX, y: iY });
            }
          }

          const exists = this.words.find(item => item === this.guessedWord);

          if (exists) {
            this.foundWords.push(this.guessedWord);
            this.foundTiles.push(...this.guess);

            if (this.debugEnabled) {
              // eslint-disable-next-line no-console
              console.log('Found Word:', this.guessedWord, sx, sy);
            }
          }

          // Gesture complete, reset the range.
          this.resetSelectedRange();
        }
      } else if (event.type === 'mouseup' || event.type === 'touchend') {
        // Verify failed, reset range (only if at the end of a gesture)
        this.resetSelectedRange();
      } else {
        this.selectedRange.end = undefined;
      }
    } else if (event.type === 'mouseup' || event.type === 'touchend') {
      // End was "null" or had no x/y. Reset.
      this.resetSelectedRange();
    } else {
      this.selectedRange.end = undefined;
    }
    return true;
  }

  private rebuildGrid() {
    this.clearGameState();
    this.buildLetterGrid();
  }

  private buildLetterGrid() {
    this.letterGrid = [...Array(this.size)].map(() => Array(this.size));

    this.words.forEach(word => {
      if (word.length > this.size) {
        // eslint-disable-next-line no-console
        console.error('Word wont fit on board');
        return;
      }

      let isValid = false;
      let x = 0;
      let y = 0;
      let dx = 0;
      let dy = 0;
      let itterationCount = 0;

      do {
        itterationCount += 1;

        if (itterationCount > 100) {
          // eslint-disable-next-line no-console
          console.error('Tried to write word 100 times and failed', word);
          return;
        }

        x = Math.floor(Math.random() * this.size);
        y = Math.floor(Math.random() * this.size);
        dx = 0;
        dy = 0;
        isValid = false;

        // Assume invalid until proven OK.

        // Directions...

        // 0 = up, 1 = up-right, 2 = right, 3 = down-right, 4 = down
        // 5 = down-left, 6 = left, 7 = up-left
        // If Backwards is enabled, can have the full 0-7 range.
        // Othewrwise, can only got 1-4

        const direction = this.backwards
          ? Math.floor(Math.random() * 8)
          : 1 + Math.floor(Math.random() * 4);

        // 1-3 goes right. 5-7 goes left

        if (direction > 0 && direction < 4) {
          dx = 1;
        } else if (direction > 4 && direction < 8) {
          dx = -1;
        }

        // 0-1 and 7  go up. 3-5 go down.

        if (direction < 2 || direction > 6) {
          dy = -1;
        } else if (direction > 2 && direction < 6) {
          dy = 1;
        }

        try {
          const endX = x + dx * word.length;
          if (endX < 0 || endX > this.size) {
            throw new Error('Word exceeds width');
          }
          const endY = y + dy * word.length;
          if (endY < 0 || endY > this.size) {
            throw new Error('Word exceeds height');
          }

          // If we have got here, then it fits on the grid
          // Simulate laying it to check for overlap.

          for (let cIndex = 0; cIndex < word.length; cIndex += 1) {
            const xCord = x + cIndex * dx;
            const yCord = y + cIndex * dy;

            if (this.letterGrid[yCord][xCord] !== undefined) {
              if (this.letterGrid[yCord][xCord] !== word[cIndex]) {
                throw new Error('Letter Overlap');
              }
            }
          }
          isValid = true;
        } catch (err) {
          isValid = false;
        }
      } while (!isValid);
      // Has been found valid, so write it in.

      for (let cIndex = 0; cIndex < word.length; cIndex += 1) {
        const xCord = x + cIndex * dx;
        const yCord = y + cIndex * dy;
        this.letterGrid[yCord][xCord] = word[cIndex];
      }
    });

    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    for (let y = 0; y < this.size; y += 1) {
      for (let x = 0; x < this.size; x += 1) {
        if (this.letterGrid[y][x] === undefined) {
          this.letterGrid[y][x] = alphabet.charAt(
            Math.floor(Math.random() * alphabet.length),
          );
        }
      }
    }
  }

  private resetSelectedRange() {
    this.selectedRange = {
      start: undefined,
      end: undefined,
    };
  }

  private clearGameState() {
    this.foundWords = [];
    this.foundTiles = [];
    this.guess = [];
  }

  private mounted() {
    this.rebuildGrid();
  }

  @Watch('foundWords', { immediate: true })
  private onWordFound(newVal: string[]) {
    if (newVal.length === this.data.length) {
      // if all solutions are picked
      EventBus.$emit(
        'activity-event-solution-bulk-update',
        this.data.map(item => ({ type: 'wordSearch', data: item })),
      );
      EventBus.$emit('activity-event-confirm');
    }
  }
}
</script>

<docs>
### Activity word search game

```jsx

<ActivityWordSearch
  :data="[
    {
      value: 'Mrs McKenzie',
      id: '1',
    },
    {
      value: 'Hello',
      id: '2',
    },
    {
      value: 'My friend',
      id: '3',
    },
    {
      value: 'Great',
      id: '4',
    },
    {
      value: 'Perfect',
      id: '5',
    },
    {
      value: 'Cool',
      id: '6',
    },
    {
      value: 'Awesome',
      id: '7',
    },
    {
      value: 'How are you',
      id: '8',
    },
  ]"
/>
```
</docs>
