<template>
  <div class="col-wrap">
    <div ref="column" class="column">
      <ActivityTextButton
        v-for="word in words"
        :key="word.id"
        data-testID="column-activity-text-button"
        desktop-size="medium"
        mobile-size="medium"
        :bg-color="selectedWord.id === word.id ? '#f3aa45' : bgColor"
        @click.native="$emit('on-select', word)"
      >
        {{ word.value }}
      </ActivityTextButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';
import { IActivityComponentDataElement } from '@/models/interfaces/activities';

@Component({
  components: {
    ActivityTextButton,
  },
})
export default class VerticalContainerColumn extends Vue {
  @Ref('column')
  private column!: HTMLDivElement;

  @Prop({ default: '#26a7df' })
  readonly bgColor!: string;

  @Prop({ required: true, type: Array })
  private words!: IActivityComponentDataElement[];

  @Prop({ type: Object, default: () => ({}) })
  private selectedWord!: IActivityComponentDataElement;
}
</script>

<style lang="scss" scoped>
.col-wrap {
  position: relative;
  flex: 1;

  margin-right: 20px;

  &:last-child {
    margin: 0;
  }
}

.activity-text-btn {
  margin-right: 0;
  margin-left: 0;

  width: 100%;
}

.indicator {
  position: absolute;

  width: 20px;
  height: 20px;

  background-color: $orange100;
  color: $white100;

  right: 0;

  display: flex;
  justify-content: center;

  border-radius: 5px;

  &-icon {
    margin-bottom: 4px;
  }

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }
}
</style>
