import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: 'Please select a reason first!',
    title: 'Community guidelines',
    popup: {
      title: 'Thank you for helping to keep our community safe!',
      btn: 'Return to Community Guidelines',
    },
    pt1: {
      subtitle: 'What is the DigiPals Community?',
      text:
        'Our DigiPals community is a safe place for you to make friends and practice English.',
    },
    pt2: {
      subtitle: 'What are the community rules?',
      text1: 'Always write in English',
      text2:
        'Do not write anything unfriendly, offensive or unkind. Swearing is not permitted!',
      text3:
        'Do not share any personal details e.g. Your full name, addresses, phone numbers, email addresses or other online/social media account details',
      text4: 'All messages are reviewed by real people.',
    },
    pt3: {
      subtitle: 'What should I do if I think someone is breaking a rule?',
      text1:
        'Sending this report helps us to keep everyone in our community happy.',
      text2: 'We will contact your grown up to find out more and help you',
    },
    btn: 'Send a report',

    option1: 'I think someone is not writing in English',
    option2: 'I think someone is being unkind',
    option3: 'I think someone shared personal information',
  },

  globe: {
    _: 'Globe',
    friendsMultiple: 'You have {friends} friends',
    friends: 'You have 1 friend',
    countries: 'in 1 country',
    countriesMultiple: 'in {countries} countries',
    defaultTitle: 'Our global DigiPal community!',
    fetchFriend: 'Fetch a new friend now!',
  },

  profile: {
    avatar: {
      title: 'Make my avatar',
    },
    error: 'Oops! Something went wrong. Could you try again?',
    messagesEmpty:
      'Oops! There are no messages here yet. Go to your list of recommended DigiPals, visit their profiles and start a conversation!',
    postTitle: 'Posts from my DigiPals',
    writePost: 'Write a DigiPost',
    profileError: 'Failed to fetch profile messages.',
    clock: 'What time is it where\n{username}\nlives?',
    country: '{username}\nlives in\n{country}',
    myCountry: 'I live in\n{country}',
    myClock: 'What time is it\nwhere I live?',
    friendsAccepted: 'You are already friends!',
    friendsAdd: 'Send a DigiPal request',
    friendsPending: 'Digipal request pending',
  },

  dictionary: {
    alphabet: {
      error: 'Failed to fetch alphabet',
    },
    words: {
      error: 'Failed to fetch words',
    },
  },

  dates: {
    yearsAgo: '{count} years ago',
    yearAgo: 'a year ago',
    monthsAgo: '{count} months ago',
    monthAgo: 'a month ago',
    daysAgo: '{count} days ago',
    dayAgo: 'a day ago',
    hoursAgo: '{count} hours ago',
    hourAge: 'an hour ago',
    minutesAgo: '{count} minutes ago',
    minuteAgo: 'a minute ago',
    seconds: 'a few seconds ago',
  },

  common: {
    someone: 'Someone',
  },

  digiPal: {
    messagesEmpty: 'There are no messages here! Write the first one!',
  },

  interests: {
    title: 'Choose my likes',
  },

  achievements: {
    title: 'See my achievements',
    messageTrophy:
      'Get trophies by sending messages to your DigiPals! Only messages that receive a response will count towards your trophies.',
    activityTrophy:
      'Get trophies by completing activities! Every star you win will count towards your trophies.',
  },

  settings: {
    title: {
      1: 'Learn UK English or US English?',
      2: "Get 'Help' in?",
    },
  },

  digipals: {
    genericErrorMessage: 'Oops! Something went wrong. Could you try again?',

    all: {
      title: 'My DigiPals',
      emptyErrorMessage:
        "You have no connected friends yet. Go to 'Recommended DigiPals' to start making friends!",
      successRequest:
        'You’ve sent {friend} a friend request. Leave a message on their profile to tell them why you would like to be friends!',
      clock:
        'What time is it where {digipal} lives? Remember, some DigiPals might be asleep when it is daytime for you!',
    },
    mutual: {
      title: 'Mutual DigiPals',
      emptyErrorMessage: 'You have no mutual friends.',
    },
    pending: {
      title: 'DigiPal requests',
      emptyErrorMessage:
        "You have no pending requests yet. Go to 'Recommended DigiPals' to start making friends!",
      successAccept:
        'You are now friends with {friend}. Visit their profile to say hi!',
    },
    search: {
      title: 'Search for a DigiPal',
    },
    suggested: {
      title: 'Message DigiPals who are online now!',
      emptyErrorMessage: 'You have no DigiPals online.',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        'Hello! I’m Domino. I’ll help you make friends all around the world! If you need any help, you can find me in the toolbar. This is your DigiPal profile page. It tells everyone something about you. Start exploring!',
      post: {
        reply:
          '{username} has replied to your message! Continue the conversation 😁',
        wallMessage:
          '{username} has left a message on your profile! Continue the conversation 😁',
      },
      dominoAdd:
        'Congratulations! You have a streak! Now Domino will appear on your profile!',
      dominoRem:
        'Oh no! You lost your streak. Earn 20 lingos to get Domino back on your profile :)',
    },
    messageDigipal: 'Message {username}',
    messageFrom: 'Message from\n{userName}',
    friendRequest: 'Friend request\n{userName}',
    requestAccepted: 'Request accepted\n{userName}',
    likeDash: '{userName}\nliked your page',
  },

  popup: {
    activityLeaveConfirmation:
      'Are you sure you want to leave the activity? Your progress will not be saved.',
    testLeaveConfirmation:
      'Are you sure you want to leave the test? You will not be able to redo it again.',
    streakPrefix: '{streak} day streak.',
    streak0:
      'Earn 20 Lingos per day to start your streak! To earn Lingos, send messages to your friends and complete activities!',
    premiumGamesErrorMessage:
      'Oops! You don’t have access to the games. Ask your parents about upgrading your account!',
    lingosErrorMessage:
      'You need Lingos to buy some items. Send messages to win Lingos.',
    offlineErrorMessage: 'Oops! The internet connection is not working.',
    genericErrorMessage: 'Oops! Something went wrong. Could you try again?',
    newsfeedErrorMessage:
      'Oops! Your message was not sent. Could you try again?',
    avatarCustomizationMenu:
      'Customise your avatar! You need Lingos to buy some items. Complete activities or send messages to win Lingos.',
    wallpaperSettings:
      'Customise your profile wallpaper! You need Lingos to buy some items. Complete activities or send messages to win Lingos.',
    interestsSettings:
      'Show your hobbies and likes! You need Lingos to buy some items. Complete activities or send messages to win Lingos.',
    achievementsMenu: 'See how many awards you’ve won!',
    globe: `Find new DigiPals around the globe. Visit their profile, leave them a message to win Lingos. Use the ${htmlFeatures.plusIcon} to invite them to be your DigiPal`,
    friendsSuggested:
      'Make friends with other DigiPals! You have some DigiPals recommended, just for you! Invite them to connect with you.',
    friends:
      'See DigiPals you are connected to. Visit their profiles, leave them a message and win Lingos.',
    friendsPending:
      'See DigiPals who want to connect with you. Accept their invitation and go to their profile to leave a message to win Lingos.',
    friendsSearch: `Know someone’s DigiPal name? Search for them here and go to their profile to leave a message and win more Lingos. Use the ${htmlFeatures.plusIcon} to invite them to be your DigiPal`,
    login: `Making friends is great! Visit your 'Recommended friends' or visit the globe to make new friends 😁`,
    profileLike: `You've liked {username}'s profile page! They will be super happy. Leave them a message to let them know why you liked it!`,
    streakNotice:
      "Want a cool, customisable Domino pet? To get Domino, earn 20 lingos each day. This is your 'Streak'.",
    profilePage:
      'Hello! I’m Domino. I’ll help you make friends all around the world! If you need any help, you can find me in the toolbar.  This is your DigiPal profile page. It tells everyone something about you. Start exploring!',
  },

  activity: {
    rewards: {
      trophyTitle: 'You’ve won a trophy!',
      starTitle: 'You’ve won a star!',
      lingoTitle: 'You’ve got {lingos} lingos!',
      returnToActivities: 'Return to activities',
      score: 'Score',
    },
    currentLesson: 'Current lesson',
    offlineMessage:
      'Uh oh! You have lost your internet connection. Don’t worry, we have saved your activity score!',
  },

  onboarding: {
    first: 'Meet DigiPals from around the world and message them in English.',
    second: 'Need help with English? You can start learning here.',
    third: 'Create your avatar. Display your likes. Find other DigiPals.',
    fourth:
      'Visit your DigiPals. Leave messages. Join chats. Invite DigiPals to message you.',
    fifth:
      'Win Lingos for messages and completing games. Buy things for your avatar and for Domino.',
    sixth:
      'Maintain a streak in Little Bridge and get Domino for your profile!',
    seventh: {
      title: 'Ready to make friends?',
      btn: `Let's go!`,
    },
  },
  tooltip: {
    toolbar: {
      home: 'Home',
      activities: 'Domino Academy',
      dominoAcademy: 'Domino \n Academy',
      friends: 'Friends',
      streak: 'Streak',
      globe: 'Find DigiPals',
      information: 'Info',
      menu: 'Menu',
      lingos: 'Lingos',
      notifications: 'Notifications',
      notificationsactive: 'Notifications',
      audio: 'Repeat question',
      exit: 'Close activity',
      snail: 'Slow question',
      steps: 'Continue',
      fetchFriend: 'Find new friends',
      findFriends: 'Find new \n friends',
    },
    profile: {
      interests: 'Interests',
      digipals: 'My DigiPals',
      achievements: 'My achievements',
      editWallpaper: 'Edit wallpaper',
      addDigipal: 'Add DigiPal',
      offline: 'Offline',
      online: 'Online',
      myAvatar: 'My Digipal',
      avatar: 'Avatar',
      sendMessage: 'Send message',
      likeProfile: 'Like profile',
      myFriends: 'My friends',
      myLikes: 'My likes',
      digipalLikes: 'Like this profile',
      alreadyLiked: 'You already like this profile',
      myMessages: 'My messages',
      requestPending: 'DigiPal request pending',
      weAreDigipals: "We're DigiPals!",
    },
    messages: {
      likes: 'Likes',
      send: 'Send',
      saySomething: 'Say something',
      askSomething: 'Ask something',
      emoji: 'Emoji',
      reply: 'Reply',
    },
    activities: {
      supportLanguage: 'English instructions',
      teachingLanguage: 'English instructions',
      next: 'Next',
      previous: 'Previous',
      slowPlay: 'Slow',
      record: 'Record',
      playback: 'Playback',
    },
    other: {
      back: 'Back',
      getItem: 'Get item',
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
    },
  },

  conversations: {
    sendMessage: 'Send message',
    yourChat:
      'Your chat with <p class="conversation__header__subtitle">{username}</p>',
    myInbox: 'My inbox',
    send: 'Send message',
    noMessages: 'There are no messages here! Write the first one!',
  },

  messageRewards: {
    title: 'You sent a message to {username}!',
    returnToHome: 'Return to profile page',
  },
};
