<template>
  <article
    v-if="!finishedPlaying"
    :class="{
      'activity-video': true,
      'activity-video--fullscreen': fullscreen,
    }"
    data-activity-asset
  >
    <div class="activity-video__element">
      <AssetVideo
        :id="data[0].id"
        ref="video"
        :key="videoSrc"
        :src="isOnline ? videoSrc : findPreloadedAsset('video', videoSrc)"
        :type="type"
        :autoplay="autoPlay"
        :play-slow="playSlow"
        @ended="handleVideoEnded"
        @on-fullscreen="onFullscreen"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
.activity-video {
  position: absolute;
  left: 49.95%;
  transform: translateX(-50%);

  max-width: $activityComponentContainerWidth;
  width: 100%;

  background-color: #10a5df;

  margin: auto;
  margin-top: 30px;
  z-index: map-get($zindex, confirmationVideo);

  &--fullscreen {
    position: static;
    left: 0;
    transform: none;
  }

  // 16:9 aspect ratio:
  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  &__element {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';

import VideoMixin from '@/components/Activity/mixins/Video.mixin';

import AssetVideo from '@/components/Asset/Video.vue';
import EventBus from '@/utils/helpers/EventBus';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';
import { IActivityComponentDataElement } from '@/models/interfaces/activities';

@Component({
  components: {
    AssetVideo,
  },
})
export default class ActivityVideoDisappearing extends Mixins(VideoMixin) {
  @Prop({ required: true, type: String })
  private type!: string;

  @Prop({ required: true, type: Array })
  private data!: IActivityComponentDataElement[];

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private finishedPlaying = false;
  private fullscreen = false;
  private autoPlay = false;
  private playSlow = false;

  get videoSrc() {
    return this.data[0].value;
  }

  private onFullscreen(status: boolean) {
    this.fullscreen = status;
  }

  private handleVideoEnded() {
    this.finishedPlaying = true;
    this.autoPlay = false;
    EventBus.$emit('activity-event-question-audio-end');
    //EventBus.$emit('activity-event-replay');
  }

  public mounted() {
    EventBus.$on('activity-event-replay', () => {
      // Re-enable the disappearing video, it will reload the component
      this.autoPlay = true;
      this.playSlow = false;
      this.finishedPlaying = false;
    });

    EventBus.$on('activity-event-replay-slow', () => {
      this.autoPlay = true;
      this.playSlow = true;
      this.finishedPlaying = false;
    });

    EventBus.$on('activity-event-replay-pause', () => {
      this.finishedPlaying = true;
    });
  }

  public destroyed() {
    EventBus.$off('activity-event-replay');
    EventBus.$off('activity-event-replay-slow');
    EventBus.$off('activity-event-replay-pause');
  }
}
</script>

<docs>
### An activity component that displays a video that disappears on end

```jsx
<ActivityVideoDisappearing style="position: static; transform: none" type="videoDisappearing" :data="[{ value: 'https://www.w3schools.com/html/mov_bbb.mp4' }]" />
```
</docs>
