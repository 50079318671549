<template>
  <div class="side-tile">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SideTile extends Vue {}
</script>

<style lang="scss" scoped>
.side-tile {
  width: 20%;
  height: 100%;

  background: $wallpaperOverlay;
  padding: 0 25px;

  @include d-flex-center;
}
</style>
