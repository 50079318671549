<template>
  <div :class="['progress-star', { 'progress-star--filled': progress >= 50 }]">
    <img
      v-if="progress === 100"
      class="progress-star__img"
      src="/images/training-menu/star-full.png"
      alt="Star full"
    />

    <img
      v-else-if="progress >= 50"
      class="progress-star__img"
      src="/images/training-menu/star-half.png"
      alt="Star half"
    />

    <img
      v-else
      src="/images/training-menu/star-empty.png"
      class="progress-star__img"
      alt="Star empty"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ProgressStar extends Vue {
  @Prop({ type: Number, required: true })
  public progress!: number;
}
</script>

<style lang="scss" scoped>
.progress-star {
  @include d-flex-center;

  border-radius: 100%;
  background-color: $white100;
  border: 2px solid $blue100;

  width: 100px;
  height: 100px;

  &--filled {
    background-color: $blue400;
  }

  &__img {
    height: 60px;

    @media (max-width: #{$mobile}px) {
      height: 40px;
    }
  }
}
</style>
