import { render, staticRenderFns } from "./RoundedFooter.vue?vue&type=template&id=660c12db&scoped=true"
import script from "./RoundedFooter.vue?vue&type=script&lang=ts"
export * from "./RoundedFooter.vue?vue&type=script&lang=ts"
import style0 from "./RoundedFooter.vue?vue&type=style&index=0&id=660c12db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660c12db",
  null
  
)

export default component.exports