import DataProvider from '@/utils/helpers/DataProvider';
import StoreUtil from '@/utils/helpers/StoreUtil';

import { IVuexState } from '@/models/interfaces/store';

import { ActionTree, MutationTree, Module } from 'vuex';
import { RootState } from '..';
import {
  IAlphabetItem,
  IMetaData,
  IWord,
} from '@/models/interfaces/dictionary';
import { AxiosError } from 'axios';

export interface State {
  alphabet: IVuexState<IAlphabetItem[]>;
  words: IVuexState<{
    metaData: IMetaData;
    words: IWord[];
  }>;
}

export const actions: ActionTree<State, RootState> = {
  async fetchAlphabet({ commit }) {
    commit('setAlphabet');

    try {
      const {
        data: { data },
      } = await DataProvider.get<{ data: IAlphabetItem[] }>(
        'dictionary/alphabet-list',
      );
      commit('setAlphabet', data);
      return data;
    } catch (error) {
      commit('setAlphabet', error);
      return error;
    }
  },

  async fetchWords({ commit }, letterId: number) {
    commit('setWords');

    try {
      const {
        data: { data },
      } = await DataProvider.get<{
        data: {
          metaData: IMetaData;
          words: IWord[];
        };
      }>(`dictionary/words/${letterId}`);

      commit('setWords', data);
      return data;
    } catch (error) {
      commit('setWords', error);
      return error;
    }
  },
};

export const mutations: MutationTree<State> = {
  setAlphabet(state, payload: IAlphabetItem[] | AxiosError) {
    state.alphabet = StoreUtil.updateState(state.alphabet, payload);
  },

  setWords(
    state,
    payload:
      | {
          metaData: IMetaData;
          words: IWord[];
        }
      | AxiosError,
  ) {
    state.words = StoreUtil.updateState(state.words, payload);
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    alphabet: StoreUtil.state(),
    words: StoreUtil.state(),
  },
  mutations,
  actions,
};

export default store;
