import { Component, Vue } from 'vue-property-decorator';
import getDefaultSizingClass from '@/utils/helpers/getDefaultSizingClass';

@Component
export default class Breakpoints extends Vue {
  public breakpoint!: 'mobile' | 'tablet' | 'desktop';

  public created() {
    this.$_Breakpoints_handleResize();
    window.addEventListener('resize', this.$_Breakpoints_handleResize);
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.$_Breakpoints_handleResize);
  }

  private $_Breakpoints_handleResize() {
    this.breakpoint = getDefaultSizingClass('desktop', 'tablet', 'mobile') as
      | 'mobile'
      | 'tablet'
      | 'desktop';
  }
}
