var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",class:{
    image: true,
    'image--fullscreen': _vm.isFullscreen,
  },style:({
    // We cannot use 100vh/100vw due to mobile browsers (iOS/Android bug):
    width: _vm.isFullscreen ? `${_vm.windowWidth}px` : '100%',
    height: _vm.isFullscreen ? `${_vm.windowHeight}px` : '100%',
  })},[_c('img',{ref:"image",staticClass:"image__asset",attrs:{"src":_vm.src,"draggable":false},on:{"load":function($event){return _vm.$emit('loaded')}}}),_vm._t("default"),(_vm.controls)?_c('div',{staticClass:"image__buttons"},[_c('button',{staticClass:"image__fullscreen",on:{"click":function($event){return _vm.fullscreen(!_vm.isFullscreen)}}},[(!_vm.isFullscreen)?_c('i',{staticClass:"fas fa-expand"}):_c('i',{staticClass:"fas fa-compress"})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }