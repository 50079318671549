import { Vue, Component } from 'vue-property-decorator';
import { IVuexState } from '@/models/interfaces/store';
import { isEmpty as ramdaIsEmpty, isNil } from 'ramda';

@Component
export default class RequestState extends Vue {
  public hasFailed<T>(model?: IVuexState<T>) {
    return Boolean(model?.status?.fail);
  }

  public hasSucceded<T>(model?: IVuexState<T>) {
    return Boolean(model?.status?.success);
  }

  public isPending<T>(model?: IVuexState<T>) {
    return Boolean(model?.status?.pending);
  }

  public isEmpty<T>(model?: IVuexState<T>) {
    return ramdaIsEmpty(model?.data) || isNil(model?.data);
  }
}
