<template>
  <article
    :class="{
      'activity-video': true,
      'activity-video--no-container': !showTextContainer,
    }"
    data-activity-asset
  >
    <div class="activity-video__element">
      <AssetVideo
        :id="data[0].id"
        ref="video"
        :key="videoSrc"
        :src="isOnline ? videoSrc : findPreloadedAsset('video', videoSrc)"
        :poster="videoPosterSrc"
        @ended="$emit('on-end')"
      />
    </div>

    <ActivityTextContainer v-if="showTextContainer" class="text-container">
      {{ data[0].label }}
    </ActivityTextContainer>
  </article>
</template>

<style lang="scss" scoped>
.activity-video {
  position: relative;

  max-width: $activityComponentContainerWidth;
  width: 100%;

  background-color: #10a5df;

  margin: auto;

  margin-bottom: 50px;

  &--no-container {
    margin-bottom: 0;
  }

  // 16:9 aspect ratio:
  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  &__element {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}

.text-container {
  position: absolute;

  width: 100%;
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';

import AssetVideo from '@/components/Asset/Video.vue';
import ActivityTextContainer from '@/components/ActivityTextContainer/ActivityTextContainer.vue';

import { isCorrectFileExtension } from '@/utils/helpers/assets';
import EventBus from '@/utils/helpers/EventBus';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';
import { PlayingTypes } from '@/models/enums/video';

@Component({
  components: {
    AssetVideo,
    ActivityTextContainer,
  },
})
export default class ActivityVideo extends Vue {
  @Prop({ required: true })
  private type!: string;

  @Prop({ required: true })
  private meta!: any;

  @Prop({ required: true })
  private data!: any;

  @Prop({ required: true })
  private isActivityValid!: boolean;

  @Prop({ required: true })
  private assetsOverlap!: boolean;

  @Ref('video')
  private video!: InstanceType<typeof AssetVideo>;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private containsLabelInType = false;

  private get videoSrc() {
    return this.data[0].value;
  }

  private get showTextContainer() {
    return this.containsLabelInType && this.data[0].label;
  }

  private get videoPosterSrc() {
    // TODO: Ideally to have information from backend about media type
    // we can not pass any type without checking
    // if we pass some video as a poster, black screen will be displayed
    // if no poster will be passed, first frame of the video will be visible - default browser behaviour
    const fileName = this.videoSrc;

    return isCorrectFileExtension(['ogv', 'mp4', 'webm'], fileName)
      ? null
      : fileName;
  }

  private playOnAutoplay() {
    if (this.meta.autoplay && !this.assetsOverlap) {
      this.video.handlePlay(PlayingTypes.Default);
    }
  }

  private playOnQuestionAudioEnd() {
    if (this.meta.autoplay) {
      this.video.handlePlay(PlayingTypes.Default);
    }
  }

  public mounted() {
    const { hiddenComponentType } = this.meta;

    if (
      hiddenComponentType?.toLowerCase().includes('label') ||
      this.type.toLowerCase().includes('textcontainer')
    ) {
      this.containsLabelInType = true;
    }

    EventBus.$on(
      'activity-event-instruction-popup-closed',
      this.playOnAutoplay,
    );

    EventBus.$on(
      'activity-event-question-audio-end',
      this.playOnQuestionAudioEnd,
    );
  }

  public beforeDestroy() {
    EventBus.$off(
      'activity-event-instruction-popup-closed',
      this.playOnAutoplay,
    );

    EventBus.$off(
      'activity-event-question-audio-end',
      this.playOnQuestionAudioEnd,
    );
  }
}
</script>
