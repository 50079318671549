import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: 'โปรดเลือกเหตุผลก่อน!',
    title: 'กฏของชุมชน',
    popup: {
      title: 'ขอบคุณที่ช่วยดูแลชุมชนของเราให้เป็นชุมชนที่ปลอดภัย',
      btn: 'กลับคืนสู่แนวปฏิบัติของชุมชน',
    },
    pt1: {
      subtitle: 'ชุมชน DigiPals คืออะไร',
      text:
        'ชุมชน DigiPals เป็นชุมชนปลอดภัยที่เอื้ออำนวยให้เด็กๆมีโอกาสฝึกฝนภาษาอังกฤษและหาเพื่อนใหม่ได้',
    },
    pt2: {
      subtitle: 'กฏของชุมชนมีอะไรบ้าง',
      text1: 'ต้องเขียนเป็นภาษาอังกฤษเท่านั้นนะคะ',
      text2:
        'ห้ามเขียนข้อความที่มุ่งร้ายต่อผู้อื่น แสดงความก้าวร้าวหรือโหดร้าย ห้ามใข้คำสบถหรือคำด่าทั้งปวง',
      text3:
        'โปรดอย่าแชร์ข้อมูลส่วนตัวเข่น ชื่อ นามสกุล ที่อยู่ เบอร์โทรศัพท์ อีเมล์ address หรือข้อมูลรายละเอียดอื่นๆที่เกี่ยวกับบัญชีออนไลน์ หรือ social mediaอื่นๆ',
      text4: 'ข้อความทุกข้อความจะผ่านการตรวจสอบจากพนักงาน',
    },
    pt3: {
      subtitle: 'จะทำอย่างไรถ้าคิดว่ามีคนทำผิดกฏที่วางไว้',
      text1: 'การส่งรายงานนี้ช่วยให้ทุกคนในชุมชนมีความสุข',
      text2: 'เราจะติดต่อผู้ใหญ่ของคุณเพื่อหาข้อมูลเพิ่มเติมและช่วยเหลือคุณ',
    },
    btn: 'รายงานการกระทำผิด',

    option1: 'คิดว่ามีคนที่ไม่ได้เขียนเป็นภาษาอังกฤษ',
    option2: 'คิดว่ามีคนที่เขียนข้อความแสดงความโหดร้าย ก้าวร้าวต่อคนอื่น',
    option3: 'คิดว่ามีคนแชร์ข้อมูลส่วนตัว',
  },

  globe: {
    _: 'โลก',
    friendsMultiple: 'คุณมีเพื่อน {friends} คน',
    friends: 'คุณมีเพื่อน 1 คน',
    countries: 'ใน 1 ประเทศ',
    countriesMultiple: 'ใน {countries} ประเทศ',
    defaultTitle: 'ชุมชน DigiPal ทั่วโลกของเรา!',
    fetchFriend: 'หาเพื่อนใหม่เดี๋ยวนี้!',
  },

  profile: {
    avatar: {
      title: 'มาสร้าง avatar กันเถอะ',
    },
    error: 'อ๊ะ! บางอย่างผิดพลาด. คุณลองอีกครั้งได้ไหม',
    messagesEmpty:
      'ยังไม่มีข้อความที่นี่เลยนะ หนูไปที่รายชื่อของ DigiPals ที่แนะนำไว้ซิคะ เข้าไปดูโปรไฟล์แล้วเริ่มคุยกันได้เลย',
    postTitle: 'โพสต์จาก DigiPals ของฉัน',
    writePost: 'เขียน DigiPost',
    profileError: 'ไม่สามารถดึงข้อความโปรไฟล์',
    clock: 'มีกี่โมงที่ {username} อาศัยอยู่?',
    country: '{username}\nอาศัยอยู่ใน\n{country}',
    myCountry: 'ฉันอาศัยอยู่ใน\n{country}',
    myClock: 'ฉันอาศัยอยู่กี่โมง',
    friendsAccepted: 'คุณเป็นเพื่อนอยู่แล้ว!',
    friendsAdd: 'ส่งคำขอ digipal',
    friendsPending: 'คำขอ Digipal ที่รอดำเนินการ',
  },

  dictionary: {
    alphabet: {
      error: 'ไม่สามารถเรียกตัวอักษร',
    },
    words: {
      error: 'ไม่สามารถเรียกคำ',
    },
  },

  dates: {
    yearsAgo: '{count} ​​ปีที่แล้ว',
    yearAgo: 'เมื่อปีก่อน',
    monthsAgo: '{count} หลายเดือนก่อน',
    monthAgo: 'เมื่อ เดือนที่แล้ว',
    daysAgo: '{count} วันที่ผ่านมา',
    dayAgo: 'วันก่อน',
    hoursAgo: '{count} ชั่วโมงที่แล้ว',
    hourAge: 'ชั่วโมงที่ผ่านมา',
    minutesAgo: '{count} นาทีที่แล้ว',
    minuteAgo: 'เมื่อนาทีที่แล้ว',
    seconds: 'ไม่กี่วินาทีที่แล้ว',
  },

  common: {
    someone: 'บางคน',
  },

  digiPal: {
    messagesEmpty: 'ที่นี่ยังไม่มีข้อความนะคะ เชิญเขียนข้อความเป็นคนแรกเลย',
  },

  interests: {
    title: 'อะไรน่าสนใจ เลือกได้เลยค่ะ',
  },

  achievements: {
    title: 'มาดูกันว่าหนูได้ทำอะไรสำเร็จไปบ้างแล้ว',
    messageTrophy:
      'มารับถ้วยรางวัลกัน  ลุ้นให้ DigiPals ตอบกลับข้อความที่คุณส่งไป',
    activityTrophy:
      'หนูจะได้รับถ้วยรางวัลเมื่อทำกิจกรรมเสร็จนะคะ ดาวที่ได้รับทุกดวงสามารถรวมกันเป็นถ้วยรางวัลได้',
  },

  settings: {
    title: {
      1: 'เรียนภาษาอังกฤษแบบอังกฤษหรืออังกฤษแบบสหรัฐอเมริกา?',
      2: "รับ 'ความช่วยเหลือ' ใน?",
    },
  },

  digipals: {
    genericErrorMessage: 'อ๊ะ! บางอย่างผิดพลาด. คุณลองอีกครั้งได้ไหม',

    all: {
      title: 'DigiPals ของฉัน',
      emptyErrorMessage:
        "ตอนนี้หนูยังไม่มีเพื่อนที่ติดต่อกันได้ ไปที่ reccommended DigiPals' เพื่อหาเพื่อนใหม่นะคะ",
      successRequest:
        'หนูได้ส่งคำขอเป็นเพื่อนกับ {friend}คนนี้แล้วนะคะ เขียนข้อความบนโปรไฟล์เขาเพื่อบอกว่าทำไมถึงอยากเป็นเพื่อนด้วย',
      clock:
        'ตอนนี้เมืองที่ {digipal}อยู่กี่โมงแล้วนะ อย่าลืมว่า DigiPals บางคนอาจจะนอนหลับอยู่ก็ได้ ในทางกลับกัน เราอาจจะกำลังนอนอยู่เวลา {digipal} ตื่นก็ได้นะ',
    },
    mutual: {
      title: 'DigiPals ร่วมกัน',
      emptyErrorMessage: 'คุณไม่มีเพื่อนร่วมกัน',
    },
    pending: {
      title: 'คำขอของ DigiPal',
      emptyErrorMessage:
        "ตอนนี้ไม่มีคำขอเป็นเพื่อนที่ค้างอยู่เลยค่ะ หนูไปที่ 'Recommended DigiPals' เพื่ดหาเพื่อนใหม่นะคะ",
      successAccept:
        'ตอนนี้หนูได้เป็นเพื่อนกับ {friend}แล้วนะคะ ไปที่โปรไฟล์ของพวกเขาเพื่อทักทายเขาหน่อยค่ะ',
    },
    search: {
      title: 'ค้นหา DigiPal',
    },
    suggested: {
      title: 'ข้อความ Digipals ที่ออนไลน์ตอนนี้!',
      emptyErrorMessage: 'คุณไม่มีเพื่อนออนไลน์',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        'สวัสดี ฉันชื่อ Domino นะ จะช่วยหาเพื่อนจากทั่วทุกมุมโลกให้ ถ้าต้องการให้ช่วย ก็ให้ไปที่ "แถบเครื่องมือ"นะ นี่คือหน้า DigiPal โปรไฟล์ซึ่งจะบอกทุกสิ่งทุกอย่างที่เกี่ยวกับหนู เริ่มกันเลยไหมคะ',
      post: {
        reply: '{username} ตอบกลับข้อความที่ได้ส่งไป คุยต่อเลยค่ะ 😁',
        wallMessage: '{username} ได้ทิ้งข้อความในโปรไฟล์หนู คุยต่อเลยค่ะ 😁',
      },
      dominoAdd:
        'ยินดีด้วยค่ะ หนูใช้โปรแกรม 5 วันติดต่อกันแล้ว Domino จะขึ้นมาบน profile ของหนู',
      dominoRem:
        'หนูเสียประวัติการเรียนอย่างต่อเนื่องไปซะแล้ว ถ้าอยากจะได้ Domino คืนต้องมาเรียนให้ได้ 5 วันติดต่อกันนะคะ 😁',
    },
    messageDigipal: 'ข้อความ {username}',
    messageFrom: 'ข้อความจาก\n{userName}',
    friendRequest: 'ขอเป็นเพื่อน\n{userName}',
    requestAccepted: 'คำขอได้รับการยอมรับ\n{userName}',
    likeDash: '{userName}\nถูกใจเพจของคุณ',
  },

  popup: {
    activityLeaveConfirmation:
      'คุณแน่ใจหรือไม่ว่าต้องการออกจากกิจกรรม ความคืบหน้าของคุณจะไม่ถูกบันทึก',
    testLeaveConfirmation:
      'คุณแน่ใจหรือไม่ว่าต้องการออกจากการทดสอบ คุณจะไม่สามารถทำซ้ำได้อีก',
    streakPrefix: '{streak} สตรีควัน',
    streak0:
      'รับ 20 Lingos ต่อวันเพื่อเริ่มสตรีคของคุณ! เพื่อรับ Lingos ส่งข้อความถึงเพื่อนของคุณและทำกิจกรรมให้เสร็จสิ้น!',
    premiumGamesErrorMessage:
      'อ๊ะ! คุณไม่สามารถเข้าถึงเกมได้ ถามผู้ปกครองเกี่ยวกับการอัปเกรดบัญชีของคุณ!',
    lingosErrorMessage:
      'คุณต้องใช้ Lingos เพื่อซื้อสินค้าบางอย่าง ส่งข้อความเพื่อรับรางวัล Lingos',
    offlineErrorMessage: 'อ๊ะ! การเชื่อมต่ออินเทอร์เน็ตไม่ทำงาน',
    genericErrorMessage: 'อ๊ะ! บางอย่างผิดพลาด. คุณลองอีกครั้งได้ไหม',
    newsfeedErrorMessage: 'อ๊ะ! ไม่ได้ส่งข้อความของคุณ คุณลองอีกครั้งได้ไหม',
    avatarCustomizationMenu:
      'ถ้าอยาก customize Avatar (ตกแต่งตามที่พึงพอใจ) ให้ส่งข้อความเพื่อลุ้นรับ Lingos แล้ว ใช้ Lingos มาซื้ออัพเกรดได้เลยค่ะ',
    wallpaperSettings:
      'ปรับแต่งวอลเปเปอร์โปรไฟล์ของคุณ! คุณต้องใช้ Lingos เพื่อซื้อสินค้าบางอย่าง ทำกิจกรรมหรือส่งข้อความเพื่อรับรางวัล Lingos',
    interestsSettings:
      'แสดงงานอดิเรกและความชอบของคุณ! คุณต้องใช้ Lingos เพื่อซื้อสินค้าบางอย่าง ทำกิจกรรมหรือส่งข้อความเพื่อรับรางวัล Lingos',
    achievementsMenu: 'เช็คดูซิคะว่าหนูได้รับมากี่รางวัลแล้ว',
    globe: `หนูหา DigiPals ใหม่ได้จากทั่วโลกนะคะ เริ่มโดยเข้าไปดูโปรไฟล์ของพวกเขา แล้วส่งข้อความเพื่อลุ้นรับ Lingos แล้วใช้ ${htmlFeatures.plusIcon} เพื่อเชิญให้เขาเป็น DigiPal ของหนูเลย`,
    friendsSuggested:
      'อยากมีเพื่อน DigiPals ใหม่ไหมคะ มี DigiPals ที่เราแนะนำไว้ให้ แล้วนะ เชิญเขาเป็นเพื่อนเลยจะได้ติดต่อกันได้นะคะ',
    friends:
      'ไปที่โปรไฟล์ DigiPals ที่ติดต่อกันอยู่แล้วนะคะ แล้วส่งข้อความไปหาเพื่อลุ้นรับ Lingos',
    friendsPending:
      'มี DigiPals ที่อยากรู้จักคุณนะ ตอบรับคำเชิญเขาเลย แล้วส่งข้อความตอบเพื่อลุ้นรับ Lingos',
    friendsSearch: `ถ้ารู้ username ของใครบางคนก็ค้นหาเขาได้ที่นี่นะ ส่งข้อความไปถึงเขา แล้วลุ้นรับ Lingos เขิญเขาให้มาเป็น DigiPals หนูโดยใช้ ${htmlFeatures.plusIcon}`,
    login: `ไปที่หน้า "Recommended Friends" หรือไปที่ 'globe' เพื่อหาเพื่อนใหม่ได้เลย 😁`,
    profileLike: `หนูกดถูกใจโปรไฟล์ของ {username}ใช่มั้ย เขาต้องตื่นเต้นแน่ถ้ารู้แน่ๆ ส่งข้อความไปบอกเขาซิ เขาจะได้ดีใจ`,
    streakNotice:
      'เรียน (n) X-วันติดต่อกันซิคะ แล้ว Domino จะมาปรากฏในโปรไฟล์ของหนู',
    profilePage:
      'สวัสดี ฉันชื่อ Domino นะ จะช่วยหาเพื่อนจากทั่วทุกมุมโลกให้ ถ้าต้องการให้ช่วย ก็ให้ไปที่ "แถบเครื่องมือ"นะ นี่คือหน้า DigiPal โปรไฟล์ซึ่งจะบอกทุกสิ่งทุกอย่างที่เกี่ยวกับหนู เริ่มกันเลยไหมคะ',
  },

  activity: {
    rewards: {
      trophyTitle: 'คุณได้รับถ้วยรางวัล!',
      starTitle: 'คุณได้รับรางวัลดาว!',
      lingoTitle: 'คุณมี {lingos} lingos!',
      score: 'คะแนน',
      returnToActivities: 'กลับไปที่กิจกรรม',
    },
    currentLesson: 'บทเรียนปัจจุบัน',
    offlineMessage:
      'เอ่อโอ้! คุณสูญเสียการเชื่อมต่ออินเทอร์เน็ต ไม่ต้องกังวล เราได้บันทึกคะแนนกิจกรรมของคุณแล้ว!',
  },

  onboarding: {
    first: 'พบกับ DigiPals จากทั่วโลกและส่งข้อความเป็นภาษาอังกฤษ',
    second:
      'ต้องการความช่วยเหลือด้านภาษาอังกฤษหรือไม่? คุณสามารถเริ่มเรียนรู้ได้ที่นี่',
    third: 'สร้างอวาตาร์ของคุณ แสดงสิ่งที่คุณชอบ ค้นหา DigiPals อื่น ๆ',
    fourth:
      'เยี่ยมชม DigiPals ของคุณ ฝากข้อความ เข้าร่วมการแชท เชิญ DigiPals ส่งข้อความถึงคุณ',
    fifth:
      'รับรางวัล Lingos สำหรับข้อความและจบเกม ซื้อของสำหรับอวาตาร์ของคุณและสำหรับโดมิโน',
    sixth: 'รักษาสถิติสตรีคใน Little Bridge และรับ Domino สำหรับโปรไฟล์ของคุณ!',
    seventh: {
      title: 'พร้อมที่จะหาเพื่อน?',
      btn: `ไปกันเถอะ!`,
    },
  },
  tooltip: {
    toolbar: {
      findFriends: 'หาเพื่อน\nใหม่',
      home: 'บ้าน',
      activities: 'Domino Academy',
      dominoAcademy: 'Domino \n Academy',
      friends: 'เพื่อน',
      streak: 'ริ้ว',
      globe: 'โลก',
      information: 'ข้อมูล',
      menu: 'เมนู',
      lingos: 'ศัพท์แสง',
      notifications: 'การแจ้งเตือน',
      notificationsactive: 'การแจ้งเตือน',
      audio: 'คำถามซ้ำ',
      exit: 'ปิดกิจกรรม',
      snail: 'คำถามช้า',
      steps: 'คำถามช้า',
      fetchFriend: 'ค้นหาเพื่อนใหม่',
    },
    profile: {
      interests: 'ความสนใจ',
      digipals: 'digipals ของฉัน',
      achievements: 'ความสำเร็จของฉัน',
      editWallpaper: 'แก้ไขวอลล์เปเปอร์',
      addDigipal: 'เพิ่ม digipal',
      offline: 'ออฟไลน์',
      online: 'ออนไลน์',
      myAvatar: 'digipal ของฉัน',
      avatar: 'สัญลักษณ์',
      sendMessage: 'ส่งข้อความ',
      likeProfile: 'ชอบโปรไฟล์',
      digipalLikes: 'เช่นโปรไฟล์นี้',
      myFriends: 'เพื่อนของฉัน',
      myLikes: 'ชอบของฉัน',
      alreadyLiked: 'คุณชอบโปรไฟล์นี้แล้ว',
      myMessages: 'ข้อความของฉัน',
      requestPending: 'คำขอ DigiPal อยู่ระหว่างดำเนินการ',
      weAreDigipals: 'พวกเราคือ DigiPals!',
    },
    messages: {
      likes: 'ชอบ',
      send: 'ส่ง',
      saySomething: 'พูดอะไรสักอย่าง',
      askSomething: 'ถามอะไรบางอย่าง',
      emoji: 'อีโมจิ',
      reply: 'ตอบกลับ',
    },
    activities: {
      supportLanguage: 'คำสั่งภาษาไทย',
      teachingLanguage: 'คำแนะนำภาษาอังกฤษ',
      next: 'ถัดไป',
      previous: 'ก่อนหน้า',
      play: 'เล่น',
      slowPlay: 'ช้า',
      record: 'บันทึก',
      playback: 'การเล่น',
    },
    other: {
      back: 'กลับ',
      getItem: 'รับรายการ',
      zoomIn: 'ขยายเข้า',
      zoomOut: 'ซูมออก',
    },
  },

  conversations: {
    sendMessage: 'ส่งข้อความ',
    myInbox: 'กล่องจดหมายของฉัน',
    send: 'สส่งข้อความ',
    yourChat:
      'การสนทนาของคุณด้วย <p class="conversation__header__subtitle">{username}</p>',
    noMessages: 'ที่นี่ยังไม่มีข้อความนะคะ เชิญเขียนข้อความเป็นคนแรกเลย',
  },

  messageRewards: {
    title: 'คุณส่งข้อความถึง {ชื่อผู้ใช้}!',
    returnToHome: 'กลับไปที่หน้าโปรไฟล์',
  },
};
