<template>
  <header class="app__supheader">
    <!-- @slot Default slot content -->
    <slot />
  </header>
</template>

<style lang="scss" scoped>
.app__supheader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 15px;
  height: 60px;

  background: $white100;
  box-shadow: 1.5px 0 1.5px 0 rgba(0, 0, 0, 0.3);

  position: sticky;
  top: 55px;
  left: 0;

  width: 100%;
  height: 33px;

  z-index: 6;

  @media (min-width: #{$mobile}px) {
    height: 60px;
    top: 80px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    flex: 1;

    font-size: 43px;
    font-weight: 700;
    color: #1b75bb;
    line-height: 1;
    text-align: center;

    @media (max-width: #{$mobile}px) {
      font-size: 24px;
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppSupheader extends Vue {}
</script>

<docs>
```jsx
<AppSupheader>
  Content
</AppSupheader>
```
</docs>
