<template>
  <button class="navigate-back" @click="$emit('click')">
    Go back
    <img
      v-tooltip="{
        content: this.$t('tooltip.other.back').toString(),
      }"
      src="@/assets/images/common/back.png"
      class="navigate-back__icon"
      alt="Back Icon"
    />
  </button>
</template>

<style lang="scss" scoped>
.navigate-back {
  // Hide label:
  font-size: 0;

  &__icon {
    width: 25px;
    height: 25px;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NavigateBack extends Vue {}
</script>

<docs>
### Simple component that emits an event on button click

```vue
<template>
  <div style="background: orange; padding: 10px;">
    <NavigateBack @click="onClick" />
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      alert('clicked')
    }
  }
}
</script>
```
</docs>
