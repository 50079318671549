export enum NotificationType {
  Lingo = 'lingo',
  FriendRequest = 'frequest',
  FriendAccept = 'faccept',
  DominoAdd = 'domino-add',
  DominoRem = 'domino-rem',
  Referral = 'referral',
  LikeProfile = 'like-profile',
  StudyBuddy = 'studybuddy',
  Post = 'post',
}
