import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: "Veuillez d'abord sélectionner une raison!",
    title: 'Règles de la communauté',
    popup: {
      title: 'Merci de contribuer à assurer la sécurité de notre communauté!',
      btn: 'Revenir au règlement de la communauté',
    },
    pt1: {
      subtitle: "Qu'est-ce que la communauté DigiPals?",
      text:
        "Notre communauté DigiPals est un endroit sûr pour vous faire des amis et pratiquer l'anglais.",
    },
    pt2: {
      subtitle: 'Quelles sont les règles de la communauté?',
      text1: 'Écrivez toujours en anglais',
      text2:
        "N'écrivez rien de désagréable, offensant ou méchant. Il est interdit de jurer!",
      text3:
        'Ne partagez aucun détail personnel, par ex. Votre nom complet, adresses, numéros de téléphone, adresses e-mail ou autres détails de votre compte en ligne / sur les réseaux sociaux',
      text4: 'Tous les messages sont examinés par de vraies personnes.',
    },
    pt3: {
      subtitle:
        "Que dois-je faire si je pense que quelqu'un enfreint une règle?",
      text1:
        "L'envoi de ce rapport nous aide à satisfaire tout le monde dans notre communauté.",
      text2: 'Nous contacterons votre adulte pour en savoir plus et vous aider',
    },
    btn: 'Envoyez un rapport',

    option1: "Je pense que quelqu'un n'écrit pas en anglais",
    option2: "Je pense que quelqu'un est méchant",
    option3: "Je pense que quelqu'un a partagé des informations personnelles",
  },

  globe: {
    _: 'Globe',
    friendsMultiple: 'Vous avez {friends} amis',
    friends: 'Vous avez 1 ami',
    countries: 'dans 1 pays',
    countriesMultiple: 'dans {countries} pays',
    defaultTitle: 'Notre communauté mondiale DigiPal!',
    fetchFriend: 'Cherchez un nouvel ami maintenant !',
  },

  profile: {
    avatar: {
      title: 'Faire mon avatar',
    },
    error: 'Zut! Un problème est survenu. Pouvez-vous réessayer?',
    messagesEmpty:
      "Zut! Il n'y a pas encore de messages ici. Accédez à votre liste de DigiPals recommandés, visitez leurs profils et lancez une conversation!",
    postTitle: 'Messages de mes DigiPals',
    writePost: 'Écrire un DigiPost',
    profileError: 'Échec de la récupération des messages de profil.',
    clock: "Quelle heure\nest-il là où vit nom\nd'utilisateur {username}?",
    country: '{username}\nvit dans\n{country}',
    myCountry: 'Je visdans\n{country}',
    myClock: "Quelle heure\nest-il là où\nj'habite?",
    friendsAccepted: 'Vous êtes déjà amis!',
    friendsAdd: 'Envoyer une demande digipale',
    friendsPending: 'Demande de digipale en attente',
  },

  dictionary: {
    alphabet: {
      error: "Échec de la récupération de l'alphabet",
    },
    words: {
      error: 'Échec de la récupération des mots',
    },
  },

  dates: {
    yearsAgo: 'il y a {count} ans',
    yearAgo: 'il y a un an',
    monthsAgo: 'il y a {count} mois',
    monthAgo: 'il y a un mois',
    daysAgo: 'il y a {count} jours',
    dayAgo: 'il y a un jour',
    hoursAgo: 'il y a {count} heures',
    hourAge: 'il y a une heure',
    minutesAgo: 'il y a {count} minutes',
    minuteAgo: "Il y'a une minute",
    seconds: 'il ya quelques secondes',
  },

  common: {
    someone: "Quelqu'un",
  },

  digiPal: {
    messagesEmpty: "Il n'y a pas de messages ici! Écrivez le premier!",
  },

  interests: {
    title: 'Choisissez mes goûts',
  },

  achievements: {
    title: 'Voir mes réalisations',
    messageTrophy:
      'Obtenez des trophées en envoyant des messages à vos DigiPals! Seuls les messages qui reçoivent une réponse compteront pour vos trophées.',
    activityTrophy:
      'Obtenez des trophées en accomplissant des activités! Chaque étoile que vous gagnez comptera pour vos trophées.',
  },

  settings: {
    title: {
      1: "Apprendre l'anglais britannique ou l'anglais américain?",
      2: "Obtenez de l'aide?",
    },
  },

  digipals: {
    genericErrorMessage: 'Zut! Un problème est survenu. Pouvez-vous réessayer?',

    all: {
      title: 'Mes DigiPals',
      emptyErrorMessage:
        "Vous n'avez pas encore d'amis connectés. Allez sur «DigiPals recommandés» pour commencer à vous faire des amis!",
      successRequest:
        "Vous avez envoyé à {friend} une demande d'amitié. Laissez un message sur leur profil pour leur dire pourquoi vous aimeriez être amis!",
      clock:
        "Quelle heure est-il où {digipal} habite? N'oubliez pas que certains DigiPals peuvent être endormis quand il fait jour pour vous!",
    },
    mutual: {
      title: 'DigiPals mutuels',
      emptyErrorMessage: "Vous n'avez pas d'amis communs.",
    },
    pending: {
      title: 'Demandes DigiPal',
      emptyErrorMessage:
        "Vous n'avez pas encore de demandes en attente. Allez sur «DigiPals recommandés» pour commencer à vous faire des amis!",
      successAccept:
        'Vous êtes maintenant ami avec {friend}. Visitez leur profil pour dire bonjour!',
    },
    search: {
      title: 'Rechercher un DigiPal',
    },
    suggested: {
      title: 'DigiPals de message qui sont en ligne maintenant!',
      emptyErrorMessage: "Vous n'avez pas d'amis en ligne.",
    },
  },

  notifications: {
    popups: {
      firstLogin:
        "Bonjour! Je suis Domino. Je vais vous aider à vous faire des amis partout dans le monde! Si vous avez besoin d'aide, vous pouvez me trouver dans la barre d'outils. Ceci est votre page de profil DigiPal. Cela dit à tout le monde quelque chose sur vous. Commencez à explorer!",
      post: {
        reply:
          '{username} a répondu à votre message! Poursuivez la conversation 😁',
        wallMessage:
          '{username} a laissé un message sur votre profil! Poursuivez la conversation 😁',
      },
      dominoAdd:
        'Toutes nos félicitations! Vous avez réalisé une séquence! Maintenant, Domino apparaîtra sur votre profil!',
      dominoRem:
        'Oh non! Vous avez perdu votre séquence. Gagnez 20 lingos pour récupérer Domino sur votre profil :)',
    },
    messageDigipal: 'Message {username}',
    messageFrom: 'message de\n{userName}',
    friendRequest: "Demande d'ami\n{userName}",
    requestAccepted: 'Requête acceptée\n{userName}',
    likeDash: '{userName}\naimé votre page',
  },

  popup: {
    activityLeaveConfirmation: `Êtes-vous sûr de vouloir quitter l'activité? Votre progression ne sera pas enregistrée.`,
    testLeaveConfirmation:
      'Êtes-vous sûr de vouloir quitter le test ? Vous ne pourrez plus le refaire.',
    streakPrefix: '{streak} jours consécutifs.',
    streak0:
      'Gagnez 20 lingos par jour pour commencer votre série! Pour gagner des Lingos, envoyez des messages à vos amis et terminez des activités!',
    premiumGamesErrorMessage:
      'Zut! Vous n’avez pas accès aux jeux. Demandez à vos parents de mettre à jour votre compte!',
    lingosErrorMessage:
      'Vous avez besoin de Lingos pour acheter certains articles. Envoyez des messages pour gagner des Lingos.',
    offlineErrorMessage: 'Zut! La connexion Internet ne fonctionne pas.',
    genericErrorMessage:
      "Zut! Quelque chose s'est mal passé. Veux-tu encore essayer ?",
    newsfeedErrorMessage:
      "Zut! Votre message n'a pas été envoyé. Pouvez-vous réessayer?",
    avatarCustomizationMenu:
      'Personnalisez votre avatar! Vous avez besoin de Lingos pour acheter certains articles. Terminez des activités ou envoyez des messages pour gagner des Lingos.',
    wallpaperSettings:
      "Personnalisez votre fond d'écran de profil! Vous avez besoin de Lingos pour acheter certains articles. Terminez des activités ou envoyez des messages pour gagner des Lingos.",
    interestsSettings:
      'Montrez vos passe-temps et vos goûts! Vous avez besoin de Lingos pour acheter certains articles. Terminez des activités ou envoyez des messages pour gagner des Lingos.',
    achievementsMenu: 'Voyez combien de récompenses vous avez gagnées!',
    globe: `Trouvez de nouveaux DigiPals dans le monde entier. Visitez leur profil, laissez-leur un message pour gagner des Lingos. Utilisez ${htmlFeatures.plusIcon} pour les inviter à devenir votre DigiPal.`,
    friendsSuggested:
      "Faites-vous des amis avec d'autres DigiPals! Vous avez des DigiPals recommandés, rien que pour vous! Invitez-les à se connecter avec vous.",
    friends:
      'Voir les DigiPals auxquels vous êtes connecté. Visitez leurs profils, laissez-leur un message et gagnez des Lingos.',
    friendsPending:
      'Voir DigiPals qui veulent se connecter avec vous. Acceptez leur invitation et accédez à leur profil pour laisser un message pour gagner des Lingos.',
    friendsSearch: `Connaissez-vous le nom DigiPal de quelqu'un? Recherchez-les ici et accédez à leur profil pour laisser un message et gagner plus de Lingos. Utilisez $ {htmlFeatures.plusIcon} pour les inviter à devenir votre DigiPal`,
    login:
      "Se faire des amis, c'est génial! Rendez visite à vos `` amis recommandés '' ou visitez le monde pour vous faire de nouveaux amis 😁",
    profileLike: `Vous avez aimé la page de profil de {username}! Ils seront super heureux. Laissez-leur un message pour leur dire pourquoi vous l'avez aimé!`,
    streakNotice:
      'Vous voulez un animal Domino cool et personnalisable? Pour obtenir Domino, gagnez 20 lingos chaque jour. Ceci est votre «séquence».',
    profilePage:
      "Bonjour! Je suis Domino. Je vais vous aider à vous faire des amis partout dans le monde! Si vous avez besoin d'aide, vous pouvez me trouver dans la barre d'outils. Ceci est votre page de profil DigiPal. Cela dit à tout le monde quelque chose sur vous. Commencez à explorer!",
  },

  activity: {
    rewards: {
      trophyTitle: 'Vous avez gagné un trophée!',
      starTitle: 'Vous avez gagné un trophée!',
      lingoTitle: 'Vous avez {lingos} lingos!',
      score: 'But',
      returnToActivities: 'Retour aux activités',
    },
    currentLesson: 'Leçon en cours',
    offlineMessage:
      "Oh oh! Vous avez perdu votre connexion Internet. Ne vous inquiétez pas, nous avons enregistré votre score d'activité!",
  },

  onboarding: {
    first:
      'Rencontrez des DigiPals du monde entier et envoyez-leur un message en anglais.',
    second:
      "Besoin d'aide avec l'anglais? Vous pouvez commencer à apprendre ici.",
    third: "Créez votre avatar. Affichez vos goûts. Trouvez d'autres DigiPals.",
    fourth:
      'Visitez vos DigiPals. Laissez des messages. Rejoignez les chats. Invitez DigiPals à vous envoyer un message.',
    fifth:
      'Gagnez Lingos pour les messages et terminer les jeux. Achetez des objets pour votre avatar et pour Domino.',
    sixth:
      'Gardez une séquence dans Little Bridge et obtenez Domino pour votre profil!',
    seventh: {
      title: 'Prêt à vous faire des amis?',
      btn: `Allons-y!`,
    },
  },
  tooltip: {
    toolbar: {
      findFriends: 'Trouver de\nnouveaux\namis',
      home: 'Domicile',
      activities: 'Académie Domino',
      dominoAcademy: 'Académie\nDomino',
      friends: 'copains',
      streak: 'Traînée',
      globe: 'Globe',
      information: 'Info',
      menu: 'Menu',
      lingos: 'Lingoches',
      notifications: 'Notifications',
      notificationsactive: 'Notifications',
      audio: 'Répéter la question',
      exit: 'Activité proche',
      snail: 'Question lente',
      steps: 'Continuez',
      fetchFriend: 'Trouver de nouveaux amis',
    },
    profile: {
      interests: 'Intérêts',
      digipals: 'Mes digipaux',
      achievements: 'Mes réalisations',
      editWallpaper: 'Modifier le papier peint',
      addDigipal: 'Ajouter digipal',
      offline: 'Hors ligne',
      online: 'En ligne',
      myAvatar: 'Mon Digipal',
      avatar: 'Avatar',
      sendMessage: 'Envoyer le message',
      likeProfile: "J'aime le profil",
      myFriends: 'Mes amies',
      myLikes: 'Mes goûts',
      digipalLikes: 'Comme ce profil',
      myMessages: 'Mes messages',
      alreadyLiked: 'Vous aimez déjà ce profil',
      requestPending: 'Demande DigiPal en attente',
      weAreDigipals: 'Nous sommes des DigiPals !',
    },
    messages: {
      likes: 'Aime',
      send: 'Envoyer',
      saySomething: 'Dis quelquechose',
      askSomething: 'Demander quelque chose',
      emoji: 'Emoji',
      reply: 'Répondre',
    },
    activities: {
      supportLanguage: 'Instructions en français',
      teachingLanguage: 'Instructions en anglais',
      next: 'Suivant',
      previous: 'Précédent',
      play: 'Jouer',
      slowPlay: 'Lent',
      record: 'Enregistrement',
      playback: 'Relecture',
    },
    other: {
      back: 'Retour',
      getItem: "Obtenir l'article",
      zoomIn: 'Agrandir',
      zoomOut: 'Dézoomer',
    },
  },

  conversations: {
    sendMessage: 'Envoyer un message',
    myInbox: 'Ma boîte de réception',
    send: 'Envoyer le message',
    yourChat:
      'Votre conversation avec <p class="conversation__header__subtitle">{username}</p>',
    noMessages: 'Il n’y a pas de messages ici ! Écris le premier!',
  },

  messageRewards: {
    title: 'Vous avez envoyé un message à {username}!',
    returnToHome: 'Retour à la page de profil',
  },
};
