<template>
  <RoundedFooter
    :tag="windowWidth > 765 ? 'aside' : 'footer'"
    class="bottom-toolbar"
  >
    <FetchFriend class="bottom-toolbar__fetch">
      <span class="bottom-toolbar__item-text">
        {{ $t('tooltip.toolbar.findFriends') }}
      </span>
    </FetchFriend>

    <StudyBuddyTrigger
      v-if="isAppFeatureEnabled('STUDDY_BUDDY')"
      class="bottom-toolbar__item bottom-toolbar__item--info"
    >
      <span class="bottom-toolbar__item-text">
        {{ $t('tooltip.toolbar.information') }}
      </span>
    </StudyBuddyTrigger>

    <button
      v-if="isAppFeatureEnabled('SCREEN_GLOBE')"
      v-tooltip="{
        content: $t(
          `tooltip.toolbar.${AppToolbarsIconsNames.Globe}`,
        ).toString(),
      }"
      type="button"
      class="bottom-toolbar__item bottom-toolbar__item--globe"
      @click="handleSelection(AppToolbarsIconsNames.Globe)"
    >
      <span class="bottom-toolbar__item-text">
        {{ $t('globe._') }}
      </span>

      <img src="/images/icons/globe.png" alt="Globe Icon" />
    </button>

    <button
      v-if="isAppFeatureEnabled('SCREEN_TRAINING')"
      v-tooltip="{
        content: $t(
          `tooltip.toolbar.${AppToolbarsIconsNames.Activities}`,
        ).toString(),
      }"
      :disabled="!hasPremiumAccount"
      type="button"
      class="bottom-toolbar__item bottom-toolbar__item--domino"
      @click="handleSelection(AppToolbarsIconsNames.Activities)"
    >
      <span class="bottom-toolbar__item-text">
        {{ $t('tooltip.toolbar.dominoAcademy') }}
      </span>

      <img src="/images/icons/domino.png" alt="Domino Icon" />
    </button>

    <button
      v-if="isAppFeatureEnabled('SCREEN_PROFILE')"
      v-tooltip="{
        content: $t(`tooltip.toolbar.${AppToolbarsIconsNames.Home}`).toString(),
      }"
      type="button"
      class="bottom-toolbar__item bottom-toolbar__item--home"
      @click="handleSelection(AppToolbarsIconsNames.Home)"
    >
      <span class="bottom-toolbar__item-text">
        {{ $t('tooltip.toolbar.home') }}
      </span>

      <img src="/images/icons/home.png" alt="Home Icon" />
    </button>
  </RoundedFooter>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import amplitude from 'amplitude-js';

import AppFeatures from '@/utils/mixins/AppFeatures';
import sounds from '@/utils/helpers/sounds';

import StudyBuddyTrigger from '@/components/StudyBuddyTrigger/StudyBuddyTrigger.vue';
import FetchFriend from '@/components/FetchFriend/FetchFriend.vue';
import RoundedFooter from '@/components/RoundedFooter/RoundedFooter.vue';

import { AppToolbarsIconsNames } from '@/models/enums/toolbars';
import { IPopupData } from '@/models/interfaces/popup';

import routeNames from '@/router/route-names';
import { eventCodeByName, EventsNames } from '@/analytics/events';

const ROUTABLE_ICONS = [
  AppToolbarsIconsNames.Home,
  AppToolbarsIconsNames.Activities,
  AppToolbarsIconsNames.Globe,
  AppToolbarsIconsNames.Friends,
];
const ROUTES_NAMES_VALUES: Partial<Record<AppToolbarsIconsNames, string>> = {
  [AppToolbarsIconsNames.Home]: routeNames.myProfile,
  [AppToolbarsIconsNames.Activities]: routeNames.trainingMenu,
  [AppToolbarsIconsNames.Globe]: routeNames.globe,
  [AppToolbarsIconsNames.Friends]: routeNames.friends,
};

@Component({
  components: {
    StudyBuddyTrigger,
    FetchFriend,
    RoundedFooter,
  },
})
export default class AppBottomToolbar extends Mixins(AppFeatures) {
  @Getter('hasPremiumAccount', { namespace: 'profile' })
  public hasPremiumAccount!: boolean;

  @State('hasUnseenNotifications', { namespace: 'notifications' })
  public hasUnseenNotifications!: boolean;

  @Mutation('togglePopup')
  public togglePopup!: (data?: IPopupData) => void;

  @Mutation('toggleRootDropdownMenuVisibility')
  public setDropdownMenuVisibility!: (state: boolean) => void;

  public AppToolbarsIconsNames = AppToolbarsIconsNames;

  public handleSelection(name: AppToolbarsIconsNames) {
    const isActivitySelected = name === AppToolbarsIconsNames.Activities;

    isActivitySelected
      ? this.handlePremiumNavigation()
      : this.handleNavigation();

    if (!this.hasPremiumAccount && isActivitySelected) {
      return;
    }

    if (ROUTABLE_ICONS.includes(name)) {
      this.$router.push({ name: ROUTES_NAMES_VALUES[name] });
    }
  }

  public handlePremiumNavigation() {
    if (this.hasPremiumAccount) {
      this.handleNavigation();
    } else {
      this.togglePopup({
        message: this.$t('popup.premiumGamesErrorMessage').toString(),
        closable: false,
        onOk: () => {
          amplitude
            .getInstance()
            .logEvent(
              `${eventCodeByName(EventsNames.UserTapsOkBtnOnPaywall)} ${
                EventsNames.UserTapsOkBtnOnPaywall
              }`,
            );
        },
      });
    }
  }

  public handleNavigation() {
    sounds.pop.play();

    this.setDropdownMenuVisibility(false);
  }
}
</script>

<style lang="scss" scoped>
.bottom-toolbar {
  position: fixed;
  width: 100%;
  height: 100px;

  bottom: 0;
  left: 0;
  z-index: 19;

  display: flex;
  align-items: flex-end;

  user-select: none;

  @media (min-width: #{$tablet}px) {
    bottom: unset;
    top: 80px;

    width: 185px;
    height: calc(100vh - 80px);

    flex-direction: column;

    background-image: url('/images/icons/side-footer.png');
  }

  &__fetch {
    margin-bottom: 15px;
    margin-right: 10px;

    order: 3;

    .bottom-toolbar__item-text {
      width: 220px;

      background: $pink200;
    }

    @media (min-width: #{$tablet}px) {
      margin-bottom: 25px;
      margin-right: -50px;

      width: 100px;
      height: 100px;
    }
  }

  &__item-text {
    display: none;

    @media (min-width: #{$tablet}px) {
      display: block;
      white-space: pre-line;

      position: absolute;
      top: 0;
      right: 0;

      height: 100%;
      border-radius: 60px;
      z-index: -1;

      background: $blue400;
      opacity: 0.5;

      color: $white100;
      font-size: 18px;
      text-align: left;
      font-weight: 700;

      display: flex;
      align-items: center;

      padding-left: 15px;
    }
  }

  &__item {
    position: relative;

    border-radius: 100%;
    background: $blue400;
    box-shadow: 0px 2px 0px $blue100;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 10px;
    margin-right: 10px;

    img {
      height: 70%;
    }

    &--info {
      order: 1;

      @media (min-width: #{$tablet}px) {
        order: 5;
      }
    }

    &--globe {
      order: 2;
    }

    &--domino {
      order: 4;
    }

    &--home {
      order: 5;

      @media (min-width: #{$tablet}px) {
        order: 1;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(4) {
      width: 33px;
      height: 33px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 50px;
      height: 50px;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: #{$tablet}px) {
      margin-bottom: 25px;
      margin-right: 0;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        width: 80px;
        height: 80px;
      }

      &:nth-of-type(1),
      &:nth-of-type(4) {
        margin-right: -15px;

        .bottom-toolbar__item-text {
          width: 185px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-right: -30px;

        .bottom-toolbar__item-text {
          width: 200px;
        }
      }
    }
  }
}
</style>
