import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import EventBus from '@/utils/helpers/EventBus';
import AssetVideo from '@/components/Asset/Video.vue';
import { PlayingTypes } from '@/models/enums/video';

@Component
export default class VideoMixin extends Vue {
  @Prop({ required: true })
  private assetsOverlap!: boolean;

  @Ref('video')
  private video!: InstanceType<typeof AssetVideo>;

  private play() {
    if (!this.assetsOverlap) {
      this.video.handlePlay(PlayingTypes.Default);
    }
  }

  private playOnQuestionAudioEnd() {
    this.video.handlePlay(PlayingTypes.Default);
  }

  public mounted() {
    EventBus.$on('activity-event-instruction-popup-closed', this.play);
    EventBus.$on(
      'activity-event-question-audio-end',
      this.playOnQuestionAudioEnd,
    );
    EventBus.$on('activity-event-next-question', this.play);
  }

  public beforeDestroy() {
    EventBus.$off(
      'activity-event-question-audio-end',
      this.playOnQuestionAudioEnd,
    );
    EventBus.$on('activity-event-instruction-popup-closed', this.play);
    EventBus.$off('activity-event-next-question', this.play);
  }
}
