<template>
  <div
    ref="container"
    :style="{
      // We cannot use 100vh/100vw due to mobile browsers (iOS/Android bug):
      width: isFullscreen ? `${windowWidth}px` : '100%',
      height: isFullscreen ? `${windowHeight}px` : '100%',
    }"
    :class="{
      video: true,
      'video--fullscreen': isFullscreen,
      'video--fullsize': fullsize,
    }"
  >
    <video
      ref="video"
      playsinline
      preload="auto"
      v-bind="$attrs"
      :class="{
        video__asset: true,
      }"
      :poster="poster"
      @loadeddata="onLoadedData"
      @ended="onVideoEnd"
    >
      <source type="video/mp4" :src="videoSource" />

      Sorry, your browser doesn't support embedded videos.
    </video>

    <slot />

    <div
      v-if="areControlsVisible"
      ref="progressbar"
      :class="{
        video__progressbar: true,
      }"
    >
      <div
        class="video__progressbar-currenttime"
        :style="{
          width: `${percentage}%`,
        }"
      ></div>
    </div>

    <div v-if="areControlsVisible" class="video__buttons">
      <button
        v-if="!playingStates[PlayingTypes.Default]"
        data-default-play-button
        class="video__play-pause"
        @click="handlePlay(PlayingTypes.Default)"
      >
        <i class="fas fa-play"></i>
      </button>

      <button
        v-else
        data-default-pause-button
        class="video__play-pause"
        @click="handlePause(PlayingTypes.Default)"
      >
        <i class="fas fa-pause"></i>
      </button>

      <button
        v-if="!playingStates[PlayingTypes.Slow]"
        data-slow-play-button
        class="video__play-slow"
        @click="handlePlay(PlayingTypes.Slow)"
      >
        <img src="@/assets/images/activities/snail.png" alt="Snail" />
      </button>

      <button
        v-else
        data-slow-pause-button
        class="video__play-slow"
        @click="handlePause(PlayingTypes.Slow)"
      >
        <i class="fas fa-pause"></i>
      </button>

      <button class="video__fullscreen" @click="fullscreen(!isFullscreen)">
        <i v-if="!isFullscreen" class="fas fa-expand"></i>
        <i v-else class="fas fa-compress"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';
import getObjectFitSize from '@/utils/helpers/getObjectFitSize';

import { fsHash } from './constants';
import { PlayingTypes } from '@/models/enums/video';

@Component
export default class AssetVideo extends Vue {
  @Prop({ required: true })
  private src!: string;

  @Prop({ default: 'video' })
  private type!: string;

  @Prop({ default: '' })
  private poster!: string;

  @Prop({ default: true })
  private controls!: boolean;

  @Prop({ default: false })
  private triggerDurationEnd!: boolean;

  @Prop({ default: false })
  private fullsize!: boolean;

  @Prop({ default: false })
  private autoplay!: boolean;

  @Prop({ default: false })
  private playSlow!: boolean;

  public $refs!: {
    video: HTMLVideoElement;
    container: HTMLDivElement;
    progressbar: HTMLDivElement;
  };

  public PlayingTypes = PlayingTypes;

  public playingStates = {
    [PlayingTypes.Default]: false,
    [PlayingTypes.Slow]: false,
  };

  public pauseStates = {
    [PlayingTypes.Default]: false,
    [PlayingTypes.Slow]: false,
  };

  private percentage = 0;
  private isFullscreen = false;
  private isDragging = false;
  private resizeTimeout = 0;

  private get getComputedFullscreenStyles() {
    return {
      width: this.isFullscreen ? `${this.windowWidth}px` : 'auto',
      height: this.isFullscreen ? `${this.windowHeight}px` : 'auto',
    };
  }

  private get areControlsVisible() {
    return this.controls && !this.poster;
  }

  private get videoSource() {
    // Show first frame of video as poster on mobiles, if there's no poster:
    // @see https://stackoverflow.com/a/50386123
    return this.poster ? this.src : `${this.src}#t=0.1`;
  }

  public handlePlay(type: PlayingTypes) {
    EventBus.$emit('activity-event-stop-media');

    const { video } = this.$refs;
    const typeToReset =
      type === PlayingTypes.Slow ? PlayingTypes.Default : PlayingTypes.Slow;
    this.pauseStates[typeToReset] = false;

    if (this.pauseStates[type]) {
      video.play();
      this.playingStates[type] = true;
      this.pauseStates[type] = false;
      return;
    }

    const rates: Record<PlayingTypes, number> = {
      [PlayingTypes.Slow]: 0.5,
      [PlayingTypes.Default]: 1,
    };

    video.playbackRate = rates[type];
    video.currentTime = 0;
    video.play();

    this.playingStates[typeToReset] = false;
    this.playingStates[type] = true;
  }

  private handlePause(type: PlayingTypes) {
    this.$refs.video.pause();
    this.playingStates[type] = false;

    this.pauseStates[type] = true;
  }

  private fullscreen(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;

    this.$emit('on-fullscreen', isFullscreen);
    this.$nextTick(() => this.positionHotspots());

    EventBus.$emit('activity-event-video-fullscreen', isFullscreen);
  }

  private onVideoEnd(event: any) {
    // Propagate event further:
    this.$emit('ended', event);

    if (this.triggerDurationEnd) {
      EventBus.$emit('activity-event-video-eneded');
    }
  }

  private onLoadedData(event: any) {
    // Propagate event further:
    this.$emit('loadeddata', event);
    this.onResize();

    if (this.autoplay) {
      this.handlePlay(this.playSlow ? PlayingTypes.Slow : PlayingTypes.Default);
    }
  }

  private onDragEnd() {
    this.isDragging = false;
  }

  private onDragStart() {
    this.isDragging = true;
  }

  private onDragMove(event: any) {
    const { video, progressbar } = this.$refs;

    if (this.isDragging && progressbar) {
      const { x, width } = progressbar.getBoundingClientRect();

      const clickPosition = event.clientX;

      const videoPosition = x;
      const videoDimension = width;

      const progressbarClick = clickPosition - videoPosition;

      // Boundaries:
      const minValue = Math.min(progressbarClick, videoDimension);
      const maxValue = Math.max(0, minValue);

      const ratio = maxValue / videoDimension;

      if (Number.isFinite(ratio)) {
        video.pause();
        video.currentTime = video.duration * ratio;

        this.percentage = (100 * video.currentTime) / video.duration;
      }
    }
  }

  private positionHotspots() {
    if (this.type === 'disappearingVideo') {
      return;
    }

    const { video, container } = this.$refs;
    if (!video || !container) {
      return;
    }
    const { left: extraLeft } = container.getBoundingClientRect();

    const assetSize = getObjectFitSize(
      container.clientWidth,
      container.clientHeight,
      video.videoWidth,
      video.videoHeight,
    );

    const positioningData = {
      ...assetSize,
      top: (container.clientHeight - assetSize.height) / 2,
      left: (container.clientWidth - assetSize.width) / 2 + extraLeft,
      position: this.isFullscreen ? 'fixed' : 'absolute',
    };

    EventBus.$emit('set-assets-hotspots-container-positioning', {
      ...positioningData,
      controls: this.controls,
      video: true,
    });
  }

  private onHashChange(e: Event) {
    const event = e as HashChangeEvent;

    if (event.oldURL.includes(fsHash)) {
      this.fullscreen(false);
    } else {
      if (process.env.NODE_ENV !== 'styleguide') {
        this.$router.back();
      }
    }
  }

  public mounted() {
    window.addEventListener('hashchange', this.onHashChange);
    EventBus.$on('activity-event-stop-media', () => {
      this.handlePause(PlayingTypes.Default);
    });

    const { video, progressbar } = this.$refs;
    const states = this.playingStates;

    video.addEventListener('play', function videoPlay() {
      states[PlayingTypes.Default] = true;

      video.removeEventListener('play', videoPlay);
    });

    video.addEventListener('ended', function videoPlay() {
      states[PlayingTypes.Default] = false;
      states[PlayingTypes.Slow] = false;
    });

    video.addEventListener('timeupdate', () => {
      this.percentage = (100 * video.currentTime) / video.duration;
    });

    if (progressbar) {
      progressbar.addEventListener('click', (event: any) => {
        const { x, width } = progressbar.getBoundingClientRect();

        const clickPosition = event.clientX;

        const videoPosition = x;
        const videoDimension = width;

        const progressbarClick = clickPosition - videoPosition;
        const ratio = progressbarClick / videoDimension;

        video.currentTime = video.duration * ratio;
      });

      progressbar.addEventListener('pointerdown', this.onDragStart);
      document.addEventListener('pointerup', this.onDragEnd);
      document.addEventListener('pointermove', this.onDragMove);
    }

    EventBus.$on('activity-event-set-question-fullscreen', () => {
      this.fullscreen(true);
    });
  }

  public destroyed() {
    EventBus.$off('activity-event-stop-media');
    const { progressbar } = this.$refs;
    window.removeEventListener('hashchange', this.onHashChange);

    if (progressbar) {
      document.removeEventListener('pointerup', this.onDragEnd);
      document.removeEventListener('pointermove', this.onDragMove);
    }

    window.clearTimeout(this.resizeTimeout);
    EventBus.$off('activity-event-set-question-fullscreen');

    EventBus.$off('blow-the-doors-off');
  }

  @Watch('windowWidth')
  private onResize() {
    this.resizeTimeout = window.setTimeout(() => {
      // Wait until user will rotate a device fully
      this.positionHotspots();
    }, 500);
  }

  @Watch('isFullscreen')
  private onFullscreenChange(val: boolean) {
    // removing hash from the current route
    // vue router may throw an error in this case,
    // because we're redirecting to the current location
    this.$router.push({ hash: val ? fsHash : '' }).catch(() => {
      /* nope */
    });
  }
}
</script>

<style lang="scss" scoped>
$videoColor: #10a5df;
$progressbarColor: $orange100;
$progressbarHeight: 4px;
$progressbarScrubSize: 8px;

.video__asset {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.video {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $videoColor;

  user-select: none;

  // Enable overflow to show scrub bar draggable indicator:
  // overflow: hidden;

  &--fullsize {
    .video__asset {
      width: 100%;
      height: 100%;
    }
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;

    background: black;

    z-index: map-get($zindex, fullscreenAsset);
  }
}

.video__buttons {
  position: absolute;
  bottom: 0;

  height: 40px;
  width: 100%;
}

.video__play-pause,
.video__fullscreen,
.video__play-slow {
  position: absolute;
  bottom: 5px;

  padding: 15px;

  color: $white100;
  text-shadow: 0 0 2px black;

  z-index: map-get($zindex, fullscreenButton);
}

.video__play-pause {
  left: 0;
}

.video__play-slow {
  left: 44px;

  img {
    height: 16px;
  }
}

.video__fullscreen {
  right: 0;
}

.video__progressbar,
.video__progressbar-currenttime {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: $progressbarHeight;

  z-index: 1;
  cursor: pointer;
}

.video__progressbar {
  background-color: grey;
}

.video__progressbar-currenttime {
  background-color: $progressbarColor;

  &::after {
    display: block;
    position: relative;
    left: 100%;

    width: $progressbarScrubSize;
    height: $progressbarScrubSize;

    margin-left: -$progressbarScrubSize / 2;
    margin-top: -$progressbarScrubSize / 4;

    background-color: $progressbarColor;
    border-radius: 100%;

    content: '';
  }
}
</style>

<docs>
### Default

```
<AssetVideo src="https://www.w3schools.com/html/mov_bbb.mp4" />
```

### With children

```
<AssetVideo src="https://www.w3schools.com/html/mov_bbb.mp4">
  <div style="position: absolute; top: 50px; left: 50px; width: 50px; height: 50px; background-color: red">Hi</div>
</AssetVideo>
```
</docs>
