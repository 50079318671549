var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'activity-item',
    `activity-item--${_vm.type}`,
    {
      'activity-item--reverse': _vm.isReversed,
      'activity-item--disabled': _vm.disabled,
    },
  ]},[_c('div',{staticClass:"activity-item__container",on:{"click":function($event){return _vm.$emit('select')}}},[_c('div',{staticClass:"activity-item__index"},[_vm._v(_vm._s(_vm.index))]),_c('ProgressStar',{attrs:{"progress":_vm.progress}}),_c('img',{staticClass:"activity-item__thumb",attrs:{"src":_vm.thumbnail,"alt":"Activity background"}})],1),(_vm.showArrow)?_c('div',{staticClass:"activity-item__arrow"},[_vm._l((_vm.dotsCount),function(dot){return _c('div',{staticClass:"activity-item__arrow-dot"})}),_c('img',{staticClass:"activity-item__arrow-end",attrs:{"src":"/images/training-menu/arrow-green.png"}})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }