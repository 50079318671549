import { IActivityComponentDataElement } from '@/models/interfaces/activities';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ActivityWithTypingGaps from './ActivityWithTypingGaps.mixin';

@Component
export default class ActivityDraggableGaps<
  P extends string = string
> extends Mixins(ActivityWithTypingGaps) {
  @Prop({ required: true })
  protected type!: P;

  @Prop({ required: true })
  protected data!: IActivityComponentDataElement[];

  protected draggableGapsSentenceParts(
    componentData: IActivityComponentDataElement,
  ) {
    const parts = this.sentenceParts(
      componentData.label || componentData.value,
    );
    const lastPartValue = parts[parts.length - 1].value; // last part will always be unfillable (letters: 0)
    const fillableParts = parts.filter(item => item.letters);

    const partsToShow = fillableParts.map((item, idx) => {
      return idx === fillableParts.length - 1
        ? {
            ...item,
            valueWithUnderscore: `${item.valueWithUnderscore}${lastPartValue}`,
          }
        : item;
    });

    return partsToShow;
  }

  protected getOrder(componentIndex: number) {
    if (this.type === 'draggableSpeechBubblesWithGaps') {
      const previousBubbles = this.data.filter(
        bubble => Number(bubble.index) < componentIndex,
      );

      const prevBubblesGapsPartsCount: number[] = [];
      previousBubbles.forEach(bubble =>
        prevBubblesGapsPartsCount.push(
          this.draggableGapsSentenceParts(bubble).length,
        ),
      );

      return (
        prevBubblesGapsPartsCount.reduce((a, b) => a + b, 0) + componentIndex
      );
    } else {
      return componentIndex;
    }
  }
}
