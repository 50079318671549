<template>
  <div class="activity-carousel">
    <button
      :class="{
        'prev-button': true,
        disabled: !buttons.left,
      }"
      @click="prev"
    >
      <img
        v-tooltip="{
          content: this.$t('tooltip.activities.previous').toString(),
        }"
        class="prev-button__img"
        :src="require(`@/assets/images/activities/slider/prev.png`)"
        alt="Prev Slider Icon"
        title="Previous slide"
      />
    </button>

    <button
      :class="{
        'next-button': true,
        disabled: !buttons.right,
      }"
      @click="next"
    >
      <img
        v-tooltip="{
          content: this.$t('tooltip.activities.next').toString(),
        }"
        class="next-button__img"
        :src="require(`@/assets/images/activities/slider/next.png`)"
        alt="Next Slider Icon"
        title="Next slide"
      />
    </button>

    <Carousel
      ref="carousel"
      auto-height
      navigate-by-slide
      :class="{
        'cancel-overflow': isFullscreen,
      }"
      :items-per-view="1"
      :speed="2500"
      :enable-buttons="false"
      @on-slide-change="handleSlideChange"
    >
      <button
        v-for="image in data"
        :key="image.id"
        class="activity-carousel__image"
      >
        <ActivityImage
          ref="image"
          :data="[{ value: image.value, id: Math.random() }]"
          :is-carousel-item="true"
          @on-fullscreen-toggle="onFullscreenToggle"
          @on-position-hotspots="positionHotspots"
        />
      </button>
    </Carousel>
  </div>
</template>

<style lang="scss" scoped>
.cancel-overflow {
  ::v-deep {
    .carousel {
      overflow: visible;
    }

    .carousel__track {
      transform: none !important;
    }
  }
}

.next-button,
.prev-button {
  height: 80px;

  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  &__img {
    height: 100%;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  @media (max-width: #{$mobile}px) {
    height: 60px;
  }
}

.next-button {
  right: -37px;

  @media (max-width: #{$mobile}px) {
    right: -28px;
  }
}

.prev-button {
  left: -37px;

  @media (max-width: #{$mobile}px) {
    left: -28px;
  }
}

.activity-carousel {
  position: relative;

  margin: 0 auto;

  width: 100%;
  max-width: $activityComponentContainerWidth;

  @media (max-width: #{$mobile}px) {
    max-width: calc(100% - 54px);
  }

  &__image {
    width: 100%;
    height: 100%;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Watch, Ref, Vue } from 'vue-property-decorator';

import theme from '@/styles/theme';

import ActivityImage from '@/components/Activity/ActivityImage.vue';
import EventBus from '@/utils/helpers/EventBus';

import Carousel, { Carousel as Vue2SimpleCarousel } from 'vue2-simple-carousel';
import { IActivityComponentDataElement } from '@/models/interfaces/activities';

@Component({
  components: {
    Carousel,
    ActivityImage,
  },
})
export default class ActivityImageCarousel extends Vue {
  @Prop({ required: true })
  private type!: string;

  @Prop({ required: true })
  private data!: any;

  @Ref('image')
  private images!: Vue[];

  @Ref('carousel')
  private carousel!: Vue & Vue2SimpleCarousel;

  private selectedSlide: null | IActivityComponentDataElement = null;
  private isFullscreen = false;

  private buttons = {
    left: false,
    right: true,
  };

  private next() {
    this.carousel.next();
  }

  private prev() {
    this.carousel.prev();
  }

  private positionHotspots(data: {
    width: number;
    height: number;
    top: number;
    position: string;
  }) {
    if (!this.images[0].$el) {
      return;
    }

    const borderSize = theme.activityMediaBorderSize.replace('px', '');
    EventBus.$emit('set-assets-hotspots-container-positioning', {
      ...data,
      left:
        this.images[0].$el.getBoundingClientRect().left +
        (Number(borderSize) || 0),
      controls: true,
    });
  }

  private handleSlideChange(index: number) {
    const maxIndex = this.data.length - 1;

    this.buttons = Object.assign(this.buttons, {
      left: index > 0,
      right: index < maxIndex,
    });

    const visibleIndex = index;
    const visibleSlide = this.data[visibleIndex];

    // Remove previous slide from solutions:
    if (this.selectedSlide !== null) {
      EventBus.$emit('activity-event-solution-del', {
        type: 'imageCarousel',
        data: this.selectedSlide,
      });
    }

    this.selectedSlide = { ...visibleSlide };

    if (this.type === 'imageCarouselHotspots') {
      return;
    }

    EventBus.$emit('activity-event-solution-add', {
      type: 'imageCarousel',
      data: this.selectedSlide,
    });
  }

  private onFullscreenToggle(val: boolean) {
    this.isFullscreen = val;
  }

  public mounted() {
    window.requestAnimationFrame(() => {
      this.handleSlideChange(0);
    });
  }

  @Watch('selectedSlide')
  private onSlideChange() {
    if (this.selectedSlide) {
      this.$nextTick(() => {
        EventBus.$emit(
          'activity-event-filter-hotspots-by-id',
          this.selectedSlide!.id,
        );
      });
    }
  }
}
</script>

<docs>

```jsx
<ActivityImageCarousel
  type="imageCarousel"
  :data="[
    { value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg' },
    { value: 'https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg' }
  ]"
/>
```
</docs>
