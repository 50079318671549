import { htmlFeatures } from '../html-features';

export default {
  cg: {
    error: 'Primero, seleccione un motivo.',
    title: 'Principios de la Comunidad',
    popup: {
      title:
        '¡Gracias por ayudar a mantener segura nuestra comunidad! Volver a las Normas de la comunidad',
      btn: 'Volver a las Normas de la comunidad',
    },
    pt1: {
      subtitle: '¿Qué es la Comunidad DigiPals?',
      text:
        'Nuestra comunidad de DigiPals es un lugar seguro para hacer amigos y practicar inglés.',
    },
    pt2: {
      subtitle: '¿Cuáles son las reglas de la comunidad?',
      text1: 'Escribe siempre en inglés',
      text2:
        'No escriba nada hostil, ofensivo o desagradable. ¡No se permite jurar!',
      text3:
        'No comparta ningún dato personal, p. Ej. Su nombre completo, direcciones, números de teléfono, direcciones de correo electrónico u otros detalles de la cuenta en línea / redes sociales',
      text4: 'Todos los mensajes son revisados por personas reales.',
    },
    pt3: {
      subtitle:
        '¿Qué debo hacer si creo que alguien está infringiendo una regla?',
      text1:
        'El envío de este informe nos ayuda a mantener felices a todos los miembros de nuestra comunidad.',
      text2:
        'Nos pondremos en contacto con tu adulto para saber más y ayudarte.',
    },
    btn: 'Envíe un informe',

    option1: 'Creo que alguien no esta escribiendo en ingles',
    option2: 'Creo que alguien esta siendo cruel',
    option3: 'Creo que alguien compartió información personal.',
  },

  globe: {
    _: 'Globo',
    friendsMultiple: 'Tienes {friends} amigos',
    friends: 'Tienes 1 amigo',
    countries: 'en 1 país',
    countriesMultiple: 'en {countries} países',
    defaultTitle: '¡Nuestra comunidad global de DigiPal!',
    fetchFriend: '¡Busca un nuevo amigo ahora!',
  },

  profile: {
    avatar: {
      title: 'Hacer mi avatar',
    },
    error: '¡Uy! Algo salió mal. ¿Podrías intentarlo de nuevo?',
    messagesEmpty:
      'Uy! Todavía no hay mensajes aquí. ¡Ve a tu lista de DigiPals recomendados, visita sus perfiles y comienza una conversación!',
    postTitle: 'Publicaciones de mis DigiPals',
    writePost: 'Escribe un DigiPost',
    profileError: 'No se pudieron recuperar los mensajes de perfil.',
    clock: '¿Qué hora es donde vive {username}?',
    country: '{username}\nvive en\n{country}',
    myCountry: 'Vivo en {country}',
    myClock: '¿Qué hora es donde vivo?',
    friendsAccepted: '¡Ya son amigos!',
    friendsAdd: 'Enviar una solicitud de digipal',
    friendsPending: 'Solicitud de digipal pendiente',
  },

  dictionary: {
    alphabet: {
      error: 'No se pudo recuperar el alfabeto',
    },
    words: {
      error: 'No se pudieron recuperar las palabras',
    },
  },

  dates: {
    yearsAgo: 'Hace {count} años',
    yearAgo: 'Hace un año',
    monthsAgo: 'Hace {count} meses',
    monthAgo: 'hace un mes',
    daysAgo: 'Hace {count} dias',
    dayAgo: 'Hace un día',
    hoursAgo: 'hace {count} horas',
    hourAge: 'hace una hora',
    minutesAgo: 'hace {count} minutos',
    minuteAgo: 'hace un minuto',
    seconds: 'hace unos segundos',
  },

  common: {
    someone: 'Alguien',
  },

  digiPal: {
    messagesEmpty: '¡No hay mensajes aquí! ¡Escribe el primero!',
  },

  interests: {
    title: 'Elige mis ‘likes’',
  },

  achievements: {
    title: 'Mira mis logros',
    messageTrophy:
      '¡Obtén trofeos enviando mensajes a tus DigiPals! Solo los mensajes que reciban una respuesta contarán para sus trofeos.',
    activityTrophy:
      '¡Obtén trofeos completando actividades! Cada estrella que ganes contará para tus trofeos.',
  },

  settings: {
    title: {
      1: '¿Quieres aprender inglés británico o americano?',
      2: "Obtener 'Ayuda' en...?",
    },
  },

  digipals: {
    genericErrorMessage: '¡Uy! Algo salió mal. ¿Podrías intentarlo de nuevo?',

    all: {
      title: 'Mis DigiPals',
      emptyErrorMessage:
        "Aún no tienes amigos conectados. ¡Ve a 'DigiPals recomendados' para comenzar a hacer amigos!",
      successRequest:
        'Has enviado a {friend} una solicitud de amistad. ¡Deja un mensaje en su perfil para decirles por qué te gustaría ser amigo!',
      clock:
        '¿Qué hora es donde vive {digipal}? ¡Recuerda, algunos DigiPals pueden estar dormidos cuando es de día para ti!',
    },
    mutual: {
      title: 'Mutual DigiPals',
      emptyErrorMessage: 'Aún no tienes mutual DigiPals.',
    },
    pending: {
      title: 'Solicitudes de DigiPal',
      emptyErrorMessage:
        "Aún no tienes solicitudes pendientes. ¡Ve a 'DigiPals recomendados' para comenzar a hacer amigos!",
      successAccept:
        'Ahora eres amigo de {friend}. Visita su perfil para saludar!',
    },
    search: {
      title: 'Mira mis DigiPals',
    },
    suggested: {
      title: 'Mensaje Digipals que están en línea ahora',
      emptyErrorMessage: 'No tienes amigos en línea.',
    },
  },

  notifications: {
    popups: {
      firstLogin:
        '¡Hola! ¡Soy Domino ¡Te ayudaré a hacer amigos en todo el mundo! Si necesitas ayuda, puedes encontrarme en la barra de herramientas. Esta es tu página de perfil de DigiPal. Les dice a todos algo sobre ti. ¡Comienza a explorar!',
      post: {
        reply:
          '{username} ha respondido a tu mensaje! Continua la conversación. 😁',
        wallMessage:
          '{username} ha dejado un mensaje en tu perfil! Continua la conversación. 😁',
      },
      dominoAdd:
        '¡Felicidades! ¡Has logrado una racha ¡Ahora Domino aparecerá en tu perfil!',
      dominoRem:
        '¡Oh, no! Perdiste tu racha. Gana 20 lingos para que Domino vuelva a tu perfil :)',
    },
    messageDigipal: 'Envia un mensaje a {username}',
    messageFrom: 'Mensaje de\n{userName}',
    friendRequest: 'Solicitud de amistad\n{userName}',
    requestAccepted: 'Petición aceptada\n{userName}',
    likeDash: '{userName}\nme gustó tu página',
  },

  popup: {
    activityLeaveConfirmation:
      '¿Seguro que quieres salir de la actividad? Su progreso no se guardará.',
    testLeaveConfirmation:
      '¿Seguro que quieres salir de la prueba? No podrás rehacerlo de nuevo.',
    streakPrefix: 'Racha de {streak} días.',
    streak0:
      '¡Gana 20 Lingos por día para comenzar tu racha! ¡Para ganar Lingos, envía mensajes a tus amigos y completa actividades!',
    premiumGamesErrorMessage:
      '¡Ups! No tienes acceso a los juegos. ¡Pregúntales a tus padres sobre cómo actualizar tu cuenta!',
    lingosErrorMessage:
      'Necesitas Lingos para comprar ciertos artículos. Envía mensajes para ganar Lingos.',
    offlineErrorMessage: '¡Uy! La conexión a internet no funciona.',
    genericErrorMessage: '¡Uy! Algo salió mal. ¿Podrías intentarlo de nuevo?',
    newsfeedErrorMessage:
      '¡Uy! Tu mensaje no fue enviado. ¿Podrías intentarlo de nuevo?',
    avatarCustomizationMenu:
      '¡Personaliza tu avatar! Necesitas Lingos para comprar ciertos artículos. Completa actividades o mensajes para ganar lingos.',
    wallpaperSettings:
      '¡Personaliza el fondo de pantalla de tu perfil! Necesitas Lingos para comprar ciertos artículos. Completa actividades o mensajes para ganar lingos.',
    interestsSettings:
      'Necesitas Lingos para comprar ciertos artículos. Completa actividades o mensajes para ganar lingos.',
    achievementsMenu: '¡Mira cuántos premios has ganado!',
    globe: `Encuentra nuevos DigiPals en todo el mundo. Visita su perfil, déjalos un mensaje para ganar Lingos. Use el ${htmlFeatures.plusIcon} para invitarlos a ser tu DigiPal.`,
    friendsSuggested:
      '¡Tienes algunos DigiPals recomendados, solo para ti! Invítalos a conectarse contigo.',
    friends:
      'Vea DigiPals al que estás conectado. Visita sus perfiles, déjalos un mensaje y gana Lingos.',
    friendsPending:
      'Vea DigiPals que desean conectarse contigo. Acepta su invitación y ve a su perfil para dejar un mensaje para ganar Lingos.',
    friendsSearch: `¿Conoces el nombre de DigiPal de alguien? Búscalos aquí y ve a su perfil para dejar un mensaje y ganar más Lingos. Usa el ${htmlFeatures.plusIcon} para invitarlos a ser tu DigiPal.`,
    login: `¡Hacer amigos es genial! Visita a tus 'Amigos recomendados' o visita el mundo para hacer nuevos amigos`,
    profileLike: `¡Le diste me gusta a la página de perfil de {username}! Estará súper feliz. ¡Déjale un mensaje para hacerle saber por qué te gustó!`,
    streakNotice:
      "¿Quieres una mascota Domino personalizable y genial? Para conseguir Domino, gana 20 lingos cada día. Esta es tu 'Racha'.",
    profilePage:
      '¡Hola! ¡Soy Domino ¡Te ayudaré a hacer amigos en todo el mundo! Si necesitas ayuda, puedes encontrarme en la barra de herramientas. Esta es tu página de perfil de DigiPal. Les dice a todos algo sobre ti. ¡Comienza a explorar!',
  },

  activity: {
    rewards: {
      trophyTitle: 'You’ve won a trophy!',
      starTitle: 'You’ve won a star!',
      lingoTitle: 'You’ve got {lingos} lingos!',
      score: 'Score',
      returnToActivities: 'Volver a Actividades',
    },
    currentLesson: 'Lección actual',
    offlineMessage:
      '¡UH oh! Ha perdido su conexión a Internet. No se preocupe, hemos guardado su puntuación de actividad.',
  },

  onboarding: {
    first: 'Conoce a DigiPals de todo el mundo y envíales mensajes en inglés.',
    second: '¿Necesitas ayuda con el inglés? Puedes comenzar a aprender aquí.',
    third: 'Crea tu avatar. Muestra tus Me gusta. Encuentra otros DigiPals.',
    fourth:
      'Visita tus DigiPals. Deja mensajes. Únete a los chats. Invita a DigiPals a enviarte un mensaje.',
    fifth:
      'Gana Lingos por mensajes y por completar juegos. Compra cosas para tu avatar y para Domino.',
    sixth:
      '¡Mantén una racha en Little Bridge y consigue Domino para tu perfil!',
    seventh: {
      title: '¿Listo para hacer amigos?',
      btn: '¡Vamonos!',
    },
  },
  tooltip: {
    toolbar: {
      home: 'Casa',
      findFriends: 'Encuentra\nnuevos\namigos',
      activities: 'Academia de dominó',
      dominoAcademy: 'Academia \n de Dominó',
      friends: 'Amigos',
      streak: 'Racha',
      globe: 'Globo',
      information: 'Info',
      menu: 'Menú',
      lingos: 'Lingos',
      notifications: 'Notificaciones',
      notificationsactive: 'Notificaciones',
      audio: 'Repetir pregunta',
      exit: 'Cerrar actividad',
      snail: 'pregunta lenta',
      steps: 'Continuar',
      fetchFriend: 'Encontrar nuevos amigos',
    },
    profile: {
      interests: 'Intereses',
      digipals: 'Mis DigiPals',
      achievements: 'Mis logros',
      editWallpaper: 'Editar fondo de perfil',
      addDigipal: 'Agregar DigiPal',
      offline: 'Desconectado',
      online: 'Conectado',
      myAvatar: 'Mi digipal',
      avatar: 'Avatar',
      sendMessage: 'Enviar mensaje',
      likeProfile: 'Me gusta el perfil',
      myFriends: 'Mis amigos',
      myLikes: 'Mis gustos',
      digipalLikes: 'Me gusta',
      alreadyLiked: 'Ya te gusta este perfil',
      myMessages: 'Mis mensajes',
      requestPending: 'Solicitud de DigiPal pendiente',
      weAreDigipals: '¡Somos DigiPals!',
    },
    messages: {
      likes: 'Me gusta',
      send: 'Enviar',
      saySomething: 'Decir algo',
      askSomething: 'Preguntar algo',
      emoji: 'Emoji',
      reply: 'Responder',
    },
    activities: {
      supportLanguage: 'Instrucciones en español',
      teachingLanguage: 'Instrucciones en inglés',
      next: 'Siguiente',
      previous: 'Anterior',
      play: 'Reproducir',
      slowPlay: 'Lento',
      record: 'Grabar',
      playback: 'Reproducir grabación',
    },
    other: {
      back: 'Regresar',
      getItem: 'Obtener',
      zoomIn: 'Acercar',
      zoomOut: 'Alejar',
    },
  },

  conversations: {
    sendMessage: 'Enviar un mensaje',
    myInbox: 'Mi bandeja de entrada',
    send: 'Enviar mensaje',
    yourChat:
      'Tu conversación con <p class="conversation__header__subtitle">{username}</p>',
    noMessages: 'No hay mensajes aquí! Escribe el primero!',
  },

  messageRewards: {
    title: '¡Enviaste un mensaje a {username}!',
    returnToHome: 'Volver a la página de perfil',
  },
};
