var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data.isShown)?_c('div',{staticClass:"popup"},[_c('div',{class:{
      'popup__close-area': true,
      'popup__close-area--before-close': _vm.isBeforeClose || _vm.isBeforeSwipeClose,
    },on:{"click":_vm.closeOnTileClick}}),_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.closeOnSwipe),expression:"closeOnSwipe",arg:"swipe",modifiers:{"right":true}}],staticClass:"popup__content",class:{
      'popup__content--before-close': _vm.isBeforeClose,
      'popup__content--swipe-close': _vm.isBeforeSwipeClose,
      'popup__content--streak': _vm.isStreakPopup,
      'popup__content--lingos': _vm.isLingosPopup,
    }},[_c('div',{staticClass:"popup__body"},[_c('div',{staticClass:"popup__icon"},[_c('img',{staticClass:"popup__icon-image",attrs:{"alt":`${_vm.data.type} Popup Icon`,"title":`${_vm.data.type} Popup Icon`,"src":_vm.popupImageSrc}})]),_c('div',{staticClass:"popup__info"},[_c('p',{staticClass:"popup__text",domProps:{"innerHTML":_vm._s(_vm.data.message)}}),(_vm.data.type === 'offline')?[(_vm.isRetryInProgress)?_c('button',{staticClass:"popup__retry-message"},[_vm._v(" Trying to resolve connection... ")]):_c('span',{staticClass:"popup__retry-message",on:{"click":_vm.retry}},[_vm._v(" Retry ")])]:_vm._e(),(_vm.isIntervention)?_c('p',{staticClass:"popup__intervention",on:{"click":_vm.onInterventionClick}},[_vm._v(" "+_vm._s(_vm.data.intervention)+" ")]):_vm._e(),(_vm.isNavigateTo)?_c('router-link',{class:{
            'popup__navigate-to': true,
            'popup__navigate-to--text': _vm.data.redirectText,
          },attrs:{"to":_vm.data.redirect,"custom":""},nativeOn:{"click":function($event){return _vm.closeNavigatePopup.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('button',{on:{"click":navigate}},[(_vm.data.redirectText)?_c('p',{staticClass:"popup__navigate-to__text"},[_vm._v(" "+_vm._s(_vm.data.redirectText)+" ")]):_c('img',{attrs:{"src":_vm.data.redirectPageIcon,"alt":"Redirect Page Icon"}})])]}}],null,false,3746061649)}):_vm._e(),(_vm.data.onOk)?_c('button',{staticClass:"ok-btn",attrs:{"type":"button"},on:{"click":_vm.onOk}},[_vm._v(" OK ")]):_vm._e()],2)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }