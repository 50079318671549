<template>
  <div id="app" :class="{ 'navigation-enabled': isNavbarVisible }">
    <AppTopToolbar v-show="isNavbarVisible" />

    <div
      v-if="isOnline || isInActivity"
      :class="{
        app__content: true,
        'app__content--hasNavigation': isNavbarVisible,
      }"
    >
      <router-view />
    </div>

    <Popup />

    <AppBottomToolbar v-show="isNavbarVisible" />

    <!-- that will load font-awesome css from the cdn at once
    needed for the offline access on the activities
     -->
    <i class="root-icon fas fa-expand"></i>
  </div>
</template>

<style lang="scss" scoped>
.root-icon.fas {
  visibility: hidden;
  position: absolute;
  z-index: -1;
  left: -1000px;
}

.navigation-enabled {
  ::v-deep .app-page {
    @media (max-width: #{$tablet}px) {
      padding-bottom: 100px;
    }
  }
}
</style>

<style lang="scss">
@import '@/styles/normalize.scss';
@import '@/styles/globals.scss';
@import '@/styles/tooltip.scss';

.app__content {
  position: relative;
  z-index: 19;

  &--hasNavigation {
    @media (max-width: #{$tablet}px) {
      margin-bottom: 60px;
    }

    @media (max-width: #{$mobile}px) {
      margin-bottom: 50px;
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State, Action } from 'vuex-class';

import AppTopToolbar from '@/components/AppTopToolbar/AppTopToolbar.vue';
import AppBottomToolbar from '@/components/AppBottomToolbar/AppBottomToolbar.vue';
import Popup from '@/components/Popup/Popup.vue';

import { IPopupData } from '@/models/interfaces/popup';
import { IUserLanguageData } from './models/interfaces/users';
import { IVuexState } from './models/interfaces/store';
import { INotification } from './models/interfaces/notifications';
import { NotificationType } from './models/constants/notifications';

import sounds from '@/utils/helpers/sounds';
import {
  PreloadedAsset,
  PreloadedAssetType,
} from './store/modules/preload-assets';
import { requireFromCache } from './utils/helpers/assets';
import routeNames from '@/router/route-names';

@Component({
  components: {
    AppTopToolbar,
    AppBottomToolbar,
    Popup,
  },
})
export default class App extends Vue {
  public popupImage!: HTMLImageElement;
  private isOnboardingShown = false;

  @State('hasUnseenNotifications', { namespace: 'notifications' })
  public hasUnseenNotifications!: boolean;

  @State('notifications', { namespace: 'notifications' })
  public notifications!: IVuexState<INotification[]>;

  @Getter('getNavbarState')
  public isNavbarVisible!: boolean;

  @Getter('getPopupData')
  public popupData!: IPopupData;

  @State('languages', { namespace: 'profile' })
  private languages!: IVuexState<IUserLanguageData>;

  @Mutation('togglePopup')
  private togglePopup!: (data?: IPopupData) => void;

  @Action('session/logout')
  public logout!: () => Promise<void>;

  @Action('notifications/sendLastReadMessageId')
  private sendLastReadMessageId!: () => Promise<any>;

  @Action('preloadAssets/preloadAssets')
  private preloadAssets!: (assetsUrls: string[]) => void;

  @State('preloadedImages', { namespace: 'preloadAssets' })
  private preloadedImages!: PreloadedAsset[];

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private get isInActivity() {
    const { path } = this.$route;
    return path.includes('activity') || path.includes('activities');
  }

  private onOffline() {
    this.$nextTick(() => {
      this.togglePopup();
      this.togglePopup({
        message: this.$t('popup.offlineErrorMessage').toString(),
        type: 'offline',
        image: requireFromCache(['domino', 'error'], this.preloadedImages),
      });
    });
  }

  private watchConnection() {
    this.$on('offline', () => {
      if (!this.isInActivity) {
        this.onOffline();
      }
    });

    this.$on('online', () => {
      if (
        this.popupData.isShown &&
        this.popupData.message ===
          this.$t('popup.offlineErrorMessage').toString() &&
        !this.isInActivity
      ) {
        document.location.reload();
      }
    });
  }

  private onLogOut(event: StorageEvent) {
    // handling logout event in other browser tabs

    if (event.storageArea === localStorage) {
      const { key, newValue } = event;

      if ((key === 'token' || key === null) && newValue === null) {
        // user logged out
        this.logout();
      }
    }
  }

  private mounted() {
    this.watchConnection();

    // preload default popup images
    // for availability offline
    this.preloadAssets([
      require('@/assets/images/popup/domino_study_buddy_normal.png'),
      require('@/assets/images/popup/domino_study_buddy_error.png'),
    ]);

    window.addEventListener('storage', this.onLogOut);
  }

  @Watch('hasUnseenNotifications', { immediate: true })
  private onNotificationsStateChanged(
    hasUnseenNotifications: boolean,
    hadUnseenNotifications: boolean,
  ) {
    if (hasUnseenNotifications && !hadUnseenNotifications) {
      sounds.notification.play();
    }
  }

  @Watch('languages', { immediate: true })
  private onLanguagesDataFetched(value: IVuexState<IUserLanguageData>) {
    if (value.data) {
      this.$root.$i18n.locale = value.data.supportLanguage;
    }
  }

  @Watch('notifications.data')
  private onNotificationsReceived(notifications: INotification[]) {
    notifications
      .filter(notification => notification.isNew)
      .forEach(notification => {
        if (notification.type === NotificationType.StudyBuddy) {
          if (notification.action === 'firstLogin') {
            this.handleFirstLoginNotification();
          } else {
            this.handleStudyBuddyNotification(notification);
          }
        }
      });
  }

  private handleFirstLoginNotification() {
    // this condition is needed because a BE may not save the
    // firstlogin notification status in time and
    // onboarding screen may appear 2 times

    if (!this.isOnboardingShown) {
      // StudyBuddy notification should be directly marked as read ASAP since they
      // trigger special interaction:
      this.sendLastReadMessageId();

      this.$router.push({ name: routeNames.onboarding });

      this.isOnboardingShown = true;
    }
  }

  private handleStudyBuddyNotification(notif: INotification) {
    // StudyBuddy notification should be directly marked as read ASAP since they
    // trigger special interaction:
    this.sendLastReadMessageId();

    this.togglePopup({
      message: this.$t(`notifications.popups.${notif.action}`).toString(),
    });
  }
}
</script>
