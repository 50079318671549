<template>
  <div class="gap-inputs">
    <!-- eslint-disable-next-line -->
    <input
      v-for="input in inputs"
      :ref="`input${input}`"
      v-model="models[input - 1]"
      class="input"
      type="text"
      autocapitalize="none"
      data-testID="gaps-inputs-field"
      @keyup="onRemoveValue($event, input)"
      @input="focusNext($event, input)"
    />
  </div>
</template>

<style lang="scss" scoped>
.gap-inputs {
  display: inline-block;

  .input {
    width: 15px;

    font-size: 18px;
    font-weight: $font-weight;
    color: $blue100;
    text-align: center;

    border-bottom: 1px solid $blue100;

    margin-right: 2px;

    border-radius: 0;

    &:last-child {
      margin: 0;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import ActivityWithTypingGaps from '../Activity/mixins/ActivityWithTypingGaps.mixin';

@Component
export default class GapInputs extends Mixins(ActivityWithTypingGaps) {
  @Prop({ required: true, type: Number })
  private inputs!: number;
}
</script>

<docs>
### A component that renders inputs based on props. Used by the DraggableSpeechBubble.vue, ActivityFillableTextContainer

```jsx
<div>
  <span>Type something</span>

  <GapInputs
    :inputs="10"
  />
</div>
```
</docs>
