import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';

import { RootState } from '..';
import { IVuexState } from '@/models/interfaces/store';
import { ILicence } from '@/models/interfaces/users';
import { IProduct, Product } from '@/models/interfaces/products';
import { complexPartsChildren } from '@/components/Avatar3D/interfaces/HumanModel';

export interface State {
  wallpaper: IVuexState<IProduct[]>;
  interests: IVuexState<IProduct[]>;
  avatarBody: IVuexState<IProduct[]>;
  avatarHead: IVuexState<IProduct[]>;
  avatarGlasses: IVuexState<IProduct[]>;
  avatarEyes: IVuexState<IProduct[]>;
  avatarHair: IVuexState<IProduct[]>;
  avatarHat: IVuexState<IProduct[]>;
  avatarBadge: IVuexState<IProduct[]>;
  avatarTop: IVuexState<IProduct[]>;
  avatarTopTexture: IVuexState<IProduct[]>;
  avatarBottom: IVuexState<IProduct[]>;
  avatarShoes: IVuexState<IProduct[]>;
  avatarSocks: IVuexState<IProduct[]>;
  avatarAccessories: IVuexState<IProduct[]>;
  dominoHair: IVuexState<IProduct[]>;
  dominoHead: IVuexState<IProduct[]>;
  dominoEyes: IVuexState<IProduct[]>;
  dominoCollar: IVuexState<IProduct[]>;
  dominoTop: IVuexState<IProduct[]>;
  productsLoading: boolean;
}

export const actions: ActionTree<State, RootState> = {
  async fetchProducts({ commit }, type: Product) {
    commit('setProducts', { type });
    commit('setProductsLoading', true);

    try {
      const resp = await DataProvider.get<any>(`products/${type}`);
      const data = resp.data.products;

      commit('setProducts', { type, data });
      return data;
    } catch (error) {
      commit('setProducts', { type, error });
      return error;
    } finally {
      commit('setProductsLoading', false);
    }
  },
  async buyLicense({ dispatch, rootState, commit }, product: ILicence) {
    const { session } = rootState;
    const { userId } = session;

    try {
      const response = await DataProvider.post(`licences/${userId}`, {
        productSku: product.sku,
      });

      commit('profile/addLicense', product, { root: true });
      dispatch('profile/removeLingos', product.price, { root: true });

      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export const mutations: MutationTree<State> = {
  setProducts(state, payload: { type: Product; data?: IProduct[] | Error }) {
    const { type, data } = payload;

    if (!state.hasOwnProperty(type)) {
      throw new Error(`Invalid product type '${type}'`);
    } else {
      state[type] = StoreUtil.updateState(state[type], data);
    }
  },

  setProductsLoading(state, status: boolean) {
    state.productsLoading = status;
  },
};

export const getters: GetterTree<State, RootState> = {
  productsForType(state) {
    return (type: Product) => {
      if (!state.hasOwnProperty(type)) {
        throw new Error(`Invalid product type '${type}'`);
      } else {
        return state[type];
      }
    };
  },

  childrenPartOf(state) {
    return (type: Product) => {
      const child = complexPartsChildren[type];

      if (!child) {
        return StoreUtil.state();
      }

      if (!state.hasOwnProperty(child)) {
        throw new Error(`Invalid product type '${type}'`);
      } else {
        return state[child];
      }
    };
  },
};

export const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    wallpaper: StoreUtil.state(),
    interests: StoreUtil.state(),
    avatarBody: StoreUtil.state(),
    avatarHead: StoreUtil.state(),
    avatarGlasses: StoreUtil.state(),
    avatarEyes: StoreUtil.state(),
    avatarHair: StoreUtil.state(),
    avatarHat: StoreUtil.state(),
    avatarBadge: StoreUtil.state(),
    avatarTop: StoreUtil.state(),
    avatarTopTexture: StoreUtil.state(),
    avatarBottom: StoreUtil.state(),
    avatarShoes: StoreUtil.state(),
    avatarSocks: StoreUtil.state(),
    avatarAccessories: StoreUtil.state(),
    dominoHair: StoreUtil.state(),
    dominoHead: StoreUtil.state(),
    dominoEyes: StoreUtil.state(),
    dominoCollar: StoreUtil.state(),
    dominoTop: StoreUtil.state(),

    productsLoading: false,
  },
  mutations,
  getters,
  actions,
};

export default store;
