<template>
  <div>Placeholder for {{ type }}</div>
</template>

<style lang="scss" scoped></style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  IActivityComponent,
  IActivityMeta,
  IActivitySolution,
  IActivityTrigger,
} from '@/models/interfaces/activities';

@Component({
  components: {},
})
export default class ActivityPlaceholder extends Vue {
  @Prop({ required: true })
  protected type!: IActivityComponent<'verticalTextButtonsWithAudio'>['type'];

  @Prop({ required: true })
  protected meta!: IActivityComponent<'verticalTextButtonsWithAudio'>['meta'];

  @Prop({ required: true })
  protected data!: IActivityComponent<'verticalTextButtonsWithAudio'>['data'];

  @Prop({ required: true })
  protected rootMeta!: IActivityMeta;

  @Prop({ required: true })
  protected relations!: any;

  @Prop({ required: true })
  protected solution!: IActivitySolution[];

  @Prop({ required: true })
  protected triggers!: IActivityTrigger[];

  @Prop({ required: true })
  protected isActivityValid!: boolean;
}
</script>
