var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"vertical-containter-with-text-box"},[_c('ActivityTextContainer',{key:_vm.selectedWordsKey,staticClass:"text-box",attrs:{"data-testID":"container-activity-text-container"}},[_vm._v(" "+_vm._s(_vm.textContainerText())+" ")]),_c('Carousel',{ref:"carousel",staticClass:"carousel",attrs:{"data-testID":"container-carousel","manual-initialize":"","hide-buttons-on-start-end":"","items-per-view":_vm.getItemsPerView}},[_vm._l((_vm.columns),function(words,columnIndex){return _c('Column',{key:columnIndex,attrs:{"data-testID":"container-column","bg-color":_vm.meta.style.backgroundColor,"words":words,"selected-word":_vm.getSelectedWord(columnIndex)},on:{"on-select":function($event){return _vm.selectWord($event, columnIndex)}}})}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: this.$t('tooltip.activities.previous').toString(),
      }),expression:"{\n        content: this.$t('tooltip.activities.previous').toString(),\n      }"}],staticClass:"carousel-prev",attrs:{"slot":"prevButton","src":_vm.isOnline
          ? require('@/assets/images/activities/arrow-left.png')
          : _vm.requireFromCache(['arrow-left'], _vm.preloadedImages),"data-testID":"container-prev-button","alt":"Prev Slider Icon","title":"Previous slide"},slot:"prevButton"}),_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: this.$t('tooltip.activities.next').toString(),
      }),expression:"{\n        content: this.$t('tooltip.activities.next').toString(),\n      }"}],staticClass:"carousel-next",attrs:{"slot":"nextButton","src":_vm.isOnline
          ? require('@/assets/images/activities/arrow-right.png')
          : _vm.requireFromCache(['arrow-right'], _vm.preloadedImages),"data-testID":"container-next-button","alt":"Next Slider Icon","title":"Next slide"},slot:"nextButton"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }