<template>
  <div
    v-if="positioningData"
    :class="classNames"
    :style="{
      top: `${positioningData.top}px`,
      left: `${positioningData.left}px`,
      width: `${positioningData.width}px`,
      height: `${getHeight}px`,
      transform: positioningData.transform,
      position: positioningData.position,
    }"
  >
    <template v-if="type.includes('Wrappers')">
      <DraggableItemWrapper
        v-for="hotspot in data"
        :key="hotspot.id"
        :data="hotspot"
        :is-depend-on-controls="positioningData.controls"
        :meta-styles="meta.style"
      />
    </template>

    <template v-else-if="type.includes('draggable')">
      <DraggableItem
        v-for="hotspot in data"
        :key="hotspot.id"
        :data="hotspot"
        :type="type"
        :is-depend-on-controls="positioningData.controls"
        :meta-styles="meta.style"
      />
    </template>

    <template v-else>
      <DefaultItem
        v-for="hotspot in computedData"
        :key="hotspot.value"
        :data="hotspot"
        :type="type"
        :meta-styles="meta.style"
        :is-depend-on-controls="positioningData.controls"
        @click="handleHotspotClick"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.assets-hotspots {
  margin-top: calc(#{$activityMediaTopSpace} + #{$activityMediaBorderSize});

  overflow: hidden;
  z-index: map-get($zindex, activityHotspot);

  &--fullscreen {
    z-index: map-get($zindex, fullscreenActivityHotspot);
  }
}
</style>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import DefaultItem from './Item.vue';
import DraggableItem from './DraggableItem.vue';
import DraggableItemWrapper from './DraggableItemWrapper.vue';

import {
  IActivityComponentDataElement,
  IActivityMeta,
  IActivitySolution,
} from '@/models/interfaces/activities';
import EventBus from '@/utils/helpers/EventBus';

export type HotsportsContainerPositioningData = {
  height: number;
  width: number;
  left: number;
  top: number;
  position: string;
  transform?: string;
  controls?: boolean;
  video?: boolean;
};

type HotspotsType =
  | 'draggableImageHotspots'
  | 'imageHotspots'
  | 'draggableImageHotspotsWrappers';

@Component({
  components: {
    DefaultItem,
    DraggableItem,
    DraggableItemWrapper,
  },
})
export default class ActivityVideoHotspots extends Vue {
  @Prop({ required: true })
  private data!: IActivityComponentDataElement[];

  @Prop({ required: true, type: String })
  private type!: HotspotsType;

  @Prop({ required: true, type: Object })
  private meta!: IActivityMeta;

  @Prop({ required: true })
  private solution!: IActivitySolution[];

  private positioningData: HotsportsContainerPositioningData | null = null;
  private computedData = this.data;
  private solutionCount = this.solution.filter(
    solution => solution.type === 'imageHotspots',
  ).length;

  private get getHeight() {
    const { controls, height, video } = this
      .positioningData as HotsportsContainerPositioningData;

    const controlsHeight = video ? 40 : 0;

    return controls ? height - controlsHeight : height;
  }

  private get classNames() {
    return {
      'assets-hotspots': true,
      'assets-hotspots--fullscreen': this.positioningData?.position === 'fixed',
    };
  }

  private handleHotspotClick(
    type: string,
    data: IActivityComponentDataElement,
  ) {
    const isSingleSelect =
      this.solution.filter(solution => solution.type === 'imageHotspots')
        .length === 1 || this.solution.length === 1;

    if (isSingleSelect) {
      EventBus.$emit('activity-event-solution-add', { type, data });
      EventBus.$emit('activity-event-confirm');
    } else {
      this.solutionCount -= 1;
      EventBus.$emit('activity-event-solution-add', { type, data });
    }
  }

  private validate(disableConfirmButton: boolean) {
    if (!disableConfirmButton) {
      EventBus.$emit('activity-event-solution-bulk-update', this.solution);
      EventBus.$emit('activity-event-confirm');
    }
  }

  public mounted() {
    EventBus.$on(
      'set-assets-hotspots-container-positioning',
      (data: HotsportsContainerPositioningData) => {
        this.positioningData = data;
      },
    );

    if (this.type === 'draggableImageHotspots') {
      EventBus.$emit('activity-event-hide-fullscreen');
    }

    if (this.type === 'draggableImageHotspotsWrappers') {
      EventBus.$on('activity-event-disable-button', this.validate);
    }

    EventBus.$on('activity-event-filter-hotspots-by-id', (targetId: string) => {
      this.computedData = this.data.filter(item => item.id === targetId);
    });
  }

  public beforeDestroy() {
    EventBus.$off('set-assets-hotspots-container-positioning');
    EventBus.$off('activity-event-filter-hotspots-by-id');
    EventBus.$off('activity-event-disable-button', this.validate);
  }
}
</script>
