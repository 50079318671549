<template>
  <div
    :class="[
      'activity-item',
      `activity-item--${type}`,
      {
        'activity-item--reverse': isReversed,
        'activity-item--disabled': disabled,
      },
    ]"
  >
    <div class="activity-item__container" @click="$emit('select')">
      <div class="activity-item__index">{{ index }}</div>
      <ProgressStar :progress="progress" />

      <img
        :src="thumbnail"
        class="activity-item__thumb"
        alt="Activity background"
      />
    </div>

    <div v-if="showArrow" class="activity-item__arrow">
      <!-- eslint-disable -->
      <div v-for="dot in dotsCount" class="activity-item__arrow-dot" />
      <!-- eslint-enable -->

      <img
        src="/images/training-menu/arrow-green.png"
        class="activity-item__arrow-end"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

import { ActivityItemTypes, StarTypes } from './models/enums';

import ProgressStar from './ProgressStar.vue';

@Component({
  components: {
    ProgressStar,
  },
})
export default class ActivityItem extends Vue {
  @Prop({ type: Number, required: true })
  public index!: number;

  @Prop({ type: Number, required: true })
  public progress!: number;

  @Prop({ type: String, default: ActivityItemTypes.Primary })
  public type!: ActivityItemTypes;

  @Prop({ type: String, required: true })
  public thumbnail!: string;

  @Prop({ type: Boolean, required: true })
  public disabled!: boolean;

  @Prop({ type: Boolean, default: true })
  public showArrow!: boolean;

  public get dotsCount() {
    return this.windowWidth > 765 ? 6 : 3;
  }

  public get starType() {
    if (this.progress === 100) {
      return StarTypes.Full;
    }

    if (this.progress >= 50) {
      return StarTypes.Half;
    }

    return StarTypes.Empty;
  }

  public get isReversed() {
    return this.index % 2 === 0;
  }
}
</script>

<style lang="scss" scoped>
@mixin coloured-wrapper($color, $shadowColor) {
  border-radius: 100%;
  background-color: $color;
  border: 2px solid $shadowColor;
}

@mixin coloured-wrapper-with-shadow($color, $shadowColor) {
  @include coloured-wrapper($color, $shadowColor);

  box-shadow: 0px 4px 0px $shadowColor;
}

.activity-item {
  @include d-flex-items-end;

  @media (max-width: #{$mobile}px) {
    align-items: flex-start;
  }

  &__thumb {
    border-radius: 100%;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: relative;
    cursor: pointer;

    width: 210px;
    height: 210px;

    @include coloured-wrapper-with-shadow($blue400, $blue100);

    padding: 20px;

    @media (max-width: #{$mobile}px) {
      width: 135px;
      height: 135px;

      padding: 15px;
    }
  }

  &--danger {
    .activity-item__container {
      @include coloured-wrapper-with-shadow($pink200, $pink200Shadow);
    }
  }

  &--disabled {
    pointer-events: none;

    .activity-item__container {
      opacity: 0.6;
    }
  }

  &__index {
    @include d-flex-center;
    @include coloured-wrapper-with-shadow($blue400, $blue100);

    width: 75px;
    height: 75px;

    color: $white100;
    font-weight: bold;
    font-size: 45px;

    position: absolute;
    top: -30px;
    left: -15px;

    @media (max-width: #{$mobile}px) {
      width: 50px;
      height: 50px;

      font-size: 30px;
    }
  }

  .progress-star {
    position: absolute;
    bottom: 10px;
    right: -20px;

    @media (max-width: #{$mobile}px) {
      width: 65px;
      height: 65px;
    }
  }

  &__arrow {
    height: calc(100% + 25px);
    flex: 1;

    height: 135px;
    display: flex;

    margin-left: -10px;

    @media (max-width: #{$mobile}px) {
      margin-top: 50px;
      height: auto;
    }

    &-dot {
      border-radius: 100%;

      width: 25px;
      height: 25px;

      background-color: $green400;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-right: 25px;
      }

      &:nth-child(1) {
        @media (max-width: #{$mobile}px) {
          margin-right: 10px;
        }
      }

      &:nth-child(2) {
        @media (max-width: #{$mobile}px) {
          margin-top: 30px;
          margin-right: 0;
        }
      }

      &:nth-child(3) {
        @media (max-width: #{$mobile}px) {
          margin-top: 75px;
          margin-left: -10px;
        }
      }

      &:nth-child(4) {
        margin-right: 10px;
        margin-top: 12px;

        @media (max-width: #{$mobile}px) {
          margin: 0;
        }
      }

      &:nth-child(5) {
        margin-top: 37px;
      }

      &:nth-child(6) {
        margin-top: 75px;
      }
    }

    &-end {
      height: 40px;

      margin-top: 125px;
      margin-left: -45px;

      @media (max-width: #{$mobile}px) {
        height: 20px;
        margin-top: 130px;
        margin-left: -45px;
      }
    }
  }

  &--reverse {
    flex-direction: row-reverse;

    .activity-item__arrow {
      flex-direction: row-reverse;
      margin-left: 0;
      margin-right: -10px;

      &-end {
        margin-left: 0;
        margin-right: -45px;
      }

      &-dot {
        margin-right: 0;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-left: 25px;
        }

        &:nth-child(1) {
          @media (max-width: #{$mobile}px) {
            margin-left: 10px;
          }
        }

        &:nth-child(2) {
          @media (max-width: #{$mobile}px) {
            margin-top: 30px;
            margin-left: 0;
          }
        }

        &:nth-child(3) {
          @media (max-width: #{$mobile}px) {
            margin-top: 75px;
            margin-right: -10px;
          }
        }

        &:nth-child(4) {
          margin-left: 10px;

          @media (max-width: #{$mobile}px) {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
