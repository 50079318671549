<template>
  <button
    v-tooltip="{
      content: $t(`tooltip.toolbar.${AppToolbarsIconsNames.Info}`).toString(),
    }"
    data-testID="study-buddy-trigger-btn"
    :class="{
      'study-buddy-trigger': true,
      'study-buddy-trigger--disable': !enabledViaEvent && disabledPopupForPage,
    }"
    :name="AppToolbarsIconsNames.Info"
    orientation="bottom"
    color="blue"
    @click="emitGlobalStudyBuddyEvent"
  >
    <slot />

    <img src="/images/icons/info.png" alt="Info Icon" />
  </button>
</template>

<style lang="scss" scoped>
.study-buddy-trigger {
  border-radius: 100%;
  background: $blue400;
  box-shadow: 0px 2px 0px $blue100;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 33px;
  height: 33px;

  img {
    height: 70%;
  }

  &--disable {
    pointer-events: none;
    position: relative;

    background: rgba(4, 169, 235, 0.6) !important;
  }
}
</style>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';
import PopupMixin from '@/utils/mixins/Popup';

import { AppToolbarsIconsNames } from '@/models/enums/toolbars';

@Component
export default class StudyBuddyTrigger extends Mixins(PopupMixin) {
  private enabledViaEvent = false;

  public AppToolbarsIconsNames = AppToolbarsIconsNames;

  private emitGlobalStudyBuddyEvent() {
    EventBus.$emit('study-buddy-event-global');
  }

  public mounted() {
    EventBus.$on('enable-instruction-popup-for-page', () => {
      this.enabledViaEvent = true;
    });

    EventBus.$on('disable-instruction-popup-for-page', () => {
      this.enabledViaEvent = false;
    });
  }

  public beforeDestroy() {
    EventBus.$off('enable-instruction-popup-for-page');
    EventBus.$off('disable-instruction-popup-for-page');
  }
}
</script>
