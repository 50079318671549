<template>
  <div class="pulse-loader">
    <div
      v-for="dot in 8"
      :key="dot"
      :style="getDotPositionStyles(dot)"
      class="pulse-loader__dot"
    >
      <div
        :class="[
          'pulse-loader__dot-item',
          { 'pulse-loader__dot-item--animated': currentAnimatedDot === dot },
        ]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PulseLoader extends Vue {
  public currentAnimatedDot = 5;
  public animationInterval!: number;

  public getDotPositionStyles(dot: number) {
    const dotIndex = dot - 1;

    const angle = 45; // 360deg / 8 (items count)
    const rotateValue = dotIndex * angle;

    return {
      transform: `
        rotate(${rotateValue}deg)
        translate(25px)
        rotate(-${rotateValue}deg)
      `,
    };
  }

  public mounted() {
    this.animationInterval = window.setInterval(() => {
      const isLast = this.currentAnimatedDot === 8;
      this.currentAnimatedDot = isLast ? 1 : this.currentAnimatedDot + 1;
    }, 300);
  }
}
</script>

<style lang="scss" scoped>
.pulse-loader {
  position: relative;
  z-index: 2;

  width: 100%;
  height: 100%;

  background-color: $pink200;
  border-radius: 50%;
  box-shadow: 2px 2px 0 0 $pink200Shadow;

  &__dot {
    width: 14px;
    height: 14px;

    margin: -7px;

    background: $white100;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    &-item {
      transition: 0.3s;
      animation-name: bounce;
      animation-duration: 0.3s;
      animation-iteration-count: infinite;

      height: 100%;
      background: inherit;
      border-radius: inherit;

      &--animated {
        transform: scale(1.4);
      }
    }
  }
}
</style>
