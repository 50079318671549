<template>
  <Hotspot
    :type="type"
    :bg-color="metaStyles.backgroundColor"
    :data="data"
    @click.native="handleClick"
  />
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import Hotspot from './Hotspot.vue';

import PositionedComponents from '../mixins/PositionedComponents.mixin';
import HotspotsStyles from '../mixins/HotspotStyles.mixin';

import Sound from '@/utils/helpers/Sound';
import { isCorrectFileExtension } from '@/utils/helpers/assets';
import EventBus from '@/utils/helpers/EventBus';

import { IActivityComponentDataElement } from '@/models/interfaces/activities';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';

@Component({
  components: {
    Hotspot,
  },
})
export default class ActivityHotspot extends Mixins(
  PositionedComponents,
  HotspotsStyles,
) {
  private isAlreadySelected = false;
  private selectedHotspots: IActivityComponentDataElement[] = [];

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private handleClick() {
    if (isCorrectFileExtension(['mp3', 'wav'], this.data.label)) {
      const src = this.isOnline
        ? this.data.label
        : this.findPreloadedAsset('sound', this.data.label);

      new Sound({
        autoplay: true,
        src,
        html5: true,
        format: ['mp3', 'm4a'],
      });

      EventBus.$emit('activity-event-audio-hotspot-select', this.data);
    }

    if (!this.isAlreadySelected) {
      this.isAlreadySelected = true;
      this.$emit('click', this.type, this.data);
    }
  }
}
</script>
