import { Module, ActionTree, MutationTree } from 'vuex';
import { AxiosResponse, AxiosError } from 'axios';
import { take, uniqBy, prop } from 'ramda';

import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';

import { RootState } from '..';
import { INotification } from '@/models/interfaces/notifications';
import { IVuexState } from '@/models/interfaces/store';

export interface State {
  hasUnseenNotifications: boolean;
  notifications: IVuexState<INotification[]>;
}

export const actions: ActionTree<State, RootState> = {
  fetchNotifications({ commit, rootState }) {
    const { session } = rootState;
    const { userId } = session;

    if (userId) {
      commit('setNotifications');

      return DataProvider.get<{ notifications: INotification[] }>(
        `users/${userId}/notifications`,
      )
        .then(response => {
          const { notifications } = response.data;

          commit('mergeNotifications', notifications);
          return notifications;
        })
        .catch((error: AxiosError) => {
          commit('setNotifications', error);
          return error;
        });
    }
  },
  sendLastReadMessageId({ state, rootState }) {
    const { session } = rootState;
    const { userId } = session;

    // There's no date property on notifications, so we make an assumption that
    // the latest message is the first one on the list. It should not impact the
    // front-end behavior based on how "mergeNotifications" is implemented.
    const lastReadMessageId = state.notifications?.data?.[0]?.id;

    if (userId && lastReadMessageId) {
      return DataProvider.put(`users/${userId}/notifications`, {
        lastReadMessageId,
      })
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          return error;
        });
    }
  },
};

export const mutations: MutationTree<State> = {
  markAsSeen(state) {
    state.hasUnseenNotifications = false;
  },
  setNotifications(state, data?: INotification[] | Error) {
    state.notifications = StoreUtil.updateState(state.notifications, data);
  },
  mergeNotifications(state, notifications: INotification[]) {
    const oldNotifications = state.notifications?.data || [];
    const allNotifications = [...notifications, ...oldNotifications];
    const uniqueNotifications = uniqBy(prop('id'), allNotifications);
    const newNotifications = take(30, uniqueNotifications);

    // if (!equals(newNotifications, oldNotifications)) {
    //   state.hasUnseenNotifications = true;
    // }

    state.hasUnseenNotifications = newNotifications.some(notif => notif.isNew);

    state.notifications = StoreUtil.updateState(
      state.notifications,
      newNotifications,
    );
  },
};

export const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    hasUnseenNotifications: false,
    notifications: StoreUtil.state<INotification[]>(),
  },
  mutations,
  actions,
};

export default store;
