import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  IActivityRelationData,
  IActivityButtonPayload,
} from '@/models/interfaces/activities';
import EventBus from '@/utils/helpers/EventBus';

@Component
export default class ComponentsWithRelations extends Vue {
  @Prop({ required: false })
  protected relations!: IActivityRelationData;

  protected get hasAudioRelations() {
    return !!this.relations?.find(item => item.componentId.includes('audio'));
  }

  protected handleRelations(
    target: IActivityButtonPayload,
    type = 'imageButtons',
  ) {
    if (!this.relations) return;

    const { id: targetId } = target;
    const relation = this.relations.find(
      relation => relation.componentTriggerId === targetId,
    );

    EventBus.$emit('activity-event-relation-change', {
      type,
      data: relation,
    });
  }
}
