<template>
  <section class="activity activity--type-in-box">
    <ActivityTextContainer class="text-container">
      <KeyboardField
        ref="keyboardField"
        class="field-wrapper"
        :value="typedWord"
      />
    </ActivityTextContainer>

    <KeyboardFreewrite
      mode="default"
      :input="typedWord"
      @onChange="handleInputChange"
    />
  </section>
</template>

<style lang="scss" scoped>
.littlebridge-keyboard {
  position: static;

  transform: none;
  max-width: $activityComponentContainerWidth;
  margin: auto;
}

.field-wrapper {
  margin: 0 0 15px;
  width: 100%;

  display: flex;
  align-items: center;
}

::v-deep .field {
  font-size: 15px;
  line-height: 20px;
  color: $blue100;
  font-weight: bold;
  text-align: left;
  height: 20px;

  border: none;
  box-shadow: none;

  width: 100%;
  padding: 0 10px;
}

.text-container {
  text-align: left;

  max-width: $activityComponentBoxWidth;

  margin: 0 auto 10px;
  padding: 0;
  height: 50px;
  border-radius: 0;

  background: $white100;
}
</style>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import EventBus from '@/utils/helpers/EventBus';

import KeyboardFreewrite from '@/components/Keyboard/KeyboardFreewrite.vue';
import ActivityTextContainer from '@/components/ActivityTextContainer/ActivityTextContainer.vue';
import KeyboardField from '@/components/KeyboardField/KeyboardField.vue';

import { IActivityComponent } from '@/models/interfaces/activities';

@Component({
  components: {
    KeyboardFreewrite,
    ActivityTextContainer,
    KeyboardField,
  },
})
export default class ActivityTypeInTextBox extends Vue {
  @Prop({ required: true })
  private type!: IActivityComponent<'typeInTextBox'>['type'];

  @Prop({ required: true })
  private data!: IActivityComponent<'typeInTextBox'>['data'];

  @Ref()
  public keyboardField!: InstanceType<typeof KeyboardField>;

  private typedWord = '';

  private async handleInputChange(input: string) {
    // wait for textarea to be available to focus (after the click handlers are finished)
    await new Promise(process.nextTick);
    this.keyboardField.focusField();

    if (this.typedWord.length || !input) {
      EventBus.$emit('activity-event-solution-del', {
        type: this.type,
        data: {
          ...this.data[0],
          value: this.typedWord,
        },
      });
    }

    this.typedWord = input;

    if (!input) {
      // the backspace action has been performed
      // we should not add an empty value to the
      // solution
      return;
    }

    EventBus.$emit('activity-event-solution-add', {
      type: this.type,
      data: {
        ...this.data[0],
        value: input,
      },
    });
  }

  public mounted() {
    EventBus.$on('on-keyboard-field-native-input', this.handleInputChange);
  }

  public beforeDestroy() {
    EventBus.$off('on-keyboard-field-native-input', this.handleInputChange);
  }
}
</script>

<docs>
```jsx
<ActivityTypeInTextBox type="typeInTextBox" :data="[{  }]" />
```
</docs>
