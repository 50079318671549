import { ActionTree, MutationTree, GetterTree, Module } from 'vuex';

import { IColorSettings } from '@/models/interfaces/color-settings';
import { IAchievementData } from '@/models/interfaces/achievements';

import VisualSettings from '@/utils/helpers/VisualSettings';
import StoreUtil from '@/utils/helpers/StoreUtil';
import DataProvider from '@/utils/helpers/DataProvider';

import { RootState } from '..';
import { IVuexState } from '@/models/interfaces/store';

const defaultAchievementsSettings = VisualSettings.getDefaultAchievementsSettings();

export interface State {
  achievements: IVuexState<IAchievementData>;
  achievementsSettings: IColorSettings;
}

export const actions: ActionTree<State, RootState> = {
  saveAchievementsSettings({ state }) {
    localStorage.setItem(
      'achievementsSettings',
      JSON.stringify(state.achievementsSettings),
    );
  },
  async fetchAchievements({ commit, rootState }, digiPalId?: string) {
    const {
      session: { userId },
    } = rootState;
    const id = digiPalId || userId;

    commit('setAchievementsData');

    try {
      const response = await DataProvider.get(`users/${id}/achievements`);

      commit('setAchievementsData', response.data);
      return response.data;
    } catch (e) {
      commit('setAchievementsData', e);
      return e;
    }
  },
};

export const mutations: MutationTree<State> = {
  setAchievementsData(state, data?: IAchievementData | Error) {
    state.achievements = StoreUtil.updateState(state.achievements, data);
  },
  updateAchievementsSettings(state, settings: IColorSettings) {
    state.achievementsSettings = Object.assign(
      state.achievementsSettings,
      settings,
    );
  },
};

export const getters: GetterTree<State, RootState> = {
  getVisualSettings(state) {
    return state.achievementsSettings;
  },
};

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    achievements: StoreUtil.state<IAchievementData>(),
    achievementsSettings: VisualSettings.getTilesSettingsFromStorage(
      defaultAchievementsSettings,
      'achievementsSettings',
    ),
  },
  mutations,
  actions,
  getters,
};

export default store;
