<template>
  <div class="container">
    <Draggable
      group="dragAndDropActivity"
      animation="150"
      sort="false"
      :list="enhancedData"
      class="activity-draggable-text-buttons"
    >
      <ActivityTextButton
        v-for="{ data: button } in enhancedData"
        :key="`${button.id}-${button.value}`"
        v-touch:tap="() => handleClick(button)"
        :bg-color-type="
          button.label || hasAudioRelations ? 'audio' : 'draggable'
        "
        data-testID="draggable-button"
        mobile-size="medium"
      >
        {{ button.value }}
      </ActivityTextButton>
    </Draggable>

    <button
      type="button"
      :class="['reset-btn', { 'reset-btn--disabled': disableReset }]"
      @click="resetDraggableData"
    >
      <i class="fas fa-redo" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.activity-draggable-text-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 0;
}

.container {
  position: relative;
  max-width: $activityComponentBoxWidth;
  margin: 0 auto;

  @media (max-width: #{$mobile}px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

.reset-btn {
  font-size: 25px;
  color: $white100;

  width: 40px;
  height: 40px;

  background: $green200;
  border-radius: 50%;

  position: absolute;
  top: 5px;
  right: -40px;

  &--disabled {
    pointer-events: none;
    background: darken($grey100, 20%);
  }

  @media (max-width: #{$mobile}px) {
    position: static;

    margin-top: 10px;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';

import EventBus from '@/utils/helpers/EventBus';

import ActivityTextButton from '@/components/ActivityTextButton/ActivityTextButton.vue';
import Draggable from 'vuedraggable';
import ComponentsWithRelations from '@/components/Activity/mixins/ComponentsWithRelations';

import {
  IActivityComponentDataElement,
  IActivitySolution,
} from '@/models/interfaces/activities';
import Sound from '@/utils/helpers/Sound';
import { insert } from 'ramda';
import { Getter } from 'vuex-class';
import { PreloadedAssetType } from '@/store/modules/preload-assets';
import randomise from '@/utils/helpers/randomise';

type DragCustomEvent = {
  originalEvent: DragEvent & { changedTouches: any[] };
  item: any;
};

@Component({
  components: {
    ActivityTextButton,
    Draggable,
  },
})
export default class ActivityDraggableTextButtons extends Mixins(
  ComponentsWithRelations,
) {
  @Prop({ required: true })
  private data!: IActivityComponentDataElement[];

  @Prop({ required: true, type: String })
  private type!: string;

  @Getter('preloadAssets/findPreloadedAsset')
  private findPreloadedAsset!: (
    type: PreloadedAssetType,
    originalUrl: string,
  ) => string;

  private ableToPlayAgain = true;

  enhancedData: Omit<
    IActivitySolution & { indexFromNativeList: number },
    'meta'
  >[] = randomise(
    this.data.map((element, idx) => ({
      data: element,
      indexFromNativeList: idx,
      type: this.type,
    })),
  );

  private get disableReset() {
    return this.enhancedData.length === this.data.length;
  }

  private updateList(
    textButton: Omit<
      IActivitySolution & { indexFromNativeList: number },
      'meta'
    >,
  ) {
    this.enhancedData = [...this.enhancedData, textButton];
  }

  private updateListByIndex(
    updateData: {
      index: number;
      data: Omit<IActivitySolution & { indexFromNativeList: number }, 'meta'>;
    } | null,
  ) {
    if (!updateData) {
      this.setUpdatedEnhancedData(this.enhancedData);
      return;
    }

    this.enhancedData = insert(
      updateData.index,
      updateData.data,
      this.enhancedData,
    );

    this.setUpdatedEnhancedData(this.enhancedData);
  }

  private setUpdatedEnhancedData(data: Omit<IActivitySolution, 'meta'>[]) {
    this.enhancedData = data.map((item, idx) => ({
      ...item,
      indexFromNativeList: idx,
    }));
  }

  private handleClick(data: IActivityComponentDataElement) {
    this.handleRelations(data, 'activityDraggableButtons');

    if (data.label && this.ableToPlayAgain) {
      // for the buttons that should trigger audio
      // they will have an mp3 link in the label field:

      this.ableToPlayAgain = false;

      const src = this.isOnline
        ? data.label
        : this.findPreloadedAsset('sound', data.label);

      new Sound({
        src,
        autoplay: true,
        html5: true,
        format: ['mp3', 'm4a'],
      }).on('end', () => {
        this.ableToPlayAgain = true;
      });
    }
  }

  private filterList(itemId: string) {
    this.enhancedData = this.enhancedData.filter(
      item => (item.data as IActivityComponentDataElement).id !== itemId,
    );
  }

  private resetDraggableData() {
    this.enhancedData = randomise(
      this.data.map((element, idx) => ({
        data: element,
        indexFromNativeList: idx,
        type: this.type,
      })),
    );

    EventBus.$emit('activity-event-solution-bulk-update', []);
    EventBus.$emit('activity-event-reset-draggable-data');
  }

  public mounted() {
    EventBus.$on('activity-event-update-draggable-list', this.updateList);
    EventBus.$on('activity-event-filter-draggable-list', this.filterList);
    EventBus.$on(
      'activity-event-update-draggable-list-by-index',
      this.updateListByIndex,
    );
  }

  public destroyed() {
    EventBus.$off('activity-event-update-draggable-list', this.updateList);
    EventBus.$off('activity-event-filter-draggable-list', this.filterList);
    EventBus.$off(
      'activity-event-update-draggable-list-by-index',
      this.updateListByIndex,
    );
  }
}
</script>

<docs>
### A component that renders a list of text buttons, allow to drag them

### Default

```jsx
<ActivityDraggableTextButtons
  :data="[
    {
      id: '1',
      label: '',
      value: 'Button_1',
      index: 1
    },
    {
      id: '2',
      label: '',
      value: 'Button_2',
      index: 2
    },
    {
      id: '',
      label: '',
      value: 'Button_3',
      index: 3
    }
  ]"
  type="draggableTextButtons"
/>
```

### If the buttons contain label prop, it they are coloured purple and trigger audio
*Label* contains an audio URL

```jsx
<ActivityDraggableTextButtons
  :data="[
    {
      id: '1',
      label: 'Button',
      value: 'Button_1',
      index: 1
    },
    {
      id: '2',
      label: 'Button',
      value: 'Button_2',
      index: 2
    },
    {
      id: '',
      label: 'Button',
      value: 'Button_3',
      index: 3
    }
  ]"
  type="draggableTextButtons"
/>
```
</docs>
