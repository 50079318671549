<template>
  <div class="v-slider">
    <button
      v-if="items.length"
      class="v-slider__trigger"
      type="button"
      :disabled="!canScrollUp"
      @click="scrollUp"
    >
      <img src="/images/training-menu/slider-arrow-up.png" alt="Arrow top" />
    </button>

    <div
      :style="{
        height: `${itemViewHeight}px`,
      }"
      class="v-slider__content"
    >
      <div
        class="v-slider__scroller"
        :style="{
          height: `${scrollerHeight}px`,
          transform: `translateY(${scrollerPosition}px)`,
        }"
      >
        <div
          v-for="item in items"
          ref="sliderItems"
          :key="item[itemKey]"
          :style="{
            marginBottom: `${itemSpacing}px`,
          }"
        >
          <slot name="item" :item="item" />
        </div>
      </div>
    </div>

    <button
      v-if="items.length"
      class="v-slider__trigger"
      type="button"
      :disabled="!canScrollDown"
      @click="scrollDown"
    >
      <img
        src="/images/training-menu/slider-arrow-down.png"
        alt="Arrow bottom"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const DEFAULT_ITEMS_PER_VIEW = 5;
const DEFAULT_ITEM_SPACING = 12;

@Component
export default class VerticalSlider extends Vue {
  @Prop({ type: Number, default: DEFAULT_ITEMS_PER_VIEW })
  public itemsPerView!: number;

  @Prop({ type: Number, default: DEFAULT_ITEM_SPACING })
  public itemSpacing!: number;

  @Prop({ type: Array, default: () => [] })
  public items!: any[];

  @Prop({ type: String, required: true })
  public itemKey!: string;

  public itemViewHeight = 0;
  public scrollerHeight = 0;
  public scrollerPosition = 0;
  public currentViewIdx = 0;

  public get canScrollUp() {
    return this.currentViewIdx !== 0;
  }

  public get canScrollDown() {
    return this.currentViewIdx < this.viewsCount - 1;
  }

  public get viewsCount() {
    return Math.ceil(this.items.length / this.itemsPerView);
  }

  public get currentPageNumber() {
    return this.currentViewIdx + 1;
  }

  public scrollUp() {
    this.scrollerPosition = this.scrollerPosition + this.itemViewHeight;
    this.currentViewIdx--;
  }

  public scrollDown() {
    this.scrollerPosition = -(this.currentPageNumber * this.itemViewHeight);
    this.currentViewIdx++;
  }

  public buildSlider() {
    const sliderItem = (this.$refs.sliderItems as Element[])?.[0];

    if (!sliderItem) {
      return;
    }

    const combinedItemHeight = sliderItem.clientHeight + DEFAULT_ITEM_SPACING;
    this.itemViewHeight = combinedItemHeight * this.itemsPerView;
    this.scrollerHeight = this.itemViewHeight * this.viewsCount;
  }

  public mounted() {
    this.buildSlider();
  }

  @Watch('itemsPerView')
  public onViewChange() {
    this.buildSlider();
  }
}
</script>

<style lang="scss" scoped>
.v-slider {
  @include d-flex-col;

  &__content {
    @include d-flex-col;

    overflow-y: hidden;
    overflow-x: visible;
  }

  &__scroller {
    transition: 0.2s;
  }

  &__trigger {
    padding: 15px;
    margin-right: 40px;

    &:disabled {
      opacity: 0.5;
    }

    img {
      height: 40px;

      @media (max-width: #{$mobile}px) {
        height: 25px;
      }
    }
  }
}
</style>
