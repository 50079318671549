<template>
  <transition name="fade" appear>
    <div v-if="isVisible" class="activity-confirm-video">
      <div class="activity-confirm-video__asset">
        <AssetVideo
          :fullsize="true"
          :src="src"
          :controls="controls"
          :autoplay="autoplay"
          :trigger-duration-end="triggerDurationEnd"
        />
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.activity-confirm-video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  max-width: $activityComponentContainerWidth;
  margin: auto;

  z-index: map-get($zindex, confirmationVideo);

  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
}
.activity-confirm-video__asset {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: $activityMediaTopSpace;
  border: $activityMediaBorderSize solid $white100;
  border-radius: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity cubic-bezier(0.52, 1.56, 0.58, 1) 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AssetVideo from '@/components/Asset/Video.vue';

@Component({
  components: {
    AssetVideo,
  },
})
export default class ActivityConfirmationVideo extends Vue {
  @Prop({ required: true, default: '' })
  public src!: string;

  @Prop({ required: false, default: false })
  public controls!: boolean;

  @Prop({ required: false, default: true })
  public autoplay!: boolean;

  @Prop({ required: false, default: true })
  public triggerDurationEnd!: boolean;

  @Prop({ required: true, default: false })
  public isVisible!: boolean;
}
</script>
