<template>
  <div class="navigation-menu">
    <ul class="navigation-menu__content">
      <router-link
        v-if="isAppFeatureEnabled('APP_DICTIONARY')"
        v-slot="{ navigate }"
        custom
        :to="{ name: routeNames.dictionary }"
        @click.native="$emit('on-close')"
      >
        <li role="link" class="navigation-menu__item" @click="navigate">
          Dictionary
        </li>
      </router-link>

      <router-link
        v-if="isAppFeatureEnabled('SCREEN_REFERRALS')"
        v-slot="{ navigate }"
        custom
        to="/referrals"
        @click.native="$emit('on-close')"
      >
        <li role="link" class="navigation-menu__item" @click="navigate">
          Referrals
        </li>
      </router-link>

      <router-link
        v-if="isAppFeatureEnabled('SCREEN_SETTINGS')"
        v-slot="{ navigate }"
        custom
        :to="{ name: routeNames.settings }"
        @click.native="$emit('on-close')"
      >
        <li role="link" class="navigation-menu__item" @click="navigate">
          Settings
        </li>
      </router-link>

      <router-link
        v-slot="{ navigate }"
        custom
        :to="{ name: routeNames.achievements }"
        @click.native="$emit('on-close')"
      >
        <li role="link" class="navigation-menu__item" @click="navigate">
          My achievements
        </li>
      </router-link>

      <router-link
        v-slot="{ navigate }"
        custom
        :to="{ name: routeNames.communityGuidelines }"
        @click.native="$emit('on-close')"
      >
        <li role="link" class="navigation-menu__item" @click="navigate">
          {{ `Community \n Guidelines` }}
        </li>
      </router-link>

      <li class="navigation-menu__item">
        <button type="button" class="logout-btn" @click="logout">
          Log out
        </button>
      </li>
    </ul>
    <div class="navigation-menu__overlay" @click="$emit('on-close')" />
  </div>
</template>

<style lang="scss" scoped>
.logout-btn {
  color: $blue100;
  font-weight: 700;
}

.navigation-menu {
  position: fixed;
  z-index: 6;
  top: 55px;
  left: 0;
  width: 100%;
  height: 100%;

  &__overlay {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.6);
    z-index: 2;
  }

  &__content {
    margin: 0;
    position: relative;
    z-index: 3;
    background: $white100;
    list-style: none;
    padding: 0;

    @media (min-width: #{$mobile}px) {
      width: 30%;
      left: initial;
      right: 0;
    }

    @media (min-width: #{$tablet}px) {
      width: 25%;
      left: initial;
      right: 0;
    }
  }

  &__item {
    color: $blue100;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;

    box-shadow: 0px 2px 0px $grey400;

    padding: 24px 0;
    text-align: center;
    cursor: pointer;

    white-space: pre;
  }

  @media (min-width: #{$mobile}px) {
    top: 82px;

    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
</style>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import AppFeatures from '@/utils/mixins/AppFeatures';
import AppHeaderMenus from '@/utils/mixins/AppHeaderMenus';

import routeNames from '@/router/route-names';

@Component
export default class NavigationMenu extends Mixins(
  AppFeatures,
  AppHeaderMenus,
) {
  @Action('session/logout')
  public logout!: () => Promise<void>;

  public routeNames = routeNames;
}
</script>

<docs>
```jsx
<NavigationMenu style="position: static" />
```
</docs>
