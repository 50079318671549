import { render, staticRenderFns } from "./KeyboardField.vue?vue&type=template&id=01e4b772&scoped=true"
import script from "./KeyboardField.vue?vue&type=script&lang=ts"
export * from "./KeyboardField.vue?vue&type=script&lang=ts"
import style0 from "./KeyboardField.vue?vue&type=style&index=0&id=01e4b772&prod&lang=scss&scoped=true"
import style1 from "./KeyboardField.vue?vue&type=style&index=1&id=01e4b772&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01e4b772",
  null
  
)

/* custom blocks */
import block0 from "./KeyboardField.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports