<template>
  <button
    v-bind="$attrs"
    type="button"
    :class="[
      'base-button',
      typeClass(),
      {
        'base-button--touched': isTouched,
        'base-button--append': $slots.append,
        'base-button--loading': loading,
        'base-button--squared': squared,
      },
    ]"
    @mousedown="isTouched = true"
    @mouseup="isTouched = false"
    @touchstart="isTouched = true"
    @touchend="isTouched = false"
    @blur="isTouched = false"
  >
    <Spinner v-if="loading" :style="{ margin: 0 }" color="white" />

    <slot v-else />

    <img
      v-if="icon"
      class="base-button__icon"
      :src="`/images/icons/${icon}.png`"
      alt="Base Button Icon"
    />

    <div
      v-if="$slots.append"
      :class="['base-button__append', typeClass('__append')]"
    >
      <slot name="append" />
    </div>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import Spinner from '@/components/Spinner/Spinner.vue';

// TODO: Add more types
type ButtonType = 'default' | 'secondary' | 'danger';

@Component({
  components: {
    Spinner,
  },
})
export default class BaseButton extends Vue {
  @Prop({ type: String, default: 'default' })
  public type!: ButtonType;

  @Prop({ type: String })
  public icon!: string | undefined;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  @Prop({ type: Boolean, default: false })
  public squared!: boolean;

  public isTouched = false;

  public typeClass(extraClass = '') {
    return `base-button${extraClass}--${this.type}`;
  }
}
</script>

<style lang="scss" scoped>
$default-color: $green400;
$default-shadow: $green400Shadow;

$danger-color: $pink200;
$danger-shadow: $pink200Shadow;

$secondary-color: $purple100;
$secondary-shadow: $purple100Shadow;

.base-button {
  font-weight: bold;
  color: $white100;
  font-size: 20px;
  line-height: 1;

  @include d-flex-center;

  position: relative;

  padding: 10px 30px;
  border-radius: 60px;

  transition: 0.1s;

  @media (min-width: #{$mobile}px) {
    font-size: 30px;
  }

  &__icon {
    height: 30px;
  }

  &--append {
    padding: 10px 40px 10px 20px;

    @media (min-width: #{$mobile}px) {
      padding-right: 50px;
    }
  }

  &--touched {
    transform: scale(0.8);
  }

  &--loading,
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &__append {
    position: absolute;

    right: -10px;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    max-height: calc(100% + 2px);
    max-width: 100%;
    aspect-ratio: 1 / 1;

    border-radius: 100%;

    &--default {
      background-color: $default-color;
      box-shadow: 0px 2px 0px $default-shadow;

      border: 1px solid $default-shadow;
    }
  }

  &--default {
    box-shadow: 0px 4px 0px $default-shadow;
    background-color: $default-color;
  }

  &--danger {
    box-shadow: 0px 4px 0px $danger-shadow;
    background-color: $danger-color;
  }

  &--secondary {
    box-shadow: 0px 4px 0px $secondary-shadow;
    background-color: $secondary-color;
  }

  &--squared {
    border-radius: 9px;
  }
}
</style>
