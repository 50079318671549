<template>
  <div class="lingos-info">
    <img src="/images/activities/lingo.png" alt="Lingos" />

    +{{ lingos }}
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LingoInfo extends Vue {
  @Prop({ type: String, required: true })
  public lingos!: string;
}
</script>

<style lang="scss" scoped>
.lingos-info {
  @include d-flex-items-center;

  font-size: 70px;
  color: $blue100;
  font-weight: bold;

  img {
    height: 110px;
    margin-right: 10px;

    @media (max-width: #{$mobile}px) {
      height: 80px;
    }
  }

  @media (max-width: #{$mobile}px) {
    font-size: 50px;
  }
}
</style>
