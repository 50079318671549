export default {
  initial: 'initial',
  dictionary: 'dictionary',
  intro: 'intro',
  onboarding: 'onboarding',
  globe: 'globe',
  achievements: 'achievements',
  digipalAchievements: 'digipal-achievements',
  interests: 'interests',
  status: 'status',
  referrals: 'referrals',
  settingsWallpaper: 'settings-wallpaper',
  friends: 'friends',
  friendsMutual: 'friends-mutual',
  friendsSuggested: 'friends-suggested',
  friendsPending: 'friends-pending',
  friendsSearch: 'friends-search',
  myProfile: 'my-profile',
  conversations: 'conversations',
  conversation: 'conversation',
  digipal: 'digipal',
  newsfeedNew: 'newsfeed-new',
  activities: 'activities',
  trainingMenu: 'training-menu',
  activityRewards: 'activity-rewards',
  activityBuilder: 'activity-builder',
  songMenu: 'song-menu',
  settings: 'settings',
  activityNoAccess: 'activity-no-access',
  communityGuidelines: 'community-guidelines',
  notifications: 'notifications',
  messageRewards: 'message-rewards',
};
