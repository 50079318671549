<template>
  <div class="keyboard-field">
    <div class="keyboard-field__wrapper">
      <textarea
        ref="field"
        v-bind="$attrs"
        class="field"
        :rows="$attrs.rows || 4"
        :value="value"
        :readonly="readonly"
        @input="onInput"
        @scroll="setCaretPosition"
      />
      <div
        v-if="isMobileResolution"
        class="caret"
        :style="{
          height: `${caretData.height}px`,
          left: `${caretData.left}px`,
          top: `${caretData.top}px`,
        }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.keyboard-field__wrapper {
  position: relative;
  width: 100%;

  overflow: hidden;
  line-height: 1;
}

.field {
  resize: none;

  width: 100%;

  background: $white100;
  border: 2px solid $blue100;
  box-shadow: 0px 2px 0px $blue100;
  border-radius: 5px;

  font-size: 14px;
  line-height: 17px;
  color: $blue100;

  padding: 15px;

  @media (min-width: #{$mobile}px) {
    padding: 25px;

    font-size: 20px;
    line-height: 24px;
  }
}

.keyboard-field {
  position: relative;
}

.caret {
  width: 2px;

  background: $blue100;

  position: absolute;

  animation: caret 0.7s linear infinite;
}

@keyframes caret {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

<style lang="scss">
// while the cursor position is dynamically calculated by textarea-caret, this package creates an element
// by document.createElement in process. It causes screen shuddering on some devices.
// here we just set the absolute position so this element
// will not affect the layout

#input-textarea-caret-position-mirror-div {
  position: absolute;
}
</style>

<script lang="ts">
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';

import getCaretPosition from 'textarea-caret';
import EventBus from '@/utils/helpers/EventBus';

@Component
export default class KeyboardField extends Vue {
  @Ref()
  private field!: HTMLTextAreaElement;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ required: true, type: String })
  private value!: string;

  private scrollTimeout = 0;

  private caretData = {
    height: 0,
    left: 0,
    top: 0,
  };

  private get readonly() {
    return this.disabled || this.isMobileResolution;
  }

  private get isMobileResolution() {
    return this.windowWidth < 1024;
  }

  private setCaretPosition() {
    if (this.isMobileResolution) {
      const scrollTop = this.field.scrollTop;
      const position = getCaretPosition(this.field, this.value.length);

      this.caretData = { ...position, top: position.top - scrollTop - 2 };
    }
  }

  private onInput(e: InputEvent) {
    type Target = HTMLInputElement;

    EventBus.$emit(
      'on-keyboard-field-native-input',
      (e?.target as Target)?.value,
    );
  }

  public focusField() {
    if (!this.isMobileResolution) {
      this.field.focus();
    }
  }

  public mounted() {
    this.focusField();
    this.setCaretPosition();

    EventBus.$on('activity-event-instruction-popup-closed', this.focusField);
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-instruction-popup-closed');
  }

  @Watch('value')
  private onInputChange() {
    this.$nextTick(() => {
      this.setCaretPosition();
    });
  }
}
</script>

<docs>
### A component that should be used with Keyboard component

**Note:** this input component contains custom caret cursor logic

```vue
<template>
  <div class="display: flex; flex-direction: column">
    <KeyboardField :value="keyboardValue" />

   <Keyboard
      style="position: static"
      :input="keyboardValue"
      @on-change="handleInputChange"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyboardValue: '',
    }
  },
  methods: {
    handleInputChange(input) {
      this.keyboardValue = input;
    }
  }
}
</script>
```
</docs>
