var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"activity-carousel"},[_c('button',{class:{
      'prev-button': true,
      disabled: !_vm.buttons.left,
    },on:{"click":_vm.prev}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: this.$t('tooltip.activities.previous').toString(),
      }),expression:"{\n        content: this.$t('tooltip.activities.previous').toString(),\n      }"}],staticClass:"prev-button__img",attrs:{"src":require(`@/assets/images/activities/slider/prev.png`),"alt":"Prev Slider Icon","title":"Previous slide"}})]),_c('button',{class:{
      'next-button': true,
      disabled: !_vm.buttons.right,
    },on:{"click":_vm.next}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: this.$t('tooltip.activities.next').toString(),
      }),expression:"{\n        content: this.$t('tooltip.activities.next').toString(),\n      }"}],staticClass:"next-button__img",attrs:{"src":require(`@/assets/images/activities/slider/next.png`),"alt":"Next Slider Icon","title":"Next slide"}})]),_c('Carousel',{ref:"carousel",class:{
      'cancel-overflow': _vm.isFullscreen,
    },attrs:{"auto-height":"","navigate-by-slide":"","items-per-view":1,"speed":2500,"enable-buttons":false},on:{"on-slide-change":_vm.handleSlideChange}},_vm._l((_vm.data),function(image){return _c('button',{key:image.id,staticClass:"activity-carousel__image"},[_c('ActivityImage',{ref:"image",refInFor:true,attrs:{"data":[{ value: image.value, id: Math.random() }],"is-carousel-item":true},on:{"on-fullscreen-toggle":_vm.onFullscreenToggle,"on-position-hotspots":_vm.positionHotspots}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }