<template>
  <ActivityAudio
    v-if="audioData.length"
    :key="key"
    :data="audioData"
    :meta="{ timeout: 1 }"
  />
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import ActivityHiddenContainerComponent from './ActivityHiddenContainerComponent.vue';

import ActivityAudio from './ActivityAudio.vue';
import {
  IActivityComponentData,
  IActivityEnhancedComponentData,
} from '@/models/interfaces/activities';

@Component({
  components: { ActivityAudio },
})
export default class ActivityAudioTrigger extends ActivityHiddenContainerComponent {
  private audioData: IActivityComponentData = [];
  private key = 0;

  public changeComponent(
    enhancedComponentData: IActivityEnhancedComponentData,
  ) {
    this.audioData = [enhancedComponentData];
    this.key++;
  }
}
</script>
