import { render, staticRenderFns } from "./ActivitySpeaking.vue?vue&type=template&id=40d5f912&scoped=true"
import script from "./ActivitySpeaking.vue?vue&type=script&lang=ts"
export * from "./ActivitySpeaking.vue?vue&type=script&lang=ts"
import style0 from "./ActivitySpeaking.vue?vue&type=style&index=0&id=40d5f912&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d5f912",
  null
  
)

export default component.exports