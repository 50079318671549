<template>
  <div
    :class="{
      'speech-bubble draggable': true,
      'speech-bubble--left': direction === 'left',
      'speech-bubble--right': direction === 'right',
      'speech-bubble--typing': isTypingGaps,
    }"
    :style="{
      left: `${position.left}px`,
      top: `${position.top}px`,
      width: `${sizing.width}px`,
      fontSize: `${sizing.fontSize}px`,
      display: isDraggableGaps ? 'block' : 'flex',
      padding: hasManyGaps ? '10px' : '0',
    }"
  >
    <template v-if="isDraggableGaps">
      <Draggable
        v-for="(part, idx) in draggableParts"
        :key="part.valueWithUnderscore"
        :list="gapsData[idx]"
        :disabled="isDisableDragging"
        :tag="hasManyGaps ? 'span' : 'div'"
        group="dragAndDropActivity"
        :class="{
          'draggable-container': true,
          'draggable-container--multiple': hasManyGaps,
        }"
        animation="150"
        @change="handleUpdate($event, idx)"
        v-html="
          gapsData[idx] && gapsData[idx].length
            ? parsedString(part.valueWithUnderscore, idx)
            : part.valueWithUnderscore
        "
      />
    </template>

    <Draggable
      v-else
      group="dragAndDropActivity"
      animation="150"
      class="draggable-container"
      :list="computedData"
      :disabled="data.label"
      @change="handleUpdate"
    >
      <div v-if="isTypingGaps">
        <template v-for="(part, partIdx) in sentenceParts(data.label)">
          {{ part.value }}

          <!-- eslint-disable-next-line -->
          <div class="input-wrapper" v-if="part.letters">
            <input
              v-for="inputIdx in part.letters"
              :key="getGapInputIndex(inputIdx, partIdx, data.label)"
              :ref="`input${getGapInputIndex(inputIdx, partIdx, data.label)}`"
              v-model="
                models[getGapInputIndex(inputIdx, partIdx, data.label) - 1]
              "
              class="input"
              :data-key="getGapInputIndex(inputIdx, partIdx, data.label)"
              type="text"
              autocapitalize="none"
              @keyup="
                onRemoveValue(
                  $event,
                  getGapInputIndex(inputIdx, partIdx, data.label),
                )
              "
              @input="
                focusNext(
                  $event,
                  getGapInputIndex(inputIdx, partIdx, data.label),
                )
              "
            />
          </div>
        </template>
      </div>

      <template v-else>
        <p v-if="data.label" class="text">
          {{ data.label }}
        </p>

        <p v-else class="text">
          {{ computedData[0] ? computedData[0].data.value : '?' }}
        </p>
      </template>
    </Draggable>
  </div>
</template>

<style lang="scss" scoped>
.input-wrapper {
  display: inline-block;
}

.speech-bubble {
  position: absolute;

  background: $white100;
  color: $blue100;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 1px solid $blue100;
  user-select: none;
  z-index: map-get($zindex, speechBubble);
  font-weight: $font-weight;

  margin-top: calc(#{$activityMediaTopSpace} + #{$activityMediaBorderSize});

  ::v-deep {
    .sortable-ghost {
      display: none;
    }

    .draggable-value {
      color: $green100;
    }
  }

  &--typing {
    display: flex;
  }

  &::after,
  &::before {
    content: '';

    position: absolute;
    bottom: 0;

    width: 0;
    height: 0;

    border-style: solid;

    display: none;
  }

  &--left {
    border-bottom-left-radius: 0;

    &::after,
    &::before {
      right: 100%;
      display: block;
    }

    &::after {
      border-color: transparent $white100 transparent transparent;
      border-width: 20px 40px 0px 0;
    }

    &::before {
      border-color: transparent $blue100 transparent transparent;
      border-width: 23px 44px 0px 0;

      bottom: -1px;
    }

    @media (max-width: #{$mobile}px) {
      &::after {
        border-width: 10px 20px 0px 0;
      }

      &::before {
        border-width: 12px 24px 0px 0;
      }
    }
  }

  &--right {
    border-bottom-right-radius: 0;

    &::after,
    &::before {
      left: 100%;
      display: block;
    }

    &::after {
      border-color: transparent transparent transparent $white100;
      border-width: 20px 0 0px 40px;
    }

    &::before {
      border-color: transparent transparent transparent $blue100;
      border-width: 23px 0 0px 44px;
      bottom: -1px;
    }

    @media (max-width: #{$mobile}px) {
      &::after {
        border-width: 10px 0 0px 20px;
      }

      &::before {
        border-width: 12px 0 0px 24px;
      }
    }
  }

  &--hovered {
    transform: scale(1.2);
    transition: 0.3s;
  }
}

.input {
  width: 15px;

  font-size: inherit;
  font-weight: inherit;
  color: $blue100;
  text-align: center;

  border-bottom: 1px solid $blue100;

  margin-right: 2px;

  border-radius: 0;

  &:last-child {
    margin: 0;
  }
}

.draggable-container {
  width: 100%;
  padding: 10px;

  &--multiple {
    padding: 0;
  }
}

.text {
  text-align: center;
  margin: 0;
}
</style>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import {
  IActivitySolution,
  IActivityComponentDataElement,
} from '@/models/interfaces/activities';
import EventBus from '@/utils/helpers/EventBus';

import PositionedComponents from '../mixins/PositionedComponents.mixin';
import ActivityDraggableGaps from '../mixins/ActivityDraggableGaps.mixin';
import ActivityComponentWithDraggableGaps from '../mixins/ActivityComponentWithDraggableGaps.mixin';

import GapInputs from '@/components/ActivityTextContainerWithGaps/GapInputs.vue';

@Component({
  components: {
    Draggable,
    GapInputs,
  },
})
export default class ActivitySpeechBubble extends Mixins(
  PositionedComponents,
  ActivityDraggableGaps,
  ActivityComponentWithDraggableGaps,
) {
  @Prop({ required: true, type: Number })
  public order!: number;

  @Prop({ required: true })
  private draggableParts!: Array<{
    value: string;
    letters: number;
    valueWithUnderscore?: string;
  }>;

  private computedData: IActivitySolution[] = [];

  private addedTypedBubbles: IActivityComponentDataElement[] = [];

  private get hasManyGaps() {
    // Don't interpret underscores as gaps for speechBubbles
    return this.draggableParts.length > 1 && this.type != 'speechBubbles';
  }

  private get direction() {
    return this.itemStyles.direction;
  }

  private get isDraggableGaps() {
    return this.type === 'draggableSpeechBubblesWithGaps';
  }

  private get isTypingGaps() {
    return this.type === 'typingSpeechBubbles';
  }

  private handleUpdate(
    data: {
      removed?: { element: IActivitySolution };
      added?: { element: IActivitySolution };
    },
    draggableGapsIndex?: number,
  ) {
    this.disableOuterDragging();
    const indexExists = draggableGapsIndex !== undefined; // check for undefined, because it may be 0

    const order = indexExists ? this.order + draggableGapsIndex! : this.order;
    const { added, removed } = data;

    const dataToChange = indexExists
      ? this.gapsData[draggableGapsIndex!]
      : this.computedData;

    // If we removed the element from the speech bubble, remove it from the
    // solutions array:
    if (removed) {
      EventBus.$emit('activity-event-solution-del-at-index', order);
    } else {
      if (!added) {
        return;
      }

      // If the speech bubble already contained an element, remove it from the
      // solutions array and update the available responses list:
      if (dataToChange.length > 1) {
        const prevSol = this.isDraggableGaps
          ? dataToChange.pop()
          : dataToChange.shift();

        EventBus.$emit('activity-event-solution-del-at-index', order);
        EventBus.$emit('activity-event-update-draggable-list', prevSol);
      }

      const currSol = dataToChange[0];
      EventBus.$emit('activity-event-solution-add-at-index', order, currSol);
    }
  }

  private reset() {
    this.gapsData = [];
    this.computedData = [];

    this.initDraggableGaps();
  }

  public focusFirstGap() {
    if (this.isTypingGaps) {
      const firstGap = (this.$refs.input1 as HTMLInputElement[])[0];
      firstGap.focus();
    }
  }

  public mounted() {
    EventBus.$on('activity-event-reset-draggable-data', this.reset);
    EventBus.$on('activity-event-instruction-popup-closed', this.focusFirstGap);
  }

  public beforeDestroy() {
    EventBus.$off('activity-event-reset-draggable-data', this.reset);
    EventBus.$off(
      'activity-event-instruction-popup-closed',
      this.focusFirstGap,
    );
    this.disableOuterDragging(false);
  }

  @Watch('models')
  private onGapsTyped(models: string[]) {
    const filteredModels = models.filter(item => !!item);

    const filledLettersCount = filteredModels.length;
    const sentenceParts = this.sentenceParts(this.data.label);

    let lettersTotal = 0;
    sentenceParts.forEach(({ letters }) => {
      lettersTotal += letters;
    });

    const isReadyToBeAdded = filledLettersCount === lettersTotal;

    if (isReadyToBeAdded) {
      const newSentence = sentenceParts
        .map((part, partIdx) => {
          let partValue = part.value;

          for (let i = 0; i < part.letters; i++) {
            partValue = `${partValue}${
              models[this.getGapInputIndex(i, partIdx, this.data.label)]
            }`;
          }

          return partValue;
        })
        .join('');

      const modifiedData = Object.assign(
        { ...this.data },
        { label: newSentence },
      );
      const alreadyAdded = this.addedTypedBubbles.find(
        item => item.id === modifiedData.id,
      );

      if (!alreadyAdded) {
        EventBus.$emit(`activity-event-solution-add`, {
          type: this.type,
          data: modifiedData,
        });

        this.addedTypedBubbles.push(modifiedData);
      }

      return;
    }

    EventBus.$emit(`activity-event-solution-del`, {
      type: this.type,
      data: this.addedTypedBubbles[0],
    });

    this.addedTypedBubbles = [];
  }

  private initDraggableGaps() {
    this.draggableParts.forEach(() => {
      this.gapsData.push([]);
    });
  }

  public created() {
    this.initDraggableGaps();
  }
}
</script>
