var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('RoundedFooter',{staticClass:"bottom-toolbar",attrs:{"tag":_vm.windowWidth > 765 ? 'aside' : 'footer'}},[_c('FetchFriend',{staticClass:"bottom-toolbar__fetch"},[_c('span',{staticClass:"bottom-toolbar__item-text"},[_vm._v(" "+_vm._s(_vm.$t('tooltip.toolbar.findFriends'))+" ")])]),(_vm.isAppFeatureEnabled('STUDDY_BUDDY'))?_c('StudyBuddyTrigger',{staticClass:"bottom-toolbar__item bottom-toolbar__item--info"},[_c('span',{staticClass:"bottom-toolbar__item-text"},[_vm._v(" "+_vm._s(_vm.$t('tooltip.toolbar.information'))+" ")])]):_vm._e(),(_vm.isAppFeatureEnabled('SCREEN_GLOBE'))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.$t(
        `tooltip.toolbar.${_vm.AppToolbarsIconsNames.Globe}`,
      ).toString(),
    }),expression:"{\n      content: $t(\n        `tooltip.toolbar.${AppToolbarsIconsNames.Globe}`,\n      ).toString(),\n    }"}],staticClass:"bottom-toolbar__item bottom-toolbar__item--globe",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleSelection(_vm.AppToolbarsIconsNames.Globe)}}},[_c('span',{staticClass:"bottom-toolbar__item-text"},[_vm._v(" "+_vm._s(_vm.$t('globe._'))+" ")]),_c('img',{attrs:{"src":"/images/icons/globe.png","alt":"Globe Icon"}})]):_vm._e(),(_vm.isAppFeatureEnabled('SCREEN_TRAINING'))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.$t(
        `tooltip.toolbar.${_vm.AppToolbarsIconsNames.Activities}`,
      ).toString(),
    }),expression:"{\n      content: $t(\n        `tooltip.toolbar.${AppToolbarsIconsNames.Activities}`,\n      ).toString(),\n    }"}],staticClass:"bottom-toolbar__item bottom-toolbar__item--domino",attrs:{"disabled":!_vm.hasPremiumAccount,"type":"button"},on:{"click":function($event){return _vm.handleSelection(_vm.AppToolbarsIconsNames.Activities)}}},[_c('span',{staticClass:"bottom-toolbar__item-text"},[_vm._v(" "+_vm._s(_vm.$t('tooltip.toolbar.dominoAcademy'))+" ")]),_c('img',{attrs:{"src":"/images/icons/domino.png","alt":"Domino Icon"}})]):_vm._e(),(_vm.isAppFeatureEnabled('SCREEN_PROFILE'))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.$t(`tooltip.toolbar.${_vm.AppToolbarsIconsNames.Home}`).toString(),
    }),expression:"{\n      content: $t(`tooltip.toolbar.${AppToolbarsIconsNames.Home}`).toString(),\n    }"}],staticClass:"bottom-toolbar__item bottom-toolbar__item--home",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleSelection(_vm.AppToolbarsIconsNames.Home)}}},[_c('span',{staticClass:"bottom-toolbar__item-text"},[_vm._v(" "+_vm._s(_vm.$t('tooltip.toolbar.home'))+" ")]),_c('img',{attrs:{"src":"/images/icons/home.png","alt":"Home Icon"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }