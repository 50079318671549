<template>
  <button type="button" @click="onClick">
    <!-- eslint-disable-next-line -->
    <span class="burger-item" v-for="item in 3" />
  </button>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

import sounds from '@/utils/helpers/sounds';

@Component
export default class AppBurgerButton extends Vue {
  public onClick() {
    sounds.pop.play();

    this.$emit('select');
  }
}
</script>

<style lang="scss" scoped>
.burger-item {
  display: block;

  width: 40px;
  height: 10px;

  background: $white100;
  box-shadow: 0px 2px 0px $blue100;
  border-radius: 3px;

  margin-bottom: 5px;

  &:last-child {
    margin: 0;
  }
}
</style>
