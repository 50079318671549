<template>
  <button
    type="button"
    class="asset-hotspot"
    :style="{ ...styles, backgroundColor: bgColor }"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.asset-hotspot {
  position: absolute;
  cursor: pointer;
}
</style>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator';

import PositionedComponents from '../mixins/PositionedComponents.mixin';

@Component
export default class ActivityHotspot extends Mixins(PositionedComponents) {
  @Prop({ default: false })
  private disablePositioning!: boolean;

  @Prop({ default: 'transparent' })
  private bgColor!: string;

  private get styles() {
    return this.disablePositioning ? null : this.itemStyles;
  }
}
</script>
