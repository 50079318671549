import { Component, Prop, Mixins } from 'vue-property-decorator';
import {
  IActivityComponentDataElement,
  IActivityComponent,
  IActivitySolution,
} from '@/models/interfaces/activities';

import EventBus from '@/utils/helpers/EventBus';

import ComponentsWithRelations from './ComponentsWithRelations';
import { append, countBy, prop, without } from 'ramda';

@Component
export default class ActivityButtons extends Mixins(ComponentsWithRelations) {
  @Prop({ required: true })
  protected meta!: IActivityComponent<'adjustableImageButtons'>['meta'];

  @Prop({ required: true })
  protected data!: IActivityComponent<'adjustableImageButtons'>['data'];

  @Prop({ required: true })
  protected solution!: IActivitySolution[];

  @Prop({ required: true, type: String })
  protected type!: string;

  protected selectedButtons: string[] = [];

  protected get isSingleChoice() {
    return (
      countBy(prop('type'), this.solution)[this.type] === 1 ||
      (this.shouldPerformGroupingLogic && this.solution.length === 2)
    );
  }

  protected get shouldPerformGroupingLogic() {
    return this.data.length && this.data[0].label === 'group';
  }

  protected handleClick(value: IActivityComponentDataElement) {
    // TODO: fetch this from `meta` once implemented in the API:
    const singleSelect = this.isSingleChoice;
    const isAlreadySelected = this.selectedButtons.includes(value.id);
    const hasAnySelectedButtons = !!this.selectedButtons.length;

    if (singleSelect) {
      if (hasAnySelectedButtons) {
        EventBus.$emit('activity-event-solution-del', {
          type: this.type,
          data: this.data.find(i => i.id === this.selectedButtons[0]),
        });
      }

      if (isAlreadySelected) {
        this.selectedButtons = [];
        return;
      }

      this.handleRelations(value);
      this.selectedButtons = [value.id];

      // Normalize solution from observable to plain object:
      EventBus.$emit('activity-event-solution-add', {
        type: this.type,
        data: { ...value },
      });

      return;
    }

    if (isAlreadySelected) {
      if (value.label === 'singleSelection') {
        this.handleRelations(value);
        return;
      }

      this.selectedButtons = without([value.id], this.selectedButtons);

      // Normalize solution from observable to plain object:
      EventBus.$emit('activity-event-solution-del', {
        type: this.type,
        data: { ...value },
      });

      return;
    }

    this.handleRelations(value);

    if (this.shouldPerformGroupingLogic) {
      const currentGroup = this.data[0].index;

      const maxSelectCountForGroup = this.solution.filter(
        item =>
          (item.data as IActivityComponentDataElement).index === currentGroup,
      ).length;

      if (this.selectedButtons.length >= maxSelectCountForGroup) {
        this.selectedButtons.shift();
        this.selectedButtons = append(value.id, this.selectedButtons);

        EventBus.$emit(
          'activity-event-solution-bulk-update',
          this.selectedButtons.map(button =>
            this.data.find(item => item.id === button),
          ),
        );

        return;
      }

      this.selectedButtons = append(value.id, this.selectedButtons);

      EventBus.$emit('activity-event-solution-add', {
        type: this.type,
        data: { ...value },
      });

      return;
    }

    this.selectedButtons = append(value.id, this.selectedButtons);

    // Normalize solution from observable to plain object:
    EventBus.$emit('activity-event-solution-add', {
      type: this.type,
      data: { ...value },
    });
  }
}
