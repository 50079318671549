var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{staticClass:"activity",attrs:{"color":'#ffffff',"image":null}},[_c('PageTopbar',{attrs:{"show-back-button":false}},[_c('ActivityTopbar',{attrs:{"selected-activity-language":_vm.selectedActivityLanguage,"disable-replay":_vm.disableReplayQuestionAudio,"is-activity-test":_vm.isActivityTest,"user-languages":_vm.userLanguages.data},on:{"activity-event-language-change":_vm.onLangChange}})],1),(_vm.currentActivity && _vm.areRandomisedQuestionsSet)?[(
        _vm.isActivityProgressShown &&
          !_vm.showActivityConfirmationVideo &&
          !_vm.showActivityConfirmationImage
      )?_c('ActivityProgress',{class:{
        'activity-progress--full': _vm.isConfirmButtonMissing,
      },attrs:{"percentage":_vm.activityProgress,"is-valid":_vm.isActivityProgressValid}}):_vm._e(),_c('SideTile',{staticClass:"side-tile--left"},[_c('img',{staticClass:"side-tile__img",attrs:{"src":"/images/activities/rewards-general.png","alt":"Domino Academy"}})]),_c('SideTile',{staticClass:"side-tile--right"},[_c('img',{staticClass:"side-tile__img side-tile__img--domino",attrs:{"src":"/images/activities/domino.png","alt":"Domino Academy"}}),_c('LingoInfo',{attrs:{"lingos":_vm.currentActivity.meta.lingos.toString()}})],1),_c('section',{key:_vm.currentQuestion,staticClass:"activity__container",style:({
        backgroundImage: _vm.getComputedBackgroundStyles,
      })},[_c('ActivityConfirmationImage',{key:String(_vm.showActivityConfirmationImage),attrs:{"src":_vm.isOnline ? _vm.answerImage : _vm.findPreloadedAsset('image', _vm.answerImage),"is-visible":_vm.showActivityConfirmationImage}},[(_vm.speechBubblesConfirmationData.length)?_c('activity_speechBubble',{tag:"component",style:({
            zIndex: 102,
            position: 'relative',
          }),attrs:{"data":_vm.speechBubblesConfirmationData,"type":"speechBubbles"}}):_vm._e()],1),_c('ActivityConfirmationVideo',{attrs:{"src":_vm.isOnline ? _vm.answerVideo : _vm.findPreloadedAsset('video', _vm.answerVideo),"is-visible":_vm.showActivityConfirmationVideo}}),(_vm.noTriggerAssetIsDisplayed)?_vm._l((_vm.components),function(child,index){return _c(`activity_${child.type}`,{key:`${_vm.currentQuestion}_${child.type}_${index}`,tag:"component",attrs:{"type":child.type,"meta":child.meta,"data":child.data,"root-meta":_vm.currentActivity.meta,"relations":_vm.question.relations.data,"solution":_vm.question.solution,"triggers":_vm.question.triggers,"is-activity-valid":_vm.solutionIsValid,"assets-overlap":_vm.questionAssetsOverlap}})}):_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }